import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, MenuItem} from '@material-ui/core'

import { PARAM_FORM_USER_FIELDS, PARAM_FORM_TECHNICAL_USER_FIELDS, PARAM_FORM_NICKNAME_FIELD, PARAM_REGISTRATION_SERVICE } from '../../../m2m-cloud-api/Api/UserService/Models/User'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import { isAlreadyExistsError } from '../../../m2m-cloud-api/Api/Helper'
import { registrationAppSetting } from '../../../index'

const EMAIL_FIELD_ID = 'email'
const EMAIL_FIELD = {
    id: EMAIL_FIELD_ID,
    title: 'email', 
    type: 'String',
    validators: ['required', 'isEmail'],
    errorMessages: ['this_field_is_required','email_is_not_valid']
}

class CreateNewUserDialog extends React.Component {
    
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            params: {},
            userType: 0,
            loading: false,
            errorMessage: null
        }
    }

    getFormFields() {
        return [PARAM_FORM_NICKNAME_FIELD, EMAIL_FIELD, ...PARAM_FORM_USER_FIELDS]
    }

    handleSubmit() {
        const { email, params, userType} = this.state
        const { api, usersGroup, adminsGroup , t } = this.props

        this.setState({loading: true, errorMessage: null})

        const groupId = userType === 1 ? adminsGroup.getId() : usersGroup.getId()

        if (registrationAppSetting) params[PARAM_REGISTRATION_SERVICE] = registrationAppSetting

        api.userService.createUser(email, params, groupId)
        .then(result => {
            this.props.onSuccess(result.userId, userType)
        })
        .catch( error => {
            let message = mapErrorMessage(error)
            if (isAlreadyExistsError(error)) {
                message = t('error_email_adress_already_exists')
            }

            this.setState({
                errorMessage: message
            })
        })
        .finally(() => this.setState({loading: false}))
    }

    render() {
        const { email, params, loading, errorMessage, userType} = this.state
        const { t, classes } = this.props

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    { loading && <LinearProgress className={classes.progress} /> }
                    <DialogTitle id="form-dialog-title">{t('create_new_user')}</DialogTitle>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit.bind(this)}
                        onError={errors => console.log("form error:", errors)}>
                        <DialogContent>
                            <TextValidator
                                select
                                value={userType}
                                onChange={(event) => {
                                    this.setState({ userType: event.target.value })
                                }}
                                margin="dense"
                                label={t('user_type')}
                                fullWidth>
                                    <MenuItem key={0} value={0}>
                                        {t('default_user')}
                                    </MenuItem>
                                    <MenuItem key={1} value={1}>
                                        {t('admin')}
                                    </MenuItem>
                            </TextValidator>
                            {this.getFormFields().map( field => {
                                const value = field.id === EMAIL_FIELD_ID ? email : params[field.id]
                                return <TextValidator
                                    className={classes.field}
                                    key={field.id}
                                    value={value}
                                    onChange={(event) => {
                                        if (field.id === EMAIL_FIELD_ID) {
                                            this.setState({email: event.target.value, errorMessage: null})
                                        } else {
                                            let _params = params
                                            _params[field.id] = event.target.value
                                            this.setState({params: _params, errorMessage: null})
                                        }
                                    }}
                                    margin="dense"
                                    id={field.id}
                                    multiline={field.type === 'Text' ? true : false}
                                    label={ t(field.title) }
                                    validators={field.validators}
                                    errorMessages={field.errorMessages.map(message => t(message))}
                                    fullWidth
                                />
                            })}
                            { errorMessage && <DialogContentText className={classes.errorText}>
                                { errorMessage }
                            </DialogContentText> }
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button disabled={loading} type="submit" color="primary">
                                {t('create')}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        )
    }
}

CreateNewUserDialog.propTypes = {
    api: PropTypes.object.isRequired,
    usersGroup: PropTypes.object.isRequired,
    adminsGroup: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}


const styles = theme => ({
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20
    },
    field: {
        marginTop: 10,
        marginBottom: 10
    }
})


export default withTranslation()(withStyles(styles)(CreateNewUserDialog))