import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withDarkTheme } from '../../../theme'
import Button from '@material-ui/core/Button'
import Logo from '../../../Libs/Components/Logo'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Typography, Grid, InputAdornment, IconButton } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import Footer from '../../../Libs/Components/Footer'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'


class PasswordReset extends Component {

    constructor(props) {
        super(props)

        let email = ''
        if (this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.email) {
            email = this.props.history.location.state.email
        }
        if (this.props.match.params.email) {
            email = this.props.match.params.email
        }

        this.state = {
            loading: false,
            email: email,
            password: '',
            passwordConfirm: '',
            code: this.props.match.params.code || '',
            showSusccessAlert: false,
            errorMessage: null,
            showPassword: false
        }
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        })
    }

    componentWillUnmount() {
        if (ValidatorForm && ValidatorForm.removeValidationRule) {
            ValidatorForm.removeValidationRule('isPasswordMatch')
        }
    }

    handleKeyPress(event) {
        if (event.charCode === 13) { // enter key
            event.preventDefault()
            this.handleSubmit()
        }
    }

    handleSubmit() {
        this.setState({ loading: true })
        const { code, email, password } = this.state
        this.props.context.userService.resetPasswordByCode(Number(code), email, password)
            .then(result => {
                this.setState({ showSusccessAlert: true })
            })
            .catch(error => {
                console.log('error', error)
                let message = mapErrorMessage(error)
                this.setState({ errorMessage: message, loading: false })
            })
    }

    render() {
        const { email, code, password, passwordConfirm, loading, errorMessage, showPassword } = this.state
        const { t, classes } = this.props
        return (
            <Grid container className={classes.container}>
                <Grid item sm={6} xs={12} className={classes.leftColumn}>
                    <Logo />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.rightColumn}>

                    <div className={classes.contentContainer}>
                        <Typography variant="h5" color="secondary" className={classes.title}>
                            {this.props.t('password_reset_title')}
                        </Typography>
                        <Typography style={{ textAlign: 'center', marginTop: 30 }} variant="body1" color="secondary" gutterBottom>
                            {this.props.t('password_reset_description')}
                        </Typography>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit.bind(this)}
                            onError={errors => console.log("form error:", errors)}>

                            <TextValidator
                                className={classes.field}
                                key={'email'}
                                value={email}
                                onChange={(event) => {
                                    this.setState({ email: event.target.value, error: null })
                                }}
                                margin="dense"
                                id={'email'}
                                label={t('email')}
                                validators={['required', 'isEmail']}
                                errorMessages={[t('this_field_is_required'), t('email_is_not_valid')]}
                                fullWidth />

                            <TextValidator
                                className={classes.field}
                                value={code}
                                onChange={(event) => {
                                    this.setState({ code: event.target.value, error: null })
                                }}
                                margin="dense"
                                label={t('code')}
                                validators={['required', 'isNumber']}
                                errorMessages={[t('this_field_is_required'), t('this_field_must_be_a_number')]}
                                fullWidth />

                            <TextValidator
                                className={classes.field}
                                value={password}
                                onChange={(event) => {
                                    this.setState({ password: event.target.value, error: null })
                                }}
                                margin="dense"
                                type={showPassword ? 'text' : 'password'}
                                label={t('password')}
                                validators={['required', 'matchRegexp:((?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%!]).{8,40})']}
                                errorMessages={[t('this_field_is_required'), t('password_complexity_error')]}
                                fullWidth 
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                size={'small'}
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({ showPassword: !showPassword })}
                                            >
                                                {showPassword ? <VisibilityOff /> :  <Visibility /> }
                                            </IconButton>
                                        </InputAdornment>
                                }} />

                            <TextValidator
                                className={classes.field}
                                value={passwordConfirm}
                                onChange={(event) => {
                                    this.setState({ passwordConfirm: event.target.value, error: null })
                                }}
                                margin="dense"
                                type={showPassword ? 'text' : 'password'}
                                label={t('password_confirm')}
                                validators={['isPasswordMatch']}
                                errorMessages={[t('passwords_do_not_match')]}
                                fullWidth InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                size={'small'}
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({ showPassword: !showPassword })}
                                            >
                                                {showPassword ? <VisibilityOff /> :  <Visibility /> }
                                            </IconButton>
                                        </InputAdornment>
                                }} />

                            {errorMessage && <div className={classes.errorText}>
                                <Typography variant="body1" color="error" gutterBottom>
                                    {errorMessage}
                                </Typography>
                            </div>}
                            <div className={classes.buttonContainer}>
                                <Button onClick={() => { }} disabled={loading} type="submit" color="primary" variant="contained" className={classes.submitButton}>
                                    {t('reset')}
                                </Button>
                                <Typography className={classes.backButton} onClick={() => this.props.history.push(`/auth${window.location.search || ''}`)} color="secondary" variant="body1" gutterBottom>
                                    {this.props.t("cancel")}
                                </Typography>
                            </div>
                        </ValidatorForm>
                        <AlertDialog
                            open={this.state.showSusccessAlert}
                            title={this.props.t('password_reset_successfully_message_title')}
                            message={this.props.t('password_reset_successfully_message_description')}
                            onSubmit={() => this.props.history.push(`/auth${window.location.search || ''}`)} />
                    </div>
                </Grid>
                <Footer />
            </Grid>
        )
    }
}

const styles = theme => ({
    contentContainer: {
        maxWidth: 260,
        width: '100%'
    },
    container: {
        height: '100%',
        alignItems: 'center',
    },
    leftColumn: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        }
    },
    rightColumn: {
        backgroundColor: 'black',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            paddingTop: theme.spacing(5)
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        marginBottom: theme.spacing(4)
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    backButton: {
        marginTop: 10,
        cursor: 'pointer'
    },
    submitButton: {
        flex: 1,
        marginTop: 10
    }
})

export default withTranslation()(withDarkTheme(withStyles(styles)(PasswordReset)))