import React from 'react'
import Button from '@material-ui/core/Button'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { mapErrorMessage } from '../Utilities/ApiHelper'
import { InputAdornment, IconButton } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { ERROR } from '../../m2m-cloud-api'

class ChangePasswordDialog extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            errorMessage: null,
            password: '',
            oldPassword: '',
            passwordConfirm: '',
            showPassword: false
        }
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        })
    }

    componentWillUnmount() {
        if (ValidatorForm && ValidatorForm.removeValidationRule) {
            ValidatorForm.removeValidationRule('isPasswordMatch')
        }
    }

    handleSubmit() {
        this.setState({ loading: true, errorMessage: null })

        const { password, oldPassword } = this.state
        const { t, userService } = this.props

        const user = userService.getActiveUser()
        userService.changePassword(user.getUserId(), password, oldPassword)
            .then(result => {
                this.setState({ password: '', passwordConfirm: '', oldPassword: '' })
                this.props.onSuccess()
            })
            .catch(error => {
                const errorType = error && error.getErrorType && error.getErrorType()
                if (errorType === ERROR.UNAUTHORIZED) {
                    this.setState({ errorMessage: t('current_password_incorrect') })
                } else {
                    this.setState({ errorMessage: t(mapErrorMessage(error)) })
                }
            })
            .finally(() => this.setState({ loading: false }))

    }

    render() {
        const { password, passwordConfirm, loading, errorMessage, showPassword, oldPassword } = this.state
        const { t, classes } = this.props

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('change_password')}</DialogTitle>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit.bind(this)}
                        onError={errors => console.log("form error:", errors)}>
                        <DialogContent>

                            <TextValidator
                                className={classes.field}
                                value={oldPassword}
                                onChange={(event) => {
                                    this.setState({ oldPassword: event.target.value, errorMessage: null })
                                }}
                                margin="dense"
                                type={showPassword ? 'text' : 'password'}
                                label={t('current_password_title')}
                                validators={['required']}
                                errorMessages={[t('this_field_is_required')]}
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                size={'small'}
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({ showPassword: !showPassword })}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />

                            <TextValidator
                                className={classes.field}
                                value={password}
                                onChange={(event) => {
                                    this.setState({ password: event.target.value, errorMessage: null })
                                }}
                                margin="dense"
                                type={showPassword ? 'text' : 'password'}
                                label={t('password')}
                                validators={['required', 'matchRegexp:((?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%!]).{8,40})']}
                                errorMessages={[t('this_field_is_required'), t('password_complexity_error')]}
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                size={'small'}
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({ showPassword: !showPassword })}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />

                            <TextValidator
                                className={classes.field}
                                value={passwordConfirm}
                                onChange={(event) => {
                                    this.setState({ passwordConfirm: event.target.value, errorMessage: null })
                                }}
                                margin="dense"
                                type={showPassword ? 'text' : 'password'}
                                label={t('password_confirm')}
                                validators={['isPasswordMatch']}
                                errorMessages={[t('passwords_do_not_match')]}
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                size={'small'}
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({ showPassword: !showPassword })}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }} />

                            {errorMessage && <DialogContentText className={classes.errorText}>
                                {errorMessage}
                            </DialogContentText>}
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button disabled={loading} onClick={() => this.refs.form.submit()} type="submit" color="primary">
                                {t('save')}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        )
    }
}


ChangePasswordDialog.propTypes = {
    userService: PropTypes.any.isRequired,
    open: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}


const styles = theme => ({
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20
    },
    field: {
        marginTop: 10,
        marginBottom: 10
    }
})


export default withTranslation()(withStyles(styles)(ChangePasswordDialog))