/* eslint no-undef: "off"*/

/**
 * m2m api
 * documentation: https://dev.mbsn.io/doc/
 */

require('es6-promise').polyfill();
if (typeof fetch === 'undefined') {
  require('isomorphic-fetch');
}
const { ApiError, getForbiddenError, getUnauthorizedError, getServerError, getInternalServerError, getUknownOrExpiredError } = require('./Errors');

const packageJSON = require('../package.json');
console.log('%c m2m-core-api Version: ' + packageJSON.version + ' ', 'background: #E63A1B; color: #fff; font-size: 12px');

/**
 * API for SFS M2M Application
 *
 * @param {String} domain
 * @param {String} logger
 */
export default class Api {
  /**
   * @param {String} domain
   * @param {Storage} storage
   * @param {Boolean} debugEnabled
   */
  constructor(domain, storage = null, debugEnabled = false) {
    this.domain = domain;
    this.storage = storage;
    this.debugEnabled = debugEnabled;
    this.token = null;
    this.tokenValidityHandler = null;
    this.unauthorizedErrorHandler = null;
    this.tokenExpireTimer = null;
  }

  /**
   * set domain
   * @param {String} domain
   */
  setDomain(domain) {
    this.domain = domain;
  }

  /**
   * Set Api Bearer Authorization Token
   * @param {String} token
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set token validity handler
   * @param {func<Promise>} handler
   */
  setTokenValidityHandler(handler) {
    this.tokenValidityHandler = handler;
    if (this.tokenExpireTimer) {
      clearInterval(this.tokenExpireTimer);
      this.tokenExpireTimer = null;
    }
    if (handler) {
      this.tokenExpireTimer = setInterval(async () => {
        const result = await this.tokenValidityHandler();
        if (result === true) {
          console.log('token successfully refreshed.');
        } else if (result === false) {
          console.log('token refresh error.');
        }
      }, 10 * 1000);
    }
  }

  /**
   * Set unauthorized error handler
   * @param {func<Promise>} handler
   */
  setUnauthorizedErrorHandler(handler) {
    this.unauthorizedErrorHandler = handler;
  }

  /**
   * Check token validity
   * @returns {Promise}
   */
  checkTokenValidity() {
    if (this.tokenValidityHandler) {
      return this.tokenValidityHandler;
    }
    return Promise.resolve();
  }

  /**
   * Make Api request
   * @param {String} method e.g. GET
   * @param {String} url
   * @param {Object<any>} body
   * @param {Object<{key: value}>} headers
   * @param {Boolean} validateToken
   * @param {String} responseType json | text | blob
   */
  request(method, url, body, headers, validateToken = true, responseType = 'json', contentType = 'application/json') {
    if (this.debugEnabled) {
      console.log(`request ${method} ${url}`, body);
    }

    const fetchPromise = () =>
      new Promise((resolve, reject) => {
        let authorization = {};
        if (this.token) {
          authorization['Authorization'] = 'Bearer ' + this.token;
        }
        if (headers && headers['Authorization'] === null) {
          authorization = {};
          delete headers.Authorization;
        }

        return fetch(url, {
          credentials: 'include',
          method: method,
          headers: {
            'Content-Type': contentType,
            'api-version': packageJSON.version,
            ...authorization,
            ...(headers || {}),
          },
          body: contentType === 'application/json' ? (body ? (typeof body === 'string' ? body : JSON.stringify(body)) : null) : body,
        })
          .then(response => {
            if (!response.ok) {
              if (response.status === 403) {
                response
                  .json()
                  .then(json => {
                    if (json.detail === 'TOKEN_UNKNOWN_OR_EXPIRED') {
                      return reject(getUknownOrExpiredError());
                    }
                    reject(getForbiddenError());
                  })
                  .catch(error => {
                    reject(getInternalServerError());
                  });
                return;
              }
              if (response.status === 401) {
                reject(getUnauthorizedError());
                if (this.unauthorizedErrorHandler) {
                  this.unauthorizedErrorHandler();
                }
                return;
              }

              response
                .json()
                .then(error => {
                  reject(new ApiError(error));
                })
                .catch(error => {
                  reject(getInternalServerError());
                });
            } else {
              switch (responseType) {
                case 'text':
                  resolve(response.text());
                  break;
                case 'blob':
                  resolve(response.blob());
                  break;
                default:
                  resolve(response.json());
                  break;
              }
            }
          })
          .catch(error => {
            console.log('api request error', error);
            reject(getServerError());
          });
      });

    if (validateToken && this.tokenValidityHandler) {
      return this.tokenValidityHandler()
        .then(() => {
          return fetchPromise();
        })
        .then(response => {
          if (this.debugEnabled) {
            console.log(`response json ${method} ${url}`, response);
          }
          return response;
        });
    }
    return fetchPromise();
  }

  /**
   * Build request url
   * @param {String} path
   * @param {Object} replacements
   * @param {Object} queryParameters
   *
   * @returns String request url
   */
  buildRequestURL(path, replacements, queryParameters) {
    if (replacements) {
      Object.keys(replacements).map(key => {
        path = path.replace(key, replacements[key]);
      });
    }

    let queryParameterKeys = queryParameters && Object.keys(queryParameters);
    const queryParametersString = queryParameterKeys && queryParameterKeys.length > 0 ? '?' + queryParameterKeys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&') : '';
    return this.domain + path + queryParametersString;
  }
}
