import { Route, Redirect } from 'react-router-dom'
import React, { Fragment, useContext } from 'react'
import { PageContext } from './Context/PageProvider'
import { getBasePath } from './App'
import WhatsNewModal from '../MessageLog/Components/WhatsNewModal'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const context = useContext(PageContext)
    return (
        <Route {...rest} render={(props) => (context.api.userService.getActiveUser() ? <Fragment><Component {...rest} {...props}/><WhatsNewModal/></Fragment> : <Redirect to={`/auth?redirect=${getBasePath()}`} />)} />
    )
}

export default ProtectedRoute