import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import ReactSVG from 'react-svg'
import clsx from 'clsx'

const SortButtons = ({ customSortEnabled, onCustomSortChange, className, ...props }) => {
    const classes = useStyles()

    return (
        <Grid {...props} container alignItems="center" justify="flex-end" className={clsx(classes.sortGroupViewContainer, className)}>
            <IconButton
                edge="end"
                size="small"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                className={clsx(classes.sortIconButton, !customSortEnabled && classes.activeSortType)}
                onClick={() => customSortEnabled && onCustomSortChange(false)}>
                <ReactSVG className={classes.svgIcon} src={"/assets/light/sorting_ascending.svg"} />
            </IconButton>
            <IconButton
                edge="end"
                size="small"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                className={clsx(classes.sortIconButton, customSortEnabled && classes.activeSortType)}
                onClick={() => !customSortEnabled && onCustomSortChange(true)}>
                <ReactSVG className={classes.svgIcon} src={"/assets/light/sorting_manual.svg"} />
            </IconButton>
        </Grid>
    )
}

SortButtons.propTypes = {
    group: PropTypes.array,
}

const useStyles = makeStyles(theme => ({
    sortIconButton: {
        padding: 2,
        borderRadius: 0,
        backgroundColor: theme.palette.common.black,
        marginRight: '0 !important'
    },
    activeSortType: {
        backgroundColor: 'rgba(255,255,255,0.9)',
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: '#ffffff',
        }
    },
    sortGroupViewContainer: {
        width: 'auto',
        paddingLeft: 10,
        paddingRight: 10,
    },
    svgIcon: { 
        width: 24, 
        height: 24 
    }
}))

export default SortButtons

