import EventEmitter from 'events';
import { getForbiddenError, getWebSocketError } from './Errors';

const tokenMessage = token => ({ type: 'set_token', token });
const keepAliveMessage = () => ({ type: 'ping' });

export default class Websocket extends EventEmitter {
  constructor(socketUrl, token) {
    super();
    this.socketUrl = socketUrl;
    this.token = token;
    this.socket = null;
    this.cleanup = false;

    // keep alive timer
    this.keepAliveTimer = null;
    this.removeKeepAliveTimer = () => {
      if (this.keepAliveTimer) {
        clearInterval(this.keepAliveTimer);
        this.keepAliveTimer = null;
      }
    };
    this.setKeepAliveTimer = () => {
      this.keepAliveTimer = setInterval(() => {
        this.sendMessage(keepAliveMessage());
      }, 45000);
    };

    this.init = () => {
      this.socket = new WebSocket(this.socketUrl);

      this.socket.onopen = event => {
        this.sendMessage(tokenMessage(this.token));
        this.setKeepAliveTimer();
        this.onReady();
      };
      this.socket.onmessage = event => {
        try {
          const data = JSON.parse(event.data);
          this.onMessage(data);
        } catch (error) {
          this.onError(error);
        }
      };
      this.socket.onclose = event => {
        this.removeKeepAliveTimer();
      };
      this.socket.onerror = error => {
        console.log('Websocket onerror: ', error);
        this.onError(getWebSocketError());
      };
    };

    this.init();
  }

  /**
   * Send message
   * @param {Object} message
   */
  sendMessage(message) {
    //console.log('sendMessage', message, this.socket)
    if (this.socket) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.log('[Websocket] socket not exists.');
      this.onError(getWebSocketError());
    }
  }

  /**
   * on ready connection handler
   */
  onReady() {
    this.emit('ready');
  }

  /**
   * on mesage received handler
   * @param {Object} message
   */
  onMessage(message) {
    if (message?.resp === 'INVALID_TOKEN') {
      this.onError(getForbiddenError());
    } else {
      this.emit('message', message);
    }
  }

  /**
   * on error handler
   * @param {Error} error
   */
  onError(error) {
    this.emit('error', error);
  }

  /**
   * destroy web socket
   */
  destroy() {
    this.removeKeepAliveTimer();
    if (this.socket) {
      this.cleanup = true;
      this.socket.close();
      this.socket = null;
    }
  }
}
