
import React, { Component, Fragment, useEffect, useState, useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { Typography, LinearProgress } from '@material-ui/core'
import DefaultListItem from './DefaultListItem'
import { ListContainer, ItemContainer } from '../../../Libs/Components/ListContainer'
import { Virtuoso } from 'react-virtuoso'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

const getListItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        background: "rgba(255,255,255,.5)"
    }),
})
const DraggableItem = ({ provided, index, snapshot, item, groupOrgId, checked, onClick, onEditClick, onDeleteClick }) => {
    const context = useContext(PageContext)
    const [replacementsTitle, setReplacementsTitle] = useState(null)
    const replacememtsString = item?.template?.replacementIds?.join(',')
    
    useEffect(async () => {
        const titles = await context.fetchReplacementTitles(groupOrgId, item.template.getReplacementIds())
        setReplacementsTitle(titles)
    }, [replacememtsString])

    return (
        <div {...provided.draggableProps} {...provided.dragHandleProps} style={getListItemStyle(snapshot.isDragging, provided.draggableProps.style)} ref={provided.innerRef}>
            <DefaultListItem
                paramsLabel={replacementsTitle}
                index={index}
                entity={item}
                checked={checked}
                onClick={onClick}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
            />
        </div>
    )
}

class SupplierItemsList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedIds: [],
        }
    }

    onClickItem = (entity) => {
        const { selectedIds } = this.state
        if (selectedIds.find(id => id === entity.id)) {
            this.setState({ selectedIds: selectedIds.filter(id => id !== entity.id) }, () => this.props.onSelectionChange(this.state.selectedIds))
        } else {
            this.setState({ selectedIds: [...selectedIds, entity.id] }, () => this.props.onSelectionChange(this.state.selectedIds))
        }
    }

    render() {
        const { t, classes, actions, searchTerm } = this.props
        const { selectedIds } = this.state

        if (!actions) return (<LinearProgress />)
        let filteredItems = actions.filter(entity => entity.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)


        const onDragEnd = (result) => {
            //send to params
            if (!result.destination) {
                return;
            }
            const items = reorder(
                filteredItems,
                result.source.index,
                result.destination.index
            );
            filteredItems = items
        }
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list)
            const [removed] = result.splice(startIndex, 1)
            result.splice(endIndex, 0, removed)
            return result
        }



        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
                <Typography variant="h5" className={classes.listTitle}>{t('offered_services')}</Typography>
                <div style={{ flex: 1 }}>
                    {actions.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body">{t('no_entries_available')}</Typography>}
                    {actions.length > 0 && filteredItems && filteredItems.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">{t('no_match_found')}</Typography>}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="droppable-action-list"
                            mode="virtual"
                            renderClone={(provided, snapshot, rubric) => (
                                <DraggableItem
                                    groupOrgId={this.props.groupOrgId}
                                    checked={selectedIds.find(id => id === filteredItems[rubric.source.index].id) ? true : false}
                                    onClick={this.onClickItem}
                                    onEditClick={this.props.onEdit}
                                    onDeleteClick={this.props.onDelete}
                                    provided={provided}
                                    snapshot={snapshot}
                                    item={filteredItems[rubric.source.index]}
                                    index={rubric.source.index}
                                />
                            )}
                        >
                            {(provided, snapshot) => (
                                <Fragment>
                                    <Virtuoso
                                        components={{
                                            Item: ItemContainer,
                                        }}
                                        style={{ listStyleType: 'none' }}
                                        scrollerRef={provided.innerRef}
                                        totalCount={filteredItems && filteredItems.length || 0}
                                        data={filteredItems}
                                        itemContent={(index, item) =>
                                            <Draggable isDragDisabled={true} key={filteredItems[index].id} draggableId={filteredItems[index].id} index={index}>
                                                {(provided, snapshot) => (
                                                    <DraggableItem
                                                        groupOrgId={this.props.groupOrgId}
                                                        checked={selectedIds.find(id => id === filteredItems[index].id) ? true : false}
                                                        onClick={this.onClickItem}
                                                        onEditClick={this.props.onEdit}
                                                        onDeleteClick={this.props.onDelete}
                                                        provided={provided}
                                                        snapshot={snapshot}
                                                        item={item}
                                                        index={index} />
                                                )}
                                            </Draggable>
                                        }
                                        Footer={() => (
                                            <div style={{ height: 80, width: '100%' }}>
                                            </div>
                                        )}>
                                    </Virtuoso>
                                    {provided.placeholder}
                                </Fragment>
                            )}

                        </Droppable>
                    </DragDropContext>
                </div>
            </div >
        )

    }

}

SupplierItemsList.contextType = PageContext

SupplierItemsList.propTypes = {
    actions: PropTypes.array,
    searchTerm: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    onChangeSortType: PropTypes.func.isRequired,
    orgId: PropTypes.string.isRequired,
    groupOrgId: PropTypes.string.isRequired
}

const styles = theme => ({
    listTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(3),
        color: theme.palette.grey['900'],
        fontWeight: theme.typography.fontWeightMedium
    },

    sortTypeContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
})


export default withTranslation()(withStyles(styles)(SupplierItemsList))