export const COLUMN_TYPE_OPTIONS = [
    {
        label: 'open',
        value: 'open'
    },
    {
        label: 'processing',
        value: 'processing'
    }
]
export const COLUMN_FILTER_OPTIONS = [
    {
        label: 'contains',
        value: 'contains'
    },
    {
        label: 'does_not_contain',
        value: 'not_contains'
    }
]

export const ROWS_FIELD_OPTIONS = [
    {
        label: 'organization',
        value: 'organization'
    },
    {
        label: 'name',
        value: 'name'
    },
    {
        label: 'parameters',
        value: 'parameters'
    },
    {
        label: 'none',
        value: 'none'
    }
]

export const ROWS_BOLD_OPTIONS = [
    {
        label: 'default',
        value: false
    },
    {
        label: 'bold',
        value: true
    },
]