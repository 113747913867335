import Permission from './Permission';
import UpdateMeta from '../../Models/UpdateMeta';

export default class Role {
  /**
   * @param {Object<Uid,String,Array,Object,Object>}
   */
  constructor({ id, name, permissions, creator, lastUpd }) {
    this.id = id;
    this.name = name;
    this.permissions = [];
    if (permissions && permissions.length > 0) {
      permissions.map(permission => {
        this.permissions.push(new Permission(permission));
      });
    }
    this.creator = creator ? new UpdateMeta(creator) : null;
    this.lastUpd = lastUpd ? new UpdateMeta(lastUpd) : null;
  }

  /**
   * Get role id
   * @returns {Uid}
   */
  getId() {
    return this.id;
  }

  /**
   * Get role name
   * @returns {String}
   */
  getName() {
    return this.name;
  }

  /**
   * Get role permissions
   * @returns {Array<Permission>}
   */
  getPermissions() {
    return this.permissions;
  }

  /**
   * Get creator
   * @returns {UpdateMeta}
   */
  getCreator() {
    return this.creator;
  }

  /**
   * Get last updater
   * @returns {UpdateMeta}
   */
  getLastUpdater() {
    return this.lastUpd;
  }
}
