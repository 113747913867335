import {
  ROOT_PARAM_KEY,
  ROOT_PARAM_OLD_FALLBACK_KEY,
  USER_PARAM_KEY_CONFIG,
  USER_PARAM_KEY_LOCATION,
  USER_PARAM_KEY_MODE,
  USER_PARAM_KEY_RECIPIENT,
  USER_PARAM_KEY_SUPPLIER,
  USER_PARAM_KEY_ALERT,
  USER_PARAM_KEY_NOTIFICATION,
  USER_PARAM_KEY_SELECTED_RECIPIENT,
  USER_PARAM_KEY_SELECTED_SUPPLIER,
  USER_PARAM_KEY_SELECTED_THEME,
} from './Contants';

const UNDEFINED_ORG_KEY = 'undefined';

export default class UserParams {
  constructor(userService) {
    this.userService = userService;

    this.getUserParam = key => {
      const user = this.userService && this.userService.getActiveUser();
      const value = user && user.getParam(key);
      if (!value) {
        // check fallback params with 'msg-app' prefix (old prefix)
        const fallbackKey = key.replace(`${ROOT_PARAM_KEY}:`, `${ROOT_PARAM_OLD_FALLBACK_KEY}:`);
        const fallbackValue = user && user.getParam(fallbackKey);
        if (fallbackValue && fallbackValue.trim() !== '') {
          console.warn('return fallback userParams param: ', fallbackKey, 'value: ', fallbackValue);
          return fallbackValue;
        }
      }
      if (!value || value.trim() === '') {
        return null;
      }
      return value;
    };

    this.setUserParam = (key, value) => {
      if (!this.userService) {
        return Promise.reject('invalid userService.');
      }
      const user = this.userService.getActiveUser();
      if (!user) {
        return Promise.reject('no active user.');
      }
      const fallbackKey = key.replace(`${ROOT_PARAM_KEY}:`, `${ROOT_PARAM_OLD_FALLBACK_KEY}:`);
      if (value === null || value === undefined) {
        if (this.getUserParam(fallbackKey)) {
          return this.userService.deleteParam(user.getUserId(), fallbackKey).then(() => this.userService.deleteParam(user.getUserId(), key));
        }
        return this.userService.putParam(user.getUserId(), key, value);
      } else {
        if (this.getUserParam(fallbackKey)) {
          return this.userService.deleteParam(user.getUserId(), fallbackKey).then(() => this.userService.putParam(user.getUserId(), key, value));
        }
        return this.userService.putParam(user.getUserId(), key, value);
      }
    };

    this.getAllSupplierNotificationValues();
  }

  /**
   * get recipient selected org ids
   * @returns {String<Uid>}
   */
  getRecipientOrgIds() {
    const key = this._getRecipientSelectedKey();
    const recipientSelectedOrgs = this.getUserParam(key);
    return recipientSelectedOrgs ? recipientSelectedOrgs.split(',') : null;
  }

  /**
   * set recipient selected orgs
   * @param {Array<Uid>} orgIds
   * @returns {Promise}
   */
  setRecipientOrgIds(orgIds) {
    const key = this._getRecipientSelectedKey();
    return this.setUserParam(key, orgIds ? orgIds.join(',') : null);
  }

  /**
   * get supplier selected org ids
   * @returns {Array<Uid>}
   */
  getSupplierParentOrgIds() {
    const key = this._getSupplierSelectedKey();
    const supplierSelectedOrgs = this.getUserParam(key);
    return supplierSelectedOrgs ? supplierSelectedOrgs.split(',') : null;
  }

  /**
   * set supplier selected orgs
   * @param {Array<Uid>} orgIds
   * @returns {Promise}
   */
  setSupplierParentOrgIds(orgIds) {
    const key = this._getSupplierSelectedKey();
    return this.setUserParam(key, orgIds ? orgIds.join(',') : null);
  }

  /**
   * get recipient location orgId //TODO: Nejdet check if this function is needed
   * @param {Uid | null} clientGroupOrgId
   * @returns {Uid}
   */
  getRecipientLocationOrgId(clientGroupOrgId) {
    return this.getUserParam(this._getRecipientLocationUserParamKey(clientGroupOrgId || UNDEFINED_ORG_KEY));
  }

  /**
   * set recipient location orgId //TODO: Nejdet check if this function is needed
   * @param {Uid} clientGroupOrgId
   * @param {Uid} orgId
   * @returns {Promise}
   */
  setRecipientLocationOrgId(clientGroupOrgId, orgId) {
    const key = this._getRecipientLocationUserParamKey(clientGroupOrgId || UNDEFINED_ORG_KEY);
    return this.setUserParam(key, orgId);
  }

  /**
   * get app mode
   * @returns {String}
   */
  /*getAppMode(clientGroupOrgId) {
        return this.getUserParam(this._getAppModeUserParamKey(clientGroupOrgId || UNDEFINED_ORG_KEY))
    }*/

  getAppMode() {
    return this.getUserParam(this._getAppModeUserParamKey());
  }

  /**
   * set app mode
   * @param {String} mode
   * @returns {Promise}
   */
  /*setAppMode(clientGroupOrgId, mode) {
        const key = this._getAppModeUserParamKey(clientGroupOrgId || UNDEFINED_ORG_KEY)
        return this.setUserParam(key, mode)
    }*/
  setAppMode(mode) {
    const key = this._getAppModeUserParamKey();
    return this.setUserParam(key, mode);
  }

  /**
   * get recipient name from user
   * @param {Uid | Null} clientGroupOrgId
   * @returns {String|Null}
   */
  getRecipientNameFromUser(clientGroupOrgId) {
    return this.getUserParam(this._getRecipientNameUserParamKey(clientGroupOrgId || UNDEFINED_ORG_KEY));
  }

  /**
   * set recipient name to user
   * @param {Uid | Null} clientGroupOrgId
   * @returns {Promise}
   */
  setRecipientNameFromUser(clientGroupOrgId, name) {
    const key = this._getRecipientNameUserParamKey(clientGroupOrgId || UNDEFINED_ORG_KEY);
    return this.setUserParam(key, name);
  }

  /**
   * get supplier name from user
   * @param {Uid | Null} clientGroupOrgId
   * @returns {String | Null}
   */
  getSupplierNameFromUser(clientGroupOrgId) {
    return this.getUserParam(this._getSupplierNameUserParamKey(clientGroupOrgId || UNDEFINED_ORG_KEY));
  }

  /**
   * set supplier name to user
   * @param {Uid | Null} clientGroupOrgId
   * @returns {Promise}
   */
  setSupplierNameFromUser(clientGroupOrgId, name) {
    const key = this._getSupplierNameUserParamKey(clientGroupOrgId || UNDEFINED_ORG_KEY);
    return this.setUserParam(key, name);
  }

  /**
   * get supplier notification enabled from user
   * @param {Uid} orgId
   * @returns {Boolean}
   */
  getSupplierNotificationEnabled(orgId) {
    return this.getUserParam(this._getSupplierNotificationParamKey(orgId)) === '1' ? true : false;
  }

  /**
   * get all
   * @param {Uid} orgId
   * @returns {Boolean}
   */
  getAllSupplierNotificationValues() {
    const user = this.userService && this.userService.getActiveUser();
    const params = user && user.getParams();
    let notificationValues = [];
    params &&
      Object.keys(params).map(key => {
        const value = parseInt(params[key]);
        const pieces = key.split(':');
        if (pieces.length === 3 && pieces[0] === this._getRootAppParamKey() && pieces[2] === USER_PARAM_KEY_NOTIFICATION) {
          notificationValues.push({ orgId: pieces[1], value });
        }
      });
    if (notificationValues.length === 0) {
      params &&
        Object.keys(params).map(key => {
          const value = parseInt(params[key]);
          const pieces = key.split(':');
          if (pieces.length === 3 && pieces[0] === ROOT_PARAM_OLD_FALLBACK_KEY && pieces[2] === USER_PARAM_KEY_NOTIFICATION) {
            console.warn('fallback supplier notification: ', pieces[1], 'value: ', value);
            notificationValues.push({ orgId: pieces[1], value });
          }
        });
    }
    return notificationValues;
  }

  /**
   * set supplier notification enabled from user
   * @param {Uid} orgId
   * @param {Boolean} enabled
   */
  setSupplierNotificationEnabled(orgId, enabled) {
    const key = this._getSupplierNotificationParamKey(orgId);
    return this.setUserParam(key, enabled ? '1' : '0');
  }

  /**
   * get supplier alert enabled from user
   * @param {Uid} orgId
   * @returns {Boolean}
   */
  getSupplierAlertEnabled(orgId) {
    return this.getUserParam(this._getSupplierAlertParamKey(orgId)) === '1' ? true : false;
  }

  /**
   * set supplier alert enabled from user
   * @param {Uid} orgId
   * @param {Boolean} enabled
   */
  setSupplierAlertEnabled(orgId, enabled) {
    const key = this._getSupplierAlertParamKey(orgId);
    return this.setUserParam(key, enabled ? '1' : '0');
  }

  /**
   * get selected theme
   * @returns {String}
   */
  getSelectedTheme() {
    return this.getUserParam(this._getThemeSelectedKey());
  }

  /**
   * set selected theme
   * @param {String} theme
   */
  setSelectedTheme(theme) {
    const key = this._getThemeSelectedKey();
    return this.setUserParam(key, theme);
  }

  /**
   * get root app param key
   * @returns {String}
   */
  _getRootAppParamKey() {
    return ROOT_PARAM_KEY;
  }
  /**
   * get config param key
   * @returns {String}
   */
  _getConfigUserParamKey() {
    return `${this._getRootAppParamKey()}:${USER_PARAM_KEY_CONFIG}`;
  }
  /**
   * get recipient location orgId param key
   * @param {Uid} orgId
   * @returns {String}
   */
  _getRecipientLocationUserParamKey(orgId) {
    return `${this._getRootAppParamKey()}:${orgId}:${USER_PARAM_KEY_LOCATION}`;
  }
  /**
   * get app mode user param key
   * @returns {String}
   */
  _getAppModeUserParamKey() {
    return `${this._getRootAppParamKey()}:${USER_PARAM_KEY_MODE}`;
  }
  /**
   * get recipient name user param key
   * @param {Uid} orgId
   * @returns {String}
   */
  _getRecipientNameUserParamKey(orgId) {
    return `${this._getRootAppParamKey()}:${orgId}:${USER_PARAM_KEY_RECIPIENT}`;
  }
  /**
   * get supplier name user param key
   * @param {Uid} orgId
   * @returns {String}
   */
  _getSupplierNameUserParamKey(orgId) {
    return `${this._getRootAppParamKey()}:${orgId}:${USER_PARAM_KEY_SUPPLIER}`;
  }

  /**
   * get supplier notification param key
   * @param {Uid} orgId
   * @returns {String}
   */
  _getSupplierNotificationParamKey(orgId) {
    return `${this._getRootAppParamKey()}:${orgId}:${USER_PARAM_KEY_NOTIFICATION}`;
  }

  /**
   * get supplier alert param key
   * @param {Uid} orgId
   * @returns {String}
   */
  _getSupplierAlertParamKey(orgId) {
    return `${this._getRootAppParamKey()}:${orgId}:${USER_PARAM_KEY_ALERT}`;
  }

  /**
   * get recipient selected key
   * @returns {String}
   */
  _getRecipientSelectedKey() {
    return `${this._getRootAppParamKey()}:${USER_PARAM_KEY_SELECTED_RECIPIENT}`;
  }

  /**
   * get supplier selected key
   * @returns {String}
   */
  _getSupplierSelectedKey() {
    return `${this._getRootAppParamKey()}:${USER_PARAM_KEY_SELECTED_SUPPLIER}`;
  }

  /**
   * get theme selected key
   * @returns {String}
   */
  _getThemeSelectedKey() {
    return `${this._getRootAppParamKey()}:${USER_PARAM_KEY_SELECTED_THEME}`;
  }
}
