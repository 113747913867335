import React, { Component, Fragment, useState, useEffect, useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { Typography, LinearProgress, Grid, IconButton } from '@material-ui/core'
import { sortItems } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import TriggerListItem from './TriggerListItem'
import { ItemContainer } from '../../../Libs/Components/ListContainer'
import ScheduleSelectionDialog from '../../../Libs/Components/ScheduleSelectionDialog'
import ChangeOrderWeightDialog from './ChangeOrderWeightDialog'
import { Virtuoso } from 'react-virtuoso'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import { DRIVER_TYPE, STA2_TYPE, SETTING_KEY_CRON } from '../../../m2m-cloud-api/Api/DeviceService/Models/Device'
import { SETTINGS_CODE_TOKEN, DEFAULT_EVENT_NAME, CODE_DEVICE_DRIVER_NAME } from '../../../m2m-cloud-api/Api/Drivers/CodeDriver'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { PARAM_APP_MSG_ITEM_SORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import ReactSVG from 'react-svg'
import { SECONDARY_COLOR } from '../../../theme'

const getListItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        background: "rgba(255,255,255,.5)"
    }),
})
const DraggableItem = withTranslation()(({ t, provided, index, snapshot, item, groupOrgId, checked, onClick, onEditClick, onDeleteClick, onResetClick, onChangeOrderpointClick, onScheduleEditClick, customSortEnabled }) => {
    const context = useContext(PageContext)
    const [replacementsTitle, setReplacementsTitle] = useState(null)

    const fetchActionTitle = (triggerGroupItemEntity) => {
        const actionGroup = context.fetchKnownOrg(triggerGroupItemEntity.definition.getOrgId())
        return actionGroup && actionGroup.getName() || null
    }

    const fetchActionBadge = (triggerGroupItemEntity) => {
        const device = triggerGroupItemEntity.device
        if (device.getDriver() === DRIVER_TYPE.STA2) {
            return device.getSubType() && device.getSubType().trim() !== "" ? `${device.getSubType()} - ${device.getPhysicalId()}` : device.getPhysicalId()
        }
        if (device.getDriver() === DRIVER_TYPE.TIMER) {
            return `${t('schedule')} - ${device.settings[SETTING_KEY_CRON]}`
        }
        if (device.getDriver() === DRIVER_TYPE.ESPI) {
            return `ESPI - ${device.getPhysicalId()}`
        }
    }

    const buildTriggerUrl = (device) => {
        if (device.getDriver() === CODE_DEVICE_DRIVER_NAME) {
            const physicalId = device.physicalId
            const code = physicalId.substring(physicalId.indexOf(':') + 1)
            const nameSpace = physicalId.substring(0, physicalId.indexOf(':'))
            const token = device.getSetting(SETTINGS_CODE_TOKEN)
            return context.api.deviceService.codeDriver.buildEventGetUrl(nameSpace, code, DEFAULT_EVENT_NAME, token, '1')
        }
        return null
    }

    const replacememtsString = item?.template?.replacementIds?.join(',')
    useEffect(async () => {
        const titles = await context.fetchReplacementTitles(groupOrgId, item?.template?.getReplacementIds())
        setReplacementsTitle(titles)
    }, [replacememtsString])

    return (
        <div {...provided.draggableProps} {...provided.dragHandleProps} style={getListItemStyle(snapshot.isDragging, provided.draggableProps.style)} ref={provided.innerRef}>
            <TriggerListItem
                paramsLabel={replacementsTitle}
                index={index}
                entity={item}
                checked={checked}
                onClick={onClick}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                onChangeOrderpointClick={onChangeOrderpointClick}
                triggerUrl={buildTriggerUrl(item.device)}
                badgeLabel={fetchActionTitle(item)}
                titleBadgeLabel={fetchActionBadge(item)}
                onResetClick={onResetClick}
                onScheduleEditClick={onScheduleEditClick}
                customSortEnabled={customSortEnabled}
            />
        </div>
    )
})


class RecipientItemsList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedIds: [],
            itemToEdit: null,
            itemToChangeOrderWeight: null,
            errorMessage: null,
            loading: false,
            customSortEnabled: false
        }

        this.handleResponseError = (error) => {
            console.log('handleResponseError', error)
            const { t } = this.props
            this.setState({
                errorMessage: t(mapErrorMessage(error)),
                loading: false
            })
            console.error(t(mapErrorMessage(error)))
        }
    }

    componentDidMount() {
        this.checkAndSetCustomSortEnabled()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.orgId !== this.props.orgId) {
            this.checkAndSetCustomSortEnabled()
            this.setState({selectedIds: []})
        }
    }

    checkAndSetCustomSortEnabled() {
        const org = this.context.childrenOrgs.find(org => this.props.orgId === org.getId()) 
        const configOrgSortParam = org?.getParam(PARAM_APP_MSG_ITEM_SORT)
        this.setState({customSortEnabled: configOrgSortParam !== null && configOrgSortParam !== undefined ? true : false})
    }

    onClickItem = (entity) => {
        const { selectedIds } = this.state
        if (selectedIds.find(id => id === entity.id)) {
            this.setState({ selectedIds: selectedIds.filter(id => id !== entity.id) }, () => this.props.onSelectionChange(this.state.selectedIds))
        } else {
            this.setState({ selectedIds: [...selectedIds, entity.id] }, () => this.props.onSelectionChange(this.state.selectedIds))
        }
    }
    
    onClickChangeOrderpoint = (entity) => {
        this.setState({itemToChangeOrderWeight: entity.device})
    }

    getSelectedIds() {
        return this.state.selectedIds
    }

    async setOrgItemSorting(orgId, itemIds) {
        try {
            const promises = [this.context.api.orgService.putParam(orgId, PARAM_APP_MSG_ITEM_SORT, itemIds.join(','))]
            const org = await this.context.updateAndCacheTopOrg(orgId, promises)
            await this.context.updateCachedOrgParams(orgId, {[PARAM_APP_MSG_ITEM_SORT]: itemIds.join(',')})
        } catch (error) {
            this.handleResponseError(error)
        }
    }
    setOrgItemSortType(customSortEnabled) {
        const { orgId } = this.props

        this.setState({ customSortEnabled }, async () => {
            try {
                const paramsToUpdate = {}
                if (customSortEnabled) {
                    const promises = [this.context.api.orgService.putParam(orgId, PARAM_APP_MSG_ITEM_SORT, '')]
                    paramsToUpdate[PARAM_APP_MSG_ITEM_SORT] = ''
                    await this.context.updateAndCacheTopOrg(orgId, promises)
                } else {
                    const promises = [this.context.api.orgService.deleteParam(orgId, PARAM_APP_MSG_ITEM_SORT)]
                    paramsToUpdate[PARAM_APP_MSG_ITEM_SORT] = undefined
                    await this.context.updateAndCacheTopOrg(orgId, promises)
                }
                await this.context.updateCachedOrgParams(orgId, paramsToUpdate)
            } catch (error) {
                this.handleResponseError(error)
            }
        })
    }

    render() {
        const { t, classes, triggers, searchTerm, orgId, groupOrgId } = this.props
        const { itemToEdit, itemToChangeOrderWeight, loading, errorMessage, customSortEnabled } = this.state

        if (!triggers) return (<LinearProgress />)
        let filteredItems = triggers.filter(entity => entity.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)

        const org = this.context.childrenOrgs.find( org => org.getId() === orgId)
        const deviceSortParam = org && org.getParam( PARAM_APP_MSG_ITEM_SORT)
        filteredItems = sortItems(deviceSortParam, filteredItems)

        const onDragEnd = (result) => {
            //send to params
            if (!result.destination) {
                return
            }
            const items = reorder(
                filteredItems,
                result.source.index,
                result.destination.index
            );
            const itemIds = items.map(item => item.id)
            this.setOrgItemSorting(orgId, itemIds)
            filteredItems = items
        }

        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list)
            const [removed] = result.splice(startIndex, 1)
            result.splice(endIndex, 0, removed)
            return result
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
                <Grid container className={classes.listTitleContainer}>
                    <Typography variant="h5" className={classes.listTitle}>{t('assigned_services')}</Typography>
                    <Grid container alignItems="center" className={classes.sortTypeContainer}>
                        <IconButton
                            edge="end"
                            size="small"
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            style={{ marginRight: 4 }}
                            className={!customSortEnabled && classes.activeSortType}
                            onClick={() => this.setOrgItemSortType(false)}>
                            <ReactSVG
                                style={{ width: 24, height: 24 }}
                                src={"/assets/light/sorting_ascending.svg"}
                            />
                        </IconButton>
                        <IconButton
                            edge="end"
                            size="small"
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            className={customSortEnabled && classes.activeSortType}
                            onClick={() => this.setOrgItemSortType(true)}>
                            <ReactSVG
                                style={{ width: 24, height: 24 }}
                                src={"/assets/light/sorting_manual.svg"}
                            />
                        </IconButton>
                    </Grid>
                </Grid>

                <div style={{ flex: 1 }}>
                    {triggers.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">{t('no_entries_available')}</Typography>}
                    {triggers.length > 0 && filteredItems.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">{t('no_match_found')}</Typography>}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="droppable-triggers-list"
                            mode="virtual"
                            renderClone={(provided, snapshot, rubric) => (
                                <DraggableItem
                                    groupOrgId={groupOrgId}
                                    provided={provided}
                                    snapshot={snapshot}
                                    item={filteredItems[rubric.source.index]}
                                    index={rubric.source.index}
                                    onClick={this.onClickItem}
                                    onDeleteClick={this.props.onDelete}
                                    onResetClick={filteredItems[rubric.source.index].device && filteredItems[rubric.source.index].device.getDriver() === 'sta2' ? (entity) => this.props.onReset(entity) : null}
                                    onScheduleEditClick={filteredItems[rubric.source.index].device && filteredItems[rubric.source.index].device.getDriver() === 'timer' ? (entity) => this.setState({ itemToEdit: entity }) : null}
                                    customSortEnabled={customSortEnabled}
                                />
                            )}
                        >
                            {(provided, snapshot) => (
                                <Fragment>
                                    <Virtuoso
                                        components={{
                                            Item: ItemContainer,
                                        }}
                                        style={{ listStyleType: 'none' }}
                                        scrollerRef={provided.innerRef}
                                        totalCount={filteredItems && filteredItems.length || 0}
                                        data={filteredItems}
                                        itemContent={(index, item) =>
                                            <Draggable key={filteredItems[index].id} draggableId={filteredItems[index].id} index={index} isDragDisabled={!customSortEnabled}>
                                                {(provided, snapshot) => (
                                                    <DraggableItem
                                                        groupOrgId={groupOrgId}
                                                        provided={provided}
                                                        snapshot={snapshot}
                                                        item={item}
                                                        index={index}
                                                        onClick={this.onClickItem}
                                                        onDeleteClick={this.props.onDelete}
                                                        onChangeOrderpointClick={item.device && item.device.getDriver() === 'sta2' && item.device.getSubType() === STA2_TYPE.WEIGHT_LOG ? this.onClickChangeOrderpoint: null }
                                                        onResetClick={item.device && item.device.getDriver() === 'sta2' ? (entity) => this.props.onReset(entity) : null}
                                                        onScheduleEditClick={item.device && item.device.getDriver() === 'timer' ? (entity) => this.setState({ itemToEdit: entity }) : null}
                                                        customSortEnabled={customSortEnabled}/>
                                                )}
                                            </Draggable>
                                        }
                                        Footer={() => (
                                            <div style={{ height: 80, width: '100%' }}>
                                            </div>
                                        )}>
                                    </Virtuoso>
                                    {provided.placeholder}
                                </Fragment>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <ScheduleSelectionDialog
                    open={itemToEdit ? true : false}
                    loading={loading}
                    apiErrorMessage={errorMessage}
                    cronString={itemToEdit && itemToEdit.device && itemToEdit.device.settings[SETTING_KEY_CRON]}
                    onSuccess={async (cronString) => {
                        this.setState({ loading: true, errorMessage: null }, async () => {
                            const promisses = [this.context.api.deviceService.updateSetting(itemToEdit.device.getId(), SETTING_KEY_CRON, cronString)]
                            this.context.api.deviceService.updateDevice(itemToEdit.device.getId(), promisses)
                                .then(result => {
                                    this.setState({ errorMessage: null, loading: false, itemToEdit: null })
                                    this.props.triggerLoadTriggers()
                                })
                                .catch(error => {
                                    console.log('onUnassignDevice, error', error)
                                    this.setState({ errorMessage: mapErrorMessage(error) })
                                })
                        })
                    }}
                    onCancel={async () => {
                        this.setState({ itemToEdit: null })
                    }} />
                { itemToChangeOrderWeight && <ChangeOrderWeightDialog onClose={() => this.setState({itemToChangeOrderWeight: null})} open={true} device={itemToChangeOrderWeight}/> }
            </div>
        )
    }

}

RecipientItemsList.contextType = PageContext

RecipientItemsList.propTypes = {
    orgId: PropTypes.string.isRequired,
    groupOrgId: PropTypes.string.isRequired,
    triggers: PropTypes.array,
    searchTerm: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    triggerLoadTriggers: PropTypes.func.isRequired,
    actionGroupItemEntities: PropTypes.array
}

const styles = theme => ({
    listTitleContainer: {
        width: 'auto',
        margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    },
    listTitle: {
        color: theme.palette.grey['900'],
        fontWeight: theme.typography.fontWeightMedium,
        flex: 1
    },
    activeSortType: {
        backgroundColor: SECONDARY_COLOR,
        color: theme.palette.common.white,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: SECONDARY_COLOR,
        }
    },
    sortTypeContainer: {
        width: 'auto'
    }
})


export default withTranslation()(withStyles(styles)(RecipientItemsList))