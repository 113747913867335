import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    }
}))

function AlertDialog({ open, title, message, onSubmit, onCancel, submitButtonTitle, cancelButtonTitle, buttonVariant, submitButtonColor, cancelButtonColor, loading, errorMessage }) {
    const { t } = useTranslation()
    const classes = useStyles()

    const submitTitle = submitButtonTitle || t('ok')
    const cancelTitle = cancelButtonTitle || t('cancel')

    return (
        <Dialog open={open} fullWidth maxWidth={'xs'} aria-labelledby="alert">
            { loading && <LinearProgress className={classes.progress} />}
            <DialogTitle id="alert-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>{message}</DialogContentText>
                {errorMessage && <DialogContentText style={{ marginTop: 20, whiteSpace: 'pre-line' }} color="error">{errorMessage}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                {onCancel && <Button disabled={loading} onClick={onCancel} color={cancelButtonColor || "default"} variant={buttonVariant}>
                    {cancelTitle}
                </Button>}
                {onSubmit && <Button onClick={onSubmit} disabled={loading} color={submitButtonColor || "primary"} variant={buttonVariant}>
                    {submitTitle}
                </Button>}
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog