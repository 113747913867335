import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { withLightTheme } from '../../../theme'
import { STA2_TYPE, DRIVER_TYPE } from '../../../m2m-cloud-api/Api/DeviceService/Models/Device'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, ListItem, ListItemText, Typography, LinearProgress, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import { TextValidator, ValidatorForm, SelectValidator } from 'react-material-ui-form-validator'
import {Virtuoso} from 'react-virtuoso'
import { ListContainer, ItemContainerNoPadding} from '../../../Libs/Components/ListContainer'
import Searchbox from '../../../Libs/Components/Searchbox'

const ALLOWED_DEVICE_DRIVERS = [DRIVER_TYPE.STA2, DRIVER_TYPE.ESPI]

class DeviceSelectionDialog extends React.PureComponent {

    constructor(props) {
        super(props)
                                                                                                                                                                                                   
        this.state = {
            ready: false,
            selectedDeviceId: null,
            devices: null,
            loading: false,
            searchTerm: '',
            logType: STA2_TYPE.PUSH_LOG,
            orderPoint: '',
            errorMessage: null
        }

        this.setAsyncState = async (state) => {
            return new Promise((resolve) => {
                this.setState(state, resolve)
            })
        }

        this.handleResponseError = (error) => {
            const { t } = this.props
            this.setState({
                errorMessage: t(mapErrorMessage(error)),
                loading: false
            })
            console.error(t(mapErrorMessage(error)))
        }
        
    }

    componentDidMount() {
        this.loadDevices()
    }

    async loadDevices() {
        let devices = await this.context.api.deviceService.searchDevice(this.props.org)
        // fetch all device id's to get definitions
        const deviceIds = []
        devices.map(device => {
            if (device.getId() && deviceIds.indexOf(device.getId()) === -1) {
                deviceIds.push(device.getId())
            }
        })
        const definitions = await this.context.api.appMessageService.getDefinitionsByMultipleDeviceId(deviceIds)
        devices = devices.filter( device => definitions.find( definition => definition.getDeviceId() === device.getId() ) ? false : true)
        this.setState({ready: true, devices})
    }


    handleSubmit = () => {
        const { devices, selectedDeviceId, logType, orderPoint } = this.state
        const selectedItem = devices && selectedDeviceId && devices.find( device => device.getId() === selectedDeviceId)
        const isSta2Device = selectedItem.getDriver() === DRIVER_TYPE.STA2
        console.log('handleSubmit - selectedItem', selectedItem.getDriver() === DRIVER_TYPE.STA2)
        this.props.onSuccess(selectedItem, isSta2Device ? logType : null, isSta2Device && logType === STA2_TYPE.WEIGHT_LOG ? orderPoint : null)
    }

    toggleSelection = (device) => {
        this.setState({selectedDeviceId: device.getId()})
    }

    render() {
        const { loading, errorMessage, searchTerm, devices, selectedDeviceId, logType, orderPoint } = this.state
        const { t, classes } = this.props

        const filteredItems = devices && devices.filter( device =>  ALLOWED_DEVICE_DRIVERS.indexOf( device.getDriver() ) >= 0 && device.getPhysicalId().toLowerCase().indexOf( searchTerm.toLowerCase() ) >= 0 )
        const selectedItem = filteredItems && selectedDeviceId && filteredItems.find( device => device.getId() === selectedDeviceId)

        return (
            <div>
                <Dialog style={{pointerEvents: devices && loading ? 'none' : null}} open={this.props.open} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    { devices && loading && <LinearProgress className={classes.progress} /> }
                    <div className={classes.dialogTitleContainer}>
                        <DialogTitle id="form-dialog-title">
                            <div>{t('devices')}</div>
                        </DialogTitle>
                        <div className={classes.searchContainer}>
                            <Searchbox
                                value={this.state.searchTerm}
                                display={loading}
                                onChange={(value) => this.setState({searchTerm: value})}/>
                        </div>
                    </div>
                    <Divider/>
                    <DialogContent className={classes.dialogContent}>
                        <Paper className={classes.listPaper}>
                                { devices && devices.length === 0 && <Typography className={styles.messageText} color={'textSecondary'} variant="body2">{t('no_entries_available')}</Typography>}
                                { devices && devices.length > 0 && filteredItems.length === 0 && <Typography className={styles.messageText} color={'textSecondary'} variant="body2">{t('no_match_found')}</Typography>}
                                { filteredItems && filteredItems.length > 0 && <Virtuoso
                                    components={{
                                        Item: ItemContainerNoPadding
                                    }}
                                    className={styles.list}
                                    totalCount={filteredItems && filteredItems.length || 0}
                                    itemContent={index => 
                                        <ListItem className={classes.listItem} onClick={() => this.toggleSelection(filteredItems[index])} selected={selectedItem && filteredItems[index].getId() === selectedItem.getId()}>
                                            <ListItemText className={classes.listItemText} primary={filteredItems[index].getPhysicalId()} />
                                        </ListItem>
                                    }>
                                </Virtuoso>}
                        </Paper>
                    </DialogContent>
                        <Divider/>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit}
                            onError={errors => console.log("form error:", errors)}>
                            <div className={selectedItem && selectedItem.getDriver() === DRIVER_TYPE.STA2 ? classes.paramsContainer : [classes.paramsContainer, classes.hidden].join(' ')}>
                                <SelectValidator
                                    className={classes.formControlLeft}
                                    value={logType}
                                    onChange={(event) => this.setState({logType: event.target.value})}
                                    margin="dense"
                                    multiline={false}
                                    label={t('logtype')}>
                                    { Object.values(STA2_TYPE).map( option => (
                                        <MenuItem key={option} value={option}>{t(option)}</MenuItem>
                                    )) }
                                </SelectValidator>
                                { logType === STA2_TYPE.WEIGHT_LOG && <TextValidator
                                    className={classes.formControlRight}
                                    value={orderPoint}
                                    onChange={(event) => this.setState({orderPoint: event.target.value, error: null}) }
                                    margin="dense"
                                    label={ t('order_point') }
                                    validators={['required', 'isNumber']}
                                    errorMessages={[t('this_field_is_required'), t('this_field_must_be_a_number')]}/> }
                            </div>
                            <DialogActions>
                                <Button disabled={loading} onClick={this.props.onCancel}>
                                    {t('cancel')}
                                </Button>
                                <Button disabled={loading || !selectedItem} type="submit" color="primary">
                                    {t('select')}
                                </Button>
                            </DialogActions>
                        </ValidatorForm>
                </Dialog>
                { errorMessage && <AlertDialog 
                    open={true} 
                    title={t('error')}
                    message={errorMessage}
                    buttonVariant={'text'}
                    onSubmit={ () => this.setState({errorMessage: null}) } 
                /> }
            </div>
        )
    }
}

DeviceSelectionDialog.contextType = PageContext

DeviceSelectionDialog.propTypes = {
    open: PropTypes.bool,
    org: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

const styles = theme => ({
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3)
    },
    dialogContent: {
        padding: 0,
        height: 440
    },
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    },
    listPaper: {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        boxShadow: 'none',
        overflow: 'auto',
        overflowY: 'auto',
        overflowX: 'hidden',
        color: 'inherit',
        backgroundColor: 'transparent',
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        maringRight: theme.spacing(1)
    },
    list: {
        width: '100%', 
        flex: 1, 
        listStyle: 'none', 
        height: '100%', 
        paddingTop: 0, 
        paddingBottom: 0
    },
    listItem: {
        cursor: 'pointer',
        paddingLeft: theme.spacing(3),
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            }
        }
    },
    listBadge: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        fontSize: theme.font.extraSmall,
        marginRight: theme.spacing(1)
    },
    emptyListLabel: {
        padding: theme.spacing(3)
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
    paramsContainer: {
        flexDirection: 'row',
        display: 'flex',
        margin: theme.spacing(1.5)
    },
    formControlLeft: {
        flex: 0.5,
        marginRight: theme.spacing(1)
    },
    formControlRight: {
        flex: 0.5,
        marginLeft: theme.spacing(1)
    },
    messageText: {
        marginLeft: 24,
        marginTop: 16
    },
    hidden: {
        display: 'none'
    }
})


export default withTranslation()(withLightTheme(withStyles(styles)(DeviceSelectionDialog)))