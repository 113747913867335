import React, { Fragment } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, ButtonGroup, Button, IconButton, LinearProgress, Collapse, Typography, Paper } from '@material-ui/core'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import {DropzoneArea} from 'material-ui-dropzone'
import Papa from 'papaparse'

const TAB_TYPE = {
    SINGLE : 0,
    IMPORT_CSV: 1
}

const PAGE_TYPE = {
    FORM: 1,
    LIST: 2
}

class ClaimDeviceDialog extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            deviceId: '',
            claimKey: '',
            tabIndex: TAB_TYPE.SINGLE,
            page: PAGE_TYPE.FORM,
            itemsToClaim: [],
            loading: false,
            devicesClaimed: false,
            errorMessage: null
        }
    }

    async claimDevices() {
        const { org } = this.props

        this.setState({ loading: true, errorMessage: null })
        const claimedDevices = []
        const itemsToClaim = [...this.state.itemsToClaim]
        for (let index = 0; index < itemsToClaim.length; index++) {
            const item = itemsToClaim[index];
            try {
                const device = await this.props.api.deviceService.claimDevice(item.deviceId, item.claimKey, item.orgId || org.getId() )
                claimedDevices.push(device)
                itemsToClaim[index]['result'] = true
                itemsToClaim[index]['error'] = null
            } catch (error) {
                itemsToClaim[index]['result'] = false
                itemsToClaim[index]['error'] = error
            }
            
        }

        this.props.onSuccess(claimedDevices)
        this.setState({loading: false, itemsToClaim, devicesClaimed: true})
    }

    async handleSubmit() {
        const { t, org } = this.props
        const { deviceId, claimKey } = this.state

        this.setState({itemsToClaim: [{deviceId, claimKey, orgId: null, result: null}], page: PAGE_TYPE.LIST, errorMessage: null})
    }

    onDrop(file) {
        const { t, org } = this.props
        const me = this

        const droppedFile = Array.isArray(file) ? file[0] : file
        if (droppedFile) {

            var reader = new FileReader()
            reader.addEventListener('load', function (e) {
                const csvdata = e.target.result
                const data = Papa.parse(csvdata)
                if (data.errors && data.errors.length > 0) {
                    console.warn("csv parse error: ", data.errors)
                    me.setState({errorMessage: t('error_invalid_csv_file')})
                    return
                }

                if ( !data.data || data.data.length === 0 || data.data[0][0] !== "DeviceID" || data.data[0][1] !== "ClaimKey" ) {
                    console.warn("invalid csv data: ", data.data)
                    me.setState({errorMessage: t('error_invalid_csv_file')})
                    return
                }

                const itemsToClaim = []
                for (let index = 1; index < data.data.length; index++) {
                    const element = data.data[index];
                    if (element.length >= 2) {
                        itemsToClaim.push({deviceId: element[0], claimKey: element[1], orgId: element.length === 3 ? element[2] : null, result: null })
                    }
                }

                me.setState({itemsToClaim, page: PAGE_TYPE.LIST, errorMessage: null})
            });
            reader.readAsBinaryString(droppedFile)
        }
    }

    renderClaimList() {
        const { t, classes } = this.props
        const { itemsToClaim, devicesClaimed, loading } = this.state

        const hasError = itemsToClaim && itemsToClaim.find( item => item.result === false ) >= 0 ? true : false

        console.log('itemsToClaim', itemsToClaim, hasError)

        return (
            <Fragment>
                <DialogContent classes={{root: classes.dialogContent}}>

                    <DialogContentText className={hasError ? [classes.description, classes.error].join(' ') : classes.description}>
                        {devicesClaimed ? ( hasError ? t('claim_result_failed') : t('claim_result_successfull') ) : t('please_check_claim_list')}
                    </DialogContentText>

                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('device_id')}</TableCell>
                                <TableCell>{t('claim_key')}</TableCell>
                                <TableCell>{t('org_id')}</TableCell>
                                { devicesClaimed && <TableCell align="right">{t('status')}</TableCell> }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { itemsToClaim && itemsToClaim.map( item => (
                                <TableRow key={item.deviceId}>
                                    <TableCell>
                                        { item.deviceId }
                                    </TableCell>
                                    <TableCell>
                                        { item.claimKey }
                                    </TableCell>
                                    <TableCell>
                                        { item.orgId || t('selected_org_id') }
                                    </TableCell>
                                    { devicesClaimed && <TableCell align="right" className={item.error ? classes.error : null}>
                                        { item.result ? t('successful') : item.error ? mapErrorMessage(item.error) : ' ' }
                                    </TableCell>}
                                </TableRow>   
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    { !devicesClaimed && <Button disabled={loading} onClick={this.props.onClose}>
                        {t('cancel')}
                    </Button>}
                    { !devicesClaimed && <Button disabled={loading} color="primary"  onClick={() => this.claimDevices() }>
                        {itemsToClaim.length === 1 ? t('claim_device') : t('claim_devices')}
                    </Button>}
                    { devicesClaimed && <Button onClick={this.props.onClose}>
                        {t('close')}
                    </Button>}
                </DialogActions>
            </Fragment>
        )
    } 

    render() {
        const { deviceId, claimKey, loading, errorMessage, tabIndex, page, itemsToClaim } = this.state
        const { t, classes } = this.props

        let canContinue = deviceId && deviceId.trim() !== "" && claimKey && claimKey.trim()


        return (
            <div>
                <Dialog open={this.props.open} className={classes.dialog} onClose={this.props.onClose} fullWidth maxWidth={page === PAGE_TYPE.FORM ? 'sm' : 'md'} aria-labelledby="form-dialog-title">
                    { loading && <LinearProgress className={classes.progress} /> }
                    <DialogTitle id="form-dialog-title">{ (itemsToClaim && itemsToClaim.length > 1 ) || (page === PAGE_TYPE.FORM && tabIndex == TAB_TYPE.IMPORT_CSV) ? t('claim_devices') : t('claim_device')}</DialogTitle>
                    { page === PAGE_TYPE.FORM && <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit.bind(this)}
                        onError={errors => console.log("form error:", errors)}>
                        <DialogContent style={{transitionDuration: '0.3s', height: tabIndex === TAB_TYPE.SINGLE ? 300 : 500}}>

                        <Grid container justify="center" >
                            <Grid item >
                                <ButtonGroup>
                                    <Button variant={tabIndex === TAB_TYPE.SINGLE ? 'contained' : null} onClick={() => this.setState({ tabIndex: TAB_TYPE.SINGLE })}>{t('claim_single_device')}</Button>
                                    <Button variant={tabIndex === TAB_TYPE.IMPORT_CSV ? 'contained' : null} onClick={() => this.setState({ tabIndex: TAB_TYPE.IMPORT_CSV })}>{t('claim_multiple_devices')}</Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" className={classes.form} >
                            <Grid item >
                                { tabIndex === TAB_TYPE.SINGLE && <Fragment>
                                    <TextValidator
                                        className={classes.field}
                                        validators={['required']}
                                        value={deviceId}
                                        onChange={(event) => {
                                            this.setState({ deviceId: event.target.value, error: null })
                                        }}
                                        margin="dense"
                                        id={'device_id'}
                                        label={t('device_id')}
                                        errorMessages={[t('this_field_is_required')]}
                                        fullWidth />
                                    <TextValidator
                                        className={classes.field}
                                        validators={['required']}
                                        value={claimKey}
                                        onChange={(event) => {
                                            this.setState({ claimKey: event.target.value, error: null })
                                        }}
                                        margin="dense"
                                        id={'claim_key'}
                                        label={t('claim_key')}
                                        errorMessages={[t('this_field_is_required')]}
                                        fullWidth />
                                </Fragment>}
                            </Grid>
                            </Grid>

                            { tabIndex === TAB_TYPE.IMPORT_CSV && 
                                <Fragment>
                                    <Grid container justify="center" >
                                        <Grid item xs={8}>
                                            <DropzoneArea 
                                                dropzoneClass={classes.dropZone}
                                                acceptedFiles={['.csv']}
                                                showAlerts={true}
                                                showPreviewsInDropzone={false}
                                                dropzoneText={t('drag_and_drop_csv_file_here_or_click')}
                                                dropzoneParagraphClass={classes.dropzoneParagraph}
                                                filesLimit={100}
                                                onDrop={this.onDrop.bind(this)}/> 
                                        </Grid>
                                    </Grid>
                                    <Grid container justify="center" >
                                        <Grid item xs={8} >
                                            <Typography className={classes.helpTitle}>
                                                { 'Use the CSV format below.' }
                                            </Typography>
                                            <Paper className={classes.helpPaper}>
                                                <Typography className={classes.helpText}>
                                                    DeviceID;ClaimKey;OrgId<br/>
                                                    xxx;yyy;zzz 
                                                </Typography>
                                            </Paper>
                                            <Typography className={classes.helpText}>
                                                <ul className={classes.helpList}>
                                                    <li><b>DeviceID</b> {t('required')}</li>
                                                    <li><b>ClaimKey</b> {t('required')}</li>
                                                    <li><b>OrgId</b> {t('optional')}</li>
                                                </ul>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Fragment>}
                            
                            {errorMessage && <DialogContentText className={classes.errorText}>
                                {errorMessage}
                            </DialogContentText>}
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onClose}>
                                {t('cancel')}
                            </Button>
                            { tabIndex === TAB_TYPE.SINGLE && <Button type="submit" color="primary">
                                {t('continue')}
                            </Button> }
                        </DialogActions>
                    </ValidatorForm> }
                    { page === PAGE_TYPE.LIST && this.renderClaimList() }
                </Dialog>
            </div>
        )
    }
}


ClaimDeviceDialog.propTypes = {
    api: PropTypes.any.isRequired,
    open: PropTypes.bool,
    org: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}


const styles = theme => ({
    dialogContent: {
        padding:0
    },
    description: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3)
    },
    error: {
        color: theme.palette.error.main,
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20
    },
    table: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    form: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    field: {
        marginTop: 10,
        marginBottom: 10
    },
    textField: {
        width: '90%',
    },
    buttonField: {
        marginTop: 18
    },
    dropZone: {
        backgroundColor: 'transparent',
        minHeight: 140,
        marginBottom: theme.spacing(5)
    },
    dropzoneParagraph: {
        fontSize: theme.font.large,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
    error: {
        color: theme.palette.error.main
    },
    helpTitle: {
        marginBottom: theme.spacing(1),
        fontSize: theme.font.medium
    },
    helpPaper: {
        backgroundColor: 'rgba(255,255,255,0.05)',
        padding: theme.spacing(1)
    },
    helpText: {
        fontSize: theme.font.small
    },
    helpList: {
        paddingLeft: 15
    }
})


export default withTranslation()(withStyles(styles)(ClaimDeviceDialog))