import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import Loading from '../../../Libs/Pages/Loading'
import { withStyles } from '@material-ui/core/styles'
import history from '../../../History'
import { Api, UserService, OrgService, AuthzService, DeviceService, AppMessageService, ERROR, ORG_TAG, ApiCache } from '../../../m2m-cloud-api'
import { PERMISSION_ACTION_TYPE, ENTITY_GROUP_TYPE } from '../../../m2m-cloud-api/Api/AuthzService/Models/Permission'
import { prepareTemplateContent } from '../../../m2m-cloud-api/Api/Helper'
import Org, { buildRootOrgNicknameParam } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { csvExportDefinitions } from '../../../Libs/Utilities/CSVExport'
import { downloadJsonFile } from '../../../Libs/Utilities/JsonExport'
import WebStorage from '../../../m2m-cloud-api/Storage/WebStorage'
import SessionStore from './SessionStorage'
import { service } from '../../../index'
import { getPath } from '../App'
import { APP_MESSAGE_ROOT_ORG_USERS_GROUP, APP_MESSAGE_ROOT_ORG_ADMINS_GROUP, APP_MESSAGE_ROOT_ORG_MANAGE_USERS_ROLE, APP_MESSAGE_ROOT_ORG_READ_ROLE, APP_MESSAGE_ROOT_ORG_WRITE_ROLE } from '../Constants'

import { v4 as uuidv4 } from 'uuid'

export const PageContext = React.createContext()

export const sortOrgsByName = (orgs) => {
    return orgs.sort((a, b) => {
        return a.getName().localeCompare(b.getName())
    })
}

const asyncWait = (waitTime) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, waitTime)
    })
}

class PageProvider extends Component {

    constructor(props) {
        super(props)

        this.sessionStorage = null
        this.cacheTopOrgs = null
        this.cacheReplacements = null

        this.loadPendingInvitations = async () => {
            try {
                const pendingInvitations = await this.authzService.listPendingInvitations()
                this.setState({ pendingInvitations })
            } catch (error) {
                console.warn("ApiContext, loadPendingInvitations error: ", error)
            }
        }

        this.initSessionStorage = () => {
            const user = this.userService.getActiveUser()
            if (user) {
                this.sessionStorage = new SessionStore(user.getUserId())
                this.cacheTopOrgs = new ApiCache(Org)
            }
        }

        this.api = new Api(service.api.domain, new WebStorage(), false)
        this.userService = new UserService(this.api,
            () => { // login
                this.initSessionStorage()
            },
            () => { // logout
                this.sessionStorage = null
                if (this.pendingInvitationsTimer) {
                    clearInterval(this.pendingInvitationsTimer)
                    this.pendingInvitationsTimer = null
                }
                history.push(`/auth?redirect=${getPath()}`)
                window.location.reload(true)
            }
        )
        this.orgService = new OrgService(this.api)
        this.authzService = new AuthzService(this.api)
        this.deviceService = new DeviceService(this.api)
        this.appMessageService = new AppMessageService(this.api)

        this.state = {
            ready: false,
            lastError: null,
            rootOrgs: null,
            selectedRootOrgId: null
        }

        this.setAsyncState = async (state) => {
            return new Promise((resolve) => {
                this.setState(state, resolve)
            })
        }

        // init user session
        this.userService.initSession()
            .then(async () => await this.onSessionReady())
            .catch((error) => {
                console.log('initSession- error', error)
                this.setState({ ready: true }, () => {
                    const errorType = error && error.getErrorType && error.getErrorType()
                    if (errorType === ERROR.FORBIDDEN || errorType === ERROR.UNAUTHORIZED) {
                        this.userService.logoutUser()
                    } else {
                        console.log('error', error)
                    }
                })
            })
    }

    /**
     * set user language if exists
     */
    setUserLanguageIfExists() {
        const user = this.userService.getActiveUser()
        const userLanguage = user && user.getUserLanguage()
        if (userLanguage) {
            i18n.changeLanguage(userLanguage)
        }
    }

    /**
     * on session ready handler ( valid token exists and user object is loaded )
     * @returns {undefined}
     */
    async onSessionReady() {
        this.setUserLanguageIfExists()
        this.initSessionStorage()
        await this.loadRootOrgs()
        const selectedRootOrg = this.getSelectedRootOrg()
        //console.log('onSessionReady', selectedRootOrg, this.state.rootOrgs)
        if (!selectedRootOrg && this.state.rootOrgs && this.state.rootOrgs.length === 1) {
            await this.setSelectedRootOrg(this.state.rootOrgs[0])
        }

        if (this.pendingInvitationsTimer) clearInterval(this.pendingInvitationsTimer)
        this.pendingInvitationsTimer = setInterval(() => {
            this.loadPendingInvitations()
        }, 60 * 1000)
        this.loadPendingInvitations()

        //await this.loadUsers()
        this.setState({ ready: true })
    }

    /**
     * get public user (nick name will be overidden if root org has a nick name defined for givem user id)
     * @param {string} userId 
     * @returns {PublicUser}
     */
    async getPublicUser(userId) {
        const publicUser = await this.userService.getPublicUser(userId)

        let selectedRootOrg = this.getSelectedRootOrg()
        if (selectedRootOrg) {
            const rootOrgNickname = selectedRootOrg.getParam(buildRootOrgNicknameParam(publicUser.getId()))
            if (rootOrgNickname && rootOrgNickname.trim() !== "") {
                publicUser.setNickname(rootOrgNickname)
            } else if (publicUser.originalNickname && publicUser.originalNickname !== publicUser.getNickname()) {
                publicUser.setNickname(publicUser.originalNickname)
            }
        }
        return publicUser
    }

    /**
     * set root org nickname
     * @param {String} userId 
     * @param {String} nickname 
     * @returns {undefined}
     */
    async setRootOrgNickname(userId, nickname) {
        const selectedRootOrgId = this.sessionStorage.getSelectedRootOrg()
        if (nickname && nickname.trim() !== "") {
            await this.orgService.putParam(selectedRootOrgId, buildRootOrgNicknameParam(userId), nickname)
        } else {
            await this.orgService.deleteParam(selectedRootOrgId, buildRootOrgNicknameParam(userId))
        }
        const rootOrgs = this.state.rootOrgs.map(org => {
            if (org.getId() === selectedRootOrgId) {
                org.setParam(buildRootOrgNicknameParam(userId), nickname && nickname.trim() !== "" ? nickname : null)
            }
            return org
        })
        this.setState({ rootOrgs })
    }

    /**
     * load manageable users from logged in user
     */
    async loadUsers() {
        const user = this.userService.getActiveUser()
        try {
            const mangeableUserIds = await this.authzService.listManageableUsers(user.getUserId())
            const users = await this.userService.getPublicUsersWithIds(mangeableUserIds)
            this.setState({ users })
        } catch (error) {
            console.warn("can't load users")
        }
    }


    /**
     * can manage root org
     * @returns {Boolean}
     */
    async canManageRootOrg() {
        let access = false
        const selectedRootOrg = this.getSelectedRootOrg()
        if (selectedRootOrg) {
            try {
                const configOrgIdsWriteable = await this.orgService.checkAccessMultiple('w', [selectedRootOrg.getId()])
                access = configOrgIdsWriteable.find(orgId => orgId === selectedRootOrg.getId()) ? true : false
            } catch (error) {
            }
        }
        return access
    }

    /**
     * get root org groups
     * @returns {Object<usersGroup, adminsGroup>}
     */
    async prepareRootOrgGroups() {
        const selectedRootOrg = this.getSelectedRootOrg()
        if (selectedRootOrg) {
            console.log('selectedRootOrg')
            const orgId = selectedRootOrg.getId()
            const org = await this.orgService.read(orgId)
            return await this.createRootOrgGroupsIfNotExists(org, false)
        }
    }

    /**
     * create root org groups if not exists
     * @returns {Object<usersGroup, adminsGroup>}
     */
    async createRootOrgGroupsIfNotExists(org, waitForPrivatePermissions = false) {
        if (org) {
            const orgId = org.getId()

            /**
             * create new group with given type, if group does not exists
             * @param {APP_MESSAGE_ROOT_ORG_USERS_GROUP || APP_MESSAGE_ROOT_ORG_ADMINS_GROUP} type 
             * @param {String} roleTitle
             * @returns {Group}
             */
            const createGroupIfNotExists = async (type, roleTitle) => {
                const rootOrgUserGroupId = org.getParam(type)
                let group = null
                if (rootOrgUserGroupId) {
                    try {
                        group = await this.authzService.readGroup(rootOrgUserGroupId)
                    } catch (error) {
                        console.warn(`can't load group, type: ${type}, org: ${org.getName()}, orgId: ${orgId}`)
                    }
                }

                if (!rootOrgUserGroupId /*group*/) {
                    const groupId = uuidv4()
                    group = await this.authzService.upsertGroup(groupId, `${org.getName()}: ${roleTitle}`)
                    await this.orgService.putParam(orgId, type, groupId)
                }
                return group
            }

            /**
             * create new role with given type, if role does not exists
             * @param {APP_MESSAGE_ROOT_ORG_MANAGE_USERS_ROLE, APP_MESSAGE_ROOT_ORG_READ_ROLE, APP_MESSAGE_ROOT_ORG_WRITE_ROLE} type 
             * @param {String} roleTitle
             * @param {Array<Object<entityGroupType, permissionActionType, resource>>} perms
             * @returns {Role}
             */
            const createRoleIfNotExists = async (type, roleTitle, perms) => {
                const rootOrgRoleId = org.getParam(type)
                let role = null
                if (rootOrgRoleId) {
                    try {
                        role = await this.authzService.readRole(rootOrgRoleId)
                    } catch (error) {
                        console.warn(`can't load role, type: ${type}, org: ${org.getName()}, orgId: ${orgId}`)
                    }
                }
                if (!rootOrgRoleId /*role*/) {
                    const roleId = uuidv4()
                    role = await this.authzService.upsertRole(roleId, `${org.getName()}: ${roleTitle}`)
                    await this.orgService.putParam(orgId, type, roleId)
                    for (let iPerm = 0; iPerm < perms.length; iPerm++) {
                        const perm = perms[iPerm]
                        for (let index = 1; index <= 15; index++) {
                            await asyncWait(1000)
                            try {
                                await this.authzService.addPerm(roleId, perm.entityGroupType, perm.permissionActionType, perm.resource)
                                break
                            } catch (error) {
                                console.log("new created role not ready, " + (index === 15 ? " after 15s." : "will retry after 1s"), "error: ", error)
                            }
                        }
                    }
                }
                return role
            }

            /**
             * assign role to group
             * @param {Group} group 
             * @param {Role} role 
             * @returns {undefined}
             */
            const assignRoleToGroup = async (group, role) => {
                let result = null
                for (let index = 1; index <= 15; index++) {
                    await asyncWait(1000)
                    try {
                        result = await this.authzService.addRole(group.getId(), role.getId())
                        break
                    } catch (error) {
                        console.log("new created group not ready, " + (index === 15 ? " after 15s." : "will retry after 1s"), "error: ", error)
                    }
                }
                return result
            }

            const usersGroup = await createGroupIfNotExists(APP_MESSAGE_ROOT_ORG_USERS_GROUP, 'Users')
            const adminsGroup = await createGroupIfNotExists(APP_MESSAGE_ROOT_ORG_ADMINS_GROUP, 'Admins')

            const readRole = await createRoleIfNotExists(APP_MESSAGE_ROOT_ORG_READ_ROLE, 'Read', [{ entityGroupType: ENTITY_GROUP_TYPE.ORG, permissionActionType: PERMISSION_ACTION_TYPE.READ, resource: orgId }])
            const writeRole = await createRoleIfNotExists(APP_MESSAGE_ROOT_ORG_WRITE_ROLE, 'Write', [{ entityGroupType: ENTITY_GROUP_TYPE.ORG, permissionActionType: PERMISSION_ACTION_TYPE.WRITE, resource: orgId }])

            const hasGroupReadRole = usersGroup.getRoles().find(roleId => roleId === readRole.getId())
            if (!hasGroupReadRole) await assignRoleToGroup(usersGroup, readRole)

            const hasAdminsGroupReadRole = adminsGroup.getRoles().find(roleId => roleId === readRole.getId())
            if (!hasAdminsGroupReadRole) await assignRoleToGroup(adminsGroup, readRole)

            const hasAdminsGroupWriteRole = adminsGroup.getRoles().find(roleId => roleId === writeRole.getId())
            if (!hasAdminsGroupWriteRole) await assignRoleToGroup(adminsGroup, writeRole)

            const hasReadRoleWriteImages = readRole.getPermissions().find(permission => permission.getEntityGroup() === ENTITY_GROUP_TYPE.ORG && permission.getAction() === PERMISSION_ACTION_TYPE.WRITE_IMAGES && permission.getResource() === orgId)
            if (!hasReadRoleWriteImages) await this.authzService.addPerm(readRole.getId(), ENTITY_GROUP_TYPE.ORG, PERMISSION_ACTION_TYPE.WRITE_IMAGES, orgId)

            const hasWriteRoleUsersGroup = writeRole.getPermissions().find(permission => permission.getEntityGroup() === ENTITY_GROUP_TYPE.GROUP && permission.getAction() === PERMISSION_ACTION_TYPE.WRITE && permission.getResource() === usersGroup.getId())
            if (!hasWriteRoleUsersGroup) await this.authzService.addPerm(writeRole.getId(), ENTITY_GROUP_TYPE.GROUP, PERMISSION_ACTION_TYPE.WRITE, usersGroup.getId())

            const hasWriteRoleAdminsGroup = writeRole.getPermissions().find(permission => permission.getEntityGroup() === ENTITY_GROUP_TYPE.GROUP && permission.getAction() === PERMISSION_ACTION_TYPE.WRITE && permission.getResource() === adminsGroup.getId())
            if (!hasWriteRoleAdminsGroup) await this.authzService.addPerm(writeRole.getId(), ENTITY_GROUP_TYPE.GROUP, PERMISSION_ACTION_TYPE.WRITE, adminsGroup.getId())

            const hasWriteRoleReadRole = writeRole.getPermissions().find(permission => permission.getEntityGroup() === ENTITY_GROUP_TYPE.ROLE && permission.getAction() === PERMISSION_ACTION_TYPE.WRITE && permission.getResource() === readRole.getId())
            if (!hasWriteRoleReadRole) await this.authzService.addPerm(writeRole.getId(), ENTITY_GROUP_TYPE.ROLE, PERMISSION_ACTION_TYPE.WRITE, readRole.getId())

            const hasWriteRoleWriteRole = writeRole.getPermissions().find(permission => permission.getEntityGroup() === ENTITY_GROUP_TYPE.ROLE && permission.getAction() === PERMISSION_ACTION_TYPE.WRITE && permission.getResource() === writeRole.getId())
            if (!hasWriteRoleWriteRole) await this.authzService.addPerm(writeRole.getId(), ENTITY_GROUP_TYPE.ROLE, PERMISSION_ACTION_TYPE.WRITE, writeRole.getId())

            // add active user to users/admins group
            const user = this.userService.getActiveUser()
            if (usersGroup.getUsers().indexOf(user.getUserId()) === -1) {
                await this.authzService.addUser(usersGroup.getId(), user.getUserId())
            }
            if (adminsGroup.getUsers().indexOf(user.getUserId()) === -1) {
                await this.authzService.addUser(adminsGroup.getId(), user.getUserId())
            }

            // remove auto created perm's from private role
            let permsToDelete = []
            let privateRole = null
            for (let index = 1; index <= (waitForPrivatePermissions ? 10 : 1); index++) {
                await asyncWait(1000)
                privateRole = await this.authzService.readRole(user.userRoleMeta.getPrivateRole())
                permsToDelete = privateRole.getPermissions().filter(permission => {
                    if (permission.getEntityGroup() === ENTITY_GROUP_TYPE.GROUP && permission.getResource() === usersGroup.getId()) return true
                    if (permission.getEntityGroup() === ENTITY_GROUP_TYPE.GROUP && permission.getResource() === adminsGroup.getId()) return true
                    if (permission.getEntityGroup() === ENTITY_GROUP_TYPE.ROLE && permission.getResource() === readRole.getId()) return true
                    if (permission.getEntityGroup() === ENTITY_GROUP_TYPE.ROLE && permission.getResource() === writeRole.getId()) return true
                    return false
                })
                if (permsToDelete.length === 8) {
                    break
                }
                waitForPrivatePermissions && console.log("auto created permissions not ready, " + (index === 10 ? " after 10s." : "will retry after 1s"), "count perms: ", permsToDelete.length)
            }
            for (let index = 0; index < permsToDelete.length; index++) {
                const perm = permsToDelete[index]
                await this.authzService.removePerm(privateRole.getId(), perm.getEntityGroup(), perm.getAction(), perm.getResource())
            }

            return { usersGroup, adminsGroup }
        }
    }

    /**
     * get selected root org
     * @returns {Org | null}
     */
    getSelectedRootOrg() {
        const selectedRootOrgId = this.sessionStorage.getSelectedRootOrg()
        if (selectedRootOrgId) {
            return this.state.rootOrgs && this.state.rootOrgs.find(org => org.getId() === selectedRootOrgId)
        }
        return null
    }

    /**
     * set selected root org
     * @param {Org | Uid} org
     */
    async setSelectedRootOrg(org) {
        const selectedRootOrgId = typeof org === 'string' || org instanceof String ? org : org.getId()
        this.sessionStorage.setSelectedRootOrg(selectedRootOrgId)
        await this.loadRootOrgCildrens()
    }

    /**
     * Get known Org
     * @param {Uid} orgId 
     * @returns {Org}
     */
    fetchKnownOrg(orgId) {
        let org = this.state.rootOrgs && this.state.rootOrgs.find(org => org.getId() === orgId)
        if (org) return org
        org = this.state.childrenOrgs && this.state.childrenOrgs.find(org => org.getId() === orgId)
        if (org) return org
        return null
    }

    /**
     * 
     * @param {*} orgIds 
     */
    async fetchOrgs(orgIds) {
        const orgs = []
        const orgIdsToLoad = []
        orgIds.map(orgId => {
            if (!orgId) return
            const hasOrgId = orgs.find(org => org.getId() === orgId)
            if (!hasOrgId) {
                let org = this.fetchKnownOrg(orgId)
                if (org) {
                    orgs.push(org)
                } else if (orgIdsToLoad.indexOf(orgId) === -1) {
                    orgIdsToLoad.push(orgId)
                }
            }
        })

        if (orgIdsToLoad.length > 0) {
            let loadedOrgs = []
            try {
                loadedOrgs = await this.orgService.readMultiple(orgIdsToLoad)
                loadedOrgs.map(org => {
                    if (org) orgs.push(org)
                })
            } catch (error) {
                console.warn("readMultiple error", error)
            }
        }

        return orgs
    }

    /**
     * create root, group or config org
     * @param {Uid} parent (optional)
     * 
     * @returns Promise<Org>
     */
    createAndCacheTopOrg(parent = null, params = null, tags = null) {
        return this.orgService.create(parent, params, tags)
            .then(org => {
                this.cacheTopOrgs.update(org)
                return org
            })
    }

    /**
     * update root, group or config org
     * @param {Uid} orgId 
     * @param {Array<Promise>} promises 
     * @returns Promise<Org>
     */
    updateAndCacheTopOrg(orgId, promises) {
        return Promise.all(promises).then(results => {
            return this.orgService.read(orgId).then((org) => {
                this.cacheTopOrgs.update(org)
                return org
            })
        })
    }

    /**
     * load all root orgs
     */
    async loadRootOrgs() {
        await this.setAsyncState({ lastError: null })
        const rootOrgs = await this.orgService.searchOrg({ tags: [ORG_TAG.ROOT] })
        this.cacheTopOrgs.setList(rootOrgs)
        const cachedOrgs = this.cacheTopOrgs.getCachedList()
        const sortedRootOrgs = sortOrgsByName(cachedOrgs.filter(org => !org.isDeactivated() && org.getTags().indexOf(ORG_TAG.ROOT) >= 0))
        await this.setAsyncState({
            rootOrgs: sortedRootOrgs
        })
    }

    /**
     * load children orgs from selected root org
     */
    async loadRootOrgCildrens() {
        const rootOrg = this.getSelectedRootOrg()
        await this.setAsyncState({ lastError: null })
        const tags = [ORG_TAG.GROUP, ORG_TAG.CONFIG, ORG_TAG.SUPPLIER_TARGET, ORG_TAG.RECIPIENT_LOCATION]
        const childrenOrgs = await this.orgService.searchOrg({ tags, searchRootOrg: [rootOrg.getId()] })
        this.cacheTopOrgs.setList(childrenOrgs)
        const cachedOrgs = this.cacheTopOrgs.getCachedList()
        const sortedChildrenOrgs = sortOrgsByName(cachedOrgs.filter(org => !org.isDeactivated() && org.getTags().findIndex(tag => tags.indexOf(tag) !== -1) >= 0))
        await this.setAsyncState({
            childrenOrgs: sortedChildrenOrgs
        })
    }

    async updateCachedOrgParams(orgId, params) {
        const { rootOrgs, childrenOrgs } = this.state
        if (rootOrgs && rootOrgs.length > 0) {
            const rootOrgIndex = rootOrgs.findIndex(org => org.getId() === orgId)
            if (rootOrgIndex !== -1) {
                const rootOrg = rootOrgs[rootOrgIndex]
                const paramKeys = Object.keys(params)
                for (let index = 0; index < paramKeys.length; index++) {
                    const paramKey = paramKeys[index]
                    rootOrg.setParam(paramKey, params[paramKey])
                }
                this.setAsyncState({ rootOrgs })
            }
        }
        if (childrenOrgs && childrenOrgs.length > 0) {
            const chidlrenOrgIndex = childrenOrgs.findIndex(org => org.getId() === orgId)
            if (chidlrenOrgIndex !== -1) {
                const childrenOrg = childrenOrgs[chidlrenOrgIndex]
                const paramKeys = Object.keys(params)
                for (let index = 0; index < paramKeys.length; index++) {
                    const paramKey = paramKeys[index]
                    childrenOrg.setParam(paramKey, params[paramKey])
                }
                this.setAsyncState({ childrenOrgs })
            }
        }
    }


    /**
     * Load articles from API
     * @returns {Array<any>}
     */
    async loadTriggerEntities(triggerOrgId) {
        let devices = await this.deviceService.searchDevice(triggerOrgId)
        if (devices && devices.length > 0) {
            devices = devices.filter(device => device.getAssignedOrg() === triggerOrgId)
        }
        // fetch all device id's to get definitions
        const deviceIds = []
        devices.map(device => {
            if (device.getId() && deviceIds.indexOf(device.getId()) === -1) {
                deviceIds.push(device.getId())
            }
        })

        // load definitions with device id's
        const definitions = await this.appMessageService.getDefinitionsByMultipleDeviceId(deviceIds)

        // fetch all template id's
        const templateIds = []
        definitions.map(definition => {
            if (definition.getTemplateId() && templateIds.indexOf(definition.getTemplateId()) === -1) {
                templateIds.push(definition.getTemplateId())
            }
        })

        const templates = await this.appMessageService.getMultipleTemplates(templateIds)
        const recipientArticles = []

        let device = null
        definitions.map(definition => {
            device = devices.find(device => device && device.getId() === definition.getDeviceId())
            const template = templates.find(template => template && template.getId() === definition.getTemplateId())
            if (template && definition.events && definition.events.length > 0) {
                const preparedTemplate = prepareTemplateContent(template, definition, device)
                recipientArticles.push({
                    definition: definition,
                    device: device,
                    id: definition.getId(),
                    template,
                    title: preparedTemplate.header
                })
            } else {
                console.warn("invalid definition or template, definition: ", definition, " template: ", template)
            }
        })

        return recipientArticles
        //const deviceSortParam = await this.orgService.getParam(triggerOrgId, PARAM_APP_MSG_ITEM_SORT)
        //return sortItems(deviceSortParam, recipientArticles)
    }

    async getDeviceAssignedOrgIdsFromDefinitionIds(definitionIds) {
        const deviceAssignedOrgIds = []
        for (let index = 0; index < definitionIds.length; index++) {
            const definitionId = definitionIds[index];
            const definition = await this.appMessageService.getDefinition(definitionId)
            const device = await this.deviceService.getDevice(definition.getDeviceId())
            deviceAssignedOrgIds.push(device.getAssignedOrg())
        }
        return deviceAssignedOrgIds
    }


    /**
     * csv export trigger entities
     * @returns {undefined}
     */
    async csvExportTriggerEntities(triggerOrgId) {
        console.log('csvExportTriggerEntities', triggerOrgId)
        let devices = await this.deviceService.searchDevice(triggerOrgId)
        if (devices && devices.length > 0) {
            devices = devices.filter(device => device.getAssignedOrg() === triggerOrgId)
        }
        // fetch all device id's to get definitions
        const deviceIds = []
        devices.map(device => {
            if (device.getId() && deviceIds.indexOf(device.getId()) === -1) {
                deviceIds.push(device.getId())
            }
        })

        // load definitions with device id's
        const definitions = await this.appMessageService.getDefinitionsByMultipleDeviceId(deviceIds)

        // fetch all template id's
        const templateIds = []
        const actionIds = []
        definitions.map(definition => {
            if (definition.getTemplateId() && templateIds.indexOf(definition.getTemplateId()) === -1) {
                templateIds.push(definition.getTemplateId())
            }
            if (definition.getActionId() && actionIds.indexOf(definition.getActionId()) === -1) {
                actionIds.push(definition.getActionId())
            }
        })

        const templates = await this.appMessageService.getMultipleTemplates(templateIds)
        const actions = await this.appMessageService.getMultipleActions(actionIds)

        csvExportDefinitions(definitions, templates, actions, devices)
    }

    /**
     * json export action entities
     * @param {Object<actions<Template>, replacements<ReplacementDefinition>} exportData 
     * @returns {undefined}
     */
    async jsonExportActionEntities(exportData) {
        downloadJsonFile("Export.json", exportData)
    }

    /**
     * Load definitions from API
     * @returns {Array<any>}
     */
    async loadActionEntities(actionOrgId) {
        let templates = await this.appMessageService.getTemplates(actionOrgId)
        templates = templates.map(template => {
            return {
                template,
                id: template.getId(),
                title: template.getHeader()
            }
        })
        return templates.sort((a, b) => {
            return a.title.localeCompare(b.title)
        })
    }

    /**
     * Load replacements from API
     * @param {String} orgId
     * @param {Boolean} useCache
     * @returns {Array<Replacement>}
     */
    async loadReplacementEntities(orgId, useCache = false) {
        let replacements = []
        if (useCache && this.cacheReplacements && this.cacheReplacements[orgId]) {
            replacements = this.cacheReplacements[orgId]
        } else {
            replacements = await this.appMessageService.readReplacementDefsByOrg(orgId)
            if (!this.cacheReplacements) this.cacheReplacements = {}
            this.cacheReplacements[orgId] = replacements
        }
        return replacements.sort((a, b) => {
            return a.getName().localeCompare(b.getName())
        })
    }

    /**
     * Handle replacement titles of given config org
     * @param {Uid} orgId
     * @param {Array} replacementIds
     * @returns {String | undefined}
     */
    async fetchReplacementTitles(orgId, replacementIds) {
        let titles = ''
        try {
            const replacementEntities = await this.loadReplacementEntities(orgId, true)
            replacementIds?.map(id => {
                const replacement = replacementEntities.find(replacement => replacement.getId() === id)
                if (replacement) {
                    if (titles === '') {
                        titles = `${replacement.title}`
                    } else {
                        titles = `${titles}, ${replacement.title}`
                    }
                }
            })
            if (titles !== '') return titles
        } catch (error) {
            console.log('fetchReplacementTitles error... :', error)
        }
    }

    render() {
        return (
            <PageContext.Provider
                value={{
                    api: {
                        userService: this.userService,
                        orgService: this.orgService,
                        authzService: this.authzService,
                        appMessageService: this.appMessageService,
                        deviceService: this.deviceService,
                    },
                    pendingInvitations: this.state.pendingInvitations,
                    loadPendingInvitations: () => this.loadPendingInvitations(),
                    sessionStorage: this.sessionStorage,
                    fetchKnownOrg: this.fetchKnownOrg.bind(this),
                    fetchOrgs: this.fetchOrgs.bind(this),
                    getRootOrgs: () => this.state.rootOrgs,
                    childrenOrgs: this.state.childrenOrgs,
                    loadRootOrgCildrens: this.loadRootOrgCildrens.bind(this),
                    createAndCacheTopOrg: this.createAndCacheTopOrg.bind(this),
                    updateAndCacheTopOrg: this.updateAndCacheTopOrg.bind(this),
                    updateCachedOrgParams: this.updateCachedOrgParams.bind(this),
                    reloadRootOrgs: () => this.loadRootOrgs(),
                    loadTriggerEntities: this.loadTriggerEntities.bind(this),
                    getDeviceAssignedOrgIdsFromDefinitionIds: this.getDeviceAssignedOrgIdsFromDefinitionIds.bind(this),
                    csvExportTriggerEntities: this.csvExportTriggerEntities.bind(this),
                    jsonExportActionEntities: this.jsonExportActionEntities.bind(this),
                    loadActionEntities: this.loadActionEntities.bind(this),
                    getSelectedRootOrg: this.getSelectedRootOrg.bind(this),
                    setSelectedRootOrg: this.setSelectedRootOrg.bind(this),
                    loadReplacementEntities: this.loadReplacementEntities.bind(this),
                    createRootOrgGroupsIfNotExists: this.createRootOrgGroupsIfNotExists.bind(this),
                    prepareRootOrgGroups: this.prepareRootOrgGroups.bind(this),
                    canManageRootOrg: this.canManageRootOrg.bind(this),
                    getManageableUsers: () => this.state.users,
                    getPublicUser: this.getPublicUser.bind(this),
                    setRootOrgNickname: this.setRootOrgNickname.bind(this),
                    fetchReplacementTitles: this.fetchReplacementTitles.bind(this)
                }}>
                {this.state.ready && (
                    <Fragment>
                        {this.props.children}
                    </Fragment>
                )}
                {!this.state.ready && (<Loading />)}
            </PageContext.Provider>
        )
    }
}

const PageConsumer = PageContext.Consumer

const styles = theme => ({

})

export default withTranslation()(withStyles(styles)(PageProvider))
export { PageConsumer }