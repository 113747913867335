import React, { useEffect, useContext, useReducer, useCallback } from 'react'
import { withRouter } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Box, Select, MenuItem } from '@material-ui/core'
import { ApiContext, ORG_TAG, sortItems, APP_MODE } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import clsx from 'clsx'
import ReactSVG from 'react-svg'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { IconWithTextButton } from '../Components/HeaderBar'
import { PARAM_APP_MSG_ITEM_SORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { USER_PARAM_KEY_RECIPIENT, USER_PARAM_KEY_SUPPLIER } from '../../../m2m-cloud-api/MessageLog/Contants'

const getRootOrgs = (orgs) => {
    return orgs?.filter(org => org.hasTags([ORG_TAG.ROOT]))
}

const getChildrenOrgs = (orgId, orgs, appMode = APP_MODE.SUPPLIER) => {
    const allowedTags = [ORG_TAG.ROOT, ORG_TAG.GROUP]
    if (appMode === APP_MODE.RECIPIENT) {
        allowedTags.push(ORG_TAG.RECIPIENT_LOCATION)
    } else if (appMode === APP_MODE.SUPPLIER) {
        //allowedTags.push(ORG_TAG.SUPPLIER_TARGET)
    }
    let childrenOrgs = orgs.filter(item => item.hasTags(allowedTags) && item.getParentId() === orgId)
    const parentOrg = orgs.find(_org => _org.getId() === orgId)
    if (parentOrg) {
        const keySuffix = parentOrg.hasTags([ORG_TAG.SUPPLIER_TARGET]) ? APP_MODE.SUPPLIER : parentOrg.hasTags([ORG_TAG.RECIPIENT_LOCATION]) ? USER_PARAM_KEY_RECIPIENT : null
        const recipientSortParam = parentOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}${keySuffix ? `-${keySuffix}` : ''}`)
        childrenOrgs = sortItems(recipientSortParam, childrenOrgs)
    }
    return childrenOrgs
}


const OrgSelect = (props) => {
    const { orgs, level = 0, onChange, getSelectedValue } = props
    //console.log('org select ', level, orgs)
    const classes = useStyles()
    const value = getSelectedValue(level)
    return (
        <div className={[classes.selectContainer, orgs?.length === 1 && !orgs[0].hasTags([ORG_TAG.RECIPIENT_LOCATION]) ? classes.hiddenSelect : ''].join(' ')}>
            <ReactSVG src="/assets/light/icon-light-next-page.svg" className={classes.nextIcon} />
            <Select
                id={String(level)}
                value={value}
                className={classes.select}
                IconComponent={props => <KeyboardArrowDownIcon {...props} className={classes.selectIcon} />}
                onChange={(event) => onChange(event.target.value, level)}>
                {orgs.map(org => <MenuItem key={org.getId()} value={org.getId()}>{org.getName()}</MenuItem>)}
            </Select>
        </div>
    )
}


const HeaderBreadcrumb = ({ appMode, onChangeSelect, history, t, ...props }) => {
    const context = useContext(ApiContext)
    const classes = useStyles()

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { loading: true, orgs: null, values: [] }
    )

    const onChangeValues = useCallback((orgs, values) => {
        let org = null
        for (let index = 0; index < values.length; index++) {
            const orgId = values[index]
            const currentOrg = orgs.find(_org => _org.getId() === orgId)
            if (appMode === APP_MODE.SUPPLIER && currentOrg.hasTags([ORG_TAG.SUPPLIER_TARGET])) {
                org = currentOrg

            } else if (appMode === APP_MODE.RECIPIENT && currentOrg.hasTags([ORG_TAG.RECIPIENT_LOCATION])) {
                org = currentOrg
            }
        }
        if (appMode === APP_MODE.RECIPIENT) {
            context.setRecipientOrgIds(values, true)
        } else if (appMode === APP_MODE.SUPPLIER) {
            context.setSupplierParentOrgIds(values, true)
        }
        onChangeSelect && onChangeSelect(org)
    }, [])

    const init = useCallback(async () => {
        let selectedOrgs = []
        if (appMode === APP_MODE.SUPPLIER) {
            selectedOrgs = context.getSupplierParentOrgIds()
        } else if (appMode === APP_MODE.RECIPIENT) {
            selectedOrgs = context.getRecipientOrgIds()
        }

        const orgs = []
        if (context.allAccessibleOrgs) {

            const addOrg = (org) => {
                if (!orgs.find(_org => _org.getId() === org.getId())) {
                    orgs.push(org)
                }
            }
            const addOrgAndParents = (org) => {
                addOrg(org)
                const parentOrg = context.allAccessibleOrgs?.find(_org => _org.getId() === org.getParentId())
                if (parentOrg) {
                    addOrgAndParents(parentOrg)
                }
            }

            for (let index = 0; index < context.allAccessibleOrgs.length; index++) {
                const org = context.allAccessibleOrgs[index]
                if (appMode === APP_MODE.SUPPLIER) {
                    if (org.getTags().indexOf(ORG_TAG.SUPPLIER_TARGET) !== -1) {
                        addOrgAndParents(org)
                    }
                } else if (appMode === APP_MODE.RECIPIENT) {
                    if (org.getTags().indexOf(ORG_TAG.RECIPIENT_LOCATION) !== -1) {
                        addOrgAndParents(org)
                    }
                }
            }
        }

        const rootOrgs = getRootOrgs(orgs)
        const values = selectedOrgs
        let currentOrg = rootOrgs.length > 0 ? rootOrgs[0] : null
        while (currentOrg) {
            const currentOrgChildrens = getChildrenOrgs(currentOrg.getId(), orgs, appMode)
            currentOrg = currentOrgChildrens.length > 0 ? currentOrgChildrens[0] : null
        }
        setState({ orgs, loading: false, values })
    }, [])

    const getSelectedValue = useCallback((level) => {
        return state.values?.length >= level ? state.values[level] : null
    }, [state.values])

    const onChange = useCallback((orgId, level) => {
        const values = level === 0 ? [] : [...state.values].slice(0, level)
        values.push(orgId)
        const childrenOrgs = getChildrenOrgs(orgId, state.orgs, appMode)
        let currentOrg = childrenOrgs.length > 0 ? childrenOrgs[0] : null
        while (currentOrg) {
            values.push(currentOrg.getId())
            const currentOrgChildrens = getChildrenOrgs(currentOrg.getId(), state.orgs, appMode)
            currentOrg = currentOrgChildrens.length > 0 ? currentOrgChildrens[0] : null
        }
        setState({ values })
        onChangeValues(state.orgs, values)
    }, [state.orgs, state.values])

    const gotoSelectScreen = () => {
        history.push('/messaging')
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (state.orgs) {
            const selectedRecipientObjectOrg = context.getSelectedRecipientObjectOrg()
            if (!selectedRecipientObjectOrg) {
                const foundRootOrg = state.orgs.find(_org => _org.hasTags([ORG_TAG.ROOT]))
                foundRootOrg && onChange(foundRootOrg.getId(), 0)
            }
        }
    }, [state.orgs])

    if (state.loading) return (null)
    const rootOrgs = getRootOrgs(state.orgs)

    return (
        <Box className={clsx(classes.root, props.className && props.className)}>
            <IconWithTextButton Icon={<ReactSVG src={appMode === APP_MODE.SUPPLIER ? '/assets/light/recipient.svg' : '/assets/light/delivery.svg'} />} onPress={gotoSelectScreen} />
            {rootOrgs && <OrgSelect orgs={rootOrgs} onChange={onChange} level={0} getSelectedValue={getSelectedValue} />}
            {state?.values?.map((selectedOrgId, level) => {
                const orgs = getChildrenOrgs(selectedOrgId, state.orgs, appMode)
                return orgs && orgs.length > 0 ? <OrgSelect orgs={orgs} key={level + 1} onChange={onChange} level={level + 1} getSelectedValue={getSelectedValue} /> : null
            })}
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    selectContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    select: {
        backgroundColor: 'rgba(255,255,255,0.2)',
        color: theme.palette.common.white,
        fontSize: 14,
        fontWeight: 500,
        padding: '0px 8px'
    },
    selectIcon: {
        top: 'calc(50% - 12px)',
        color: 'rgba(255, 255, 255, 0.4)',
        right: 0,
        position: 'absolute',
        pointerEvents: 'none'
    },
    hiddenSelect: {
        display: 'none'
    }
}))

export default withRouter(withTranslation()(HeaderBreadcrumb))