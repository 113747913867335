import React from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress} from '@material-ui/core'
import { PageContext } from '../Context/PageProvider'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { buildRootOrgNicknameParam } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'

class ChangeNickname extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            errorMessage: null,
            nickname: ''
        }
    }

    async componentDidMount() {
        const { rootOrg, userId, context } = this.props
        try {
            let nickname = await context.api.orgService.getParam( rootOrg.getId(), buildRootOrgNicknameParam(userId) )
            if (!nickname || nickname.trim() === "") {
                const publicUser = await context.getPublicUser( userId )
                nickname = publicUser.getNickname()
            }
            this.setState({errorMessage: null, nickname, loading: false})
        } catch (error) {
            this.setState({errorMessage: mapErrorMessage(error), loading: false})
        }
    }

    handleSubmit() {
        this.setState({ loading: true, errorMessage: null })
        const { nickname } = this.state
        const { t, context, rootOrg, userId, onSuccess } = this.props

        this.setState({loading: true}, async () => {
            try {
                await context.setRootOrgNickname(userId, nickname)
                onSuccess()
            } catch (error) {
                this.setState({errorMessage: mapErrorMessage(error), loading: false})
            }
        })
    }

    render() {
        const { nickname, loading, errorMessage } = this.state
        const { t, classes } = this.props

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    { loading && <LinearProgress /> }
                    <DialogTitle id="form-dialog-title">{t('edit_nickname')}</DialogTitle>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit.bind(this)}
                        onError={errors => console.log("form error:", errors)}>
                        <DialogContent>
                            <TextValidator
                                className={classes.field}
                                value={nickname}
                                disabled={loading}
                                onChange={(event) => {
                                    this.setState({nickname: event.target.value, errorMessage: null})
                                }}
                                margin="dense"
                                multiline={false}
                                label={ t('nickname') }
                                fullWidth
                            />

                            {errorMessage && <DialogContentText className={classes.errorText}>
                                {errorMessage}
                            </DialogContentText>}
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button disabled={loading} onClick={() => this.refs.form.submit()} type="submit" color="primary">
                                {t('save')}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        )
    }
}

ChangeNickname.contextType = PageContext


ChangeNickname.propTypes = {
    context: PropTypes.any.isRequired,
    userId: PropTypes.string.isRequired,
    rootOrg: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}


const styles = theme => ({
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20
    },
    field: {
        marginTop: 10,
        marginBottom: 10
    }
})


export default withTranslation()(withStyles(styles)(ChangeNickname))