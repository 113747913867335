import React, { useContext, useState, useEffect, Fragment } from 'react'
import { IconButton, Dialog, DialogTitle, Typography, DialogContent, List, ListItem, ListItemText, Divider, DialogActions, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ApiContext, sortItems } from '../../../../m2m-cloud-api/MessageLog/ApiContext'
import { ORG_TAG } from '../../../../m2m-cloud-api'
import CloseIcon from '@material-ui/icons/Close'
import { PARAM_APP_MSG_ITEM_SORT } from '../../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { USER_PARAM_KEY_RECIPIENT } from '../../../../m2m-cloud-api/MessageLog/Contants'

export const ORG_SELECTION_TYPE = {
    RECIPIENT_OBJECT: 0,
    SUPPLIER_GROUP: 1
}

const getRelevantRootAndGroupOrgs = (context, orgSelectionType) => {
    const endOrgs = context.allAccessibleOrgs.filter( _org => _org.hasTags(orgSelectionType === ORG_SELECTION_TYPE.RECIPIENT_OBJECT ? [ORG_TAG.RECIPIENT_LOCATION] : [ORG_TAG.SUPPLIER_TARGET] ))
    const orgs = [...endOrgs]
    for (let index = 0; index < endOrgs.length; index++) {
        const endOrg = endOrgs[index]
        let searchOrg = endOrg && endOrg.getParentId() && context.allAccessibleOrgs.find( _org => _org.getId() === endOrg.getParentId() )
        do {
            if ( searchOrg && !orgs.find(_org => _org.getId() === searchOrg.getId()) ) {
                orgs.push(searchOrg)
            }
            searchOrg = searchOrg && searchOrg.getParentId() ? context.allAccessibleOrgs.find( _org => _org.getId() === searchOrg.getParentId() ) : null
        } while (searchOrg)
    }
    return orgs
}

const GroupSelectionModal = ({ visible, onClose, orgSelectionType }) => {
    const context = useContext(ApiContext)
    const classes = useStyles()
    const { t } = useTranslation()

    const [selectedOrgs, setSelectedOrgs] = useState([])
    const [title, setTitle] = useState(null)
    const [items, setItems] = useState([])

    useEffect(() => {
        const relevantRootAndGroupOrgs = getRelevantRootAndGroupOrgs(context, orgSelectionType)
        const rootOrgs = sortItems(null, relevantRootAndGroupOrgs.filter( org => org.hasTags([ORG_TAG.ROOT])))
        const lastSelectedOrg = selectedOrgs.length > 0 ? selectedOrgs[selectedOrgs.length-1] : null
        const searchTags = orgSelectionType === ORG_SELECTION_TYPE.RECIPIENT_OBJECT ? [ORG_TAG.GROUP, ORG_TAG.RECIPIENT_LOCATION] : [ORG_TAG.GROUP]
        
        const selectedGroupOrgs = lastSelectedOrg && relevantRootAndGroupOrgs.filter( org => org.getParentId() === lastSelectedOrg.getId() && org.hasTags(searchTags) )
        const isSourceOrg = selectedGroupOrgs?.find(_org=> _org.hasTags([ORG_TAG.RECIPIENT_LOCATION])) ? true : false
        const keySuffix = orgSelectionType === ORG_SELECTION_TYPE.RECIPIENT_OBJECT && isSourceOrg  ? USER_PARAM_KEY_RECIPIENT : null
        const selectedOrgSort = lastSelectedOrg?.getParam(`${PARAM_APP_MSG_ITEM_SORT}${keySuffix ? `-${keySuffix}`: ''}`)
        const items = lastSelectedOrg ? sortItems(selectedOrgSort, selectedGroupOrgs) : rootOrgs

        let title = rootOrgs.length === 1 ? t('organization') : t('organizations')
        if (items.length === 0) {
            let hasSelectedGroup = selectedOrgs.find( org => org.hasTags([ORG_TAG.GROUP]) )
            let hasSelectedRecipientObject = selectedOrgs.find( org => org.hasTags([ORG_TAG.RECIPIENT_LOCATION]) )
            if (orgSelectionType === ORG_SELECTION_TYPE.SUPPLIER_GROUP && hasSelectedGroup) {
                onClose(selectedOrgs.map( org => org.getId()))
                return
            } else if (orgSelectionType === ORG_SELECTION_TYPE.RECIPIENT_OBJECT && hasSelectedRecipientObject) {
                onClose(selectedOrgs.map( org => org.getId()))
                return
            }
        } else {
            const firstOrg = items[0]
            if ( firstOrg.hasTags([ORG_TAG.GROUP]) ) {
                title = items.length === 1 ? t('group') : t('groups')
            } else if ( firstOrg.hasTags([ORG_TAG.RECIPIENT_LOCATION]) ) {
                title = items.length === 1 ? t('location') : t('locations')
            }
        }
        setItems(items)
        setTitle(title)
    }, [context.allAccessibleOrgs, selectedOrgs])

    const onItemPress = (item) => {
        const orgs = [...selectedOrgs]
        orgs.push(item)
        setSelectedOrgs(orgs)
    }

    const onBackPress = () => {
        const orgs = [...selectedOrgs].slice(0,-1)
        setSelectedOrgs(orgs)
    }

    return (
        <Dialog fullWidth classes={{ paper: classes.rootPaper }} open={visible} onClose={() => console.log('onClose...')}>
            <DialogTitle className={classes.dialogTitle}>
                <Typography variant="inherit">{title}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={() => onClose(null)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                <Fragment>
                    <Typography className={classes.itemCount}>{items ? items.length : 0} {title}</Typography>
                    <List className={classes.list} component="nav" aria-label="groups">
                        {items.length === 0 && (
                            <ListItem>
                                <ListItemText className={[classes.listItemText, classes.emptyListLabel].join(' ')} primary={t('no_entries_available')} />
                            </ListItem>
                        )}
                        {items.length > 0 && items.map(item => (
                            <div key={item.getId()}>
                                <ListItem button onClick={() => onItemPress(item)}>
                                    <ListItemText className={classes.listItemText} primary={item.getName()} />
                                </ListItem>
                                <Divider />
                            </div>
                        ))}
                    </List>
                </Fragment>
            </DialogContent>
            { selectedOrgs.length > 0 && <DialogActions classes={{ root: classes.dialogActions }}>
                <Button onClick={onBackPress} color="primary">{t('back')}</Button>
            </DialogActions>}
        </Dialog>
    )
}

GroupSelectionModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired
}

const useStyles = makeStyles(theme => ({
    rootPaper: {
        maxWidth: 450,
        minHeight: 400,
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    itemCount: {
        position: 'absolute',
        zIndex: 2,
        width: '100%',
        height: 26,
        backgroundColor: '#ddd',
        padding: 2,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    dialogContent: {
        padding: 0,
    },
    dialogActions: {
        justifyContent: 'flex-start'
    },
    list: {
        padding: 0,
        paddingTop: 26
    },
    loaderContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: -20,
        marginTop: -20
    },
    loader: {
        flex: 1,
    },
    listItemText: {
        padding: 0,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        fontSize: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingVertical: theme.spacing(2),
            paddingHorizontal: theme.spacing(3),
        },
    },
    emptyListLabel: {
        color: theme.palette.secondary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#fff',
    }
}))

export default GroupSelectionModal

