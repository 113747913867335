import Message from './Models/Message';
import MessageAction from './Models/MessageAction';
import MessageTemplate from './Models/MessageTemplate';
import MessageDefinition from './Models/MessageDefinition';
import Replacement from './Models/Replacement';
import MemoryCache from '../MemoryCache';

const { getInvalidParameterError, ApiError } = require('../Errors');

const API_SERVICE_KEY_APP_MESSAGE = 'app-msg';
export const API_SERVICE_KEY_FILE_SERVICE = 'core-fileservice';

export default class AppMessageService {
  /**
   * @param {Api} api
   */
  constructor(api) {
    this.api = api;
    this.actionCache = new MemoryCache();
    this.templateCache = new MemoryCache();
    this.definitionCache = new MemoryCache();
  }

  /**
   * Get action
   * @param {Uid} actionId (required)
   *
   * @returns Promise<MessageAction>
   */
  getAction(actionId) {
    if (!actionId) {
      return Promise.reject(getInvalidParameterError('actionId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/actions/:actionId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':actionId': actionId,
    });
    return this.api.request('GET', url, null).then(action => new MessageAction(action));
  }

  /**
   * Get multiple actions
   * @param {Array<Uid>} actionIds
   * @returns Promise<Array<MessageAction>>
   */
  getMultipleActions(actionIds) {
    if (!actionIds) {
      return Promise.reject(getInvalidParameterError('actionId'));
    }

    if (actionIds.length === 0) {
      return Promise.resolve([]);
    }

    const url = this.api.buildRequestURL('/:serviceKey/api/actions/getMultiple', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
    });
    return this.api.request('POST', url, actionIds).then(actions => {
      actions.map((action, index) => {
        actions[index] = new MessageAction(action);
      });
      return actions;
    });
  }

  /**
   * Get action from given org
   * @param {Uid} orgId (required)
   *
   * @returns Promise<Array<MessageAction>>
   */
  getActions(orgId) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }

    const cachedList = this.actionCache.getList(orgId);
    if (cachedList) return Promise.resolve(cachedList);

    const url = this.api.buildRequestURL('/:serviceKey/api/actions/byOrg/:orgId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':orgId': orgId,
    });
    return this.api.request('GET', url, null).then(actions => {
      actions.map((action, index) => {
        actions[index] = new MessageAction(action);
      });
      this.actionCache.setList(actions, orgId);
      return actions;
    });
  }

  /**
   * Insert or update action
   * @param {Uid} actionId - self generated uid to create or action uid to update
   * @param {String} name
   * @param {Object} data
   * @param {Uid} orgId
   * @param {MESSAGE_ACTION_TYPE} type
   *
   *
   * @returns Promise<Boolean>
   */
  upsertAction(actionId, name, data, orgId, type) {
    const url = this.api.buildRequestURL('/:serviceKey/api/actions/:actionId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':actionId': actionId,
    });
    const body = { name, data, orgId, type };

    const _upsertAction = actionBeforeUpsert => {
      return this.api.request('PUT', url, body).then(result => {
        if (result.done) {
          return this.getAction(actionId).then(action => {
            if (actionBeforeUpsert && actionBeforeUpsert.getOrgId() === orgId) {
              this.actionCache.updateItem(action, orgId);
            } else {
              if (actionBeforeUpsert && actionBeforeUpsert.getOrgId() !== orgId) {
                this.actionCache.removeItem(actionId, actionBeforeUpsert.getOrgId());
              }
              this.actionCache.resetList(orgId);
            }
            return result.done;
          });
        } else {
          return result.done;
        }
      });
    };

    return this.getAction(actionId)
      .then(actionBeforeUpsert => _upsertAction(actionBeforeUpsert))
      .catch(error => _upsertAction(null));
  }

  /**
   * Delete action
   * @param {MessageAction} action (required)
   *
   * @returns Promise<Boolean>
   */
  deleteAction(action) {
    if (!action || !action.getId()) {
      return Promise.reject(getInvalidParameterError('actionId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/actions/:actionId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':actionId': action.getId(),
    });
    return this.api.request('DELETE', url, null).then(result => {
      if (result.done) {
        this.actionCache.removeItem(action.getId(), action.getOrgId());
      }
      return result.done;
    });
  }

  /**
   * Get template
   * @param {Uid} templateId (required)
   *
   * @returns Promise<MessageTemplate>
   */
  getTemplate(templateId) {
    if (!templateId) {
      return Promise.reject(getInvalidParameterError('templateId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/templates/:templateId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':templateId': templateId,
    });
    return this.api.request('GET', url, null).then(template => new MessageTemplate(template));
  }

  /**
   * Get multiple templates
   * @param {Array<Uid>} templateIds
   * @returns Promise<Array<MessageTemplate>>
   */
  getMultipleTemplates(templateIds) {
    if (!templateIds) {
      return Promise.reject(getInvalidParameterError('templateId'));
    }

    if (templateIds.length === 0) {
      return Promise.resolve([]);
    }

    const url = this.api.buildRequestURL('/:serviceKey/api/templates/getMultiple', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
    });
    return this.api.request('POST', url, templateIds).then(templates => {
      templates.map((template, index) => {
        templates[index] = template ? new MessageTemplate(template) : null;
      });
      return templates;
    });
  }

  /**
   * Get action from given org
   * @param {Uid} orgId (required)
   *
   * @returns Promise<Array<MessageTemplate>>
   */
  getTemplates(orgId) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }

    const cachedList = this.templateCache.getList(orgId);
    if (cachedList) return Promise.resolve(cachedList);

    const url = this.api.buildRequestURL('/:serviceKey/api/templates/byOrg/:orgId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':orgId': orgId,
    });
    return this.api.request('GET', url, null).then(templates => {
      templates.map((template, index) => {
        templates[index] = new MessageTemplate(template);
      });
      this.templateCache.setList(templates, orgId);
      return templates;
    });
  }

  /**
   * Insert or update template
   * @param {Uid} templateId - self generated uid to create or template uid to update
   * @param {String} name
   * @param {String} header
   * @param {String} body
   * @param {Uid} orgId
   * @param {Array<Uid>} replacementIds
   *
   *
   * @returns Promise<Boolean>
   */
  upsertTemplate(templateId, name, header, body, orgId, replacementIds = []) {
    const _upsertTemplate = templateBeforeUpsert => {
      return this.createTemplate(templateId, name, header, body, orgId, replacementIds).then(success => {
        if (success) {
          return this.getTemplate(templateId).then(template => {
            if (templateBeforeUpsert && templateBeforeUpsert.getOrgId() === orgId) {
              this.templateCache.updateItem(template, orgId);
            } else {
              if (templateBeforeUpsert && templateBeforeUpsert.getOrgId() !== orgId) {
                this.templateCache.removeItem(templateId, templateBeforeUpsert.getOrgId());
              }
              this.templateCache.resetList(orgId);
            }
            return template;
          });
        } else {
          return null;
        }
      });
    };

    return this.getTemplate(templateId)
      .then(templateBeforeUpsert => _upsertTemplate(templateBeforeUpsert))
      .catch(error => _upsertTemplate(null));
  }

  /**
   * Insert template
   * @param {Uid} templateId - self generated uid
   * @param {String} name
   * @param {String} header
   * @param {String} body
   * @param {Uid} orgId
   * @param {Array<Uid>} replacementIds
   * @returns {Boolean}
   */
  createTemplate(templateId, name, header, body, orgId, replacementIds = []) {
    const url = this.api.buildRequestURL('/:serviceKey/api/templates/:templateId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':templateId': templateId,
    });
    const requestBody = { name, header, body, orgId, replacementIds };
    return this.api.request('PUT', url, requestBody).then(result => result.done);
  }

  /**
   * Delete template
   * @param {MessageTemplate} template (required)
   *
   * @returns Promise<Boolean>
   */
  deleteTemplate(template) {
    if (!template || !template.getId()) {
      return Promise.reject(getInvalidParameterError('templateId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/templates/:templateId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':templateId': template.getId(),
    });
    return this.api.request('DELETE', url, null).then(result => {
      if (result.done) {
        this.templateCache.removeItem(template.getId(), template.getOrgId());
      }
      return result.done;
    });
  }

  /**
   * Get definition
   * @param {Uid} definitionId (required)
   *
   * @returns Promise<MessageDefinition>
   */
  getDefinition(definitionId) {
    if (!definitionId) {
      return Promise.reject(getInvalidParameterError('definitionId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/definitions/:definitionId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':definitionId': definitionId,
    });
    return this.api.request('GET', url, null).then(definition => new MessageDefinition(definition));
  }

  /**
   * Get definitions
   * @param {Uid} orgId (required)
   *
   * @returns Promise<Array<MessageDefinition>>
   */
  getDefinitions(orgId) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }

    const cachedList = this.definitionCache.getList(orgId);
    if (cachedList) return Promise.resolve(cachedList);

    const url = this.api.buildRequestURL('/:serviceKey/api/definitions/byOrg/:orgId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':orgId': orgId,
    });
    return this.api.request('GET', url, null).then(definitions => {
      definitions.map((definition, index) => {
        definitions[index] = new MessageDefinition(definition);
      });
      this.definitionCache.setList(definitions, orgId);
      return definitions;
    });
  }

  /**
   * Get definitions by multiple device ids
   * @param {Array<Uid>} deviceIds
   */
  getDefinitionsByMultipleDeviceId(deviceIds) {
    if (!deviceIds) {
      return Promise.reject(getInvalidParameterError('deviceIds'));
    }

    const url = this.api.buildRequestURL('/:serviceKey/api/definitions/byDevice', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
    });
    return this.api.request('POST', url, deviceIds).then(definitions => {
      definitions.map((definition, index) => {
        definitions[index] = new MessageDefinition(definition);
      });
      return definitions;
    });
  }

  /**
   * Insert or update definition
   * @param {Uid} definitionId - self generated uid to create or definition uid to update
   * @param {Uid} actionId
   * @param {Uid} deviceId
   * @param {Array<String>} events
   * @param {Object<keyof,value>} params
   * @param {Uid} orgId
   * @param {Uid} templateId
   *
   *
   * @returns Promise<Boolean>
   */
  registerDefinition(definitionId, actionId, deviceId, events, params, orgId, templateId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/definitions/:definitionId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':definitionId': definitionId,
    });
    const requestBody = { actionId, deviceId, events, params, orgId, templateId };
    return this.api.request('PUT', url, requestBody).then(result => {
      this.definitionCache.resetList(orgId);
      return result.done;
    });
  }

  /**
   * update definition
   * @param {Uid} definitionId
   * @param {Uid} actionId
   * @param {Object<keyof,value>} params
   * @param {Uid} orgId
   * @param {Uid} templateId
   *
   *
   * @returns Promise<Boolean>
   */
  updateDefinition(definitionId, actionId, params, orgId, templateId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/definitions/:definitionId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':definitionId': definitionId,
    });
    const requestBody = { actionId, params, orgId, templateId };

    return this.api.request('PATCH', url, requestBody).then(result => {
      if (result.done) {
        return this.getDefinition(definitionId).then(definition => {
          this.definitionCache.updateItem(definition, orgId);
          return result.done;
        });
      } else {
        return result.done;
      }
    });
  }

  /**
   * Delete definition
   * @param {MessageDefinition} definition (required)
   *
   * @returns Promise<Boolean>
   */
  deleteDefinition(definition) {
    if (!definition || !definition.getId()) {
      return Promise.reject(getInvalidParameterError('definitionId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/definitions/:definitionId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':definitionId': definition.getId(),
    });
    return this.api.request('DELETE', url, null).then(result => {
      if (result.done) {
        this.definitionCache.removeItem(definition.getId(), definition.getOrgId());
      }
      return result.done;
    });
  }

  /**
   * For a given list of devices, return only those, which are currently not mapped to a definition.
   * @param {Array<Uid>} deviceIds
   * @returns Promise<Array<Uid>>
   */
  getUnusedDevices(deviceIds) {
    if (!deviceIds) {
      return Promise.reject(getInvalidParameterError('deviceIds'));
    }

    const url = this.api.buildRequestURL('/:serviceKey/api/getUnusedDevices', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
    });
    return this.api.request('POST', url, deviceIds);
  }

  /**
   * read messages by definition org
   * @param {Uid} orgId
   * @param {Number} offset
   * @param {Number} size
   * @param {Boolean} ackState
   * @param {Date} from
   * @param {Date} to
   *
   * @returns Promise<Array<Message>>
   */

  readMessagesByDefinitionOrg(orgId, offset = 0, size = 100, ackState = false, from = null, to = null) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/byDefinitionOrg/:orgId?offset=:offset&size=:size&ackState=:ackState:from:to', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':orgId': orgId,
      ':offset': offset,
      ':size': size,
      ':ackState': ackState ? 'true' : 'false',
      ':from': from ? '&from=' + from.getTime() : '',
      ':to': to ? '&to=' + to.getTime() : '',
    });
    return this.api.request('GET', url, null).then(messages => {
      messages.map((message, index) => {
        messages[index] = new Message(message);
      });
      return messages;
    });
  }

  /**
   * read messages by device org
   * @param {Uid} orgId
   * @param {Number} offset
   * @param {Number} size
   * @param {Boolean} ackState
   * @param {Date} from
   * @param {Date} to
   *
   * @returns Promise<Array<Message>>
   */

  readMessagesByDeviceOrg(orgId, offset = 0, size = 100, ackState = false, from = null, to = null) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/byDeviceOrg/:orgId?offset=:offset&size=:size&ackState=:ackState:from:to', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':orgId': orgId,
      ':offset': offset,
      ':size': size,
      ':ackState': ackState ? 'true' : 'false',
      ':from': from ? '&from=' + from.getTime() : '',
      ':to': to ? '&to=' + to.getTime() : '',
    });
    return this.api.request('GET', url, null).then(messages => {
      messages.map((message, index) => {
        messages[index] = new Message(message);
      });
      return messages;
    });
  }

  /**
   * read messages by multiple definition orgs
   * @param {Array<Uid>} orgIds
   * @param {Number} offset
   * @param {Number} size
   * @param {Boolean} ackState
   * @param {Date} from (UTC)
   * @param {Date} to (UTC)
   *
   * @returns Promise<Array<Message>>
   */
  readMultipleMessagesByDefinitionOrg(orgIds, offset = 0, size = 100, ackState = false, from = null, to = null) {
    if (!orgIds) {
      return Promise.reject(getInvalidParameterError('orgIds'));
    }
    if (orgIds.length === 0) {
      return Promise.resolve([]);
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/byDefinitionOrg?offset=:offset&size=:size&ackState=:ackState:from:to', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':offset': offset,
      ':size': size,
      ':ackState': ackState ? 'true' : 'false',
      ':from': from ? '&from=' + from.getTime() : '',
      ':to': to ? '&to=' + to.getTime() : '',
    });
    return this.api.request('POST', url, orgIds).then(messages => {
      messages.map((message, index) => {
        messages[index] = new Message(message);
      });
      return messages;
    });
  }

  /**
   * read messages by multiple device orgs
   * @param {Array<Uid>} orgIds
   * @param {Number} offset
   * @param {Number} size
   * @param {Boolean} ackState
   * @param {Date} from (UTC)
   * @param {Date} to (UTC)
   *
   * @returns Promise<Array<Message>>
   */
  readMultipleMessagesByDeviceOrg(orgIds, offset = 0, size = 100, ackState = false, from = null, to = null) {
    if (!orgIds) {
      return Promise.reject(getInvalidParameterError('orgIds'));
    }
    if (orgIds.length === 0) {
      return Promise.resolve([]);
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/byDeviceOrg?offset=:offset&size=:size&ackState=:ackState:from:to', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':offset': offset,
      ':size': size,
      ':ackState': ackState ? 'true' : 'false',
      ':from': from ? '&from=' + from.getTime() : '',
      ':to': to ? '&to=' + to.getTime() : '',
    });
    return this.api.request('POST', url, orgIds).then(messages => {
      messages.map((message, index) => {
        messages[index] = new Message(message);
      });
      return messages;
    });
  }

  /**
   * set message processing
   * @param {Uid} msgId
   *
   * @returns Promise<Boolean>
   */
  setMessageProcessing(msgId) {
    if (!msgId) {
      return Promise.reject(getInvalidParameterError('msgId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/:msgId/processing', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':msgId': msgId,
    });
    return this.api.request('GET', url, null).then(message => new Message(message));
  }

  /**
   * unset message processing
   * @param {Uid} msgId
   *
   * @returns Promise<Boolean>
   */
  unsetMessageProcessing(msgId) {
    if (!msgId) {
      return Promise.reject(getInvalidParameterError('msgId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/:msgId/processing', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':msgId': msgId,
    });
    return this.api.request('DELETE', url, null).then(message => new Message(message));
  }

  /**
   * set message acknowledged
   * @param {Uid} msgId
   * @param {Object} changedParams
   *
   * @returns Promise<Message>
   */
  setMessageAck(msgId, changedParams) {
    //check changedParams
    if (!msgId) {
      return Promise.reject(getInvalidParameterError('msgId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/:msgId/ack', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':msgId': msgId,
    });
    return this.api.request('POST', url, { changedParams }).then(message => new Message(message));
  }

  /**
   * set message acknowledged report
   * @param {Uid} msgId
   * @param {String} message
   *
   * @returns Promise<Message>
   */
  setMessageAckReport(msgId, message) {
    if (!msgId) {
      return Promise.reject(getInvalidParameterError('msgId'));
    }
    if (!message || message.trim() === '') {
      return Promise.reject(getInvalidParameterError('message'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/:msgId/ack', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':msgId': msgId,
    });
    return this.api.request('POST', url, { text: message }).then(message => new Message(message));
  }

  /**
   * Delete message
   * @param {Uid} msgId (required)
   *
   * @returns Promise<Boolean>
   */
  deleteMessage(msgId) {
    if (!msgId) {
      return Promise.reject(getInvalidParameterError('msgId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/:msgId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':msgId': msgId,
    });
    return this.api.request('DELETE', url, null).then(result => result.done);
  }

  /**
   * get replacement def
   * @param {Uid} replacementId
   * @returns {Replacement}
   */
  getReplacementDef(replacementId) {
    if (!replacementId) {
      return Promise.reject(getInvalidParameterError('replacementId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/replacementdefs/:replacementId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':replacementId': replacementId,
    });
    return this.api.request('GET', url, null).then(result => new Replacement(result));
  }

  /**
   * read replacement defs by org
   * @param {Uid} orgId
   * @returns {Array<Replacement>}
   */
  readReplacementDefsByOrg(orgId) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/replacementdefs/byOrg/:orgId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':orgId': orgId,
    });
    return this.api.request('GET', url, null).then(results => {
      results.map((result, index) => {
        results[index] = new Replacement(result);
      });
      return results;
    });
  }

  /**
   * read multiple replacement defs
   * @param {Array<Uid>} ids
   * @returns {Array<Replacement>}
   */
  readMultipleReplacementDefs(ids) {
    if (!ids) {
      return Promise.reject(getInvalidParameterError('ids'));
    }

    if (ids.length === 0) {
      return Promise.resolve([]);
    }

    const url = this.api.buildRequestURL('/:serviceKey/api/replacementdefs/', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
    });
    return this.api.request('POST', url, ids).then(results => {
      results.map((result, index) => {
        results[index] = new Replacement(result);
      });
      return results;
    });
  }

  /**
   * upsert replacement def
   * @param {Uid} replacementId
   * @param {Uid} orgId
   * @param {String} name
   * @param {String} type
   * @param {Object} desc
   * @returns { Replacement || null }
   */
  upsertTextReplacement(replacementId, orgId, name, type, desc) {
    if (!replacementId) {
      return Promise.reject(getInvalidParameterError('replacementId'));
    }

    const url = this.api.buildRequestURL('/:serviceKey/api/replacementdefs/:replacementId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':replacementId': replacementId,
    });

    const requestBody = { orgId, name, type, desc };
    return this.api.request('PUT', url, requestBody).then(result => {
      if (result.done) {
        return new Replacement({ replacementId, orgId, name, type, desc });
      }
      return null;
    });
  }

  /**
   * delete replacement def
   * @param {Uid} replacementId
   */
  deleteReplacementDef(replacementId) {
    if (!replacementId) {
      return Promise.reject(getInvalidParameterError('replacementId'));
    }

    const url = this.api.buildRequestURL('/:serviceKey/api/replacementdefs/:replacementId', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':replacementId': replacementId,
    });
    return this.api.request('DELETE', url, null).then(result => result.done);
  }

  /**
   * read tx count by org
   * @param {Uid} orgId
   * @param {Date} from (UTC)
   * @param {Date} to (UTC)
   * @param {Object} queryParams
   *
   * @returns Promise<String>
   */
  getTxCountByOrg(orgId, from, to, queryParams = null) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }
    if (!from) {
      return Promise.reject(getInvalidParameterError('from'));
    }
    if (!to) {
      return Promise.reject(getInvalidParameterError('to'));
    }
    let queryParamsString = '';
    if (queryParams && Object.keys(queryParams).length > 0) {
      Object.keys(queryParams).map(key => {
        queryParamsString += '&' + key + '=' + queryParams[key];
      });
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/report/TxCount/byOrg/:orgId?:from:to:queryParamsString', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':orgId': orgId,
      ':from': from ? '&from=' + from.getTime() : '',
      ':to': to ? '&to=' + to.getTime() : '',
      ':queryParamsString': queryParamsString,
    });
    console.log('url', url, queryParams);
    return this.api.request('GET', url, null, null, true, 'text');
  }

  /**
   * upload replacement file
   * @param {Uid} orgId
   * @param {Uid} definitionId
   * @param {Object} fileObject
   *
   * @returns Promise<Object>
   */
  setReplacementFile(orgId, definitionId, fileObject) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }
    if (!definitionId) {
      return Promise.reject(getInvalidParameterError('definitionId'));
    }
    if (!fileObject) {
      return Promise.reject(getInvalidParameterError('fileObject'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/files/?type=:type&typeInfo=:typeInfo&extension=:extension&org=:org', {
      ':serviceKey': API_SERVICE_KEY_FILE_SERVICE,
      ':type': 'app-msg.message.attachment',
      ':typeInfo': `${definitionId}:${new Date().getTime()}`,
      ':extension': fileObject.name.split('.').pop(),
      ':org': orgId,
    });

    const data = fileObject;
    return this.api.request('POST', url, data, null, true, 'json', fileObject.type).then(result => {
      return result;
    });
  }

  /**
   * get replacement file
   * @param {Uid} fileId
   * @param {Number} width
   * @param {Number} height
   *
   * @returns Promise<String>
   */
  getReplacementFile(fileId, width = null, height = null) {
    if (!fileId) {
      return Promise.reject(getInvalidParameterError('fileId'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/files/:fieldId/data:query', {
      ':serviceKey': API_SERVICE_KEY_FILE_SERVICE,
      ':fieldId': fileId,
      ':query': width && height ? `?convertor=thumbnail&convertorParams=${width}x${height}` : '',
    });
    return this.api.request('GET', url, null, null, null, 'blob').then(result => {
      return result;
    });
  }

  /**
   * update message
   * @param {Uid} messageId
   * @param {Object} params
   *
   * @returns Promise<Boolean>
   */
  updateMessage(messageId, params) {
    const url = this.api.buildRequestURL('/:serviceKey/api/messages/:msgId/update', {
      ':serviceKey': API_SERVICE_KEY_APP_MESSAGE,
      ':msgId': messageId,
    });
    const data = { params };
    return this.api.request('POST', url, data).then(result => {
      return new Message(result);
    });
  }
}
