import React, { useState, useContext, Fragment, useEffect } from 'react'
import { makeStyles, } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../Context/PageProvider'
import { Button, Dialog, DialogActions, Grid, DialogContent, DialogContentText, FormControlLabel, Checkbox, DialogTitle, TextField, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { PARAM_APP_MSG_AUTO_EXPORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { TimePicker } from '../../../Libs/Components/DatePicker'
import { REPLACEMENT_TYPE, REPLACEMENT_DATE_TYPE, REPLACEMENT_DATE_FORMAT } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import moment from 'moment'
import CronBuilder from 'cron-builder'

const PERIODS = {
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly'
}

const WEEKDAYS = {
    MONDAY: 'monday',
    TUESDAY: 'tuesday',
    WEDNESDAY: 'wednesday',
    THURSDAY: 'thursday',
    FRIDAY: 'friday',
    SATURDAY: 'saturday',
    SUNDAY: 'sunday'
}

const DAY_OF_MONTH = Array.from({ length: 31 }, (_, i) => i + 1)

const useStyles = makeStyles(theme => ({
    modalContent: {
        paddingBottom: theme.spacing(4)
    },
    formControlSelect: {
        marginRight: theme.spacing(1),
        '& .MuiInputBase-inputMarginDense': {
            paddingTop: 6
        }
    },
    datePicker: {
        marginTop: theme.margin.medium,
        marginBottom: theme.margin.medium,
        '& .Mui-disabled': {
            color: '#fff',
        },
        '& .Mui-disabled:before': {
            borderBottomStyle: 'inset'
        }
    }
}))

function AutoExportDialog({ org, onSubmit, onCancel, errorMessage }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const context = useContext(PageContext)
    const [loading, setLoading] = useState(false)


    const _org = context.api.orgService.getOrgFromCache(org.getId()) || org
    const getParamData = _org.getParam(PARAM_APP_MSG_AUTO_EXPORT)
    const autoExport = getParamData && JSON.parse(getParamData)
    const cronTimes = { time: moment(1, 'HH'), weekDay: WEEKDAYS.MONDAY, dayOfMonth: 1 }

    if (autoExport) {
        const decodeCron = autoExport.cron.split(' ')
        cronTimes.time = moment(`${decodeCron[2]}:${decodeCron[1]}`, 'HH:mm')

        if (autoExport.period === PERIODS.WEEKLY) {
            const weekdayIndex = Object.keys(WEEKDAYS).map(key => key.substring(0, 3)).findIndex(i => i === decodeCron[5])
            cronTimes.weekDay = Object.values(WEEKDAYS)[weekdayIndex]
        }
        if (autoExport.period === PERIODS.MONTHLY) {
            cronTimes.dayOfMonth = decodeCron[3]
        }
    }


    const [autoExportEnabled, setAutoExportEnabled] = useState(autoExport ? true : false)
    const [email, setEmail] = useState(autoExport ? autoExport.mail : '')
    const [period, setPeriod] = useState(autoExport ? autoExport.period : PERIODS.DAILY)
    const [weekDay, setWeekday] = useState(cronTimes.weekDay)
    const [dayOfMonth, setDayOfMonth] = useState(cronTimes.dayOfMonth)
    const [time, setTime] = useState(cronTimes.time)

    const prepareSubmit = () => {
        setLoading(true)
        if (autoExportEnabled) {
            const mins = moment(time).format('m')
            const hour = moment(time).format('H')

            let cronExpression = `0 ${mins} ${hour} 1/1 * ? *`
            if (period === PERIODS.WEEKLY) {
                const _weekDay = weekDay.toUpperCase().substring(0, 3)
                cronExpression = `0 ${mins} ${hour} ? * ${_weekDay} *`
            }
            if (period === PERIODS.MONTHLY) {
                cronExpression = `0 ${mins} ${hour} ${dayOfMonth} 1/1 ? *`
            }
            const param = {
                orgId: org.getId(),
                cron: cronExpression,
                period: period,
                format: 'csv',
                mail: email
            }

            onSubmit({ [PARAM_APP_MSG_AUTO_EXPORT]: param })
        } else {
            onSubmit({ [PARAM_APP_MSG_AUTO_EXPORT]: null })
        }
    }

    const TextFieldComponent = (props) => {
        return <TextField {...props} disabled={true} />
    }

    return (
        <Dialog open={true} fullWidth maxWidth={'xs'} aria-labelledby="alert">
            <ValidatorForm onSubmit={prepareSubmit} onError={errors => console.log("form error:", errors)}>
                {loading && <LinearProgress />}
                <DialogTitle id="alert-title">{t('auto_export')}</DialogTitle>
                <DialogContent className={classes.modalContent}>

                    <FormControl fullWidth margin="dense">
                        <FormControlLabel
                            control={<Checkbox name="custom-color" color="primary" checked={autoExportEnabled} onChange={({ target }) => setAutoExportEnabled(target.checked)} />}
                            label={t('auto_export_enabled')}
                        />
                    </FormControl>

                    {autoExportEnabled && <Fragment>
                        <TextValidator
                            autoFocus
                            margin="dense"
                            id="email"
                            value={email}
                            disabled={loading}
                            onChange={(event) => setEmail(event.target.value)}
                            label={t('email')}
                            fullWidth
                            validators={['required', 'isEmail']}
                            errorMessages={[t('this_field_is_required'), t('email_is_not_valid')]}
                        />
                        <Grid container direction="row" wrap="nowrap">
                            <FormControl className={classes.formControlSelect} fullWidth margin="dense">
                                <InputLabel id="period">{t('period')}</InputLabel>
                                <Select
                                    labelId="period"
                                    value={period}
                                    onChange={(event) => {
                                        setPeriod(event.target.value)
                                    }}>
                                    {Object.values(PERIODS).map(key => (<MenuItem value={key}>{t(key)}</MenuItem>))}
                                </Select>
                            </FormControl>


                            <Fragment>
                                {period === PERIODS.WEEKLY && <FormControl className={classes.formControlSelect} fullWidth margin="dense">
                                    <InputLabel id="day">{t('day')}</InputLabel>
                                    <Select
                                        labelId="day"
                                        value={weekDay}
                                        onChange={(event) => setWeekday(event.target.value)}>
                                        {Object.values(WEEKDAYS).map(key => (<MenuItem value={key}>{t(key)}</MenuItem>))}
                                    </Select>
                                </FormControl>}
                                {period === PERIODS.MONTHLY && <FormControl className={classes.formControlSelect} fullWidth margin="dense">
                                    <InputLabel id="month">{t('month')}</InputLabel>
                                    <Select
                                        labelId="weekdays"
                                        value={dayOfMonth}
                                        onChange={(event) => setDayOfMonth(event.target.value)}>
                                        {Object.values(DAY_OF_MONTH).map(item => (<MenuItem value={item}>{t(item)}</MenuItem>))}
                                    </Select>
                                </FormControl>}
                            </Fragment>


                            <TimePicker
                                TextFieldComponent={TextFieldComponent}
                                label={t('time')}
                                className={classes.datePicker}
                                value={time}
                                fullWidth={true}
                                format={REPLACEMENT_DATE_FORMAT.TIME}
                                onChange={(date) => setTime(date)} />
                        </Grid>
                    </Fragment>}

                    {errorMessage && <DialogContentText style={{ marginTop: 20, whiteSpace: 'pre-line' }} color="error">{errorMessage}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    {<Button disabled={loading} onClick={onCancel} color="default">
                        {t('cancel')}
                    </Button>}
                    <Button disabled={loading} type="submit" color={"primary"}>
                        {t('save')}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    )
}

export default AutoExportDialog