
import React, { Fragment, useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PageContext } from '../Context/PageProvider'
import ReactSVG from 'react-svg'
import { ORG_TAG } from '../../../m2m-cloud-api'
import { sortItems } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { PARAM_APP_MSG_ITEM_SORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { useTranslation } from 'react-i18next'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import { List, ListItem, ListItemText, ListItemIcon, DialogContentText, Divider, Button, Dialog, DialogActions, DialogContent, LinearProgress, DialogTitle } from '@material-ui/core'

const LEVEL_PADDING = 15

function GroupListItem({ entity, active, disabledOrgIds, hiddenOrgIds, onClick, ...otherProps }) {
    const classes = useStyles()
    const isDisabled = disabledOrgIds && disabledOrgIds.indexOf(entity.getId()) !== -1
    const isHidden = hiddenOrgIds && hiddenOrgIds.indexOf(entity.getId()) !== -1
    if (isHidden) return null
    return (
        <Fragment>
            <ListItem disabled={isDisabled} {...otherProps} className={active ? [classes.listItem, classes.listItemActive].join(' ') : classes.listItem} onClick={() => !isDisabled && onClick(entity)}>
                <ListItemIcon className={classes.listItemIcon}>
                    <ReactSVG src={"/assets/light/folder.svg"} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}
                    primary={<div>{entity.getName()}</div>} />
            </ListItem>
            <Divider className={classes.divider} />
        </Fragment>
    )
}

const Group = ({ selectedOrgId, parentOrgId, disabledOrgIds, hiddenOrgIds, level = 0, onClick }) => {
    const context = useContext(PageContext)
    const classes = useStyles()

    const [filteredItems, setFilteredItems] = useState([])

    useEffect(() => {
        const rootOrg = context.getSelectedRootOrg()
        if (rootOrg) {
            const parentOrg = parentOrgId ? context.childrenOrgs.find(org => org.getId() === parentOrgId) : context.getSelectedRootOrg()
            const sortOrgIds = parentOrg && parentOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}`)
            const items = context.childrenOrgs.filter(org => ((!parentOrgId && org.getParentId() === rootOrg.getId()) || parentOrgId === org.getParentId()) && org.hasTags([ORG_TAG.GROUP]))
            const sortedItems = sortItems(sortOrgIds, items)
            setFilteredItems(sortedItems)
        }
    }, [context.childrenOrgs, parentOrgId, selectedOrgId])

    return (
        <List component="nav" className={classes.list}>
            {filteredItems && filteredItems.map((entity, index) => (
                <div key={entity.getId()}>
                    <GroupListItem entity={entity} active={entity.getId() === selectedOrgId} disabledOrgIds={disabledOrgIds} hiddenOrgIds={hiddenOrgIds} onClick={onClick} level={level + 1} style={{ paddingLeft: (level * LEVEL_PADDING) + LEVEL_PADDING }} />
                    <Group selectedOrgId={selectedOrgId} parentOrgId={entity.getId()} disabledOrgIds={disabledOrgIds} hiddenOrgIds={hiddenOrgIds} level={level + 1} renderRootTitle={false} onClick={onClick} />
                </div>
            ))}
        </List>
    )
}

const GroupMoveDialog = ({ open, onClose, org }) => {
    const context = useContext(PageContext)
    const classes = useStyles()
    const { t } = useTranslation()

    const [selectedOrgId, setSelectedOrgId] = useState(null)
    const [loading, setLoading] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const onSubmit = async() => {
        setLoading(true)
        try {
            const promises = [context.api.orgService.move(org.getId(), selectedOrgId)]
            const updatedOrg = await context.updateAndCacheTopOrg(org.getId(), promises)
            await context.loadRootOrgCildrens()
            setLoading(false)
            onClose()
        } catch (error) {
            setErrorMessage( t(mapErrorMessage(error)) )
            setLoading(false)
        }
    }

    const rootOrg = context.getSelectedRootOrg()
    const disabledOrgIds = org ? [org.getParentId()] : []
    const hiddenOrgIds = org ? [org.getId()] : []

    return (
        <Dialog open={open} fullWidth maxWidth={'xs'} aria-labelledby="alert">
            { loading && <LinearProgress />}
            <DialogTitle id="alert-title">{t('move_group')}</DialogTitle>
            <DialogContent className={loading ? [classes.root, classes.loading].join(' ') : classes.root}>
                <GroupListItem entity={rootOrg} disabledOrgIds={disabledOrgIds} hiddenOrgIds={hiddenOrgIds} active={rootOrg.getId() === selectedOrgId} onClick={(entity) => setSelectedOrgId(entity.getId())} level={0} style={{ paddingLeft: LEVEL_PADDING }} />
                <Group selectedOrgId={selectedOrgId} disabledOrgIds={disabledOrgIds} hiddenOrgIds={hiddenOrgIds} parentOrgId={null} level={1} onClick={(entity) => setSelectedOrgId(entity.getId())} />
            </DialogContent>
            {errorMessage && <DialogContentText className={classes.errorMessage} color="error">{errorMessage}</DialogContentText>}
            <DialogActions>
                {<Button disabled={loading} onClick={onClose} color="default">
                    {t('cancel')}
                </Button>}
                <Button disabled={!selectedOrgId || loading} onClick={onSubmit} color={"primary"}>
                    {t('move')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.common.darkBackground,
        padding: 0,
    },
    loading: {
        pointerEvents: 'none'
    },
    list: {
        padding: 0,
        overflowY: 'auto'
    },
    listItem: {
        backgroundColor: theme.palette.common.darkBackground,
        cursor: 'pointer'
    },
    listItemActive: {
        backgroundColor: theme.palette.primary.main
    },
    listItemText: {
        fontWeight: 500
    },
    listItemIcon: {
        minWidth: 35,
    },
    divider: {
        backgroundColor: '#000'
    },
    errorMessage: {
        marginTop: 20, 
        marginLeft: 20,
        marginRight: 20,
        whiteSpace: 'pre-line'
    }
}))


export default GroupMoveDialog