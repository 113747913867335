import React, { useContext } from 'react'
import { withRouter } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import ReactSVG from 'react-svg'

const MIN_FONT_SIZE = 0
const MAX_FONT_SIZE = 6

const FontSize = ({ t }) => {
    const context = useContext(ApiContext)
    const classes = useStyles()
    
    const userFontSize = context.userFontSize

    return (
        <>
            <IconButton disabled={userFontSize === MIN_FONT_SIZE} size="small" style={userFontSize === MIN_FONT_SIZE ? { opacity: .5, marginRight: 12 } : { marginRight: 12 }} onClick={() => context.setUserFontSize(userFontSize - 1)}>
                <ReactSVG src="/assets/light/icon-light-font-decrease.svg" />
            </IconButton>
            <IconButton disabled={userFontSize === MAX_FONT_SIZE} size="small" style={userFontSize === MAX_FONT_SIZE ? { opacity: .5 } : {}} onClick={() => context.setUserFontSize(userFontSize + 1)}>
                <ReactSVG src="/assets/light/icon-light-font-increase.svg" />
            </IconButton>
        </>
    )
}

const useStyles = makeStyles(theme => ({
}))

export default withRouter(withTranslation()(FontSize))