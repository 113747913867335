import React, { useState, useEffect } from 'react'
import { makeStyles, } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, LinearProgress, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { PARAM_NAME, PARAM_PRIORITY, PARAM_BILLING_POINT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'


const PRIORITY = {
    DEFAULT: 1,
    HIGHT: 2,
    HIGHEST: 3
}

const useStyles = makeStyles(theme => ({
}))

function TriggerGroupDialog({ groupEntity, onSubmit, onCancel, loading, errorMessage }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [name, setName] = useState(groupEntity && groupEntity.getName() || '')
    const [priority, setPriority] = useState(groupEntity && groupEntity.getParam(PARAM_PRIORITY) || PRIORITY.DEFAULT)
    const [billingPoint, setBillingPoint] = useState(groupEntity && groupEntity.getParam(PARAM_BILLING_POINT) || '')
    const title = groupEntity ? t('edit_trigger_org_title') : t('add_new_trigger_org_title')

    const handleSubmit = () => {
        onSubmit({ [PARAM_NAME]: name, [PARAM_PRIORITY]: priority, [PARAM_BILLING_POINT]: billingPoint })
    }
    return (
        <Dialog open={true} fullWidth maxWidth={'xs'} aria-labelledby="alert">
            { loading && <LinearProgress />}
            <DialogTitle id="alert-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={name}
                    disabled={loading}
                    onChange={(event) => setName(event.target.value)}
                    label={t('name')}
                    fullWidth
                />
                <FormControl className={classes.formControl} fullWidth margin="dense">
                    <InputLabel id="priority">{t('priority')}</InputLabel>
                    <Select
                        labelId="priority"
                        value={priority}
                        onChange={(event) => {
                            const _priority = event.target.value
                            setPriority(_priority)
                        }}>
                        <MenuItem value={PRIORITY.DEFAULT}>{t('default_priority')}</MenuItem>
                        <MenuItem value={PRIORITY.HIGHT}>{t('high_priority')}</MenuItem>
                        <MenuItem value={PRIORITY.HIGHEST}>{t('highest_priority')}</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    id="billing-point"
                    value={billingPoint}
                    disabled={loading}
                    onChange={(event) => setBillingPoint(event.target.value)}
                    label={t('billing_point')}
                    fullWidth
                />
                {errorMessage && <DialogContentText style={{ marginTop: 20, whiteSpace: 'pre-line' }} color="error">{errorMessage}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                {<Button disabled={loading} onClick={onCancel} color="default">
                    {t('cancel')}
                </Button>}
                <Button disabled={!name || name.trim() === "" || loading} onClick={handleSubmit} color={"primary"}>
                    {groupEntity ? t('ok') : t('create')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TriggerGroupDialog