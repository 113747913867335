const CACHE_DURATION = 30 * 1000;
export const CACHE_ITEM_TYPE = {
  ADD: 'add', // after adding entries via API are not returned immediately, so they have to be cached.
  SET: 'set',
};

export default class ApiCache {
  /**
   * @param {Model} model
   * @param {Integer} cacheDuration
   */
  constructor(model, cacheDuration = null) {
    this.model = model;
    this.cache = null;
    this.cacheDuration = cacheDuration === null ? CACHE_DURATION : cacheDuration;
    this.searchList = [];
  }

  /**
   * set list
   * @param {Array} list
   */
  setList(list) {
    this.searchList = list;
  }

  /**
   * get cached list
   */
  getCachedList() {
    let cachedItems = {};
    this.searchList.map(item => {
      cachedItems[item.getId()] = item;
    });
    const addedItems = this.getAddedItems();
    //console.warn('addedItems', addedItems)
    addedItems.map(item => {
      cachedItems[item.getId()] = item;
    });
    const updatedItems = this.getUpdatedItems();
    //console.warn('updatedItems', updatedItems)
    updatedItems.map(item => {
      cachedItems[item.getId()] = item;
    });
    let cacheList = [];
    Object.keys(cachedItems).map(id => {
      cacheList.push(cachedItems[id]);
    });

    return cacheList;
  }

  /**
   * get item
   * @returns {Any}
   */
  get(id) {
    const items = this.getCachedList();
    return items.find(item => item.getId() === id);
  }

  /**
   * add item
   * @param {Object} item
   * @returns {undefined}
   */
  add(item) {
    this.set(item, CACHE_ITEM_TYPE.ADD);
  }

  /**
   * update item
   * @param {Object} item
   * @returns {undefined}
   */
  update(item) {
    this.set(item, CACHE_ITEM_TYPE.SET);
  }

  /**
   * set item
   * @param {Object} item
   * @param {CACHE_ITEM_TYPE} type
   * @returns {undefined}
   */
  set(item, type) {
    const items = this.cache || {};
    items[item.getId()] = {
      date: new Date(),
      item: item,
      type: type,
    };
    this.cache = items;
  }

  /**
   * delete item
   * @param {String} itemId
   */
  delete(itemId) {
    let items = this.cache || {};
    if (items && items[itemId]) {
      delete items[itemId];
    }
    this.cache = items;
  }

  /**
   * get added items
   * @returns {Array}
   */
  getAddedItems() {
    const items = this.cache || {};
    const ids = Object.keys(items);

    let cacheItems = [];
    ids.map(id => {
      const item = items[id];
      if (item.type === CACHE_ITEM_TYPE.ADD && !this._isExpired(item)) {
        cacheItems.push(new this.model(item.item));
      }
    });
    return cacheItems;
  }

  /**
   * get updated items
   * @returns {Array}
   */
  getUpdatedItems() {
    const items = this.cache || {};
    const ids = Object.keys(items);

    let cacheItems = [];
    ids.map(id => {
      const item = items[id];
      if (item.type === CACHE_ITEM_TYPE.SET && !this._isExpired(item)) {
        cacheItems.push(new this.model(item.item));
      }
    });
    return cacheItems;
  }

  /**
   * is item expired
   * @param {Object} item
   * @returns {Boolean}
   */
  _isExpired(item) {
    const date = new Date(item.date);
    const diff = new Date().getTime() - date.getTime();
    return diff > this.cacheDuration;
  }
}
