export default class ReleaseResult {
  /**
   * @param {Object<String>}
   */
  constructor({ claimKey, device }) {
    this.claimKey = claimKey;
    this.device = device;
  }

  /**
   * Get claim key
   * @returns {String}
   */
  getClaimKey() {
    return this.claimKey;
  }

  /**
   * Get device uid
   * @returns {String}
   */
  getDeviceId() {
    return this.device;
  }
}
