
import React, { Fragment, useEffect, useState, useContext, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PageContext } from '../Context/PageProvider'
import ls from "local-storage"
import ReactSVG from 'react-svg'
import { ORG_TAG } from '../../../m2m-cloud-api'
import { sortItems } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { PARAM_APP_MSG_ITEM_SORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { useTranslation } from 'react-i18next'
import { IconButton, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Paper, RootRef, Divider, Menu, MenuItem, Grid } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import GroupDialog from './GroupDialog'
import GroupMoveDialog from './GroupMoveDialog'
import GroupCopyDialog from './GroupCopyDialog'

import SortButtons from './SortButtons'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import AutoExportDialog from './AutoExportDialog'
import UserAccessManagementDialog from './UserAccessManagementDialog'

import EditIcon from '@material-ui/icons/Edit'
import MoreIcon from '@material-ui/icons/MoreVert'
import CopyIcon from '@material-ui/icons/FileCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import MoveIcon from '@material-ui/icons/OpenWith'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import SearchIcon from '@material-ui/icons/Search'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Searchbox from '../../../Libs/Components/Searchbox'
import clsx from 'clsx'

const LEVEL_PADDING = 12

const getListItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        background: "rgba(255,255,255,.5)"
    })
})

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

const getLastSelectedGroupOrgId = (context) => {
    const user = context.api.userService.getActiveUser()
    const rootOrg = context.getSelectedRootOrg()
    if (rootOrg && user) {
        const key = `${user.getUserId()}-${rootOrg.getId()}-admin-groupId`
        return ls.get(key)
    }
}

const setLastSelectedGroupOrgId = (context, orgId) => {
    const user = context.api.userService.getActiveUser()
    const rootOrg  = context.getSelectedRootOrg()
    if (rootOrg && user) {
        const key = `${user.getUserId()}-${rootOrg.getId()}-admin-groupId`
        ls.set(key, orgId)
    }
}

function GroupListItem({ entity, children, active, isOpen, customSortEnabled, onClick, onOpenClick, onEditClick, onDeleteClick, onAutoExportClick, onUserManagementClick, onAddClick, onMoveClick, onCopyClick, ...otherProps }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const context = useContext(PageContext)

    const [menuAnchorEl, setMenuAnchorEl] = useState(null)
    const canOpen = context.childrenOrgs.findIndex( org => !org.isDeactivated() && org.hasTags([ORG_TAG.GROUP]) && org.getParentId() === entity.getId() ) !== -1

    return (
        <Fragment>
            <ListItem {...otherProps} className={active ? [classes.listItem, classes.listItemActive].join(' ') : classes.listItem} onClick={() => onClick(entity)} button={false}>
                {   
                    canOpen ? (
                        <IconButton size="small" className={classes.expandButton} onClick={(event) => { event.stopPropagation(); onOpenClick(entity)}} aria-label="Expand">
                            { isOpen ? <ExpandLess /> : <ExpandMore />} 
                        </IconButton>
                    ) :
                    (<div className={classes.dummyExpandButton}></div>)
                }
                <ListItemIcon className={classes.listItemIcon}>
                    <ReactSVG src={canOpen ? isOpen ? "/assets/light/folder_open.svg" : "/assets/light/folder.svg" : "/assets/light/folder_empty.svg"} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText} 
                    primary={<div>{entity.getName()}</div>}/>
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                    <Grid>
                    <IconButton
                        className={classes.moreIconButton}
                        size={'small'}
                        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                        edge="end">
                        <MoreIcon style={{opacity: active ? 1 : .35}} />
                    </IconButton>
                    {customSortEnabled && <ListItemIcon className={classes.listItemIconDrag}>
                      <ReactSVG className={classes.dragIcon} src={"/assets/light/drag.svg"} />
                    </ListItemIcon>}
                    </Grid>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider className={classes.divider} />
            { menuAnchorEl && <Menu
                id="menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}>
                <MenuItem onClick={(event) => { setMenuAnchorEl(null); onEditClick(entity) }}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('edit')}</Typography>
                </MenuItem>
                <MenuItem disabled={!onDeleteClick} onClick={(event) => { setMenuAnchorEl(null); onDeleteClick(entity) }}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('delete')}</Typography>
                </MenuItem>
                <MenuItem disabled={!onCopyClick} onClick={(event) => { setMenuAnchorEl(null); onCopyClick(entity) }}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <CopyIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('copy')}</Typography>
                </MenuItem>
                <MenuItem disabled={!onMoveClick} onClick={(event) => { setMenuAnchorEl(null); onMoveClick(entity) }}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <MoveIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('move')}</Typography>
                </MenuItem>
                <MenuItem disabled={!onAddClick} onClick={(event) => { setMenuAnchorEl(null); onAddClick(entity) }}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ReactSVG src={"/assets/light/add_sub_folder.svg"} />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('add_sub_group')}</Typography>
                </MenuItem>
                <MenuItem onClick={(event) => { setMenuAnchorEl(null); onAutoExportClick(entity) }}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <CloudDownloadIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('auto_export')}</Typography>
                </MenuItem>
                <MenuItem onClick={(event) => { setMenuAnchorEl(null); onUserManagementClick(entity) }}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <VpnKeyIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('access_management')}</Typography>
                </MenuItem>
            </Menu>}
        </Fragment>
    )
}

const GroupView = ({selectedOrgId, onSelectOrgId, parentOrgId, selectionDisabled }) => {
    const context = useContext(PageContext)
    const classes = useStyles()
    const { t } = useTranslation()

    const [customSortEnabled, setCustomSortEnabled] = useState(null)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [activeEntity, setActiveEntity] = useState(null)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showAutoExportDialog, setShowAutoExportDialog] = useState(false)
    const [showAccessManagementDialog, setShowAccessManagementDialog] = useState(false)
    const [showAddGroupDialog, setShowAddGroupDialog] = useState(false)
    const [showGroupMoveDialog, setShowGroupMoveDialog] = useState(false)
    const [showGroupCopyDialog, setShowGroupCopyDialog] = useState(false)
    const [openIds, setOpenIds] = useState([])
    const [searchTerm, setSearchTerm] = useState(null)
    const [searchBarVisible, setSearchBarVisible] = useState(false)

    useEffect(() => {
        const rootOrg = context.getSelectedRootOrg()
        if (rootOrg) {
            const sortOrgIds = rootOrg && rootOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}`)
            setCustomSortEnabled((sortOrgIds !== undefined && sortOrgIds !== null) ? true : false)

            if (selectedOrgId && openIds.length === 0) {
                const selectedOrg = selectedOrgId && context.childrenOrgs.find( org => org.getId() === selectedOrgId)
                let parentOrgId = selectedOrg && selectedOrg.getId() !== rootOrg.getId() ? selectedOrg.getParentId() : null
                const _openIds = []
                do {
                    _openIds.push(parentOrgId)
                    const parentOrg = parentOrgId && context.childrenOrgs.find( org => org.getId() === parentOrgId)
                    parentOrgId = parentOrg && parentOrg.getId() !== rootOrg.getId() ? parentOrg.getParentId() : null
                    console.log('test')
                } while (parentOrgId)
                console.log('_openIds', _openIds)
                setOpenIds(_openIds)
            }
    
        }
    }, [selectedOrgId])

    const addOrg = useCallback(async (params) => {
        setLoading(true)
        try {
            const rootOrg = context.getSelectedRootOrg()
            const org = await context.createAndCacheTopOrg(activeEntity ? activeEntity.getId() : rootOrg.getId(), params, [ORG_TAG.GROUP])
            await context.loadRootOrgCildrens()
            resetDialogs()
        } catch (error) {
            setErrorMessage( t(mapErrorMessage(error)) )
            setLoading(false)
        }
    }, [activeEntity && activeEntity.getId()])

    const updateOrgParams = useCallback(async (params) => {
        setLoading(true)
        try {
            const promises = []
            Object.keys(params).map(name => {
                if (params[name] === null) {
                    promises.push(context.api.orgService.deleteParam(activeEntity.getId(), name))
                } else {
                    promises.push(context.api.orgService.putParam(activeEntity.getId(), name, params[name]))
                }
            })
            const org = await context.updateAndCacheTopOrg(activeEntity.getId(), promises)
            await context.loadRootOrgCildrens()
            resetDialogs()
        } catch (error) {
            setErrorMessage( t(mapErrorMessage(error)) )
            setLoading(false)
        }
    }, [activeEntity && activeEntity.getId()])

    const deleteOrg = useCallback(async () => {
        setLoading(true)
        try {
            await context.updateAndCacheTopOrg(activeEntity.getId(), [context.api.orgService.deactivate(activeEntity.getId())])
            await context.loadRootOrgCildrens()
            resetDialogs()
        } catch (error) {
            setErrorMessage( t(mapErrorMessage(error)) )
            setLoading(false)
        }
    }, [activeEntity && activeEntity.getId()])

    

    const resetDialogs = useCallback(() => {
        setLoading(false)
        setActiveEntity(null)
        setErrorMessage(null)
        setShowEditDialog(false)
        setShowDeleteDialog(false)
        setShowAutoExportDialog(false)
        setShowAccessManagementDialog(false)
        setShowAddGroupDialog(false)
        setShowGroupMoveDialog(false)
        setShowGroupCopyDialog(false)
    }, [])


    const onClick = (entity) => {
        setLastSelectedGroupOrgId(context, entity.getId())
        onSelectOrgId(entity.getId())
    }
    const onEditClick = (entity) => {
        setActiveEntity(entity)
        setShowEditDialog(true)
    }
    const onDeleteClick = (entity) => {
        setActiveEntity(entity)
        setShowDeleteDialog(true)
    }
    const onAutoExportClick = (entity) => {
        setActiveEntity(entity)
        setShowAutoExportDialog(true)
    }
    const onUserManagementClick = (entity) => {
        setActiveEntity(entity)
        setShowAccessManagementDialog(true)
    }
    const onAddClick = (entity) => {
        setActiveEntity(entity)
        setShowAddGroupDialog(true)
    }
    const onMoveClick = (entity) => {
        setActiveEntity(entity)
        setShowGroupMoveDialog(true)
    }
    const onCopyClick = (entity) => {
        setActiveEntity(entity)
        setShowGroupCopyDialog(true)
    }

    const items = context.childrenOrgs.filter( org => org.hasTags([ORG_TAG.GROUP]) )
    const deleteEnabled = items.length > 1 ? true : false

    return (
        <Paper className={classes.root}>
            <Grid container className={classes.groupTitleContainer}>
                <Typography variant="caption" color={'textSecondary'} style={{ flex: 1 }}>
                    {t('groups')}
                </Typography>
                <SortButtons customSortEnabled={customSortEnabled} onCustomSortChange={(enabled) => setCustomSortEnabled(enabled)}/>
                <IconButton
                    edge="end"
                    size="small"
                    className={classes.toolbarButton}
                    onClick={() => setShowAddGroupDialog(true)}>
                    <ReactSVG src={"/assets/light/add_folder.svg"} />
                </IconButton>
                <IconButton
                    size={'small'}
                    disabled={!items || items.length === 0}
                    onClick={() => {
                        setSearchBarVisible(!searchBarVisible)
                        setSearchTerm(null)
                    }}>
                    <SearchIcon />
                </IconButton>
            </Grid>
            <Grid className={clsx(classes.searchContainer, searchBarVisible && classes.searchVisible)}>
                <Searchbox value={searchTerm} onChange={(value)=> setSearchTerm(value)} className={classes.search} />
            </Grid>
            { customSortEnabled !== null && <Group searchTerm={searchTerm} selectedOrgId={selectedOrgId} onSelectOrgId={onSelectOrgId} parentOrgId={parentOrgId} selectionDisabled={selectionDisabled} openIds={openIds} level={0} customSortEnabled={customSortEnabled} onClick={onClick} onEditClick={onEditClick} onDeleteClick={deleteEnabled ? onDeleteClick : null} onAutoExportClick={onAutoExportClick} onUserManagementClick={onUserManagementClick} onAddClick={onAddClick} onMoveClick={onMoveClick} onCopyClick={onCopyClick} setOpenIds={setOpenIds}/>}
            { showEditDialog && <GroupDialog
                loading={loading}
                groupEntity={activeEntity}
                errorMessage={errorMessage}
                onSubmit={errorMessage ? null : updateOrgParams}
                onCancel={resetDialogs} />}
            { showAddGroupDialog && <GroupDialog
                loading={loading}
                groupEntity={null}
                errorMessage={errorMessage}
                onSubmit={errorMessage ? null : addOrg}
                onCancel={resetDialogs} />}
            { showDeleteDialog && <AlertDialog
                open={true}
                loading={loading}
                errorMessage={errorMessage}
                title={t('delete_group_org_title')}
                message={errorMessage ? null : t('delete_group_org_description')}
                submitButtonTitle={errorMessage ? t('delete') : null}
                cancelButtonTitle={errorMessage ? t('close') : t('cancel')}
                buttonVariant={'text'}
                submitButtonColor={'primary'}
                onSubmit={errorMessage ? null : deleteOrg }
                onCancel={resetDialogs} />}
            { showAutoExportDialog && <AutoExportDialog
                open={true}
                org={activeEntity}
                onCancel={resetDialogs}
                onSubmit={updateOrgParams} />}
            { showAccessManagementDialog && <UserAccessManagementDialog
                open={true}
                orgId={activeEntity.getId()}
                onCancel={resetDialogs}
                onSuccess={resetDialogs}/>}
            { showGroupMoveDialog && <GroupMoveDialog
                open={true}
                org={activeEntity}
                onClose={resetDialogs}/>}
            { showGroupCopyDialog && <GroupCopyDialog
                open={true}
                org={activeEntity}
                onClose={resetDialogs}/>}
        </Paper>
    )
}

const Group = ({searchTerm, selectedOrgId, onSelectOrgId, parentOrgId, selectionDisabled, level = 0, openIds, customSortEnabled, onClick, onEditClick, onDeleteClick, onAutoExportClick, onUserManagementClick, onAddClick, onMoveClick, onCopyClick, setOpenIds }) => {
    const context = useContext(PageContext)
    const classes = useStyles()
    const { t } = useTranslation()

    const [filteredItems, setFilteredItems] = useState([])

    useEffect(() => {
        const rootOrg = context.getSelectedRootOrg()
        if (rootOrg) {
            const parentOrg = parentOrgId ? context.childrenOrgs.find( org => org.getId() === parentOrgId) : context.getSelectedRootOrg()
            const sortOrgIds = parentOrg && parentOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}`)
            let items = context.childrenOrgs.filter( org => ( (!parentOrgId && org.getParentId() === rootOrg.getId() ) || parentOrgId === org.getParentId() ) && org.hasTags([ORG_TAG.GROUP]) )
            if (searchTerm){
                items = context.childrenOrgs.filter( org => org.hasTags([ORG_TAG.GROUP]) )
                items = items.filter(org => {
                    const searchString = `${org.getName() || ''}`
                    return searchString.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
                })
            }
            const sortedItems = sortItems(customSortEnabled && sortOrgIds, items)
            setFilteredItems(sortedItems)
            if (!selectedOrgId || !context.childrenOrgs.find( org => org.getId() === selectedOrgId)) {
                const lastSelectedGroupOrgId = getLastSelectedGroupOrgId(context)
                if (lastSelectedGroupOrgId && context.childrenOrgs.find( org => org.getId() === lastSelectedGroupOrgId)) {
                    onSelectOrgId(lastSelectedGroupOrgId)
                } else if ( sortedItems.length > 0 ) {
                    onSelectOrgId(sortedItems[0].getId())
                }
            }
        }
    }, [context.childrenOrgs, parentOrgId, customSortEnabled, selectedOrgId, searchTerm])

    useEffect(() => {
        const parentOrg = context.getSelectedRootOrg()
        if (parentOrg) {
            const sortOrgIds = parentOrg && parentOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}`)
            const hasSortParam = sortOrgIds !== null && sortOrgIds !== undefined
            if ( hasSortParam && !customSortEnabled ) {
                setSorting(null)
            } else if ( !hasSortParam && customSortEnabled ) {
                setSorting([])
            }
        }
    }, [customSortEnabled])


    const setSorting = async (orgIds) => {
        const parentOrg = parentOrgId ? context.childrenOrgs.find( org => org.getId() === parentOrgId) : context.getSelectedRootOrg()
        let promises = []
        const paramsToUpdate = {}
        if (orgIds === null) {
            const key = `${PARAM_APP_MSG_ITEM_SORT}`
            promises.push(context.api.orgService.deleteParam(parentOrg.getId(), key))
            paramsToUpdate[key] = null
        }
        if (orgIds) {
            const key = `${PARAM_APP_MSG_ITEM_SORT}`
            promises.push(context.api.orgService.putParam(parentOrg.getId(), key, orgIds.join(',')))
            paramsToUpdate[key] = orgIds.join(',')
        }
        if (promises.length > 0) {
            await context.updateAndCacheTopOrg(parentOrg.getId(), promises)
            await context.updateCachedOrgParams(parentOrg.getId(), paramsToUpdate)
        }
    }

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return
        }
        const items = reorder(
            filteredItems,
            result.source.index,
            result.destination.index
        )
        setFilteredItems(items)
        setSorting(items.map(item => item.id))
    }

    const onOpenClick = (entity) => {
        console.log('entity:', entity)
        const id = entity.getId()
        const isOpen = openIds.find(openId => openId === id) ? true : false
        if (isOpen) {
            setOpenIds(openIds.filter(openId => openId !== id))
        } else {
            setOpenIds([...openIds, id])
        }
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable-group-list">
                    {(provided, snapshot) => (
                        <RootRef rootRef={provided.innerRef}>
                            <List component="nav" className={classes.list}>
                                {filteredItems && filteredItems.map((entity, index) => (
                                    <Draggable key={entity.getId()} draggableId={entity.getId()} index={index} isDragDisabled={!customSortEnabled}>
                                        {(provided, snapshot) => (
                                            <div key={entity.getId()} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getListItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                <GroupListItem entity={entity} active={!selectionDisabled && entity.getId() === selectedOrgId} isOpen={openIds.indexOf(entity.getId()) !== -1} onOpenClick={onOpenClick} customSortEnabled={customSortEnabled} onClick={onClick} onEditClick={onEditClick} onDeleteClick={onDeleteClick} onAutoExportClick={onAutoExportClick} onUserManagementClick={onUserManagementClick} onAddClick={onAddClick} onMoveClick={onMoveClick} onCopyClick={onCopyClick} level={level+1} style={{paddingLeft: (level * LEVEL_PADDING) + LEVEL_PADDING}} />
                                                { openIds.indexOf(entity.getId()) !== -1 && <Group selectedOrgId={selectedOrgId} onSelectOrgId={onSelectOrgId} parentOrgId={entity.getId()} selectionDisabled={selectionDisabled} openIds={openIds} level={level+1} renderRootTitle={false} customSortEnabled={customSortEnabled} onClick={onClick} onEditClick={onEditClick} onDeleteClick={onDeleteClick} onAutoExportClick={onAutoExportClick} onUserManagementClick={onUserManagementClick} onAddClick={onAddClick} onMoveClick={onMoveClick} onCopyClick={onCopyClick} setOpenIds={setOpenIds}/> }
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    root: { 
        display: 'flex', 
        flexDirection: 'column', 
        backgroundColor: 'transparent',
        flex: 1,
        overflowY: 'auto'
    },
    groupTitleContainer: {
        alignItems: 'center',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        marginBottom: theme.spacing(1)
    },
    toolbarButton: {
        marginTop: theme.spacing(1)
    },
    list: {
        padding: 0,
        overflowY: 'auto'
    },
    listItem: {
        backgroundColor: theme.palette.common.darkBackground,
        '&:hover': {
            backgroundColor: theme.palette.common.darkBackground
        }
    },
    listItemActive: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        },
    },
    listItemText: {
        marginRight: 40,
        fontWeight: 500
    },
    listItemIcon: {
        minWidth: 35,
    },
    divider: {
        backgroundColor: '#000'
    },
    listItemIconDrag: {
        minWidth: 24,
        marginLeft: theme.spacing(1),
        verticalAlign: 'middle'
    },
    dragIcon: {
    },
    expandButton: {
        marginRight: theme.spacing(1),
    },
    dummyExpandButton: {
        marginRight: theme.spacing(1),
        width: 30,
        height: 24
    },
    searchContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        top: 36,
        width: '100%',
        overflow: 'hidden',
        transition: 'height 0.2s linear',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        height: 0
    },
    searchVisible:{
        height: 36
    },
    search: {
        width: '100%'
    }
}))


export default GroupView