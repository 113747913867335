import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { PRIMARY_COLOR } from '../../../theme'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Grid, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, LinearProgress, FormControl, InputLabel, Select, MenuItem, Typography } from '@material-ui/core'
import { PARAM_NAME, PARAM_APP_MSG_VISIBILITY_MODE, PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS, PARAM_APP_MSG_COLOR } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { ColorPicker } from 'material-ui-color'

const useStyles = makeStyles(theme => ({
    colorPickerContainer: {
        marginLeft: -6
    }
}))

const COLOR_PICKER_ID = 'custom-color-picker'
function ActionGroupDialog({ groupEntity, onSubmit, onCancel, loading, errorMessage }) {

    let _visibility = groupEntity?.getParam(PARAM_APP_MSG_VISIBILITY_MODE) || PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.LOCAL
    if (_visibility === PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.CONFIG) {
        _visibility = PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.GROUP
    }

    const { t } = useTranslation()
    const classes = useStyles()
    const [name, setName] = useState(groupEntity && groupEntity.getName() || '')
    const [visibility, setVisibility] = useState(_visibility)
    const title = groupEntity ? t('edit_action_org_title') : t('add_new_action_org_title')
    const customColor = groupEntity && groupEntity.getParam(PARAM_APP_MSG_COLOR)
    const [sendButtonColor, setSendButtonColor] = useState(customColor ? customColor : PRIMARY_COLOR)
    const [customColorActive, setCustomColorActive] = useState(customColor ? true : false)

    const getPickerColor = () => {
        const colorPickerContainer = document.getElementById(COLOR_PICKER_ID)
        const color = colorPickerContainer && colorPickerContainer.getElementsByTagName('input')[0].value
        return color
    }

    return (
        <Dialog open={true} fullWidth maxWidth={'xs'} aria-labelledby="alert">
            { loading && <LinearProgress />}
            <DialogTitle id="alert-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={name}
                    disabled={loading}
                    onChange={(event) => setName(event.target.value)}
                    label={t('name')}
                    fullWidth
                />
                <FormControl className={classes.formControl} fullWidth margin="dense">
                    <InputLabel id="visibility">{t('visibility')}</InputLabel>
                    <Select
                        labelId="visibility"
                        value={visibility}
                        onChange={(event) => {
                            const _visibility = event.target.value
                            setVisibility(_visibility)
                        }}>
                        <MenuItem value={PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.LOCAL}>{t('visibility_local')}</MenuItem>
                        <MenuItem value={PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.GROUP}>{t('visibility_group')}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl} fullWidth margin="dense">
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox name="custom-color" color="primary" checked={customColorActive} onChange={({ target }) => setCustomColorActive(target.checked)} />}
                            label={t('custom_color')}
                        />

                    </Grid>
                    {
                        customColorActive &&
                        <Grid item className={classes.colorPickerContainer} id={COLOR_PICKER_ID}>
                            <ColorPicker defaultValue={sendButtonColor} value={sendButtonColor} onChange={color => setSendButtonColor(`#${color.hex}`)} />
                        </Grid>
                    }
                </FormControl>
                {errorMessage && <DialogContentText style={{ marginTop: 20, whiteSpace: 'pre-line' }} color="error">{errorMessage}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                {<Button disabled={loading} onClick={onCancel} color="default">
                    {t('cancel')}
                </Button>}
                <Button
                    disabled={!name || name.trim() === "" || loading}
                    onClick={() => onSubmit({ [PARAM_NAME]: name, [PARAM_APP_MSG_VISIBILITY_MODE]: visibility, [PARAM_APP_MSG_COLOR]: customColorActive ? getPickerColor() : null })}
                    color={"primary"}>
                    {groupEntity ? t('ok') : t('create')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ActionGroupDialog