import React, { Fragment, useState, useRef, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, TextField, LinearProgress, FormControl, InputLabel, Select, MenuItem, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { PARAM_APP_MSG_CALLBACK_URL, PARAM_APP_MSG_CALLBACK_METHOD, PARAM_APP_MSG_CALLBACK_BODY } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { PageContext } from '../Context/PageProvider'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
    dialogContent: {
        overflowY: 'auto',
        maxHeight: '90%'
    },
    replacementBlocks: {
        fontSize: theme.font.small
    },
    expansionPanel: {
        boxShadow: 'none',
        '&:before': {
            backgroundColor: 'transparent'
        }
    },
    expansionPanelSummary: {
        padding: 0,
    }
}))

function HttpCallbackDialog({ open, rootOrgId, onClose }) {

    const { t } = useTranslation()
    const classes = useStyles()
    const formRef = useRef()
    const context = useContext(PageContext)

    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(true)
    const [url, setUrl] = useState('')
    const [method, setMethod] = useState('get')
    const [body, setBody] = useState('')
    const [httpCallbackEnabled, setHttpCallbackEnabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const laodGroupOrg = async () => {
        try {
            const org = await context.api.orgService.read(rootOrgId)
            setUrl(org && org.getParam(PARAM_APP_MSG_CALLBACK_URL) || '')
            setMethod(org && org.getParam(PARAM_APP_MSG_CALLBACK_METHOD) || 'get')
            setBody(org && org.getParam(PARAM_APP_MSG_CALLBACK_BODY) || '')
            setHttpCallbackEnabled(org && org.getParam(PARAM_APP_MSG_CALLBACK_URL) ? true : false)
            setReady(true)
            setLoading(false)
        } catch (error) {
            setErrorMessage(mapErrorMessage(error))
            setLoading(false)
        }

    }

    useEffect(() => {
        laodGroupOrg()
    }, [])

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const promisses = httpCallbackEnabled ? [
                context.api.orgService.putParam(rootOrgId, PARAM_APP_MSG_CALLBACK_URL, url),
                context.api.orgService.putParam(rootOrgId, PARAM_APP_MSG_CALLBACK_METHOD, method),
                context.api.orgService.putParam(rootOrgId, PARAM_APP_MSG_CALLBACK_BODY, body)
            ] : [
                    context.api.orgService.deleteParam(rootOrgId, PARAM_APP_MSG_CALLBACK_URL),
                    context.api.orgService.deleteParam(rootOrgId, PARAM_APP_MSG_CALLBACK_METHOD),
                    context.api.orgService.deleteParam(rootOrgId, PARAM_APP_MSG_CALLBACK_BODY)
                ]
            await context.api.orgService.updateOrg(rootOrgId, promisses)
            setLoading(false)
            onClose()
        } catch (error) {
            setErrorMessage(mapErrorMessage(error))
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} fullWidth maxWidth={'xs'} maxHeight={'80%'} aria-labelledby="alert">
            { loading && <LinearProgress />}

            <DialogTitle id="alert-title">{t('http_callback')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <ValidatorForm
                    ref={formRef}
                    onSubmit={handleSubmit}
                    onError={errors => console.log("form error:", errors)}>
                    {ready && <Fragment>
                        <FormControl className={classes.formControl} fullWidth margin="dense">
                            <FormControlLabel
                                control={<Checkbox name="custom-color" color="primary" checked={httpCallbackEnabled} onChange={({ target }) => setHttpCallbackEnabled(target.checked)} />}
                                label={t('http_callback_enabled')}
                            />
                        </FormControl>
                        {httpCallbackEnabled && <Fragment>
                            <FormControl className={classes.formControl} fullWidth margin="dense">
                                <InputLabel id="method">{t('method')}</InputLabel>
                                <Select
                                    labelId="method"
                                    value={method}
                                    onChange={(event) => {
                                        const _method = event.target.value
                                        setMethod(_method)
                                    }}>
                                    <MenuItem value={'get'}>{t('http_method_get')}</MenuItem>
                                    <MenuItem value={'post'}>{t('http_method_post')}</MenuItem>
                                </Select>
                            </FormControl>
                            <TextValidator
                                autoFocus
                                margin="dense"
                                id="url"
                                value={url}
                                disabled={loading}
                                onChange={(event) => setUrl(event.target.value)}
                                label={t('url')}
                                instantValidate={true}
                                validators={['required', 'matchRegexp:^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?']}
                                errorMessages={[t('this_field_is_required'), t('invalid_url')]}
                                fullWidth />
                            {method === 'post' && <TextField
                                margin="dense"
                                id="url"
                                value={body}
                                disabled={loading}
                                multiline={true}
                                inputProps={{ maxLength: 2048 }}
                                onChange={(event) => setBody(event.target.value)}
                                label={t('body')}
                                fullWidth />}
                            <ExpansionPanel classes={{ root: classes.expansionPanel }}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    classes={{ root: classes.expansionPanelSummary }}>
                                    <Typography className={classes.heading}>{t('available_variables')}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography className={classes.replacementBlocks}>
                                        $cmd<br />
                                        $defId<br />
                                        $msgId<br />
                                        $ts<br />
                                        $header<br />
                                        $body<br />
                                        $processingTs<br />
                                        $ackTs<br />
                                        $triggeringUserId<br />
                                        $ackUserId<br />
                                        $processingUserId<br />
                                        $param:{'<UUID>'}
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Fragment>}
                    </Fragment>}
                    {errorMessage && <DialogContentText style={{ marginTop: 20, whiteSpace: 'pre-line' }} color="error">{errorMessage}</DialogContentText>}
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
                {<Button disabled={loading} onClick={onClose} color="default">
                    {t('cancel')}
                </Button>}
                <Button
                    disabled={!ready || loading}
                    onClick={() => formRef.current.submit()}
                    color={"primary"}>
                    {t('ok')}
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default HttpCallbackDialog
