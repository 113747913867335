
import React, { useEffect, useContext, useState, useCallback } from 'react'
import { Toolbar, IconButton, ListSubheader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { ORG_TAG } from '../../../m2m-cloud-api'
import { PARAM_APP_MSG_ITEM_SORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { USER_PARAM_KEY_RECIPIENT, USER_PARAM_KEY_SUPPLIER } from '../../../m2m-cloud-api/MessageLog/Contants'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import SortButtons from './SortButtons'
import ActionGroupDialog from './ActionGroupDialog'
import TriggerGroupDialog from './TriggerGroupDialog'

import AddIcon from '@material-ui/icons/Add'

const GroupViewListHeader = ({ groupOrgId, listViewType, title, customSortEnabled, onCustomSortChange, className, ...props }) => {
    const context = useContext(PageContext)
    const classes = useStyles()
    const { t } = useTranslation()

    const [modalVisible, setModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    useEffect(() => {
        const groupOrg = context.childrenOrgs.find(org => org.getId() === groupOrgId)
        const key = `${PARAM_APP_MSG_ITEM_SORT}-${ listViewType === ORG_TAG.RECIPIENT_LOCATION ? USER_PARAM_KEY_RECIPIENT : USER_PARAM_KEY_SUPPLIER}`
        const orgIds = groupOrg && groupOrg.getParam(key)
        onCustomSortChange(orgIds !== undefined && orgIds !== null)
    }, [groupOrgId])

    const createNewChildOrg = useCallback(async (orgTag, params) => {
        setLoading(true)
        try {
            const org = await context.createAndCacheTopOrg(groupOrgId, params, [orgTag])
            await context.loadRootOrgCildrens()
            resetDialogs()
        } catch (error) {
            setErrorMessage( t(mapErrorMessage(error)) )
            setLoading(false)
        }
    }, [groupOrgId])

    const resetDialogs = useCallback(() => {
        setLoading(false)
        setErrorMessage(null)
        setModalVisible(false)
    }, [])

    return (
        <div {...props} className={className ? [classes.root, className].join(' ') : classes.root}>
            <Toolbar className={classes.toolbar}>
                <ListSubheader className={classes.listItemTitle}>{title}</ListSubheader>
                <SortButtons customSortEnabled={customSortEnabled} onCustomSortChange={onCustomSortChange}/>
                <IconButton className={classes.moreIconButton} size={'small'} onClick={(event) => setModalVisible(true)} edge="end">
                    <AddIcon />
                </IconButton>
            </Toolbar>
            { modalVisible && listViewType === ORG_TAG.SUPPLIER_TARGET && <ActionGroupDialog
                loading={loading}
                groupEntity={null}
                errorMessage={errorMessage}
                onSubmit={(params) => errorMessage ? null : createNewChildOrg(ORG_TAG.SUPPLIER_TARGET, params)}
                onCancel={resetDialogs}
            />}
            { modalVisible && listViewType === ORG_TAG.RECIPIENT_LOCATION && <TriggerGroupDialog
                loading={loading}
                groupEntity={null}
                errorMessage={errorMessage}
                onSubmit={(params) => errorMessage ? null : createNewChildOrg(ORG_TAG.RECIPIENT_LOCATION, params)}
                onCancel={resetDialogs}
            />}
        </div>
    )
}

GroupViewListHeader.propTypes = {
    groupOrgId: PropTypes.string.isRequired,
    listViewType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10
    },
    toolbar: {
        paddingLeft: 0,
        paddingRight: theme.spacing(2)
    },
    listItemTitle: {
        flex: 1,
        fontSize: theme.font.extraSmall,
        lineHeight: '16px',
        letterSpacing: .4,
        fontWeight: 'normal',
        color: 'rgba(255, 255, 255, 0.5)',
        paddingLeft:0,
        backgroundColor: theme.palette.common.darkBackground,
    },
}))

export default GroupViewListHeader