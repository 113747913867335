import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'


const ReplacementImage = ({ className, replacement, onLoad, size = null, ...props }) => {
    const context = useContext(ApiContext)
    const classes = useStyles()
    const [data, setData] = useState(null)

    useEffect(() => {
        if (replacement.value) {
            loadImage()
        } else {
            setData(null)
        }
    }, [replacement?.value])

    const loadImage = async () => {
        const width = size && size.width > 0 ? size.width : null
        const height = size && size.height > 0 ? size.height : null
        try {
            const file = await context.appMessageService.getReplacementFile(replacement.value, width, height)
            var reader = new FileReader()
            reader.onload = function () {
                setData({ base64: reader.result })
                onLoad && onLoad()
            }
            reader.readAsDataURL(file)
        } catch (error) {
            console.log('getReplacementFile - error', error)
        }
    }

    const _classes = [classes.imageFile]
    if (className) _classes.push(className)
    if (props.onClick) _classes.push(classes.pointer)

    return (data ? <div {...props} className={_classes.join(' ')} style={{ backgroundImage: `url(${data.base64})` }} /> : null)
}

const useStyles = makeStyles(theme => ({
    imageFile: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    pointer: {
        cursor: 'pointer'
    }
}))

export default ReplacementImage