import UpdateMeta from '../../Models/UpdateMeta';
import { ROOT_PARAM_KEY, ROOT_PARAM_OLD_FALLBACK_KEY } from '../../../MessageLog/Contants';
import { ALL_GROUP_WITHOUT_CONFIG_ORG_TAGS, ORG_TAG } from '../OrgService';

const PARAM_PREFIX = 'core-org.param';
export const PARAM_NAME = `${PARAM_PREFIX}.name`;
const PARAM_DESCRIPTION = `${PARAM_PREFIX}.desc`;
export const PARAM_THEME = `${PARAM_PREFIX}.theme`;
export const PARAM_DEACTIVATE_DISABLED = `core-org.deactivate.disabled`;
export const PARAM_CODE_NAME_SPACE = `drv.code.namespace`;
export const PARAM_APP_MSG_VISIBILITY_MODE = 'app-msg.visibility-mode';
export const PARAM_APP_MSG_SEND_ORG_NAME = 'app-msg.send-org-name';
export const PARAM_APP_MSG_RECIPIENT_SIMPLY_MODE = 'app-msg.recipient-simply-mode';
export const PARAM_CUSTOMER_ID = 'app-msg.customerid';
export const PARAM_RESPONSIBLE = 'app-msg.responsible';
export const PARAM_PRIORITY = 'app-msg.priority';
export const PARAM_INVOICE_BLOCK = 'app-msg.invoice-block';
export const PARAM_BILLING_POINT = 'app-msg.billing-point';
export const PARAM_CNR = 'app-msg.cnr-sfs';
export const PARAM_APP_MSG_COLOR = 'app-msg.color';
export const PARAM_APP_MSG_CALLBACK_URL = 'app-msg.callback-http-address';
export const PARAM_APP_MSG_CALLBACK_METHOD = 'app-msg.callback-http-method';
export const PARAM_APP_MSG_CALLBACK_BODY = 'app-msg.callback-http-body';
export const PARAM_APP_MSG_AUTO_EXPORT = 'app-msg.autoexport';
export const PARAM_APP_MSG_ITEM_SORT = 'app-msg.item-sorting';
export const PARAM_APP_MSG_ROTATION_MODE = 'app-msg.rotation-mode';
export const PARAM_APP_MSG_ROTATION_STAY_TIME = 'app-msg.rotation-stay-time';
export const PARAM_DEVICE_REPORT_DEFINITION = 'core-device-reportdefinition';
export const PARAM_DEVICE_REPORT_DEFINITION_FAVOURITES = 'core-org-reportdefinition-favorites';
export const PARAM_MONITORING_AREAS = 'monitoring.areas';

export const buildRootOrgNicknameParam = userId => {
    return `app-msg.user.${userId}.nick`;
};

export const PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS = {
    LOCAL: 'local',
    /**
     * @deprecated ( use GROUP instead of CONFIG )
     */
    CONFIG: 'config',
    GROUP: 'group',
};

export const PARAM_FORM_FIELDS = [
    {
        id: PARAM_NAME,
        title: 'name',
        type: 'String',
        validators: ['required', 'trim'],
        errorMessages: ['this_field_is_required', 'this_field_is_required'],
    },
    {
        id: PARAM_DESCRIPTION,
        title: 'description',
        type: 'Text',
        validators: [],
        errorMessages: [],
    },
];

export default class Org {
    /**
     * @param {Object<Uid,Uid,Booelan, Array,Object,Array,Object,Object>}
     */
    constructor({ id, parent, deactivated, tags, params, vis, creator, lastUpd }) {
        this.id = id;
        this.parent = parent;
        this.deactivated = deactivated;
        this.tags = tags;
        this.params = params;
        this.vis = vis;
        this.creator = creator ? new UpdateMeta(creator) : null;
        this.lastUpd = lastUpd ? new UpdateMeta(lastUpd) : null;
    }

    /**
     * clone org
     * @returns {Org}
     */
    clone() {
        const org = new Org({});
        org.id = this.id;
        org.parent = this.parent;
        org.deactivated = this.deactivated;
        org.tags = this.tags;
        org.params = this.params;
        org.vis = this.vis;
        org.creator = this.creator;
        org.lastUpd = this.lastUpd;
        return org;
    }

    /**
     * Get id
     * @returns {Uid}
     */
    getId() {
        return this.id;
    }

    /**
     * Get parent id
     * @returns {Uid}
     */
    getParentId() {
        return this.parent;
    }

    /**
     * is org deactivated
     * @returns {Uid}
     */
    isDeactivated() {
        return this.deactivated;
    }

    /**
     * Get tags
     * @returns {Array<String>}
     */
    getTags() {
        return this.tags;
    }

    /**
     * Has tags
     * @param {Array<String>} tagsToSearch
     * @returns {Boolean}
     */
    hasTags(tagsToSearch) {
        for (let index = 0; index < tagsToSearch.length; index++) {
            const tag = tagsToSearch[index];
            if (this.getTags().indexOf(tag) !== -1) {
                return true;
            }
            if (ALL_GROUP_WITHOUT_CONFIG_ORG_TAGS.indexOf(tag) !== -1 && this.getTags().indexOf(ORG_TAG.CONFIG) !== -1) {
                // config org tags are deprecated and should be handled like groups
                return true;
            }
        }
        return false;
    }

    /**
     * Get params
     * @returns {Array<Object>}
     */
    getParams() {
        return this.params;
    }

    /**
     * Get param
     * @param {String} name
     * @returns {String}
     */
    getParam(name) {
        const value = this.params && this.params[name];
        if (!value) {
            // check fallback params with 'msg-app' prefix (old prefix)
            const fallbackName = name.replace(`${ROOT_PARAM_KEY}:`, `${ROOT_PARAM_OLD_FALLBACK_KEY}:`);
            const fallbackValue = this.params && this.params[fallbackName];
            if (fallbackValue && fallbackValue.trim() !== '') {
                console.warn('return fallback org param: ', fallbackName, 'value: ', fallbackValue);
                return fallbackValue;
            }
        }
        return value;
    }

    /**
     * Get name
     * @returns {String}
     */
    getName() {
        return this.getParam(PARAM_NAME) || this.getId();
    }

    /**
     * Get description
     * @returns {String}
     */
    getDescription() {
        return this.getParam(PARAM_DESCRIPTION);
    }

    /**
     * Set param
     * @param {String} name
     * @returns {String}
     */
    setParam(name, value) {
        if (!this.params) this.params = [];
        this.params[name] = value;
    }

    /**
     * Get visibility
     * @returns {Array<Object>}
     */
    getVis() {
        return this.vis;
    }

    /**
     * Get creator
     * @returns {UpdateMeta}
     */
    getCreator() {
        return this.creator;
    }

    /**
     * Get last updater
     * @returns {UpdateMeta}
     */
    getLastUpdater() {
        return this.lastUpd;
    }
}
