import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Chip, InputLabel, closeParamsMenu, Menu, MenuItem, Tooltip, Grid } from '@material-ui/core'
import MessageTemplate from '../../../m2m-cloud-api/Api/AppMesageService/Models/MessageTemplate'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import AddIcon from '@material-ui/icons/Add'
import ControlCameraIcon from '@material-ui/icons/ControlCamera'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import { v4 as uuidv4 } from 'uuid'
import { PARAM_APP_MSG_ITEM_SORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { ORG_PARAM_KEY_REPLACEMENT } from '../../../m2m-cloud-api/MessageLog/Contants'
import { sortItems } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { REPLACEMENT_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'

const getListItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
})

const getListStyle = isDraggingOver => ({
    display: 'flex',
    overflow: 'auto',
})

class ActionDialog extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            header: props.template && props.template.getHeader() || '',
            body: props.template && props.template.getBody() || '',
            replacementIds: props.template && props.template.getReplacementIds() || [],
            loading: false,
            errorMessage: null,
            paramsMenuAnchorEl: null
        }
    }

    isEditMode() {
        const { template } = this.props
        return template ? true : false
    }

    handleSubmit() {
        const { template, orgId } = this.props
        const { header, body, replacementIds } = this.state

        this.setState({ loading: true, errorMessage: null })

        const upsertTemplate = (templateId) => {
            const template = new MessageTemplate({ templateId, orgId, name: header, header: header, body, replacementIds: replacementIds })
            this.context.api.appMessageService.upsertTemplate(template.getId(), template.getHeader(), template.getHeader(), template.getBody(), template.getOrgId(), template.getReplacementIds())
                .then(result => {
                    this.props.onSuccess(template)
                })
                .catch(error => {
                    this.setState({
                        errorMessage: mapErrorMessage(error)
                    })
                })
                .finally(() => this.setState({ loading: false }))
        }

        if (this.isEditMode()) {
            upsertTemplate(template.getId())
        } else {
            const templateId = uuidv4()
            upsertTemplate(templateId)
        }
    }

    render() {
        const { header, body, loading, errorMessage } = this.state
        const { t, classes } = this.props

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('service_definition')}</DialogTitle>
                    <Divider />
                    <DialogContent className={classes.dialogContent}>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit.bind(this)}
                            onError={errors => console.log("form error:", errors)}>

                            <TextValidator
                                className={classes.field}
                                value={header}
                                onChange={(event) => this.setState({ header: event.target.value })}
                                margin="dense"
                                multiline={false}
                                label={t('name')}
                                validators={['required', 'trim']}
                                errorMessages={[t('this_field_is_required'), t('this_field_is_required')]}
                                fullWidth />

                            <TextValidator
                                className={classes.field}
                                value={body}
                                onChange={(event) => this.setState({ body: event.target.value })}
                                margin="dense"
                                multiline={true}
                                rows={5}
                                label={t('background_information')}
                                validators={[]}
                                errorMessages={[]}
                                fullWidth />

                            {this.renderParameters()}

                            {errorMessage && <DialogContentText className={classes.errorText}>
                                {errorMessage}
                            </DialogContentText>}
                        </ValidatorForm>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button disabled={loading} onClick={this.props.onCancel}>
                            {t('cancel')}
                        </Button>
                        <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
                            {t('save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    prepareSortParam() {
        return `${PARAM_APP_MSG_ITEM_SORT}-${ORG_PARAM_KEY_REPLACEMENT}`
    }

    renderParameters() {
        const { t, classes, replacements, groupOrgId } = this.props
        const { paramsMenuAnchorEl, replacementIds } = this.state

        let notAddedReplacements = replacements && replacements.filter(replacement => replacementIds.indexOf(replacement.getId()) === -1) || []
        const filteredNoteReplacements = replacements?.filter(_replacement => _replacement.getType() === REPLACEMENT_TYPE.NOTE)

        const groupOrg = this.context.childrenOrgs.find(org => org.getId() === groupOrgId)
        const parametersIds = groupOrg?.getParam(this.prepareSortParam())
        notAddedReplacements = sortItems(parametersIds, notAddedReplacements)

        const onDragEnd = (result) => {
            //send to params
            if (!result.destination) {
                return
            }
            const items = reorder(
                replacementIds,
                result.source.index,
                result.destination.index
            );
            console.log('sort items:', items)
            // this.handleSubmit(items)
            this.setState({ replacementIds: items })

        }

        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list)
            const [removed] = result.splice(startIndex, 1)
            result.splice(endIndex, 0, removed)
            return result
        }

        return (
            <Fragment>
                <InputLabel className={classes.parametersLabel} id="replacement-type">{t('selectable_features')}</InputLabel>

                <Grid container alignItems={'center'}>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable-replacement-list" mode={'virtual'} direction="horizontal">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                    {...provided.droppableProps}
                                >
                                    {replacementIds && replacementIds.map((param, index) => {
                                        const replacement = replacements.find(replacement => replacement.getId() === param)
                                        if (!replacement) return null
                                        return (
                                            <Draggable key={param} draggableId={param} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getListItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <Chip icon={<ControlCameraIcon />} key={param} className={classes.chip} label={replacement?.getName()} onDelete={this.onReplacementDeleteClick.bind(this, param)} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Button
                        disabled={notAddedReplacements.length === 0}
                        aria-controls="params-menu"
                        aria-haspopup="true"
                        color="primary"
                        className={classes.addButton}
                        onClick={this.openParamsMenu.bind(this)}
                        startIcon={<AddIcon />}>
                        {t('add')}
                    </Button>
                    <Menu
                        id="params-menu"
                        anchorEl={paramsMenuAnchorEl}
                        keepMounted
                        open={Boolean(paramsMenuAnchorEl)}
                        onClose={this.closeParamsMenu.bind(this)}>
                        {notAddedReplacements.map(replacement => {
                            const isSelectedNoteType = filteredNoteReplacements?.some(_replacement => this.state.replacementIds.includes(_replacement.getId()))
                            return (
                                <MenuItem
                                    key={replacement.getId()}
                                    onClick={this.onReplacementClick.bind(this, replacement)}
                                    disabled={isSelectedNoteType && replacement.getType() === REPLACEMENT_TYPE.NOTE}
                                >
                                    {replacement.getName()}
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </Grid>
            </Fragment>
        )
    }

    openParamsMenu(event) {
        this.setState({ paramsMenuAnchorEl: event.currentTarget })
    }

    closeParamsMenu(event) {
        this.setState({ paramsMenuAnchorEl: null })
    }

    onReplacementClick(replacement) {
        const replacementIds = this.state.replacementIds
        replacementIds.push(replacement.getId())
        this.setState({ paramsMenuAnchorEl: null, replacementIds })
    }

    onReplacementDeleteClick(param) {
        const replacementIds = this.state.replacementIds.filter(_param => _param !== param)
        this.setState({
            replacementIds
        })
    }
}

ActionDialog.contextType = PageContext

ActionDialog.propTypes = {
    open: PropTypes.bool,
    orgId: PropTypes.any,
    template: PropTypes.any,
    replacements: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    groupOrgId: PropTypes.any
}

const styles = theme => ({
    dialogContent: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4)
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
    parametersLabel: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    chip: {
        marginRight: theme.spacing(1),
        borderRadius: 2,
        cursor: 'grab'
    }
})


export default withTranslation()(withStyles(styles)(ActionDialog))