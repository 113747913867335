import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import { REPLACEMENT_TYPE, REPLACEMENT_DATE_TYPE, REPLACEMENT_DATE_FORMAT } from '../../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import { MenuItem, FormControl, Typography, Button, Card, CardContent, IconButton, CardMedia, } from '@material-ui/core'
import { DatePicker, TimePicker, DateTimePicker } from '../../../../Libs/Components/DatePicker'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import { ValidatorForm } from 'react-material-ui-form-validator'


export const addValidationRules = () => {
    ValidatorForm.addValidationRule('minDecimalValue', (value, min) => {
        const minNumber = Number(min)
        const cleanedValue = value.replace(',', '.')
        const numberValue = Number(cleanedValue)
        if (cleanedValue !== "" && (numberValue === Infinity || Math.floor(numberValue) < minNumber)) {
            return false
        }
        return true
    })
    ValidatorForm.addValidationRule('maxDecimalValue', (value, max) => {
        const maxNumber = Number(max)
        const cleanedValue = value.replace(',', '.')
        const numberValue = Number(cleanedValue)
        if (cleanedValue !== "" && (numberValue === Infinity || Math.ceil(numberValue) > maxNumber)) {
            return false
        }
        return true
    })
}
addValidationRules()

const ReplacementField = (props) => {
    switch (props.replacement.getType()) {
        case REPLACEMENT_TYPE.TEXT:
            return <ReplacementTextField {...props} />
        case REPLACEMENT_TYPE.INTEGER:
            return <ReplacementNumberField {...props} />
        case REPLACEMENT_TYPE.ENUM:
            return <ReplacementEnumField {...props} />
        case REPLACEMENT_TYPE.DATE:
            return <ReplacementDateField {...props} />
        case REPLACEMENT_TYPE.QR_CODE:
            return <ReplacementCodeField {...props} codeType={REPLACEMENT_TYPE.QR_CODE} />
        case REPLACEMENT_TYPE.BAR_CODE:
            return <ReplacementCodeField {...props} codeType={REPLACEMENT_TYPE.BAR_CODE} />
        case REPLACEMENT_TYPE.IMAGE:
            return <ReplacementImageField {...props} />
        default:
            return null
    }
}

export const ReplacementTextField = ({ replacement, loading, value, onValueChange, hideLabel, validators, errorMessages, ...props }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const key = replacement?.getId()
    const desc = replacement?.getDesc()

    let _validators = validators ? validators : replacement && isFieldRequired(replacement) ? ['required'] : []
    let _errorMessages = errorMessages ? errorMessages : replacement && isFieldRequired(replacement) ? [t('this_field_is_required')] : []

    if (!validators && !errorMessages && replacement) {
        _validators = [..._validators, `maxStringLength:${desc?.max}`]
        _errorMessages = [..._errorMessages, t('allowed_maximum_character_length', { max: desc?.max })]
    }

    return (
        <TextValidator
            key={key}
            disabled={loading}
            className={classes.field}
            value={value}
            onChange={(event) => onValueChange(replacement, event.target.value)}
            margin="dense"
            multiline={false}
            label={!hideLabel && replacement?.getName()}
            validators={_validators}
            errorMessages={_errorMessages}
            fullWidth />
    )
}

export const ReplacementNumberField = ({ replacement, loading, value, onValueChange, validators, errorMessages, hideLabel }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const key = replacement?.getId()
    const desc = replacement?.getDesc()

    let _validators = validators ? validators : isFieldRequired(replacement) ? ['required'] : []
    let _errorMessages = errorMessages ? errorMessages : isFieldRequired(replacement) ? [t('this_field_is_required')] : []

    if (!validators && !errorMessages) {
        _validators = [..._validators, desc.allowDecimals ? `minDecimalValue:${desc.min}` : `minNumber:${desc.min}`, desc.allowDecimals ? `maxDecimalValue:${desc.max}` : `maxNumber:${desc.max}`]
        _errorMessages = [..._errorMessages, t('allowed_minimum_value', { min: desc.min }), t('allowed_maximum_value', { max: desc.max })]

        _validators = [desc.allowDecimals ? 'matchRegexp:^[0-9.,]+$' : 'isNumber', ..._validators]
        _errorMessages = [t('invalid_number'), ..._errorMessages]
    }

    return (
        <TextValidator
            key={key}
            disabled={loading}
            className={classes.field}
            value={value}
            onChange={(event) => onValueChange(replacement, event.target.value)}
            margin="dense"
            multiline={false}
            label={!hideLabel && replacement?.getName()}
            validators={_validators}
            errorMessages={_errorMessages}
            fullWidth />
    )
}

export const ReplacementEnumField = ({ replacement, loading, value, onValueChange, validators, errorMessages, hideLabel }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const key = replacement.getId()
    const desc = replacement.getDesc()
    const options = desc ? desc.values : []

    return (
        <SelectValidator
            key={key}
            disabled={loading}
            className={classes.field}
            value={value}
            onChange={(event) => onValueChange(replacement, event.target.value)}
            margin="dense"
            multiline={false}
            label={!hideLabel && replacement?.getName()}
            validators={validators ? validators : isFieldRequired(replacement) ? ['required'] : null}
            errorMessages={errorMessages ? errorMessages : isFieldRequired(replacement) ? [t('this_field_is_required')] : null}
            fullWidth>
            {options.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
        </SelectValidator>
    )
}

export const ReplacementDateField = ({ replacement, value, onValueChange, onDateFieldError, customFieldError, hideLabel }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const key = replacement.getId()
    const desc = replacement.getDesc()

    switch (desc.type) {
        case REPLACEMENT_DATE_TYPE.DATE:
            return (
                <DatePicker
                    key={key}
                    label={!hideLabel && replacement?.getName()}
                    value={value || null}
                    fullWidth={true}
                    format={REPLACEMENT_DATE_FORMAT.DATE}
                    onError={(error) => onDateFieldError && onDateFieldError(replacement, error)}
                    error={customFieldError && customFieldError}
                    onChange={(date) => onValueChange(replacement, date)} />
            )
        case REPLACEMENT_DATE_TYPE.DATETIME:
            return (
                <DateTimePicker
                    key={key}
                    label={!hideLabel && replacement?.getName()}
                    value={value || null}
                    fullWidth={true}
                    format={REPLACEMENT_DATE_FORMAT.DATETIME}
                    onError={(error) => onDateFieldError && onDateFieldError(replacement, error)}
                    error={customFieldError}
                    onChange={(date) => onValueChange(replacement, date)} />
            )
        case REPLACEMENT_DATE_TYPE.TIME:
            return (
                <TimePicker
                    key={key}
                    label={!hideLabel && replacement?.getName()}
                    value={value || null}
                    fullWidth={true}
                    format={REPLACEMENT_DATE_FORMAT.TIME}
                    onError={(error) => onDateFieldError && onDateFieldError(replacement, error)}
                    error={customFieldError}
                    onChange={(date) => onValueChange(replacement, date)} />
            )
        default:
            return (null)
    }
}

export const ReplacementCodeField = ({ replacement, loading, value, onValueChange, codeType, hideLabel }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const key = replacement.getId()

    return (
        <TextValidator
            key={key}
            disabled={loading}
            className={classes.field}
            value={value}
            onChange={(event) => onValueChange(replacement, event.target.value)}
            margin="dense"
            multiline={false}
            label={!hideLabel && replacement?.getName()}
            validators={isFieldRequired(replacement) ? ['required'] : null}
            errorMessages={isFieldRequired(replacement) ? [t('this_field_is_required')] : null}
            fullWidth />
    )
}

export const ReplacementImageField = ({ replacement, value, onValueChange, customFieldError, onDeleteImage }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const key = replacement.getId()
    const inputRef = useRef()

    const removeImage = () => {
        inputRef.current.value = null
        onDeleteImage(key)
    }

    return (
        <FormControl key={key} margin="dense" fullWidth>
            <Typography variant="body1" color={customFieldError ? 'error' : 'secondary'}>
                {replacement.getName()}
            </Typography>
            <input
                ref={inputRef}
                style={{ display: 'none' }}
                accept="image/*"
                id={`img-input-${replacement.getId()}`}
                type="file"
                onChange={async (e) => {
                    const image = e.target.files[0]
                    onValueChange(replacement, image)
                }}
            />
            <label htmlFor={`img-input-${replacement.getId()}`}>
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                >
                    {t('select_image')}
                </Button>
            </label>
            {customFieldError && <Typography variant="caption" color="error" style={{ marginTop: 4 }}>
                {customFieldError}
            </Typography>}

            {value &&
                <FormControl margin="dense" fullWidth>
                    <Card>
                        <CardContent style={{ position: 'relative' }}>
                            <IconButton
                                onClick={removeImage}
                                className={classes.imageRemoveButton}
                                size="small"
                            >
                                <RemoveCircleIcon />
                            </IconButton>
                            <CardMedia className={classes.imageCard} image={URL.createObjectURL(value)} />
                        </CardContent>
                    </Card>
                </FormControl>}
        </FormControl>
    )
}

const isFieldRequired = (replacement) => {
    if (!replacement) return false
    const desc = replacement.getDesc()
    return desc.optional === true ? false : true
}

const useStyles = makeStyles(theme => ({
    imageRemoveButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.primary.main
    },
    imageCard: {
        height: 200,
        marginBottom: theme.spacing(1),
    },
}))

export default ReplacementField