import { get, set } from "local-storage"

const KEY_ROOT_ORG = 'message-admin-root-org'

export default class SessionStore {
    
    constructor(userId) {
        this.userId = userId

        this.getValue = (key) => {
            if (this.userId) {
                return get(`${this.userId}-${key}`)
            } else {
                console.warn("[SessionStore] has no session, can't get session setting.")
            }
        }

        this.setValue = (key, value) => {
            if (this.userId) {
                set(`${this.userId}-${key}`, value)
            } else {
                console.warn("[SessionStore] has no session, can't set session setting.")
            }
        }
    }

    /**
     * set selected root org
     * @param {Uid} orgId 
     */
    setSelectedRootOrg(orgId) {
        this.setValue(KEY_ROOT_ORG, orgId)
    }

    /**
     * set selected root org
     * @returns {Uid | null}
     */
    getSelectedRootOrg() {
        return this.getValue(KEY_ROOT_ORG)
    }

}
