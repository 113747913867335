import moment from 'moment';

export default class AccessToken {
  /**
   * @param {Object<String,Date,String, String>}
   */
  constructor({ id, expires, key, name }) {
    this.id = id;
    this.expires = expires;
    this.key = key;
    this.name = name;
  }

  /**
   * Get token id
   * @returns {Uid}
   */
  getId() {
    return this.id;
  }

  /**
   * Get token expire date
   * @returns {Date}
   */
  getExpiresDate() {
    return moment(moment(moment(this.expires).format('YYYY-MM-DD HH:mm')) - 1).format('YYYY-MM-DD HH:mm');
  }

  /**
   * Get token
   * @param {String} name
   * @returns {String}
   */
  getKey() {
    return this.key;
  }

  /**
   * get name
   * @returns {String}
   */
  getName() {
    return this.name;
  }
}
