import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { PARAM_ON_HOLD } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Message'
import { ORG_TAG } from '../../../m2m-cloud-api'
import { IconButton, Tooltip, FormControlLabel, Checkbox, TextField, InputAdornment } from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { DefaultStyles } from '../../../theme'
import ReactSVG from 'react-svg'
import MessageList, { MESSAGE_LIST_TYPE, MESSAGE_TYPE } from '../Components/MessageList'
import DateRangePicker from '../../../Libs/Components/DateRangePicker'
import DownloadIcon from '@material-ui/icons/GetApp'
import { csvExportCompletedMessages } from '../../../Libs/Utilities/CSVExport'

let moment = require('moment')

class CompletedMessages extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            publicUsers: null,
            messages: null,
            loading: true,
            errorMessage: null,
            isComponentActive: false,
            datePickerVisible: false,
            exportModalVisible: false,
            exportIdFields: true,
            dateRange: [moment().subtract(6, 'day'), moment()]
        }
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            isComponentActive: nextProps.isActive,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.isComponentActive !== this.state.isComponentActive && this.state.isComponentActive
            ||
            (prevProps.org && this.props.org && prevProps.org.getId() !== this.props.org.getId())
        ) {
            this.loadMessages()
        }
    }

    componentDidMount() {
        this.loadMessages()
    }

    render() {
        const { messages, publicUsers, dateRange, exportModalVisible, datePickerVisible, loading, exportIdFields } = this.state
        const hasTodayIncluded = dateRange[1].isSame(moment(), 'day')
        const { classes, t, messageType } = this.props

        const actionButtons = [{
            toolTip: t('export'),
            onClick: () => this.setState({ exportModalVisible: true }),
            icon: <DownloadIcon />
        }]

        return (
            <div className={classes.root} >
                <div className={classes.buttonContainer}>
                    <IconButton ize="small" className={classes.buttonNavigation} onClick={this.onPrevClick}>
                        <ReactSVG src="../assets/light/prev_page.svg" />
                    </IconButton>
                    <TextField
                        label={t('time_period')}
                        value={`${dateRange[0].format('L')} - ${dateRange[1].format('L')}`} className={classes.dateRangeButton}
                        onClick={() => this.setState({ datePickerVisible: true })}
                        inputProps={{
                            readOnly: true
                        }}
                        InputProps={{
                            endAdornment: <DateRangeIcon className={classes.endAdornment} />,
                        }} />
                    <IconButton ize="small" disabled={hasTodayIncluded} className={classes.buttonNavigation} onClick={this.onNextClick}>
                        <ReactSVG src="../assets/light/next_page.svg" />
                    </IconButton>



                </div>

                <div className={classes.tabContent}>
                    <MessageList actionButtons={actionButtons} messageListType={MESSAGE_LIST_TYPE.COMPLETED} headerTitle={t('completed')} messages={messages} messageType={messageType} publicUsers={publicUsers} />
                </div>

                {exportModalVisible && <DateRangePicker
                    open={true}
                    loading={loading}
                    title={t('completed_message_export_modal_title')}
                    description={t('completed_message_export_modal_description')}
                    footerContent={
                        <FormControlLabel
                            style={{ flex: 1, fontSize: 10 }}
                            classes={{ label: classes.exportCheckboxLabel }}
                            control={
                                <Checkbox
                                    checked={!exportIdFields}
                                    onChange={(event) => this.setState({ exportIdFields: !event.target.checked })} />
                            }
                            label={t('dont_export_id_fields')} />
                    }
                    onSubmitLabel={t('export')}
                    value={[moment().subtract(6, 'day'), moment()]}
                    onClose={() => this.setState({ exportModalVisible: false })}
                    onSubmit={this.exportMessages} />}

                {datePickerVisible && <DateRangePicker
                    open={true}
                    loading={loading}
                    title={t('time_period')}
                    description={t('completed_message_date_picker_description')}
                    onSubmitLabel={t('select')}
                    value={dateRange}
                    onClose={() => this.setState({ datePickerVisible: false })}
                    onSubmit={this.dateRangeChanged} />}
            </div>
        )
    }

    dateRangeChanged = (selectedDateRange) => {
        const { dateRange } = this.state
        const currentStartDate = moment(selectedDateRange[0]).startOf('day')
        const currentEndDate = moment(selectedDateRange[1]).startOf('day')
        if (dateRange[0].toDate().getTime() !== currentStartDate.toDate().getTime() || dateRange[1].toDate().getTime() !== currentEndDate.toDate().getTime()) {
            this.setState({ dateRange: [...selectedDateRange] }, () => {
                this.loadMessages()
            })
        }
    }

    onPrevClick = () => {
        const { dateRange } = this.state
        const countDays = dateRange[1].diff(dateRange[0], 'days')
        const endOfToday = moment().endOf('day')
        const newStartDate = dateRange[0].clone().subtract(countDays + 1, 'day').startOf('day')
        let newEndDate = newStartDate.clone().add(countDays, 'day').endOf('day')
        this.setState({ dateRange: [newStartDate, newEndDate] }, () => this.loadMessages())
    }

    onNextClick = () => {
        const { dateRange } = this.state
        const countDays = dateRange[1].diff(dateRange[0], 'days')
        const endOfToday = moment().endOf('day')
        const newStartDate = dateRange[1].clone().add(1, 'day').startOf('day')
        let newEndDate = newStartDate.clone().add(countDays, 'day').endOf('day')
        if (newEndDate.toDate().getTime() > endOfToday.toDate().getTime()) {
            newEndDate = endOfToday
        }
        this.setState({ dateRange: [newStartDate, newEndDate] }, () => this.loadMessages())
    }

    exportMessages = async (dates) => {
        const start = dates[0]
        const end = dates[1]
        this.setState({ loading: true })
        const startDate = start.clone().startOf('day')
        const endDate = end.clone().endOf('day')
        try {
            let { publicUsers, messages, orgs } = await this.loadMessagesFromApi(startDate, endDate)
            const allOrgs = await this.context.orgService.searchOrg({ tags: [ORG_TAG.ROOT, ORG_TAG.GROUP, ORG_TAG.CONFIG, ORG_TAG.RECIPIENT_LOCATION, ORG_TAG.SUPPLIER_TARGET] })

            messages = messages.sort((a, b) => a.ts - b.ts)
            const fileName = `Export-${start.format('YYYY_MM_DD')}-${end.format('YYYY_MM_DD')}.csv`
            csvExportCompletedMessages(messages, publicUsers, allOrgs, this.context.replacements, fileName, this.state.exportIdFields)
            this.setState({ loading: false, exportModalVisible: false })
        } catch (error) {
            console.log("[exportMessages] error: ", error)
            this.setState({ loading: false })
        }
    }

    async loadMessages() {
        this.setState({ loading: true, messages: null, publicUsers: null, errorMessage: null })
        const { dateRange } = this.state
        const startDate = dateRange[0].clone().startOf('day')
        const endDate = dateRange[1].clone().endOf('day')

        try {
            const { publicUsers, messages } = await this.loadMessagesFromApi(startDate, endDate)
            this.setState({
                publicUsers,
                messages,
                datePickerVisible: false,
                loading: false
            })
        } catch (error) {
            console.log("[loadMessages] error: ", error)
            this.setState({ loading: false })
        }

    }

    async loadMessagesFromApi(startDate, endDate, loadOrgs = false) {

        const { messageType } = this.props

        let loadMessagePromise = null
        let messages = null
        if (this.props.org) {
            switch (messageType) {
                case MESSAGE_TYPE.SUPPLIER:
                    messages = await this.context.appMessageService.readMessagesByDefinitionOrg(this.props.org.getId(), 0, 100000000, true, startDate.toDate(), endDate.toDate())
                    break
                case MESSAGE_TYPE.RECIPIENT:
                    messages = await this.context.appMessageService.readMessagesByDeviceOrg(this.props.org.getId(), 0, 100000000, true, startDate.toDate(), endDate.toDate())
                    break
            }
        } else {
            console.log('[loadMessages] has no org: ', this.props.org)
        }

        if (messages) {

            let publicUserIds = []
            let orgsToLoad = []
            let replacementIds = []
            const triggeringDeviceIds = []
            messages.sort((a, b) => a.ts - b.ts).map(message => {
                const acknowledgedUserId = message.getAcknowledgedUserId()
                const processingUserId = message.getProcessingUserId()
                const triggeringUserId = message.getTriggeringUserId()
                if (acknowledgedUserId && publicUserIds.indexOf(acknowledgedUserId) === -1) {
                    publicUserIds.push(acknowledgedUserId)
                }
                if (processingUserId && publicUserIds.indexOf(processingUserId) === -1) {
                    publicUserIds.push(processingUserId)
                }
                if (triggeringUserId && publicUserIds.indexOf(triggeringUserId) === -1) {
                    publicUserIds.push(triggeringUserId)
                }

                const params = message.getParams()
                if (params && Object.keys(params).length > 0) {
                    Object.keys(params).map(replacementId => {
                        if (replacementIds.indexOf(replacementId) === -1 && replacementId.indexOf(PARAM_ON_HOLD) !== 0) replacementIds.push(replacementId)
                    })
                }

                const definitionOrgId = message.getDefinitionOrgId()
                const deviceAssignedOrgId = message.getDeviceAssignedOrgId()
                if (definitionOrgId && orgsToLoad.indexOf(definitionOrgId) === -1) {
                    orgsToLoad.push(definitionOrgId)
                }
                if (deviceAssignedOrgId && orgsToLoad.indexOf(deviceAssignedOrgId) === -1) {
                    orgsToLoad.push(deviceAssignedOrgId)
                }

                if (triggeringDeviceIds.indexOf(message.getTriggeringDeviceId()) === -1) {
                    triggeringDeviceIds.push(message.getTriggeringDeviceId())
                }

            })

            const publicUsers = await this.context.loadPublicUsers(publicUserIds)
            await this.context.loadAndCacheReplacementsByIds(replacementIds)
            await this.context.loadAndCacheDevices(triggeringDeviceIds)

            let orgs = []
            if (orgsToLoad.length > 0) {
                orgs = await this.context.loadAndCacheCompletedMessageOrgs(orgsToLoad)
            }

            return {
                publicUsers,
                messages,
                orgs
            }
        }
    }

}

CompletedMessages.propTypes = {
    messageType: PropTypes.string.isRequired,
    org: PropTypes.any
}

CompletedMessages.contextType = ApiContext

const styles = theme => ({
    root: {
        ...DefaultStyles.AbsoluteFill,
    },
    buttonContainer: {
        position: 'absolute',
        zIndex: 2,
        left: theme.spacing(3),
        right: theme.spacing(3),
        height: 70,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tabContent: {
        position: 'absolute',
        zIndex: 1,
        top: 78,
        left: 0,
        right: 0,
        bottom: 0,
    },
    buttonNavigation: {
        backgroundColor: theme.palette.secondary.dark,
        overflow: 'hidden',
        width: 30,
        height: 30,
        borderRadius: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        '& div': {
            display: 'flex'
        }
    },
    dateRangeButton: {
        marginTop: 16,
    },
    endAdornment: {
        marginLeft: 10,
        color: theme.palette.secondary.dark,
    },
    buttonExport: {
        position: 'absolute',
        zIndex: 1,
        top: 76,
        left: 0,
        right: 0,
        bottom: 0,
    },
    buttonNavigation: {
        backgroundColor: theme.palette.secondary.dark,
        overflow: 'hidden',
        width: 30,
        height: 30,
        borderRadius: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        '& div': {
            display: 'flex'
        }
    },
    exportCheckboxLabel: {
        fontSize: theme.font.medium
    },
    actionButtons: {
        marginTop: -theme.spacing(0.5),
        marginRight: theme.spacing(1.5),
        color: theme.palette.common.black
    }
})

export default withTranslation()(withStyles(styles)(CompletedMessages))
