import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withDarkTheme } from '../../../theme'
import { Button, InputLabel, Typography, Grid, MenuItem, FormControl, Select } from '@material-ui/core'
import Logo from '../../../Libs/Components/Logo'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { withTranslation } from 'react-i18next'
import { PARAM_FORM_USER_FIELDS, PARAM_FORM_TYPE_FIELD, PARAM_FORM_NICKNAME_FIELD, PARAM_FORM_LANGUAGE_FIELD, PARAM_REGISTRATION_SERVICE } from '../../../m2m-cloud-api/Api/UserService/Models/User'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import Footer from '../../../Libs/Components/Footer'
import { registrationAppSetting } from '../../../index'
import i18n from '../../../i18n'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { isAlreadyExistsError } from '../../../m2m-cloud-api/Api/Helper'

class Signup extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            params: this.getDefaultParamValues(),
            showSuccessAlert: false,
            createdUser: null,
            email: '',
            errorMessage: null
        }
    }

    getDefaultParamValues() {
        let params = {}
        params[PARAM_FORM_TYPE_FIELD.id] = PARAM_FORM_TYPE_FIELD.options[0]
        const browserLang = i18n.language
        if (PARAM_FORM_LANGUAGE_FIELD.options.indexOf(browserLang) === -1) {
            params[PARAM_FORM_LANGUAGE_FIELD.id] = PARAM_FORM_LANGUAGE_FIELD.options[0]
        } else {
            params[PARAM_FORM_LANGUAGE_FIELD.id] = browserLang
        }

        return params
    }

    handleKeyPress(event) {
        if (event.charCode === 13) { // enter key
            event.preventDefault()
            this.handleSubmit()
        }
    }

    handleSubmit() {
        this.setState({ loading: true })

        const { t } = this.props
        const { params } = this.state

        const userParams = {}
        userParams[PARAM_FORM_TYPE_FIELD.id] = params[PARAM_FORM_TYPE_FIELD.id]
        userParams[PARAM_FORM_LANGUAGE_FIELD.id] = params[PARAM_FORM_LANGUAGE_FIELD.id]
        userParams[PARAM_FORM_NICKNAME_FIELD.id] = params[PARAM_FORM_NICKNAME_FIELD.id]

        if (params[PARAM_FORM_TYPE_FIELD.id] === PARAM_FORM_TYPE_FIELD.options[0]) {
            PARAM_FORM_USER_FIELDS.map(field => {
                userParams[field.id] = params[field.id]
            })
        }

        if (registrationAppSetting) userParams[PARAM_REGISTRATION_SERVICE] = registrationAppSetting

        this.props.context.userService.createUser(this.state.email, userParams)
            .then(user => {
                this.setState({ createdUser: user, showSuccessAlert: true })
            })
            .catch(error => {
                console.log('error', JSON.stringify(error))
                let message = mapErrorMessage(error)
                if (isAlreadyExistsError(error)) {
                    message = t('error_email_adress_already_exists')
                }
                this.setState({ errorMessage: message, loading: false })
            })

    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value })
    }

    handleParamChange(event) {
        const { params } = this.state
        params[event.target.name] = event.target.value
        this.setState({ params })
    }

    getFormFields() {
        let fields = []
        fields = [PARAM_FORM_NICKNAME_FIELD, ...PARAM_FORM_USER_FIELDS, PARAM_FORM_LANGUAGE_FIELD]
        return fields
    }


    render() {
        const { email, params, loading, errorMessage } = this.state
        const { t, classes } = this.props
        const userTypeField = PARAM_FORM_TYPE_FIELD
        const value = params[userTypeField.id]
        return (
            <Grid container className={classes.container}>
                <Grid item sm={6} xs={12} className={classes.leftColumn}>
                    <Logo />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.rightColumn}>
                    <div className={classes.contentContainer}>
                        <Typography variant="h4" color="secondary" className={classes.title}>
                            {this.props.t('signup')}
                        </Typography>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit.bind(this)}
                            onError={errors => console.log("form error:", errors)}>
                            <TextValidator
                                className={classes.field}
                                key={'email'}
                                value={email}
                                onChange={(event) => {
                                    this.setState({ email: event.target.value, errorMessage: null })
                                }}
                                margin="dense"
                                id={'email'}
                                label={t('email')}
                                validators={['required', 'isEmail']}
                                errorMessages={[t('this_field_is_required'), t('email_is_not_valid')]}
                                fullWidth />


                            {this.getFormFields().map(field => {
                                const value = params[field.id]
                                if (field.type === 'select') {
                                    return <FormControl key={field.id} className={classes.formControl} fullWidth>
                                        <InputLabel htmlFor="user-lang">{t(field.title)}</InputLabel>
                                        <Select
                                            value={value}
                                            disabled={loading}
                                            className={classes.field}
                                            onChange={(event) => {
                                                let _params = params
                                                _params[field.id] = event.target.value
                                                this.setState({ params: _params, errorMessage: null })
                                            }}
                                            inputProps={{
                                                name: field.id,
                                                id: 'user-lang',
                                            }}>
                                            {field.options.map(option => (
                                                <MenuItem key={option} value={option}>{t(option)}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                } else {
                                    return <TextValidator
                                        className={classes.field}
                                        key={field.id}
                                        disabled={loading}
                                        value={value}
                                        onChange={(event) => {
                                            let _params = params
                                            _params[field.id] = event.target.value
                                            this.setState({ params: _params, errorMessage: null })
                                        }}
                                        margin="dense"
                                        id={field.id}
                                        multiline={field.type === 'Text' ? true : false}
                                        label={t(field.title)}
                                        validators={field.validators}
                                        errorMessages={field.errorMessages.map(message => t(message))}
                                        fullWidth
                                    />
                                }
                            })}

                            {errorMessage && <div className={classes.errorText}>
                                <Typography variant="body1" color="error" gutterBottom>
                                    {errorMessage}
                                </Typography>
                            </div>}
                            <div className={classes.buttonContainer}>
                                <Button onClick={() => { }} disabled={loading} type="submit" color="primary" variant="contained" className={classes.submitButton}>
                                    {t('submit')}
                                </Button>
                                <Typography className={classes.backButton} onClick={() => this.props.history.push(`/auth${window.location.search || ''}`)} color="secondary" variant="body1" gutterBottom>
                                    {this.props.t("back")}
                                </Typography>
                            </div>
                        </ValidatorForm>
                        <AlertDialog
                            open={this.state.showSuccessAlert}
                            title={this.props.t('account_crated_email_sent_title')}
                            message={this.props.t('account_crated_email_sent_description')}
                            onSubmit={() => this.props.history.push('/activate/' + this.state.createdUser.userId)} />
                    </div>
                </Grid>
                <Footer />
            </Grid>

        )
    }
}

const styles = theme => ({
    container: {
        height: '100%',
        alignItems: 'center',
    },
    leftColumn: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        }
    },
    rightColumn: {
        backgroundColor: 'black',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            paddingTop: theme.spacing(5)
        }
    },
    contentContainer: {
        maxWidth: 260,
        width: '100%'
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    field: {
        marginBottom: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(4)
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    backButton: {
        marginTop: 10,
        cursor: 'pointer',
        textTransform: 'uppercase'
    },
    submitButton: {
        flex: 1,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
})

export default withTranslation()(withDarkTheme(withStyles(styles)(Signup)))