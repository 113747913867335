export default class MessageTemplate {
  /**
   * @param {Object<Uid,Uid,String,String,String>}
   */
  constructor({ templateId, orgId, name, header, body, replacementIds }) {
    this.templateId = templateId;
    this.orgId = orgId;
    this.name = name;
    this.header = header;
    this.body = body;
    this.replacementIds = replacementIds || []; //  || { replacements:  "86af5bb6-004b-4b00-b9a9-53daa9beae51,463d047e-85f0-4402-8b23-88b91d6b6772,2c94ac5f-a7da-421e-946d-30f12cbfe7f0,f964b3ad-9af1-450a-8b09-03da55530ae9,ef545657-3033-41b2-af8c-e68973c9502f" }
  }

  /**
   * Get id
   * @returns {Uid}
   */
  getId() {
    return this.templateId;
  }

  /**
   * Get Org id
   * @returns {Uid}
   */
  getOrgId() {
    return this.orgId;
  }

  /**
   * Get template name
   * @returns {String}
   */
  getName() {
    return this.name;
  }

  /**
   * Get header
   * @returns {String}
   */
  getHeader() {
    return this.header;
  }

  /**
   * Get body
   * @returns {String}
   */
  getBody() {
    return this.body;
  }

  /**
   * Get params
   * @returns {Array<String>}
   */
  getParams() {
    return this.params;
  }

  /**
   * Get replacements
   * @returns {Array<Uid>}
   */
  getReplacementIds() {
    return this.replacementIds;
  }
}
