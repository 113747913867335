import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { APP_MODE } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { Grid } from '@material-ui/core'
import HeaderBar from '../Components/HeaderBar'
import SupplierTabView from '../Components/Supplier/SupplierTabView'
import { MOBILE_MENU_BREAKPOINT } from '../../MessageLog/Components/DrawerMenu/DrawerMenu'
import UserButton from '../../../Libs/Components/UserButton'
import HeaderBreadcrumb from '../Components/HeaderBreadcrumb'
import FontSize from '../Components/FontSize'
import ThemeSelector from '../Components/ThemeSelector'
import RotationModeButton from '../Components/RotationModeButton'
import { MAX_COMNTENT_WIDTH } from '../App'
import clsx from 'clsx'
import { PARAM_PN_SOUND } from '../../../m2m-cloud-api/Api/UserService/Models/User'
import PNSounds from '../../../Libs/Utilities/PNSounds'

class SupplierScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            settingsNotificationSettings: {},
            drawerMenuopen: false,
            selectedOrg: null,
            lastSupplierNotifyDates: {},
            lastSupplierNotifyReady: false
        }
    }

    async componentDidMount() {
        const { context } = this.props
        const selectedOrgs = context.getSupplierParentOrgIds()
        if (!selectedOrgs) {
            console.warn("supplier orgs not setted, redirect to select screen..., selectedOrgs: ", selectedOrgs)
            this.gotoSelectScreen()
        } else {
            if (context.appMode !== APP_MODE.SUPPLIER) {
                context.setAppMode(APP_MODE.SUPPLIER)
            }

            await this.updateLastSelectedSupplierOrgIfNeeded(selectedOrgs)

            this.initNotificationSettings()
            setTimeout(() => {
                this.setState({ lastSupplierNotifyReady: true })
            }, 2000);
        }
    }

    initNotificationSettings(){
        const user = this.props.context.userService.getActiveUser()
        const pnSoundName = user.getParam(PARAM_PN_SOUND)
        const pnSound = pnSoundName ? PNSounds.find(sound => sound.name === pnSoundName) : PNSounds.find(sound => sound.name === 'Default')
        this.audio = new Audio(pnSound.filePath)
    }

    componentDidUpdate(prevProps, prevState) {
        const { context } = this.props
        this.checkNewNotifications()
        const selectedOrgs = context.getSupplierParentOrgIds()
        this.updateLastSelectedSupplierOrgIfNeeded(selectedOrgs)
    }

    async updateLastSelectedSupplierOrgIfNeeded(selectedOrgs) {
        const { context } = this.props
        if (this.state.selectedOrgs !== selectedOrgs.join(',')) {
            const selectedOrg = await context.getLastSelectedSupplierOrg()
            if (selectedOrg) {
                this.setState({ selectedOrg, selectedOrgs: selectedOrgs.join(',') })
            }
        }
    }

    checkNewNotifications() {
        const { context } = this.props
        const orgs = context.getSupplierOrgs()
        if (orgs) {
            const lastSupplierNotifyDates = this.state.lastSupplierNotifyDates
            let notify = false
            orgs && orgs.map(org => {
                const orgMessages = context.getSupplierMessages(org.getId())
                if (orgMessages && orgMessages.length > 0) {
                    const lastNotifiedTimestamp = lastSupplierNotifyDates[org.getId()] || 0
                    const lastCreatedMessageTimestamp = orgMessages.map((e) => e.getCreatedDate().getTime()).sort().reverse()[0]
                    if (lastCreatedMessageTimestamp > lastNotifiedTimestamp) {
                        lastSupplierNotifyDates[org.getId()] = lastCreatedMessageTimestamp
                        if (context.getSupplierAlertEnabled(org.getId())) {
                            notify = true
                        }
                    }
                }
            })
            if (this.state.lastSupplierNotifyReady && notify) {
                this.audio.play()
            }
            this.state.lastSupplierNotifyDates = lastSupplierNotifyDates
        }
    }

    gotoSelectScreen() {
        this.props.history.push('/messaging')
    }

    setSelectedOrg = (item) => {
        const { context } = this.props
        context.setLastSelectedSupplierOrg(item.org)
        this.setState({ selectedOrg: item.org })
    }

    /*selectFirstOrgIfNeeded(force = false) {
        const { context } = this.props
        const orgs = context.getSupplierOrgs()
        if ((this.state.selectedOrg === null || force) && orgs && orgs.length > 0) {
            const lastSelectedOrgId = get(SETTING_SELECTED_SUPPLIER_ORG_ID)
            const selectedOrg = lastSelectedOrgId && orgs.find(org => org.getId() === lastSelectedOrgId) || orgs[0]
            this.setState({
                selectedOrg
            })

            const selectedOrgs = context.getSupplierParentOrgIds()
            if (!selectedOrgs) {
                const currentOrgs = [context.rootOrg.getId(), context.groupOrg.getId(), context.groupConfigOrg.getId(), selectedOrg.getId()]
                context.setSupplierParentOrgIds(currentOrgs, true)
            }
        }
    }*/

    render() {
        const { classes, context, t } = this.props
        const { selectedOrg } = this.state
        const theme = context.getRootOrgTheme()
        const themes = context.getRootOrgThemes()
        const isFullWidth = theme?.cols.length >= 3

        const orgs = context.getSupplierOrgs()
        const rightMenuItems = []
        orgs && orgs.map(org => {
            const orgMessages = context.getSupplierMessages(org.getId())
            rightMenuItems.push({
                id: org.getId(),
                name: org.getName(),
                selected: this.state.selectedOrg && org.getId() === this.state.selectedOrg.getId(),
                countProcessingMessages: orgMessages && orgMessages.filter(message => message.isProcessing()).length,
                countAllMessages: orgMessages && orgMessages.length,
                org: org
            })
        })

        return (
            <div className={classes.container}>
                <HeaderBar
                    leftContent={null}
                    centerContent={
                        <Fragment>
                            <HeaderBreadcrumb appMode={APP_MODE.SUPPLIER} />
                        </Fragment>
                    }
                    rightContent={<div style={{ display: 'flex' }}>
                        <RotationModeButton />
                        <FontSize />
                        {themes && themes.length > 0 && <ThemeSelector />}
                        <UserButton
                            userService={context.userService}
                            authzService={context.authzService}
                            pendingInvitations={context.pendingInvitations}
                            onInvitationAccepted={() => {
                                context.loadPendingInvitations()
                            }}
                            user={context.userService.getActiveUser()}
                            onLogout={() => context.userService.logoutUser()} />
                    </div>
                    } />
                <Grid container className={classes.contentContainer}>
                    <Grid item xs={12} className={isFullWidth ? clsx(classes.contentContainerInner, classes.fullWidth) : classes.contentContainerInner}>
                        <SupplierTabView org={selectedOrg} rightMenuItems={rightMenuItems} onSelectedOrgChange={this.setSelectedOrg} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}


const styles = theme => ({
    container: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#000',
    },
    contentContainer: {
        flex: 1,
        backgroundColor: '#fff',
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            width: `100%`
        },
    },
    contentContainerInner: {
        maxWidth: MAX_COMNTENT_WIDTH,
        position: 'relative',
        margin: '0 auto'
    },
    menuButton: {
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            display: 'none',
        },
    },
    notificationButton: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    listItem: {
        justifyContent: 'space-between',
    },
    listItemActive: {
        backgroundColor: theme.palette.primary.main + '!important',
    },
    listItemLabel: {
        backgroundColor: theme.palette.common.white,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius
    },
    fullWidth: {
        width: '100%',
        maxWidth: '100%'
    }
})

export default withTranslation()(withStyles(styles)(SupplierScreen))