export const MESSAGE_ACTION_TYPE = {
  MAIL: 'mail', //
  EMAIL: 'email',
  ENDPOINT_CMD: 'endpointcmd',
  HTTP: 'http', //
  LOG_CMD: 'logcmd',
  MATTER_HOST: 'mattermost',
  SLACK: 'slack',
  MESSAGE: 'message',
};

export const DATA_KEY_TYPE = `type`;

export default class MessageAction {
  /**
   * @param {Object<Uid,String,Object,Uid,MESSAGE_ACTION_TYPE>}
   */
  constructor({ actionId, name, data, orgId, type }) {
    this.actionId = actionId;
    this.name = name;
    this.data = data;
    this.orgId = orgId;
    this.type = type;
  }

  /**
   * Get id
   * @returns {Uid}
   */
  getId() {
    return this.actionId;
  }

  /**
   * Get name
   * @returns {String}
   */
  getName() {
    return this.name;
  }

  /**
   * Get data
   * @returns {Object}
   */
  getData() {
    return this.data;
  }

  /**
   * Get org id
   * @returns {Uid}
   */
  getOrgId() {
    return this.orgId;
  }

  /**
   * Get type
   * @returns {MESSAGE_ACTION_TYPE}
   */
  getType() {
    return this.type;
  }

  /**
   * Get type target
   */
  getTypeTarget() {
    const data = this.getData();
    console.warn('data', data, this.getType(), MESSAGE_ACTION_TYPE.MAIL);
    switch (this.getType()) {
      case MESSAGE_ACTION_TYPE.HTTP:
        return `${data.url} [${data.method}]`;
      case MESSAGE_ACTION_TYPE.MAIL:
      case MESSAGE_ACTION_TYPE.MESSAGE:
        return data.recipient;
    }
  }
}
