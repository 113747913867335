import { PARAM_NICKNAME } from './User';

export default class PublicUser {
  /**
   * @param {Object<String,Uid,Array>}
   */
  constructor({ id, params }) {
    this.id = id;
    this.params = params;
    this.originalNickname = null;
  }

  /**
   * Get user id
   * @returns {Uid}
   */
  getId() {
    return this.id;
  }

  /**
   * Get user params
   * @returns {Array<Object>}
   */
  getParams() {
    return this.params;
  }

  /**
   * Get param
   * @param {String} name
   * @returns {String}
   */
  getParam(name) {
    return this.params && this.params[name];
  }

  /**
   * get nick name
   * @returns {String}
   */
  getNickname() {
    return this.getParam(PARAM_NICKNAME);
  }

  /**
   * set nick name
   * @param {String} nickname
   * @returns {String}
   */
  setNickname(nickname) {
    if (this.originalNickname === null) this.originalNickname = String(this.params[PARAM_NICKNAME]);
    this.params[PARAM_NICKNAME] = nickname;
  }
}
