import React, { Fragment, useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemIcon,ListItemText, ListItemSecondaryAction, IconButton, Collapse, Fab, AppBar } from '@material-ui/core'
import Searchbox from './Searchbox'

import OrgIcon from '@material-ui/icons/Work'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxHeight: '100%',
        overflow: 'auto',
        paddingBottom: 80,
        //backgroundColor: theme.palette.background.paper,
    },
    activeListItemButton: {
        backgroundColor: theme.palette.primary.main
    },
    activeListItem: {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
        cursor: 'pointer'
    },
    fab: {
        marginRight: 5
    },
    appBar: {
    },
    search:{
        flex: 1,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
    }
}))

const TREE_LEVEL_PADDING = 30
export const ROOT_NODE_ID = 'root-node'

function OrgListView({ orgItems: _orgItems, onClickItem, activeOrgId, onAdd, disabledOrgIds, renderRootNode, className, listButtonEnabled = true, onChangeRootItems }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState(null)
    const [orgItems, setOrgItems] = useState(_orgItems)
    const onClickRef = useRef()

    useEffect(() => {
        setOrgItems(_orgItems)
    }, [_orgItems])

    useEffect(() => {
        if (searchTerm) {
            
            const searchItems = orgItems.filter(org => {
                const searchString = `${org.getName() || ''} ${org.getId() || ''}`
                return searchString.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
            })
            setOrgItems(searchItems)
        } else {
            setOrgItems(_orgItems)
        }
    }, [searchTerm])

    const getRootItem = (org) => {
        if (orgItems && orgItems.length > 0) {
            for (let index = 0; index < orgItems.length; index++) {
                const currentOrg = orgItems[index];
                if ( org.getParentId && org.getParentId() && currentOrg.getId() === org.getParentId() ) {
                    return currentOrg
                }
            }
        }
        return null
    }

    const [openIds, setOpenIds] = React.useState([])

    useEffect(() => {
        //if (openIds.length === 0) {
            let openedIds = [...openIds]
            if (activeOrgId) {
                const activeOrg = orgItems?.find(org => org.getId() === activeOrgId)
                if (activeOrg) {
                    let rootOrg = getRootItem(activeOrg)
                    while ( rootOrg ) {
                        openedIds.push( rootOrg.getId() )
                        rootOrg = getRootItem(rootOrg)
                    }
                }
            }
            setOpenIds(openedIds)
        //}
        console.log('activeOrgId change', activeOrgId, openIds)
    }, [activeOrgId]);

    useEffect(() => {
        setTimeout(() => {
            if(activeOrgId && (!onClickRef.current || onClickRef.current !== activeOrgId) ) {
                var scrollDiv = document.getElementById(activeOrgId)
                if (scrollDiv) {
                    try {
                        scrollDiv.scrollIntoView({behavior: onClickRef?.current ? 'smooth' : 'auto', block: 'center', alignToTop: false})
                    } catch (error) {
                    }
                }
                onClickRef.current = activeOrgId
            }
        }, 500)
        console.log('openIds change', activeOrgId, openIds)
    }, [activeOrgId, openIds])


    useEffect(() => {
        const rootItems = getRootItems()
        onChangeRootItems && onChangeRootItems(rootItems)
    }, [orgItems])

    const onClickHandler = (org) => {
        onClickRef.current = org ? org.getId() : null
        onClickItem(org)
    }

    const isOrgDisabled = (org) => {
        if (disabledOrgIds && disabledOrgIds.length > 0) {
            if ( disabledOrgIds.indexOf(org.getId()) >= 0 ) {
                return true
            }

            let rootOrg = getRootItem(org)
            let isDisabled = false

            while (isDisabled === false && rootOrg ) {
                if ( disabledOrgIds.indexOf(rootOrg.getId()) >= 0 ) {
                    isDisabled = true
                }
                rootOrg = getRootItem(rootOrg)
            }
            return  isDisabled
        }
        return false
    }

    const getRootItems = () => {
        const items =  orgItems && orgItems.filter( item => getRootItem(item) === null ) || []
        return items.sort((a, b) => {        
            return a.getName().localeCompare(b.getName())
        })
    }

    const getChildItems = (rootItem) => {
        return orgItems && orgItems.filter( item => item.getParentId() === rootItem.getId()).sort((a, b) => {        
            return a.getName().localeCompare(b.getName())
        })
    }

    const triggerExpand = (org) => {
        const id = org.getId()
        const isOpen = openIds.find(openId => openId === id) ? true : false
        if (isOpen) {
            setOpenIds(openIds.filter(openId => openId !== id))
        } else {
            setOpenIds([...openIds, id])
        }
    }

    const renderOrg = (org, level) => {
        const id = org.getId()
        const name = org.getName()

        const childItems = getChildItems(org)
        const isActive = id === activeOrgId
        const canOpen = childItems && childItems.length > 0 
        const isOpen = canOpen && openIds.find(openId => openId === id) ? true : false
        return (
            <Fragment key={id}>
                <ListItem 
                    className={isActive? listButtonEnabled ? classes.activeListItemButton : classes.activeListItem : null} 
                    id={id}
                    style={{paddingLeft: TREE_LEVEL_PADDING * (level+(renderRootNode ? 2 : 1)), cursor: 'pointer'}}
                    button={listButtonEnabled} 
                    disabled={isOrgDisabled(org)}
                    key={id} 
                    onClick={() => onClickHandler(org)}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <OrgIcon />
                    </ListItemIcon>
                    <ListItemText primary={name || id} />
                    <ListItemSecondaryAction>
                        {onAdd && <Fab onClick={() => onAdd(org)} size={'small'} color="secondary" aria-label="Add" className={classes.fab}>
                            <AddIcon />
                        </Fab> }
                        {   
                            canOpen && (
                                <IconButton size="small" onClick={() => triggerExpand(org)} edge="end" aria-label="Expand">
                                    { isOpen ? <ExpandLess /> : <ExpandMore />} 
                                </IconButton>
                            )
                        }
                    </ListItemSecondaryAction>
                </ListItem>
                {
                    isOpen && childItems.map( org => (
                        <Collapse key={org.getId()} in={true} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                { renderOrg(org, level+1) }
                            </List>
                        </Collapse>
                    ))
                }
            </Fragment>
        )
    }

    return (
        <Fragment>
            <AppBar position="sticky" color='transparent' elevation='none' className={classes.appBar}>
                <Searchbox value={searchTerm} onChange={(value) => setSearchTerm(value)} className={classes.search} />
            </AppBar>
            <List
                component="nav"
                aria-labelledby={t('org_list_title')}
                subheader={null}
                classes={{}}
                className={[classes.root, className].join(' ')}>
                {
                    renderRootNode === true && (
                        <Fragment>
                            <ListItem
                                className={activeOrgId === ROOT_NODE_ID ? listButtonEnabled ? classes.activeListItemButton : classes.activeListItem : null}
                                style={{ paddingLeft: TREE_LEVEL_PADDING, cursor: 'pointer' }}
                                button={listButtonEnabled}
                                key={ROOT_NODE_ID}
                                onClick={() => onClickItem(ROOT_NODE_ID)}>
                                <ListItemIcon>
                                    <OrgIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('root_org_node')} />
                            </ListItem>
                            <Collapse key={'root'} in={true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {getRootItems().map(org => renderOrg(org, 0))}
                                </List>
                            </Collapse>
                        </Fragment>
                    )
                }
                {
                    !renderRootNode && (
                        getRootItems().map(org => renderOrg(org, 0))
                    )
                }
            </List>
        </Fragment>
    )
}

export default OrgListView