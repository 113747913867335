import Device from './Models/Device';
import CmdEvtDesc from './Models/CmdEvtDesc';
import SettingsDesc from './Models/SettingsDesc';
import ReleaseResult from './Models/ReleaseResult';
import CodeDriver from '../Drivers/CodeDriver';
import TimerDriver from '../Drivers/TimerDriver';
import ApiCache, { CACHE_ITEM_TYPE } from '../ApiCache';
import Websocket from '../Websocket';

const API_SERVICE_KEY_DEVICE = 'core-device';
const API_SERVICE_KEY_DEVICE_SEARCH = 'core-device-search';
const API_SERVICE_KEY_DEVICE_DATA = 'core-device-data';
const API_SERVICE_KEY_DEVICE_MSG_GATEWAY = 'core-devmsggw';

export default class DeviceService {
    /**
     * @param {Api} api
     */
    constructor(api) {
        this.api = api;
        this.apiCache = new ApiCache(Device);
        this.codeDriver = new CodeDriver(this.api, this.apiCache);
        this.timerDriver = new TimerDriver(this.api, this.apiCache);
    }

    /**
     * get websocket
     * @returns {Websocket}
     */
    getWebSocket() {
        this.destroyWebSocket();
        const domain = `${this.api.domain.replace('https://', 'wss://')}/core-devmsggw/api/ws/connect`;
        this.websocket = new Websocket(domain, this.api.token);
        return this.websocket;
    }

    /**
     * destroy websocket
     */
    destroyWebSocket() {
        if (this.websocket) {
            this.websocket.destroy();
            this.websocket = null;
        }
    }

    /**
     * Get device
     * @param {Uid} deviceId
     * @returns {Device}
     */
    getDevice(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('GET', url, null).then(device => new Device(device));
    }

    /**
     * Get multiple devices
     * @param {Array<Uid>} deviceIds
     */
    getDeviceMultiple(deviceIds) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/getMultiple', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
        });

        if (deviceIds.length === 0) return Promise.resolve([]);

        const body = deviceIds;
        return this.api.request('POST', url, body).then(devices => {
            devices.map((device, index) => {
                devices[index] = new Device(device);
            });
            return devices;
        });
    }

    /**
     * update device
     * @param {Uid} deviceId
     * @param {Array<Promise>} promises
     * @returns Promise<Device>
     */
    updateDevice(deviceId, promises) {
        const me = this;
        return Promise.all(promises).then(results => {
            return me.getDevice(deviceId).then(device => {
                me.apiCache.update(device);
                return device;
            });
        });
    }

    /**
     * Assign device to org
     * @param {Uid} deviceId
     * @param {Uid} orgId
     *
     * @returns {Boolean}
     */
    assignDevice(deviceId, orgId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/assign', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        const body = { assignee: orgId };
        return this.api.request('POST', url, body).then(result => result.done);
    }

    /**
     * Unassign device from org
     * @param {Uid} deviceId
     *
     * @returns {Boolean}
     */
    unassignDevice(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/unassign', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('POST', url, null).then(result => result.done);
    }

    /**
     * Activate assigned device
     * @param {Uid} deviceId
     *
     * @returns {Boolean}
     */
    activateDevice(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/activate', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('POST', url, null).then(result => result.done);
    }

    /**
     * Deactivate assigned device
     * @param {Uid} deviceId
     *
     * @returns {Boolean}
     */
    deactivateDevice(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/deactivate', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('POST', url, null).then(result => result.done);
    }

    /**
     * Claim a device with a given claim-key. Requires the correct write key and write-permission on the new owner org.
     * @param {Uid} deviceId
     * @param {claimKey} claimKey
     * @param {Uid} owner
     */
    claimDevice(deviceId, claimKey, owner) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/claim', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('POST', url, { key: claimKey, owner }).then(result => new Device(result));
    }

    /**
     * Un-claims this device (deletes the owner entry) and generates a new claim key. Requires write-permission on current owner org.
     * @param {Uid} deviceId
     */
    unclaimDevice(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/unclaim', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('POST', url, null).then(result => new ReleaseResult(result));
    }

    /**
     * change device owner
     * @param {Uid} deviceId
     * @param {Uid} orgId
     * @returns {undefined}
     */
    changeOwner(deviceId, orgId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/owner/:orgId/preserveAssignment', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
            ':orgId': orgId,
        });
        return this.api.request('POST', url, null);
    }

    /**
     * Get settings
     * @param {Uid} deviceId
     * @param {String} key
     * @returns {String | null}
     */
    getSetting(deviceId, key) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/settings/:key', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
            ':key': key,
        });
        return this.api.request('GET', url, null);
    }

    /**
     * Update setting
     * @param {Uid} deviceId
     * @param {String} key
     * @param {String} value
     */
    updateSetting(deviceId, key, value) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/settings/:key', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
            ':key': key,
        });
        return this.api.request('POST', url, value, { 'Content-Type': 'text/plain' });
    }

    /**
     * Update settings
     * @param {Uid} deviceId
     * @param {Object} settings { "k1":"v1-1", "k2":null } // k2 will be deleted
     * @param {String} value
     */
    updateSettings(deviceId, settings) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/settings', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('POST', url, settings);
    }

    /**
     * Get device activations data
     * @param {Uid} deviceId
     * @param {Number} from
     * @param {Number} to
     */
    getDeviceActivationsData(deviceId, from = 0, to = 0) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:deviceId/activations?from=:from&to=:to', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':deviceId': deviceId,
            ':from': from,
            ':to': to,
        });
        return this.api.request('GET', url, null);
    }

    /**
     * Get device assignments data
     * @param {Uid} deviceId
     * @param {Number} from
     * @param {Number} to
     */
    getDeviceAssignmentsData(deviceId, from = 0, to = 0) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:deviceId/assignments?from=:from&to=:to', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':deviceId': deviceId,
            ':from': from,
            ':to': to,
        });
        return this.api.request('GET', url, null);
    }

    /**
     * Get current device data
     * @param {[Uid]} deviceIds
     * @param {[String]} events
     * @param {[String]} filters
     */
    getDeviceData(deviceIds, events, filters = null) {
        const url = this.api.buildRequestURL('/:serviceKey/api/data/current', {
            ':serviceKey': API_SERVICE_KEY_DEVICE_DATA,
        });
        const body = { deviceIds: deviceIds, events };
        if (filters) body.filters = filters;
        return this.api.request('POST', url, body);
    }

    /**
     * Get historical device data
     * @param {[Uid]} deviceIds
     * @param {[String]} events
     * @param {[String]} filters
     */
    getHistoricalDeviceData(deviceIds, events, from = 0, to = 0, filters = null) {
        const url = this.api.buildRequestURL('/:serviceKey/api/data/history?from=:from&to=:to', {
            ':serviceKey': API_SERVICE_KEY_DEVICE_DATA,
            ':from': from,
            ':to': to,
        });
        const body = { deviceIds: deviceIds, events };
        if (filters) body.filters = filters;
        return this.api.request('POST', url, body);
    }

    /**
     * @deprecated
     * Get params by device
     * @param {[Uid]} deviceId
     */
    getParamsByDevice(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/param/getByDevice/:deviceId', {
            ':serviceKey': API_SERVICE_KEY_DEVICE_DATA,
            ':deviceId': deviceId,
        });
        return this.api.request('GET', url, null);
    }

    /**
     * Read Command descriptions
     * @param {Uid} deviceId
     * @returns {Array<CmdEvtDesc>}
     */
    readCommandDescs(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/descs/commands', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('GET', url, null).then(items => {
            items.map((item, index) => {
                items[index] = new CmdEvtDesc(item);
            });
            return items;
        });
    }

    /**
     * Read Event descriptions
     * @param {Uid} deviceId
     * @returns {Array<CmdEvtDesc>}
     */
    readEventDescs(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/descs/events', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('GET', url, null).then(items => {
            items.map((item, index) => {
                items[index] = new CmdEvtDesc(item);
            });
            return items;
        });
    }

    /**
     * Read Settings descriptions
     * @param {Uid} deviceId
     * @returns {Array<SettingsDesc>}
     */
    readSettingDescs(deviceId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/device/:id/descs/settings', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':id': deviceId,
        });
        return this.api.request('GET', url, null).then(items => {
            items.map((item, index) => {
                items[index] = new SettingsDesc(item);
            });
            return items;
        });
    }

    /**
     * search device
     * @param {Number} orgId
     * @param {Number} from
     * @param {Number} size
     */
    searchDevice(orgId, from = 0, size = 10000) {
        const url = this.api.buildRequestURL('/:serviceKey/api/req/?from=:from&size=:size', {
            ':serviceKey': API_SERVICE_KEY_DEVICE_SEARCH,
            ':from': from,
            ':size': size,
        });
        const body = { org: orgId };
        return this.api.request('POST', url, body).then(result => {
            result.items.map((device, index) => {
                result.items[index] = new Device(device);
            });
            this.apiCache.setList(result.items);
            return this.apiCache.getCachedList().filter(device => device.getOwnerOrg() === orgId || device.getAssignedOrg() === orgId);
        });
    }

    /**
     * search devices
     * @param {Object} searchParams search, type, driver, physicalIds, ids, org, ownerOrg, assignedOrg, settings
     * @param {String|undefined} searchAfter
     * @param {Number} size
     */
    searchDevices(searchParams, searchAfter = undefined, size = 10000) {
        const url = this.api.buildRequestURL('/:serviceKey/api/req/?size=:size:&searchAfter', {
            ':serviceKey': API_SERVICE_KEY_DEVICE_SEARCH,
            ':size': size,
            ':&searchAfter': !searchAfter ? '' : `&searchAfter=${searchAfter}`
        });
        return this.api.request('POST', url, searchParams).then(result => {
            result.items.map((device, index) => {
                result.items[index] = new Device(device);
            });
            return result;
        });
    }

    /**
     * locate devices
     * @param {Object} searchParams search, type, driver, physicalIds, ids, org, ownerOrg, assignedOrg, settings
     * @param {String|undefined} searchAfter
     * @param {Number} size
     */
    locateDevices(searchParams, searchAfter = undefined, size = 10000) {
        const url = this.api.buildRequestURL('/:serviceKey/api/locate/?size=:size:&searchAfter', {
            ':serviceKey': API_SERVICE_KEY_DEVICE_SEARCH,
            ':size': size,
            ':&searchAfter': !searchAfter ? '' : `&searchAfter=${searchAfter}`
        });
        return this.api.request('POST', url, searchParams);
    }

    /**
     * get org assigned device count
     * @param {Array<Uid>} orgIds
     * @returns {Object<orgId,count>}
     */
    getOrgAssignedDeviceCount(orgIds) {
        const url = this.api.buildRequestURL('/:serviceKey/api/assignments/count', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
        });
        return this.api.request('POST', url, orgIds);
    }

    /**
     * get device report
     * @param {Org} reportDefinitionOrg
     * @param {Array<Org>} selectedOrgIds
     * @param {String|undefined} searchAfter
     * @param {Number} size
     * @param {Object} otherParams - like: search, type, driver, physicalIds, ids, ownerOrg, assignedOrg, settings, fullSettings, omitProjection, orgVisibility, settingsProjection
     * @returns
     */
    getDeviceReport(reportDefinitionOrg, selectedOrgIds, searchAfter = undefined, size = 1000, otherParams = {}) {
        const url = this.api.buildRequestURL('/:serviceKey/api/report/:orgId?size=:size:&searchAfter', {
            ':serviceKey': API_SERVICE_KEY_DEVICE,
            ':orgId': reportDefinitionOrg,
            ':size': size,
            ':&searchAfter': !searchAfter ? '' : `&searchAfter=${searchAfter}`,
        });

        // wenn du nach ownerOrg oder assignedOrg filterst, musst du "org" weglassen... weil org ist quasi synonym für "ownerOrg ODER assignedOrg"
        let orgFilter = {
            org: selectedOrgIds,
        };
        if (otherParams && (otherParams.ownerOrg || otherParams.assignedOrg)) {
            orgFilter = {};
        }

        const body = {
            deviceSelectorType: 'DeviceSearchRequest',
            deviceSelectorContent: {
                ...orgFilter,
                ...(otherParams || {}),
            },
        };
        return this.api.request('POST', url, body);
    }

    /**
     * get monitoring summary report
     * @param {Array<Uid>} deviceIds
     * @param {Array<String>} events
     * @param {Object} filter
     * @returns
     */
    getMonitoringSummaryReport(deviceIds, events, filter = null) {
        const url = this.api.buildRequestURL('/:serviceKey/api/data/current/aggregated', {
            ':serviceKey': API_SERVICE_KEY_DEVICE_DATA,
        });
        const body = {
            deviceIds: deviceIds,
            events: events,
        };
        if (filter) {
            body['filters'] = filter;
        }
        return this.api.request('POST', url, body);
    }

    /**
     * send reset command to sta2 device
     * @param {Uid} deviceId
     * @param {String} command e.g.: 'RESET'
     * @param {any|null} data0 e.g.: 1
     * @param {any|null} data1 e.g.: 1
     * @param {Object|null} fields e.g.: 1
     * @returns {Boolean}
     */
    sendSta2DeviceCommand(deviceId, command, data0 = null, data1 = null, fields = null) {
        const url = this.api.buildRequestURL('/:serviceKey/api/dispatchUserCommand', {
            ':serviceKey': API_SERVICE_KEY_DEVICE_MSG_GATEWAY,
        });
        const body = { deviceId: deviceId, cmd: command };
        if (data0 !== null) body.data0 = data0;
        if (data1 !== null) body.data1 = data1;
        if (fields !== null) body.fields = fields;
        return this.api.request('POST', url, body).then(result => result.done);
    }
}
