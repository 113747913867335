import React, { useState, useEffect, useContext, useCallback, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, TextField, LinearProgress, Grid, Box, Divider, IconButton, Badge } from '@material-ui/core'
import { PageContext } from '../../Context/PageProvider'
import clsx from 'clsx'
import ThemeModal, { getDefaultTheme } from './ThemeModal'
import { PARAM_THEME } from '../../../../m2m-cloud-api/Api/OrgService/Models/Org'
import AlertDialog from '../../../../Libs/Components/AlertDialog'
import { mapErrorMessage } from '../../../../Libs/Utilities/ApiHelper'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
    dialogContent: {
        overflowY: 'auto',
        maxHeight: '90%',
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: 350
    },
    dialogActions: {
        margin: theme.spacing(2),
        justifyContent: 'flex-start',
        '& button:last-child': {
            marginLeft: 'auto'
        }
    },
    contentHoriztontalPadding: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    addThemeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        '& p': {
            flex: 1
        }
    },
    themeItem: {
        marginBottom: theme.spacing(1),
        width: '100%',
        display: 'block',
        textAlign: 'left',
        alignItems: 'center',
        marginBottom: 0
    },
    listItem: {
        display: 'flex',
    },
    listItemText: {
        flex: 1,
        alignSelf: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    listItemBadge: {
        alignSelf: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    }, 
    listItemTextButton: {
        marginTop: theme.spacing(1.5),
        height: 30
    }
}))

const ThemesModal = ({ open, onClose, org }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const context = useContext(PageContext)
    const [loading, setLoading] = useState(false)
    const [themes, setThemes] = useState([])
    const [selectedTheme, setSelectedTheme] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const onSave = useCallback(async() => {
        setLoading(true)
        try {
            await context.api.orgService.putParam(org.getId(), PARAM_THEME, JSON.stringify(themes))
            setLoading(false)
            onClose()
        } catch (error) {
            setErrorMessage(t(mapErrorMessage(error)))
            setLoading(false)
        }
    }, [org && org.getId(), themes])

    const onApplyTheme = useCallback((theme) => {
        const _themes = [...themes]
        const themeIndex = _themes.findIndex(_theme => _theme.id === theme.id)
        if (themeIndex !== -1) {
            _themes[themeIndex] = theme
        } else {
            _themes.push(theme)
        }
        setThemes(_themes)
        setSelectedTheme(null)
    }, [themes])

    const onDeleteTheme = useCallback((theme) => {
        const _themes = [...themes].filter( _theme => _theme.id !== theme.id )
        setThemes(_themes)
        setSelectedTheme(null)
    }, [themes])

    const getParamThemes = async () => {
        try {
            setLoading(true)
            const paramThemes = await context.api.orgService.getParam(org.getId(), PARAM_THEME)
            if (paramThemes) {
                setLoading(false)
                setThemes(JSON.parse(paramThemes))
            }
        } catch (error) {
            setErrorMessage(t(mapErrorMessage(error)))
        }
        setLoading(false)
    }

    useEffect(() => {
        getParamThemes()
    }, [org && org.getId()])

    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} aria-labelledby="alert">
            {loading && <LinearProgress />}

            <DialogTitle id="alert-title">{t('themes')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText className={classes.contentHoriztontalPadding} color={'inherit'}> {t('themes_modal_description')}</DialogContentText>
                <Grid className={clsx(classes.addThemeContainer, classes.contentHoriztontalPadding)}>
                    <Typography>{t('your_themes')}</Typography>
                    <Button onClick={() => {
                        setSelectedTheme(getDefaultTheme())
                    }}>{t('add_theme')}</Button>
                </Grid>
                <Grid className={classes.contentHoriztontalPadding}>
                    {themes.length > 0 ? themes?.map((theme, index) => (
                        <Fragment>
                            <Typography component={'div'} className={classes.listItem}>
                                <Typography className={classes.listItemText}>{theme.title}</Typography>
                                {theme.default && <Badge className={classes.listItemBadge}>{t('default')}</Badge>}
                                <IconButton className={classes.listItemTextButton} size={'small'} onClick={() => setSelectedTheme(theme)}><EditIcon/></IconButton>
                                <IconButton className={classes.listItemTextButton} size={'small'} onClick={() => onDeleteTheme(theme)} ><DeleteIcon/></IconButton>
                                {/*<Button className={classes.themeItem} onClick={() => setSelectedTheme(theme)}>{theme.title}</Button>*/}
                            </Typography>
                            { index < themes.length-1 && <Divider /> }
                        </Fragment>
                    )): <Typography color={'textSecondary'} variant="body2">{t('no_layouts_available')}</Typography>}
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button disabled={loading} onClick={onClose}>
                    {t('cancel')}
                </Button>
                <Button disabled={loading} onClick={onSave} type="submit" color="primary" variant={'contained'}>
                    {t('save')}
                </Button>
            </DialogActions>
            {selectedTheme &&
                <ThemeModal
                    open={true}
                    title={selectedTheme ? t('new_theme') : t('edit_theme')}
                    onApplyTheme={onApplyTheme}
                    theme={selectedTheme}
                    onClose={() => setSelectedTheme(null)} />}

            {errorMessage && (
                <AlertDialog
                    open={true}
                    title={t('error')}
                    message={errorMessage}
                    submitButtonTitle={t('ok')}
                    onSubmit={() => setErrorMessage(null)}
                />
            )}
        </Dialog>
    )
}

export default ThemesModal
