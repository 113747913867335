import React, { useState, useEffect, useContext, Fragment } from 'react'
import { makeStyles, } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../Context/PageProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Checkbox, FormControlLabel, DialogTitle, TextField, LinearProgress, FormControl, InputLabel, Select, MenuItem, Link, Typography } from '@material-ui/core'
import { PARAM_CUSTOMER_ID, PARAM_RESPONSIBLE, PARAM_INVOICE_BLOCK } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { APP_MESSAGE_ROOT_ORG_ADMINS_GROUP } from '../Constants'
import CountriesJSON from '../../../Libs/Utilities/Countries.json'
import i18n from '../../../i18n'
import { ORG_TAG } from '../../../m2m-cloud-api'
import { VISIBILITY_MESSAGE_LOG_TEMPLATE } from '../../../m2m-cloud-api/MessageLog/Contants'


const useStyles = makeStyles(theme => ({
    billingInfoText: {
        marginTop: theme.margin.extraLarge,
        fontWeight: 'bold'
    },
    acceptContainer: {
        marginTop: theme.margin.large
    },
    checkboxFormControl: {
        marginTop: theme.margin.large,
    }
}))

function RootOrgDialog({ rootEntity, onSubmit, onCancel, loading, errorMessage }) {
    const { t } = useTranslation()
    const context = useContext(PageContext)
    const classes = useStyles()
    const [name, setName] = useState(rootEntity && rootEntity.getName() || '')
    const [customerId, setCustomerId] = useState(rootEntity && rootEntity.getParam(PARAM_CUSTOMER_ID) || '')
    const [contactPerson, setContactPerson] = useState(rootEntity && rootEntity.getParam(PARAM_RESPONSIBLE) || context.api.userService.getActiveUser().getUserId())
    const [loadingData, setLoadingData] = useState(rootEntity ? true : false)
    const [adminUsers, setAdminUsers] = useState(null)
    const [useTemplate, setUseTemplate] = useState(false)
    const [selectedTemplateOrgId, setSelectedTemplateOrgId] = useState(null)
    const [templateOrgs, setTemplateOrgs] = useState(null)
    const [rootOrgs, setRootOrgs] = useState(null)
    const title = rootEntity ? t('edit_root_org_title') : t('create_new_root_org_title')
    const message = rootEntity ? t('edit_root_org_description') : t('create_new_root_org_description')

    let getBillingInfos = null
    try {
        getBillingInfos = rootEntity && JSON.parse(rootEntity.getParam(PARAM_INVOICE_BLOCK))
    } catch (error) {
        console.log("org has no billing info", error)
    }
    const [billingInfos, setBillingInfos] = useState({
        company: getBillingInfos ? getBillingInfos.company : '',
        streetAndNumber: getBillingInfos ? getBillingInfos.streetAndNumber : '',
        postalCodeAndCity: getBillingInfos ? getBillingInfos.postalCodeAndCity : '',
        country: getBillingInfos ? getBillingInfos.country : '',
        'company-a1': getBillingInfos ? getBillingInfos['company-a1'] : '',
        'company-a2': getBillingInfos ? getBillingInfos['company-a2'] : '',
    })
    const [paidServiceAccept, setPaidServiceAccept] = useState(rootEntity ? true : false)
    const [thermsAccept, setThermsAccept] = useState(rootEntity ? true : false)

    const fetchData = async () => {
        let admins = []

        const creatorUserId = rootEntity.getCreator().getId()
        const creatorUser = await context.getPublicUser(creatorUserId)

        const rootOrg = await context.api.orgService.read(rootEntity.getId())
        const adminGroupId = rootOrg.getParam(APP_MESSAGE_ROOT_ORG_ADMINS_GROUP)
        if (adminGroupId) {
            const adminsGroup = await context.api.authzService.readGroup(adminGroupId)
            if (adminsGroup) {
                const adminUserIds = adminsGroup.getUsers()
                const creatorUserId = rootEntity.getCreator().getId()
                if (adminUserIds.indexOf(creatorUserId) === -1) adminUserIds.push(creatorUserId)
                const adminUsers = await context.api.userService.getPublicUsersWithIds(adminUserIds)
                admins = [creatorUser, ...adminUsers.filter(user => user.getId() !== creatorUser.getId())]
            } else {
                console.warn("[RootOrgDialog] can't load group: ", adminGroupId)
            }
        } else {
            console.warn("[RootOrgDialog] can't fetch admin group id from root org: ", rootEntity)
        }
        setAdminUsers(admins.sort((a, b) => a.getNickname().localeCompare(b.getNickname())))
        setLoadingData(false)
    }

    const fetchTemplateOrgs = async () => {
        setLoadingData(true)
        let templateOrgs = []
        const orgs = await context.api.orgService.searchOrg({ visibility: [VISIBILITY_MESSAGE_LOG_TEMPLATE], tags: [ORG_TAG.ROOT, ORG_TAG.GROUP] })
        const rootOrgs = orgs.filter(org => org.hasTags([ORG_TAG.ROOT]))
        setRootOrgs(rootOrgs)
        if (rootOrgs) {
            for (let i = 0; i < rootOrgs.length; i++) {
                const rootOrg = rootOrgs[i]
                const groupOrgs = orgs.filter(org => org.hasTags([ORG_TAG.GROUP]) && org.getParentId() === rootOrg.getId())
                templateOrgs = [...templateOrgs, ...groupOrgs]
            }
        }
        if (templateOrgs.length > 0) {
            setTemplateOrgs(templateOrgs)
            setSelectedTemplateOrgId(templateOrgs[0].getId())
        }
        setLoadingData(false)
    }

    useEffect(() => {
        if (!rootEntity) {
            setAdminUsers([])
            fetchTemplateOrgs()
        } else {
            fetchData()
        }
    }, [rootEntity])

    const submit = () => {
        const selectedTemplateOrg = useTemplate && selectedTemplateOrgId ? templateOrgs.find(org => org.getId() === selectedTemplateOrgId) : null
        const templateRootOrg = selectedTemplateOrg ? rootOrgs.find(org => org.getId() === selectedTemplateOrg.getParentId()) : null
        onSubmit({ name, customerId, contactPerson, billingInfos: JSON.stringify(billingInfos), selectedTemplateOrg, templateRootOrg })
    }

    return (
        <Dialog open={true} fullWidth maxWidth={'sm'} aria-labelledby="alert">
            {(loading || loadingData) && <LinearProgress />}
            <DialogTitle id="alert-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={name}
                    disabled={(loading || loadingData)}
                    onChange={(event) => setName(event.target.value)}
                    label={t('name')}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="customer-id"
                    value={customerId}
                    disabled={(loading || loadingData || rootEntity)}
                    onChange={(event) => !rootEntity && setCustomerId(event.target.value)}
                    label={t('customer_id')}
                    fullWidth
                />

                <FormControlLabel
                    className={classes.checkboxFormControl}
                    control={<Checkbox checked={useTemplate}
                        disabled={!templateOrgs || templateOrgs.length === 0}
                        onChange={(event) => setUseTemplate(event.target.checked)} color="primary" />}
                    label={<Typography variant="body2">
                        {t('use_template')}
                    </Typography>}
                />
                {useTemplate && <FormControl className={classes.formControl} fullWidth margin="dense">
                    <InputLabel id="responsible">{t('org_templates')}</InputLabel>
                    <Select
                        margin="dense"
                        id="responsible"
                        value={selectedTemplateOrgId}
                        disabled={(loading || loadingData)}
                        onChange={(event) => setSelectedTemplateOrgId(prevState => event.target.value)}
                        fullWidth>{templateOrgs && templateOrgs.map(org => <MenuItem value={org.getId()}>{org.getName()}</MenuItem>)}
                    </Select>
                </FormControl>}

                {rootEntity && <FormControl className={classes.formControl} fullWidth margin="dense">
                    <InputLabel id="responsible">{t('contact_person')}</InputLabel>
                    <Select
                        margin="dense"
                        id="responsible"
                        value={contactPerson}
                        disabled={(loading || loadingData)}
                        onChange={(event) => setContactPerson(event.target.value)}
                        label={t('contact_person')}
                        fullWidth>
                        {adminUsers && adminUsers.map(publicUser => (
                            <MenuItem value={publicUser.getId()}>{publicUser.getNickname()}</MenuItem>
                        ))}
                    </Select>
                </FormControl>}

                <Typography variant="subtitle1" className={classes.billingInfoText}>{t('billing_information')}</Typography>

                <TextField
                    margin="dense"
                    id="company"
                    value={billingInfos.company}
                    disabled={(loading || loadingData || rootEntity)}
                    onChange={(event) => { const value = event.target.value; setBillingInfos(prevState => ({ ...prevState, company: value || '' })) }}
                    label={t('company')}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="company-a1"
                    value={billingInfos['company-a1']}
                    disabled={(loading || loadingData || rootEntity)}
                    onChange={(event) => { const value = event.target.value; setBillingInfos(prevState => ({ ...prevState, ['company-a1']: value || '' })) }}
                    label={t('company_addition_1')}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="company-a2"
                    value={billingInfos['company-a2']}
                    disabled={(loading || loadingData || rootEntity)}
                    onChange={(event) => { const value = event.target.value; setBillingInfos(prevState => ({ ...prevState, ['company-a2']: value || '' })) }}
                    label={t('company_addition_2')}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="street-number"
                    value={billingInfos.streetAndNumber}
                    disabled={(loading || loadingData || rootEntity)}
                    onChange={(event) => { const value = event.target.value; setBillingInfos(prevState => ({ ...prevState, streetAndNumber: value || '' })) }}
                    label={t('street_and_number')}
                    fullWidth
                />
                <TextField
                    margin="dense"
                    id="postalcode-city"
                    value={billingInfos.postalCodeAndCity}
                    disabled={(loading || loadingData || rootEntity)}
                    onChange={(event) => { const value = event.target.value; setBillingInfos(prevState => ({ ...prevState, postalCodeAndCity: value || '' })) }}
                    label={t('postalcode_and_city')}
                    fullWidth
                />

                <FormControl className={classes.formControl} fullWidth margin="dense">
                    <InputLabel id="responsible">{t('country')}</InputLabel>
                    <Select
                        margin="dense"
                        id="responsible"
                        value={billingInfos.country}
                        disabled={(loading || loadingData || rootEntity)}
                        onChange={(event) => setBillingInfos(prevState => ({ ...prevState, country: event.target.value }))}
                        label={t('country')}
                        fullWidth>
                        {
                            CountriesJSON &&
                            CountriesJSON.sort((a, b) => {
                                const countryName = `land_${i18n.language || 'en'}`
                                return a[countryName].localeCompare(b[countryName])
                            }).map(country => (
                                <MenuItem value={country.land}>{country[`land_${i18n.language || 'en'}`]}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                {!rootEntity && <FormControl className={classes.acceptContainer}>
                    <FormControlLabel
                        control={<Checkbox checked={paidServiceAccept} onChange={(event) => setPaidServiceAccept(event.target.checked)} color="primary" />}
                        label={<Typography variant="body2">
                            {t('paid_service_accept')} ( <Link href="https://www.sfs.ch/messageLOG-booklet" target="_blank" color="textPrimary">{t('see_booklet')}</Link> )
                        </Typography>}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={thermsAccept} onChange={(event) => setThermsAccept(event.target.checked)} color="primary" />}
                        label={<Typography variant="body2">
                            {t('therms_accept')}  ( <Link href="https://www.sfs.ch/AGB" target="_blank" color="textPrimary">{t('terms_and_conditions')}</Link> )
                        </Typography>}
                    />
                </FormControl>}


                {errorMessage && <DialogContentText style={{ marginTop: 20, whiteSpace: 'pre-line' }} color="error">{errorMessage}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                {<Button disabled={(loading || loadingData)} onClick={onCancel} color="default">
                    {t('cancel')}
                </Button>}
                <Button
                    disabled={!name || name.trim() === "" || (loading || loadingData) || !thermsAccept || !paidServiceAccept}
                    onClick={submit}
                    color={"primary"}>
                    {rootEntity ? t('ok') : t('create')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default RootOrgDialog