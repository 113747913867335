import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { ThemeProvider } from '@material-ui/styles'
import { darkTheme, DefaultStyles } from '../../../theme'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { Typography, LinearProgress, Fab, Menu, MenuItem } from '@material-ui/core'
import UserListItem from './UserListItem'
import { ListContainer, ItemContainer } from '../../../Libs/Components/ListContainer'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import { Virtuoso } from 'react-virtuoso'
import AddIcon from '@material-ui/icons/Add'
import CreateNewUserDialog from './CreateNewUserDialog'
import EditUserDialog from './EditUserDialog'
import ChangeNicknameDialog from './ChangeNicknameDialog'
import InviteUserDialog from './InviteUserDialog'

class UserList extends Component {

    constructor(props) {
        super(props)

        this.destroyed = false

        this.state = {
            selectedIds: [],
            users: {},
            usersGroup: null,
            adminsGroup: null,
            createNewUserDialogVisible: false,
            inviteUserDialogVisible: false,
            loading: false,
            errorMessage: null,
            itemsToDelete: null,
            profileToEdit: false,
            itemsToConvertRole: null,
            addUserAnchorEl: null,
            offeredInvitations: null
        }

        props.onRef(this)

        this.refreshTimer = setInterval(async () => {
            await this.reloadGroup()
            await this.loadOfferedInvitations()
        }, 10 * 1000)
    }

    async componentDidMount() {
        await this.init()
    }

    componentWillUnmount() {
        this.destroyed = true
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer)
            this.refreshTimer = null
        }
    }

    async init() {
        const { usersGroup, adminsGroup } = await this.context.prepareRootOrgGroups()
        if (this.destroyed) {
            return
        }
        await this.loadPublicUsers([...usersGroup.getUsers(), ...adminsGroup.getUsers()])
        this.setState({ usersGroup, adminsGroup }, () => this.loadOfferedInvitations())
    }

    async reloadGroup() {
        const { usersGroup, adminsGroup } = this.state
        const _usersGroup = await this.context.api.authzService.readGroup(usersGroup.getId())
        const _adminsGroup = await this.context.api.authzService.readGroup(adminsGroup.getId())
        await this.loadPublicUsers([...usersGroup.getUsers(), ...adminsGroup.getUsers()])
        if (this.destroyed) {
            return
        }
        this.setState({ usersGroup: _usersGroup, adminsGroup: _adminsGroup })
    }

    async loadOfferedInvitations() {
        const { usersGroup, adminsGroup } = this.state
        const offeredInvitations = await this.context.api.authzService.listOfferedInvitations()
        const filteredOfferedInvitations = offeredInvitations.filter(invitation => invitation.getGroupId() === usersGroup.getId() || invitation.getGroupId() === adminsGroup.getId())

        const users = []
        filteredOfferedInvitations.map(invitation => {
            if (users.indexOf(invitation.invitee) === -1) {
                users.push(invitation.invitee)
            }
        })

        if (users.length > 0) {
            await this.loadPublicUsers(users)
        }

        this.setState({ offeredInvitations: filteredOfferedInvitations })
    }

    async loadPublicUsers(userIds) {
        const publicUsers = await this.context.api.userService.getPublicUsersWithIds(userIds)

        const users = this.state.users
        if (userIds && userIds.length > 0) {
            for (let index = 0; index < userIds.length; index++) {
                const userId = userIds[index]
                if (!users[userId]) {
                    const user = publicUsers?.find(publicUser => publicUser.getId() === userId)
                    users[userId] = user
                }
            }
        }
        this.setState({ users })
    }

    async reloadPublicUser(userId) {
        const { users } = this.state
        const user = await this.context.getPublicUser(userId, false)
        users[userId] = user
        this.setState({ users })
    }

    async deleteSelectedItems() {
        const entities = this.getEntities()
        const selectedIds = this.getSelectedIds()
        const selectedEntities = entities.filter(entity => selectedIds.indexOf(entity.id) >= 0)
        this.setState({ itemsToDelete: selectedEntities })
    }

    onSubmitDeleteDialog() {
        const { t } = this.props
        const { usersGroup, adminsGroup, itemsToDelete, offeredInvitations, users } = this.state

        this.setState({ loading: true }, async () => {

            const _itemsToDelete = [...itemsToDelete]
            _itemsToDelete.map(itemToDelete => {
                if (itemToDelete.isAdmin) {
                    if (itemToDelete.invitation) {
                        const userInvitation = offeredInvitations.find(invitation => invitation.invitee === itemToDelete.id && usersGroup.getId() === invitation.groupId)
                        if (userInvitation) _itemsToDelete.push(userInvitation)
                    } else {
                        const userId = usersGroup.getUsers().find(userId => userId === itemToDelete.id)
                        const user = users[userId]
                        if (userId) {
                            _itemsToDelete.push({ id: user.getId(), title: user.getNickname(), invitation: false, isAdmin: false })
                        }
                    }
                }
            })

            let errorMessage = null
            for (let index = 0; index < _itemsToDelete.length; index++) {
                const item = _itemsToDelete[index]
                const groupId = item.isAdmin ? adminsGroup.getId() : usersGroup.getId()
                try {
                    if (item.invitation) {
                        await this.context.api.authzService.revokeInvitation(groupId, item.id)
                    } else {
                        await this.context.api.authzService.removeUser(groupId, item.id)
                    }
                } catch (error) {
                    errorMessage = mapErrorMessage(error)
                    break
                }
            }

            await this.reloadGroup()
            await this.loadOfferedInvitations()
            if (errorMessage === null) {
                await this.resetLoadingState()
            } else {
                this.setState({ errorMessage })
            }
        })
    }

    onSubmitConvertRoleDialog() {
        const { t } = this.props
        const { usersGroup, adminsGroup, itemsToConvertRole } = this.state

        const { entity, isAdmin } = itemsToConvertRole

        this.setState({ loading: true }, async () => {
            let errorMessage = null
            try {
                if (isAdmin) {
                    const isInUsersGroup = usersGroup.getUsers().indexOf(entity.id) >= 0
                    if (!isInUsersGroup) {
                        await this.context.api.authzService.addUser(usersGroup.getId(), entity.id)
                    }
                    await this.context.api.authzService.removeUser(adminsGroup.getId(), entity.id)
                } else {
                    await this.context.api.authzService.inviteUser(adminsGroup.getId(), entity.id)
                }
            } catch (error) {
                errorMessage = mapErrorMessage(error)
            }

            await this.reloadGroup()
            await this.loadOfferedInvitations()
            if (errorMessage === null) {
                await this.resetLoadingState()
            } else {
                this.setState({ errorMessage })
            }
        })

    }

    async resetLoadingState() {
        this.setState({ loading: false, itemsToDelete: null, itemsToConvertRole: null })
    }

    getSelectedIds() {
        return this.state.selectedIds
    }

    getEntities() {
        const { usersGroup, adminsGroup, users, offeredInvitations } = this.state

        const entities = []

        adminsGroup.getUsers().map(userId => {
            const user = users[userId]
            if (user) {
                entities.push({ id: user.getId(), title: user.getNickname(), invitation: false, isAdmin: true })
            }
        })

        usersGroup.getUsers().map(userId => {
            const hasUserAsAdminUser = adminsGroup.getUsers().find(adminUserId => adminUserId === userId) ? true : false
            const hasUserOfferedAdminInvitation = offeredInvitations && offeredInvitations.find(invitation => adminsGroup.getId() === invitation.groupId && invitation.invitee === userId)
            if (!hasUserAsAdminUser && !hasUserOfferedAdminInvitation) {
                const user = users[userId]
                const item = user ? { id: user.getId(), title: user.getNickname(), invitation: false, isAdmin: false } : { id: userId, title: userId, invitation: false, isAdmin: false }
                entities.push(item)
            }
        })

        offeredInvitations && offeredInvitations.map(invitation => {
            const isUser = usersGroup.getId() === invitation.groupId
            const isAdmin = adminsGroup.getId() === invitation.groupId
            const hasAdminGroup = offeredInvitations.find(invitation => invitation.groupId === adminsGroup.getId())
            if (!(hasAdminGroup && isUser)) {
                const user = users[invitation.invitee]
                entities.push(user ? { id: user.getId(), title: user.getNickname(), invitation: true, isAdmin } : { id: invitation.invitee, title: invitation.invitee, invitation: true, isAdmin })
            }
        })

        return entities
    }

    render() {
        const { t, classes, searchTerm } = this.props
        const { usersGroup, adminsGroup, selectedIds, itemsToDelete, itemsToConvertRole, errorMessage, loading, profileToEdit, addUserAnchorEl, createNewUserDialogVisible, inviteUserDialogVisible } = this.state

        if (!usersGroup || !adminsGroup) return (
            <Fragment>
                <LinearProgress />
                <Fab className={classes.fabButton} disabled={true} color={'primary'}>
                    <AddIcon />
                </Fab>
            </Fragment>
        )

        const entities = this.getEntities()
        const canDeleteAdmins = entities?.filter(entity => entity.isAdmin).length > 1
        let filteredItems = searchTerm.trim() !== "" ? entities.filter(entity => entity.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) : entities
        filteredItems = filteredItems.sort((a, b) => {
            return a.title.localeCompare(b.title)
        })

        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
                <Typography variant="h5" className={classes.listTitle}>{t('user_management')}</Typography>
                <div style={{ flex: 1 }}>
                    {entities.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">{t('no_user_added')}</Typography>}
                    {entities.length > 0 && filteredItems.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">{t('no_match_found')}</Typography>}
                    <Virtuoso
                        components={{
                            Item: ItemContainer,
                            Footer: () => <div style={{ height: 80, width: '100%' }} />
                        }}
                        style={{ width: '100%', flex: 1, height: '100%', listStyle: 'none' }}
                        totalCount={filteredItems && filteredItems.length || 0}
                        itemContent={index =>
                            <UserListItem
                                index={index}
                                entity={filteredItems[index]}
                                invited={filteredItems[index].invitation}
                                isAdmin={filteredItems[index].isAdmin}
                                checked={selectedIds.find(id => id === filteredItems[index].id) ? true : false}
                                onEditProfileClick={(entity) => this.setState({ profileToEdit: entity })}
                                onClick={(entity) => {
                                    let newSelectedItems = []
                                    if (selectedIds.find(id => id === entity.id)) {
                                        newSelectedItems = selectedIds.filter(id => id !== entity.id)
                                    } else {
                                        newSelectedItems = [...selectedIds, entity.id]
                                    }
                                    this.setState({ selectedIds: newSelectedItems })
                                    this.props.onSelectionChange(newSelectedItems)
                                }}
                                onConvertRoleClick={(entity, isAdmin) => {
                                    this.setState({ errorMessage: null, itemsToConvertRole: { entity, isAdmin } })
                                }}
                                onDeleteClick={(filteredItems[index].isAdmin && canDeleteAdmins) || !filteredItems[index].isAdmin ? (entity) => this.setState({ itemsToDelete: [entity] }) : null} />
                        }>
                    </Virtuoso>
                    <Fab className={classes.fabButton} color={'primary'} onClick={(event) => this.setState({ addUserAnchorEl: event.currentTarget })} >
                        <AddIcon />
                    </Fab>
                    <Menu
                        anchorEl={addUserAnchorEl}
                        keepMounted
                        open={Boolean(addUserAnchorEl)}
                        onClose={() => this.setState({ addUserAnchorEl: null })}>
                        <MenuItem onClick={() => this.setState({ addUserAnchorEl: null, createNewUserDialogVisible: true })}>{t('create_new_user')}</MenuItem>
                        <MenuItem onClick={() => this.setState({ addUserAnchorEl: null, inviteUserDialogVisible: true })}>{t('invite_user')}</MenuItem>
                    </Menu>
                    <ThemeProvider theme={darkTheme}>
                        {(itemsToDelete && itemsToDelete.length > 0) && <AlertDialog
                            open={true}
                            loading={loading}
                            errorMessage={errorMessage}
                            title={itemsToDelete.length > 1 ? t('user_management_remove_users_from_group_title') : t('user_management_remove_user_from_group_title')}
                            message={itemsToDelete.length > 1 ? t('user_management_remove_users_from_group_description') : t('user_management_remove_user_from_group_description')}
                            submitButtonTitle={errorMessage ? t('delete') : null}
                            cancelButtonTitle={errorMessage ? t('close') : t('cancel')}
                            buttonVariant={'text'}
                            submitButtonColor={'primary'}
                            onSubmit={errorMessage ? null : this.onSubmitDeleteDialog.bind(this)}
                            onCancel={this.resetLoadingState.bind(this)}
                        />}
                        {itemsToConvertRole && <AlertDialog
                            open={true}
                            loading={loading}
                            errorMessage={errorMessage}
                            title={itemsToConvertRole.isAdmin ? t('convert_to_user') : t('convert_to_admin')}
                            message={itemsToConvertRole.isAdmin ? t('user_management_convert_to_user_description') : t('user_management_convert_to_admin_description')}
                            submitButtonTitle={errorMessage ? t('ok') : null}
                            cancelButtonTitle={errorMessage ? t('close') : t('cancel')}
                            buttonVariant={'text'}
                            submitButtonColor={'primary'}
                            onSubmit={errorMessage ? null : this.onSubmitConvertRoleDialog.bind(this)}
                            onCancel={this.resetLoadingState.bind(this)}
                        />}
                        {profileToEdit && <ChangeNicknameDialog
                            context={this.context}
                            rootOrg={this.context.getSelectedRootOrg()}
                            userId={profileToEdit.id}
                            open={true}
                            onCancel={() => this.setState({ profileToEdit: null })}
                            onSuccess={async () => {
                                await this.reloadGroup()
                                await this.reloadPublicUser(profileToEdit.id)
                                this.setState({ profileToEdit: null })
                            }}
                        />}
                        {createNewUserDialogVisible && <CreateNewUserDialog
                            api={this.context.api}
                            open={true}
                            usersGroup={usersGroup}
                            adminsGroup={adminsGroup}
                            onSuccess={async (userId, isAdmin) => {
                                if (isAdmin) {
                                    const group = adminsGroup
                                    group.users.push(userId)
                                    this.setState({ adminsGroup: group }, () => {
                                        setTimeout(async () => {
                                            await this.loadPublicUsers(group.users)
                                            this.setState({ createNewUserDialogVisible: false })
                                        }, 200)
                                    })
                                } else {
                                    const group = usersGroup
                                    group.users.push(userId)
                                    this.setState({ usersGroup: group }, () => {
                                        setTimeout(async () => {
                                            await this.loadPublicUsers(group.users)
                                            this.setState({ createNewUserDialogVisible: false })
                                        }, 200)
                                    })
                                }
                            }}
                            onCancel={() => { this.setState({ createNewUserDialogVisible: false }) }}
                        />}
                        {inviteUserDialogVisible && <InviteUserDialog
                            api={this.context.api}
                            usersGroup={usersGroup}
                            adminsGroup={adminsGroup}
                            open={true}
                            onSuccess={async () => {
                                await this.loadOfferedInvitations()
                                this.setState({ inviteUserDialogVisible: false })
                            }}
                            onCancel={() => { this.setState({ inviteUserDialogVisible: false }) }}
                        />}
                    </ThemeProvider>
                </div>
            </div>
        )
    }

}

UserList.contextType = PageContext

UserList.propTypes = {
    onRef: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
}

const styles = theme => ({
    fabButton: {
        position: 'absolute',
        right: theme.spacing(2),
        bottom: theme.spacing(2)
    },
    listTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(3),
        color: theme.palette.grey['900'],
        fontWeight: theme.typography.fontWeightMedium
    },
})


export default withTranslation()(withStyles(styles)(UserList))