import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { withDarkTheme } from '../../../theme'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import {  APP_MESSAGE_STA2_WEIGHT_LOG_ORDER_POINT } from '../Constants'
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, LinearProgress } from '@material-ui/core'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

class ChangeOrderWeightDialog extends React.PureComponent {

    constructor(props) {
        super(props)
                                                                                                                                                                                                   
        this.state = {
            loading: false,
            ready: false,
            orderPoint: '',
            errorMessage: null
        }

        this.setAsyncState = async (state) => {
            return new Promise((resolve) => {
                this.setState(state, resolve)
            })
        }

        this.handleResponseError = (error) => {
            const { t } = this.props
            this.setState({
                errorMessage: t(mapErrorMessage(error)),
                loading: false
            })
            console.error(t(mapErrorMessage(error)))
        }
        
    }

    async componentDidMount() {
        const { device } = this.props
        try {
            const orderPoint = await this.context.api.deviceService.getSetting(device.getId(), APP_MESSAGE_STA2_WEIGHT_LOG_ORDER_POINT)
            this.setState({orderPoint, ready: true})
        } catch (error) {
            this.handleResponseError(error)
        }
    }


    handleSubmit = () => {
        const { device, onClose } = this.props
        const { orderPoint } = this.state
        this.setState({loading: true}, async() => {
            try {
                await this.context.api.deviceService.updateSettings(device.getId(), { [APP_MESSAGE_STA2_WEIGHT_LOG_ORDER_POINT]: Number(orderPoint) })
                onClose()
            } catch (error) {
                this.handleResponseError(error)
            }
        })
    }

    render() {
        const { loading, ready, errorMessage, orderPoint } = this.state
        const { t, classes } = this.props

        return (
            <div>
                <Dialog open={this.props.open} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    { (loading || !ready) && <LinearProgress className={classes.progress} /> }
                    <DialogTitle id="form-dialog-title">
                        <div>{t('change_order_weight')}</div>
                    </DialogTitle>
                    <DialogContent>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log("form error:", errors)}>
                        <div className={classes.form}>
                            <TextValidator
                                className={classes.formControl}
                                value={orderPoint}
                                disabled={loading||!ready}
                                onChange={(event) => this.setState({orderPoint: event.target.value, error: null}) }
                                margin="dense"
                                label={ t('order_point') }
                                validators={['required', 'isNumber']}
                                errorMessages={[t('this_field_is_required'), t('this_field_must_be_a_number')]}/>
                        </div>
                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onClose}>
                                {t('cancel')}
                            </Button>
                            <Button disabled={loading||!ready} type="submit" color="primary">
                                {t('save')}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                    </DialogContent>
                </Dialog>
                { errorMessage && <AlertDialog 
                    open={true} 
                    title={t('error')}
                    message={errorMessage}
                    buttonVariant={'text'}
                    onSubmit={ () => this.setState({errorMessage: null}) } 
                /> }
            </div>
        )
    }
}

ChangeOrderWeightDialog.contextType = PageContext

ChangeOrderWeightDialog.propTypes = {
    open: PropTypes.bool,
    device: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired
}

const styles = theme => ({
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
    form: {
        marginBottom: theme.spacing(3)
    },
    formControl: {
    },
    messageText: {
        marginLeft: 24,
        marginTop: 16
    }
})


export default withTranslation()(withDarkTheme(withStyles(styles)(ChangeOrderWeightDialog)))