export const UPDATE_META_TYPE = {
  TYPE_DEVICE: 'device',
  TYPE_SERVICE: 'service',
  TYPE_TASK: 'task',
  TYPE_USER: 'user',
};

export default class UpdateMeta {
  /**
   * @param {Object<String,Uid,Array>}
   */
  constructor({ type, id, ts }) {
    this.type = type;
    this.id = id;
    this.ts = ts;
  }

  /**
   * Get type
   * @returns {UPDATE_META_TYPE}
   */
  getType() {
    return this.type;
  }

  /**
   * Get id
   * @returns {Uid}
   */
  getId() {
    return this.id;
  }

  /**
   * Get date
   * @returns {Array<Object>}
   */
  getDate() {
    return new Date(this.ts);
  }
}
