export default class UserMetaRole {
  /**
   * @param {Object<uid,uid,uid>}
   */
  constructor({ userId, privateGroup, privateRole }) {
    this.userId = userId;
    this.privateGroup = privateGroup;
    this.privateRole = privateRole;
  }

  /**
   * Get user id
   * @returns {Uid}
   */
  getUserId() {
    return this.userId;
  }

  /**
   * Get private group uid
   * @returns {Uid}
   */
  getPrivateGroup() {
    return this.privateGroup;
  }

  /**
   * Get private role uid
   * @returns {Uid}
   */
  getPrivateRole() {
    return this.privateRole;
  }
}
