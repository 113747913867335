import React, { useState, useEffect, useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import DeleteIcon from '@material-ui/icons/Delete'
import Loading from '../../../Libs/Pages/Loading'
import { withTranslation } from 'react-i18next'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

const MessageNoteModal = ({ open, onClose, message, replacementId, messageNote, t }) => {
    const context = useContext(ApiContext)
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [note, setNote] = useState(messageNote ? messageNote : null)
    const formRef = useRef()


    const addMessageNote = async () => {
        try {
            setLoading(true)
            const params = { [replacementId]: note }
            await context.appMessageService.updateMessage(message.getId(), params)
            setLoading(false)
            onClose(note)
        } catch (error) {
            console.log('Add message error... ', error)
        }
    }

    const deleteMessageNote = async () => {
        try {
            setLoading(true)
            const params = { [replacementId]: '' }
            await context.appMessageService.updateMessage(message.getId(), params)
            setLoading(false)
            onClose(true)
        } catch (error) {
            console.log('Delete message error... ', error)
        }
    }

    return (
        <Dialog open={open} PaperProps={{ className: classes.dialogContainer }} onClose={() => void (0)}>
            <DialogTitle className={classes.dialogTitle} variant="h6" disableTypography >
                <Typography variant="h6" style={{ flexGrow: 1 }}>{t('add_note')}</Typography>
                <IconButton size={'small'} onClick={deleteMessageNote} disabled={!messageNote}>
                    <DeleteIcon style={{ color: messageNote ? '#fff' : '#808080' }} />
                </IconButton>
            </DialogTitle>
            <ValidatorForm
                ref={formRef}
                onSubmit={addMessageNote}
                onError={errors => console.log("MessageNoteModal error:", errors)}>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <TextValidator
                        multiline
                        rows={6}
                        value={note}
                        fullWidth
                        onChange={(event) => setNote(event?.target?.value)}
                        disabled={loading}
                        margin="dense"
                        label={t('note')}
                        validators={['required']}
                        errorMessages={[t('this_field_is_required')]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => onClose('')}>
                        {t('cancel')}
                    </Button>
                    <Button disabled={loading} type="submit" color="primary">
                        {t('save')}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        width: '100%',
        maxWidth: 480
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
}))

export default withTranslation()(MessageNoteModal)