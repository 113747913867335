import ls from 'local-storage';

export default class WebStorage {
  constructor() {}

  /**
   * get value
   * @param {String} key
   * @returns {Promise<String>}
   */
  get(key) {
    return Promise.resolve(ls.get(key));
  }

  /**
   * set value
   * @param {String} key
   * @param {String} value
   */
  set(key, value) {
    ls.set(key, value);
    return Promise.resolve();
  }

  /**
   * remove value
   * @returns {Promise}
   */
  remove(key) {
    ls.remove(key);
    return Promise.resolve();
  }
}
