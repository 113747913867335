import React, { Fragment, useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, Divider, DialogContent, DialogActions, Button } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { useTranslation } from 'react-i18next'

const ReplacementChecklistItemDialog = ({ item, open, onSuccess, onCancel }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const form = useRef(null)
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const handleSubmit = () => {
        console.log('handleSubmit....')
        onSuccess(title, content)
    }

    useEffect(() => {
        setTitle(item ? item.title : '')
        setContent(item ? item.content : '')
    }, [item])

    return (
        <div>
        <Dialog open={open} onClose={() => onCancel()} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{item ? t('edit_checklist_item') : t('add_checklist_item')}</DialogTitle>
            <Divider/>
            <DialogContent className={classes.dialogContent}>
                <ValidatorForm
                    ref={form}
                    onSubmit={() => handleSubmit()}
                    onError={errors => console.log("form error:", errors)}>
                    
                    <TextValidator
                        className={classes.field}
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                        margin="dense"
                        multiline={false}
                        label={t('title')}
                        validators={['required', 'trim']}
                        errorMessages={[t('this_field_is_required'), t('this_field_is_required')]}
                        fullWidth />
                    <TextValidator
                        className={classes.field}
                        value={content}
                        onChange={(event) => setContent(event.target.value)}
                        margin="dense"
                        multiline={true}
                        rows={8}
                        label={t('content')}
                        validators={['required', 'trim']}
                        errorMessages={[t('this_field_is_required'), t('this_field_is_required')]}
                        fullWidth />

                </ValidatorForm>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button onClick={() => onCancel()}>
                    {t('cancel')}
                </Button>
                <Button onClick={() => form.current.submit()} color="primary">
                    {item ? t('edit') : t('add')}
                </Button>
            </DialogActions>
        </Dialog>
    </div>
    )
}


const useStyles = makeStyles(theme => ({
    dialogContent: {
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(4)
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
}))

export default ReplacementChecklistItemDialog