import React, { useState } from "react"
import PropTypes from "prop-types"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import { useTranslation } from 'react-i18next'
import { makeStyles } from "@material-ui/styles"
import MomentUtils from "@date-io/moment"
import classNames from "classnames"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, LinearProgress } from '@material-ui/core'

// see origin: https://github.com/mui-org/material-ui-pickers/issues/364#issuecomment-496021379

const utils = new MomentUtils()

const useStyles = makeStyles((theme) => ({
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    },
    dialogContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiPickersStaticWrapper-staticWrapperRoot': {
            backgroundColor:'transparent'
        },
        '& .MuiPickersCalendar-transitionContainer': {
            minHeight: 260
        }
    },
    day: {
        margin: 0,
        width: 44,
        borderRadius: 0,
        transition: 'none',
    },
    selectedDay: {
        backgroundColor: theme.palette.primary.light,
        color: '#fff'
    },
    disabledDay: {
        pointerEvents: 'none',
        opacity: 0.3
    },
    beginCap: {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
    },
    endCap: {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
    },
}), { name: 'DateRangePicker' })



function DateRangeWrapper({ title, value, open, onClose, onCloseLabel, onSubmit, onSubmitLabel, errorMessage, loading, description, footerContent, ...props }) {
    const { t } = useTranslation()

    const [begin, setBegin] = useState(value[0])
    const [end, setEnd] = useState(value[1])
    const [hover, setHover] = useState(undefined)
    const classes = useStyles()

    const min = Math.min(begin, end || hover)
    const max = Math.max(begin, end || hover)

    function renderDay(day, selectedDate, dayInCurrentMonth, dayComponent) {

        return React.cloneElement(dayComponent, {
            onClick: e => {
                e.stopPropagation()
                if (loading) return
                if (!begin) setBegin(day)
                else if (!end) {
                    setEnd(day)
                } else {
                    setBegin(day)
                    setEnd(undefined)
                }
            },
            onMouseEnter: e => setHover(day),
            className: classNames('MuiPickersDay', classes.day, {
                ['MuiPickersDay-hidden']: dayComponent.props.hidden,
                ['MuiPickersDay-current']: dayComponent.props.current,
                ['MuiPickersDay-disabled']: utils.isAfterDay(day, utils.moment()) || dayComponent.props.disabled,
                [classes.disabledDay]: utils.isAfterDay(day, utils.moment()) || dayComponent.props.disabled,
                ['MuiPickersDay-daySelected']: utils.isSameDay(day, min) || utils.isSameDay(day, max) || (day >= min && day <= max),
                [classes.selectedDay]: (day.startOf('day') > begin && day.endOf('day') < end),
                [classes.beginCap]: utils.isSameDay(day, min),
                [classes.endCap]: utils.isSameDay(day, max),
            }),
        })
    }

    return (
            <Dialog open={open} onClose={null} fullWidth maxWidth={'xs'}>
                { loading && <LinearProgress className={classes.progress} /> }
                { title && <DialogTitle id="form-dialog-title">{title}</DialogTitle> }
                <DialogContent>
                    {description && <DialogContentText style={{marginBottom: 20}}>{description}</DialogContentText>}
                    <div className={classes.dialogContainer}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                {...props}
                                color={'secondary'}
                                variant={'static'}
                                value={begin}
                                renderDay={renderDay}
                                onClose={onClose}
                                disableToolbar={true}/>
                        </MuiPickersUtilsProvider>
                        <Typography style={{marginTop: 10}} variant="caption" color={'textSecondary'}>{begin && utils.format(begin, "L") || '?'} - {end && utils.format(end, "L") || '?'}</Typography>
                    </div>
                    {errorMessage && <DialogContentText style={{marginTop: 20}} color="error">{errorMessage}</DialogContentText>}
                </DialogContent>
                <DialogActions style={{marginLeft: 15, marginRight: 15}}>
                    { footerContent }
                    <Button disabled={loading} onClick={onClose}>
                        {onCloseLabel || t('cancel')}
                    </Button>
                    <Button 
                        disabled={loading || !begin || !end} 
                        onClick={() => {
                            onSubmit([begin.startOf('day'), end ? end.endOf('day') : begin.endOf('day')])
                        }} 
                        color="primary">
                        {onSubmitLabel || t('submit')}
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

DateRangeWrapper.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.array,
    onSubmit: PropTypes.func,
    onSubmitLabel: PropTypes.string,
    onClose: PropTypes.func,
    onCloseLabel: PropTypes.string,
    description: PropTypes.string,
    errorMessage: PropTypes.string,
    footerContent: PropTypes.any,
    loading: PropTypes.bool,
}

export default DateRangeWrapper