import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { REPLACEMENT_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import { Grid } from '@material-ui/core'
import HeaderBar from '../Components/HeaderBar'
import RecipientTabView from '../Components/Recipient/RecipientTabView'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import { isNotFoundError } from '../../../m2m-cloud-api/Api/Helper'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import ReplacementsModal from '../Components/Recipient/ReplacementsModal'
import UserButton from '../../../Libs/Components/UserButton'
import HeaderBreadcrumb from '../Components/HeaderBreadcrumb'
import FontSize from '../Components/FontSize'
import { APP_MODE } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { MAX_COMNTENT_WIDTH } from '../App'
import Loading from '../../../Libs/Pages/Loading'


const INVISIBLE_REPLACEMENTS = [REPLACEMENT_TYPE.REPORT, REPLACEMENT_TYPE.NOTE]
class RecipientScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            triggerArticle: null,
            triggerArticleCallback: null,
            loading: false
        }
    }

    componentDidMount() {
        const { context } = this.props
        const selectedOrgs = context.getRecipientOrgIds()
        if (!selectedOrgs) {
            console.warn("location org not setted, redirect to select screen..., selectedOrgs: ", selectedOrgs);
            this.gotoSelectScreen()
        } else {
            if (context.appMode !== APP_MODE.RECIPIENT) {
                context.setAppMode(APP_MODE.RECIPIENT)
            }
        }
    }

    onTriggerArticleEvent = (article, callback) => {
        const { t } = this.props
        const { replacementIds } = article
        const visibleReplacementIds = replacementIds && replacementIds.filter(replacement => !replacement?.desc?.serviceProvider && INVISIBLE_REPLACEMENTS.indexOf(replacement.getType()) === -1)

        if (visibleReplacementIds && visibleReplacementIds.length > 0) {
            this.setState({ triggerArticle: article, triggerArticleCallback: callback })
        } else {
            this.triggerArticleEvent(article).then(() => callback())
        }

    }

    triggerArticleEvent(article, replacementIdValues) {
        const { t } = this.props

        this.setState({ loading: true })

        const { definition, device, replacementIds } = article

        const autoFillReplacements = replacementIds && replacementIds.filter(replacement => replacement?.desc?.serviceProvider || INVISIBLE_REPLACEMENTS.indexOf(replacement.getType()) !== -1)
        if (autoFillReplacements && autoFillReplacements.length > 0) {
            if (!replacementIdValues) {
                replacementIdValues = {}
            }
            autoFillReplacements.map(reportReplacement => {
                replacementIdValues[reportReplacement.getId()] = "" // add empty value to detect replacement in message objects
            })
        }

        const imageReplacements = replacementIds && replacementIds.filter(replacement => replacement.getType() === REPLACEMENT_TYPE.IMAGE)
        if (imageReplacements && imageReplacements.length > 0) {
            //console.log('values:', replacementIdValues)
        }

        return this.props.context.triggerArticleEvent(definition, device, replacementIdValues)
            .then(() => true)
            .catch(error => {
                console.warn('emitEvent - error', error)
                let message = mapErrorMessage(error)
                if (isNotFoundError(error)) {
                    message = t('error_device_not_found')
                } else if (message === 'open_or_processing_order_already_exists') {
                    message = t(message)
                }
                this.setState({ errorMessage: message })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    gotoSelectScreen() {
        this.props.history.push('/messaging')
    }

    render() {
        const { classes, t } = this.props
        const { triggerArticle, triggerArticleCallback, loading } = this.state
        const context = this.props.context

        const recipient = this.props.context.getSelectedRecipientObjectOrg()
        const userFontSize = context.userFontSize

        return (
            <div className={classes.container}>
                {loading && <Loading />}
                <HeaderBar
                    leftContent={null}
                    centerContent={
                        <HeaderBreadcrumb appMode={APP_MODE.RECIPIENT} />
                    }
                    rightContent={<div style={{ display: 'flex' }}>
                        <FontSize />
                        <UserButton
                            userService={this.props.context.userService}
                            authzService={this.props.context.authzService}
                            pendingInvitations={this.props.context.pendingInvitations}
                            onInvitationAccepted={() => this.props.context.loadPendingInvitations()}
                            user={this.props.context.userService.getActiveUser()}
                            onLogout={() => this.props.context.userService.logoutUser()} />
                    </div>} />
                <Grid container className={classes.contentContainer}>
                    <Grid container item xs={12} className={classes.contentContainerInner}>
                        <RecipientTabView org={recipient} triggerArticleEvent={this.onTriggerArticleEvent} />
                    </Grid>
                </Grid>
                {triggerArticle && <ReplacementsModal
                    visible={true}
                    article={triggerArticle}
                    loading={loading}
                    onSuccess={(replacementIdValues) => {
                        const triggerArticleEvent = () => {
                            this.triggerArticleEvent(triggerArticle, replacementIdValues)
                                .then((result) => console.log('triggerArticleEvent, result: ', result))
                                .finally(() => {
                                    triggerArticleCallback()
                                    this.setState({ triggerArticleCallback: null, triggerArticle: null })
                                })
                        }
                        if (replacementIdValues === null) {
                            this.setState({ triggerArticle: null }, () => triggerArticleEvent())
                        } else {
                            triggerArticleEvent()
                        }

                    }}
                    onClose={() => {
                        triggerArticleCallback && triggerArticleCallback()
                        this.setState({ triggerArticle: null })
                    }} />}

                {this.renderErrorMessage()}
            </div>
        )
    }

    renderErrorMessage() {
        const { errorMessage } = this.state
        const { t } = this.props
        if (errorMessage) {
            return (
                <AlertDialog
                    open={errorMessage ? true : false}
                    title={t('error')}
                    message={errorMessage}
                    onSubmit={() => this.setState({ errorMessage: null })} />
            )
        } else {
            return (null)
        }
    }


}


const styles = theme => ({
    container: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#000',
    },
    contentContainer: {
        flex: 1,
        backgroundColor: '#fff'
    },
    contentContainerInner: {
        maxWidth: MAX_COMNTENT_WIDTH,
        position: 'relative',
        margin: '0 auto'
    }
})

export default withTranslation()(withStyles(styles)(RecipientScreen))