import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles, ThemeProvider } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../Context/PageProvider'
import { darkTheme } from '../../../theme'
import { Typography, LinearProgress } from '@material-ui/core'
import DeviceListView from './DeviceListView'
import DeviceDialog from '../../../Libs/Components/Device/DeviceDialog'
import ClaimDeviceDialog from '../../../Libs/Components/Device/ClaimDeviceDialog'
import UnClaimDeviceDialog from '../../../Libs/Components/Device/UnClaimDeviceDialog'
import DeviceEventDataDialog from '../../../Libs/Components/Device/DeviceEventDataDialog'
import DeviceTriggerEventDialog from '../../../Libs/Components/Device/DeviceTriggerEventDialog'
import DeviceSendCommandDialog from '../../../Libs/Components/Device/DeviceSendCommandDialog'
import DeviceAssignDialog from '../../../Libs/Components/Device/DeviceAssignDialog'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import PropListDialog from '../../../Libs/Components/PropListDialog'
import { ORG_TAG } from '../../../m2m-cloud-api/Api/OrgService/OrgService'
import { CODE_DEVICE_DRIVER_NAME } from '../../../m2m-cloud-api/Api/Drivers/CodeDriver'

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    listTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(3),
        color: theme.palette.grey['900'],
        fontWeight: theme.typography.fontWeightMedium
    },
    fabButton: {
        position: 'absolute',
        right:  theme.spacing(2),
        bottom:  theme.spacing(2)
    }
})

class Devices extends React.PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            errorMessage: null,
            items: null,
            deviceDialogVisible: false,
            unClaimDialogVisible: false,
            deviceCommandOrEventDialogVisible: false,
            selectedDevice: null,
            deviceEventDataDialogVisible: false,
            orgToAssignDialogOpen: false,
            deviceSettingsDialogVisible: false,
            deviceToAssign: null
        }
    }

    componentDidMount() {
        this.queryAllDevices()
    }

    queryAllDevices() {
        this.setState({ deviceDialogVisible: false, loading: this.state.items ? false : true })
        this.context.api.deviceService.searchDevice(this.props.org.getId())
            .then( async (items) => {

                const filteredItems = items.filter( device => device.getDriver() !== CODE_DEVICE_DRIVER_NAME)
                const itemOrgIds = []
                const handleDeviceOrgId = (orgId) => {
                    if (!orgId) return
                    if (itemOrgIds.indexOf(orgId) === -1) {
                        itemOrgIds.push(orgId)
                    }
                }
                filteredItems.map( device => {
                    handleDeviceOrgId( device.getOwnerOrg() )
                    handleDeviceOrgId( device.getAssignedOrg() )
                })
                let itemOrgs = await this.context.fetchOrgs(itemOrgIds)

                /*const usedItemIds = []
                const definitions = await this.context.api.appMessageService.getDefinitionsByMultipleDeviceId(items.map( device => device.getId()))
                definitions.map( definition => usedItemIds.push( definition.getDeviceId() ) )
                console.warn('usedItemIds', usedItemIds)*/

                this.setState({ items: filteredItems, itemOrgs, /*usedItemIds,*/ loading: false })
            })
            .catch(error => {
                console.log('searchDevice, error', error)
                this.setState({ items: null, loading: false, errorMessage: mapErrorMessage(error) })
            })
    }

    onAssignDevice = (device) => {
        this.setState({ orgToAssignDialogOpen: true, deviceToAssign: device })
    }

    onUnassignDevice = (device) => {
        const promisses = [this.context.api.deviceService.unassignDevice(device.getId())]
        this.context.api.deviceService.updateDevice(device.getId(), promisses)
            .then(result => {
                this.queryAllDevices()
            })
            .catch(error => {
                console.log('onUnassignDevice, error', error)
                this.setState({errorMessage: mapErrorMessage(error)})
            })
    }

    activateDevice = (device) => {
        const promisses = [this.context.api.deviceService.activateDevice(device.getId())]
        this.context.api.deviceService.updateDevice(device.getId(), promisses)
            .then(result => {
                this.queryAllDevices()
            })
            .catch(error => {
                console.log('activateDevice, error', error)
                this.setState({errorMessage: mapErrorMessage(error)})
            })
    }

    deactivateDevice = (device) => {
        const promisses = [this.context.api.deviceService.deactivateDevice(device.getId())]
        this.context.api.deviceService.updateDevice(device.getId(), promisses)
            .then(result => {
                this.queryAllDevices()
            })
            .catch(error => {
                console.log('deactivateDevice, error', error)
                this.setState({errorMessage: mapErrorMessage(error)})
            })
    }

    updateSettings = (device, newSettings) => {

        this.setState({ loading: true })

        const settings = device.getSettings() || {}
        
        Object.keys(settings).map( key => {
            settings[key] = null
        })

        Object.keys(newSettings).map( key => {
            settings[key] = newSettings[key]
        })

        const promisses = [this.context.api.deviceService.updateSettings(device.getId(), settings)]
        this.context.api.deviceService.updateDevice(device.getId(), promisses)
            .then(result => {
                this.setState({deviceSettingsDialogVisible:false, selectedDevice: null} )
                this.queryAllDevices()
            })
            .catch(error => {
                console.log('updateSettings, error', error)
                this.setState({errorMessage: mapErrorMessage(error)})
            })
    }

    onResetClick = async (device) => {
        const resetCommandResult = await this.context.api.deviceService.sendSta2DeviceCommand(device.getId(), 'RESET')
    }

    onShowEventData = (device) => {
        this.setState({ deviceEventDataDialogVisible: true, selectedDevice: device })
    }

    render() {
        const { errorMessage, loading, items, itemOrgs, /*usedItemIds,*/ deviceDialogVisible, unClaimDialogVisible, selectedDevice, deviceCommandOrEventDialogVisible, deviceEventDataDialogVisible, orgToAssignDialogOpen, deviceSettingsDialogVisible, deviceToAssign } = this.state
        const { t, org, searchTerm, classes } = this.props
        
        const filteredItems = searchTerm && searchTerm.trim() !== "" ? items.filter( device => device.getPhysicalId().indexOf(searchTerm) !== -1 || device.getId().indexOf(searchTerm) !== -1 ) : items

        const hasItems = items && items.length > 0

        return (
            <Fragment>
                <Typography variant="h5" className={classes.listTitle}>{t('devices')}</Typography>
                { !items && <LinearProgress /> }
                { !hasItems && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">{t('no_devices_available')}</Typography>}
                { hasItems && filteredItems.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">{t('no_match_found')}</Typography>}
                { hasItems && <DeviceListView
                    api={this.context.api}
                    items={filteredItems}
                    itemOrgs={itemOrgs}
                    org={org}
                    onAdd={ () => this.setState({ deviceDialogVisible: true }) }
                    onReset={this.onResetClick}
                    onShowEventData={this.onShowEventData}
                    /*onAssign={this.onAssignDevice.bind(this)}
                    onUnassign={this.onUnassignDevice.bind(this)}
                    onActivate={this.activateDevice.bind(this)}
                    onDeactivateDevice={this.deactivateDevice.bind(this)}*/
                    activeDeviceId={null}
                    /*onUnclaim={(device) => this.setState({ unClaimDialogVisible: true, selectedDevice: device })}*/ 
                />}
                <ThemeProvider theme={darkTheme}>

                
                {unClaimDialogVisible && <UnClaimDeviceDialog
                    api={this.context.api}
                    open={true}
                    devices={[selectedDevice]}
                    onSuccess={(deviceIds) => this.setState({items: items.filter( item => !deviceIds || deviceIds.indexOf(item.getId()) === -1 )})}
                    onClose={() => this.setState({ unClaimDialogVisible: false, selectedDevice: null })} />}
                {deviceDialogVisible && <ClaimDeviceDialog
                    api={this.context.api}
                    open={true}
                    org={org}
                    onSuccess={(devices) => this.setState({items: [...items, ...devices]})}
                    onClose={() => this.setState({ deviceDialogVisible: false })} />}
                {selectedDevice && deviceEventDataDialogVisible && <DeviceEventDataDialog
                    api={this.context.api}
                    open={true}
                    device={selectedDevice}
                    onClose={() => this.setState({ deviceEventDataDialogVisible: false, selectedDevice: null })} />}
                { deviceCommandOrEventDialogVisible && selectedDevice.getDriver() === CODE_DEVICE_DRIVER_NAME && <DeviceTriggerEventDialog
                    api={this.context.api}
                    open={deviceCommandOrEventDialogVisible}
                    org={org}
                    device={selectedDevice}
                    onCancel={() => this.setState({ deviceCommandOrEventDialogVisible: false })}
                    onSuccess={() => this.setState({ deviceCommandOrEventDialogVisible: false })}
                />}
                { deviceCommandOrEventDialogVisible && selectedDevice.getDriver() !== CODE_DEVICE_DRIVER_NAME && <DeviceSendCommandDialog
                    api={this.context.api}
                    open={deviceCommandOrEventDialogVisible}
                    org={org}
                    device={selectedDevice}
                    onCancel={() => this.setState({ deviceCommandOrEventDialogVisible: false })}
                    onSuccess={() => this.setState({ deviceCommandOrEventDialogVisible: false })}
                />}
                { orgToAssignDialogOpen && <DeviceAssignDialog
                    api={this.context.api}
                    searchOrgProps={{tags: [ORG_TAG.RECIPIENT_LOCATION]}}
                    open={orgToAssignDialogOpen}
                    device={deviceToAssign}
                    onSuccess={() =>
                        this.setState({ orgToAssignDialogOpen: false, deviceToAssign: null }, () => {
                            this.queryAllDevices()
                        })
                    }
                    onCancel={() => this.setState({ orgToAssignDialogOpen: false, deviceToAssign: null })}
                />}
                { deviceSettingsDialogVisible && <PropListDialog
                    open={true}
                    loading={loading}
                    title={t('device_settings')}
                    params={selectedDevice.getSettings()}
                    onSubmit={(params) => this.updateSettings(selectedDevice, params)}
                    onCancel={() => this.setState({deviceSettingsDialogVisible:false, selectedDevice: null} )}
                />}
                {errorMessage && (
					<AlertDialog
						open={errorMessage ? true : false}
						title={t('error')}
						message={errorMessage}
						submitButtonTitle={t('ok')}
						onSubmit={() => this.setState({ errorMessage: null })}
					/>
				)}
                </ThemeProvider>
            </Fragment>
        )
    }

}

Devices.contextType = PageContext

Devices.propTypes = {
    org: PropTypes.any.isRequired,
    searchTerm: PropTypes.string
}

export default withTranslation()(withStyles(styles)(Devices))