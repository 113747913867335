import { Route } from 'react-router-dom'
import React, { useContext } from 'react'
import { ApiContext } from '../../m2m-cloud-api/MessageLog/ApiContext'

const DefaultRoute = ({ component: Component, ...rest }) => {
    const context = useContext(ApiContext)
    return (
        <Route {...rest} render={(props) => (<Component {...rest} {...props} context={context} />)} />
    )
}

export default DefaultRoute