import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { PageContext } from '../Context/PageProvider'
import { APP_MESSAGE_CREATOR, APP_MESSAGE_CREATOR_ADMIN, APP_MESSAGE_STA2_LOGMODE, APP_MESSAGE_STA2_WEIGHT_LOG_ORDER_POINT } from '../Constants'
import { getPath } from '../App'
import { withTranslation } from 'react-i18next'
import { ThemeProvider } from '@material-ui/styles'
import { lightTheme, DefaultStyles } from '../../../theme'
import { ORG_TAG } from '../../../m2m-cloud-api'
import { DRIVER_TYPE } from '../../../m2m-cloud-api/Api/DeviceService/Models/Device'
import { isResourceInUseError } from '../../../m2m-cloud-api/Api/Helper'
import { Grid, Toolbar, IconButton, Typography, List, ListItem, ListItemIcon, ListItemText, Fab, Button, CircularProgress } from '@material-ui/core'
import Searchbox from '../../../Libs/Components/Searchbox'
import ReplacementsList from '../Components/ReplacementsList'
import RecipientItemsList from '../Components/RecipientItemsList'
import SupplierItemsList from '../Components/SupplierItemsList'
import UserAccessManagementDialog from '../Components/UserAccessManagementDialog'
import GroupView from '../Components/GroupView'
import GroupViewList, { SPECIAL_ITEM_ID } from '../Components/GroupViewList'
import UserList from '../Components/UserList'
import Devices from '../Components/Devices'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/GetApp'
import UploadIcon from '@material-ui/icons/Publish'
import UserButton from '../../../Libs/Components/UserButton'
import ActionDialog from '../Components/ActionDialog'
import ActionImportDialog from '../Components/ActionImportDialog'
import TriggerImportDialog from '../Components/TriggerImportDialog'
import TriggerDialog from '../Components/TriggerDialog'
import ReplacementDialog from '../Components/ReplacementDialog'
import RootOrgToolbar from '../Components/RootOrgToolbar'
import ReactSVG from 'react-svg'


export const MENU_TYPE = {
    GROUPS: 0,
    USER_MANAGEMENT: 3,
    DEVICES: 4
}

const DIALOG_TYPE = {
    NONE: 0,
    ADD_TRIGGER: 1, DELETE_TRIGGER: 2, DELETE_TRIGGERS: 3,
    ADD_ACTION: 4, EDIT_ACTION: 5, DELETE_ACTION: 6, DELETE_ACTIONS: 7,
    ADD_REPLACEMENT: 8, EDIT_REPLACEMENT: 9, DELETE_REPLACEMENT: 10, DELETE_REPLACEMENTS: 11,
    RESET_TRIGGER: 12
}

class MainScreen extends React.Component {
    constructor(props) {
        super(props)

        this.state = {

            ready: null,

            waitigForReload: false,

            dialogType: DIALOG_TYPE.NONE,

            rootEntityId: props.match.params.id,
            selectedGroupOrgId: null,
            selectedGroupItemOrgId: null,

            activeMenuType: MENU_TYPE.GROUPS,
            actionImportDialogVisible: false,
            triggerImportDialogVisible: false,

            triggerGroupItemEntities: null,
            selectedTriggerGroupItemIds: [],
            triggerToDelete: null,
            triggerToReset: null,

            actionGroupItemEntities: null,
            selectedActionGroupItemIds: [],
            actionToEditOrDelete: null,

            replacementEntities: null,
            selectedReplacementItemIds: [],
            replacementToEditOrDelete: null,

            selectedUserListIds: [],

            searchTerm: '',

            errorMessage: null
        }


        this.setAsyncState = async (state) => {
            return new Promise((resolve) => {
                this.setState(state, resolve)
            })
        }

        this.handleResponseError = (error) => {
            const { t } = this.props
            this.handleErrorMessage(t(mapErrorMessage(error)))
        }

        this.handleErrorMessage = (errorMessage) => {
            this.setState({
                errorMessage: errorMessage,
                loading: false
            })
            console.error(errorMessage)
        }

        this.resetLoadingState = () => {
            this.setState({
                errorMessage: null,
                dialogType: null,
                actionToEditOrDelete: null,
                loading: false
            })
        }

    }

    async componentDidMount() {
        const { match, history } = this.props
        const currentRootOrgId = match.params.id
        const rootOrgs = this.context.getRootOrgs()
        const rootOrg = rootOrgs && rootOrgs.find(org => org.getId() === currentRootOrgId)
        if (rootOrg) {
            let ready = null
            await this.context.setSelectedRootOrg(rootOrg)
            const canManageRootOrg = await this.context.canManageRootOrg()
            if (canManageRootOrg) {
                ready = true
            } else {
                ready = false
            }
            this.setState({ ready })
        } else {
            console.warn('root org: ' + currentRootOrgId + ', not found. Redirect to roots select root screen..')
            history.push(`${getPath()}`)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedGroupItemOrgId !== this.state.selectedGroupItemOrgId) {
            this.loadTriggerGroupItems()
            this.loadActionGroupItems()
        }
        if (prevState.selectedGroupOrgId !== this.state.selectedGroupOrgId) {
            this.loadReplacementEntities()
        }
    }

    /**
     * load tigger item entities from api
     */
    async loadTriggerGroupItems(resetEntitiesBeforeLoad = true) {
        const { selectedGroupItemOrgId } = this.state
        resetEntitiesBeforeLoad && await this.setAsyncState({ triggerGroupItemEntities: null })
        const selectedGroupItemOrg = this.context.childrenOrgs.find(org => selectedGroupItemOrgId === org.getId())
        const isSelectedGroupItemRecipient = selectedGroupItemOrg && selectedGroupItemOrg.hasTags([ORG_TAG.RECIPIENT_LOCATION])
        if (isSelectedGroupItemRecipient) {
            try {
                const triggerGroupItemEntities = await this.context.loadTriggerEntities(selectedGroupItemOrgId)
                if (selectedGroupItemOrgId !== this.state.selectedGroupItemOrgId) {
                    console.log('[loadTriggerGroupItems] selectedGroupItemOrgId has changed during loading.', selectedGroupItemOrgId, '=>', this.state.selectedGroupItemOrgId)
                    return
                }
                await this.setAsyncState({ triggerGroupItemEntities })
            } catch (error) {
                await this.setAsyncState({ triggerGroupItemEntities: null })
                this.handleResponseError(error)
            }
        }
    }

    /**
     * load action item entities from api
     */
    async loadActionGroupItems() {
        const { selectedGroupItemOrgId } = this.state
        await this.setAsyncState({ actionGroupItemEntities: null })
        const selectedGroupItemOrg = this.context.childrenOrgs.find(org => selectedGroupItemOrgId === org.getId())
        const isSelectedGroupItemSupplier = selectedGroupItemOrg && selectedGroupItemOrg.hasTags([ORG_TAG.SUPPLIER_TARGET])
        if (isSelectedGroupItemSupplier) {
            try {
                const actionGroupItemEntities = await this.context.loadActionEntities(selectedGroupItemOrgId)
                if (selectedGroupItemOrgId !== this.state.selectedGroupItemOrgId) {
                    console.log('[loadActionGroupItems] selectedGroupItemOrgId has changed during loading.', selectedGroupItemOrgId, '=>', this.state.selectedGroupItemOrgId)
                    return
                }
                await this.setAsyncState({ actionGroupItemEntities })
            } catch (error) {
                await this.setAsyncState({ actionGroupItemEntities: null })
                this.handleResponseError(error)
            }
        }
    }

    /**
     * load action item entities from api
     */
    async loadReplacementEntities(replacementEntitiesBefore = true) {
        const { selectedGroupOrgId } = this.state
        replacementEntitiesBefore && await this.setAsyncState({ replacementEntities: null })
        try {
            const replacementEntities = await this.context.loadReplacementEntities(selectedGroupOrgId)
            await this.setAsyncState({ replacementEntities })
        } catch (error) {
            await this.setAsyncState({ replacementEntities: null })
            this.handleResponseError(error)
        }
    }

    renderAccessDenied() {
        const { t, classes, history } = this.props
        const { waitigForReload } = this.state
        const rootOrgs = this.context.getRootOrgs()
        return (
            <div className={classes.accessDeniedContainer}>
                <div className={classes.accessDeniedContainerInner}>
                    {!waitigForReload && <Fragment>
                        <Typography variant="h6" color={'inherit'} className={classes.accessDeniedText}>
                            {t('access_denied')}
                        </Typography>
                        {rootOrgs && rootOrgs.length > 1 && <Button color="primary" style={{ marginTop: 30 }} onClick={() => history.push(`${getPath()}`)}>{t('back_to_the_overview')}</Button>}
                    </Fragment>}
                    {waitigForReload && <CircularProgress />}
                </div>
                <div className={classes.userButtonContainer}>
                    <UserButton
                        userService={this.context.api.userService}
                        authzService={this.context.api.authzService}
                        pendingInvitations={this.context.pendingInvitations}
                        user={this.context.api.userService.getActiveUser()}
                        onInvitationAccepted={() => {
                            this.setState({ waitigForReload: true }, () => {
                                setTimeout(() => {
                                    document.location.reload()
                                }, 4000)
                            })
                        }}
                        onLogout={() => this.context.api.userService.logoutUser()} />
                </div>
            </div>
        )
    }

    render() {
        if (this.state.ready === null) return (null)
        if (this.state.ready === false) return this.renderAccessDenied()

        const { t, classes, history } = this.props
        const { rootEntityId, activeMenuType, selectedGroupId, selectedTriggerGroupItemIds, selectedActionGroupItemIds, selectedReplacementItemIds, actionGroupItemEntities, selectedUserListIds, replacementEntities, selectedGroupOrgId, selectedGroupItemOrgId } = this.state
        const countRootOrgs = this.context.getRootOrgs().length
        const rootEntity = this.context.getRootOrgs().find(entity => entity.getId() === rootEntityId)
        const isSelectedGroupItemReplacements = selectedGroupItemOrgId === SPECIAL_ITEM_ID.REPLACEMENTS
        const selectedGroupItemOrg = !isSelectedGroupItemReplacements && this.context.childrenOrgs.find(org => selectedGroupItemOrgId === org.getId())
        const isSelectedGroupItemRecipient = selectedGroupItemOrg && activeMenuType === MENU_TYPE.GROUPS && selectedGroupItemOrg.hasTags([ORG_TAG.RECIPIENT_LOCATION])
        const isSelectedGroupItemSupplier = selectedGroupItemOrg && activeMenuType === MENU_TYPE.GROUPS && selectedGroupItemOrg.hasTags([ORG_TAG.SUPPLIER_TARGET])

        const hasCenterContent = activeMenuType === MENU_TYPE.GROUPS
        const hasRightContent = activeMenuType === MENU_TYPE.REPLACEMENTS || (activeMenuType === MENU_TYPE.GROUPS && selectedGroupItemOrgId) || activeMenuType === MENU_TYPE.USER_MANAGEMENT || activeMenuType === MENU_TYPE.DEVICES

        return (
            <Grid container className={classes.root}>
                <Grid item className={classes.leftColumn}>
                    <RootOrgToolbar countRootOrgs={countRootOrgs} rootEntity={rootEntity} groupId={selectedGroupId} history={history} />
                    <Typography variant="subtitle1" color={'inherit'} className={classes.rootEntityTitle}>
                        {rootEntity && rootEntity.getName()}
                    </Typography>
                    <GroupView selectedOrgId={selectedGroupOrgId} selectionDisabled={activeMenuType !== MENU_TYPE.GROUPS} onSelectOrgId={(orgId) => this.setState({ activeMenuType: MENU_TYPE.GROUPS, selectedGroupOrgId: orgId })} />
                    <List subheader={<Typography className={classes.menuSectionTitle} variant="caption" color={'textSecondary'}>{t('administration')}</Typography>} className={classes.administrationMenu}>
                        <ListItem button classes={{ root: classes.administrationMenuItem }} selected={activeMenuType === MENU_TYPE.USER_MANAGEMENT} onClick={() => this.setState({ activeMenuType: MENU_TYPE.USER_MANAGEMENT })}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <ReactSVG src="/assets/user_management.svg" />
                            </ListItemIcon>
                            <ListItemText primary={t('user_management')} />
                        </ListItem>
                        <ListItem button classes={{ root: classes.administrationMenuItem }} selected={activeMenuType === MENU_TYPE.DEVICES} onClick={() => this.setState({ activeMenuType: MENU_TYPE.DEVICES })}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <ReactSVG src="/assets/device.svg" />
                            </ListItemIcon>
                            <ListItemText primary={t('devices')} />
                        </ListItem>
                    </List>
                    <div className={classes.userButtonContainer}>
                        <UserButton
                            userService={this.context.api.userService}
                            authzService={this.context.api.authzService}
                            pendingInvitations={this.context.pendingInvitations}
                            onInvitationAccepted={() => {
                                this.context.loadPendingInvitations()
                            }}
                            user={this.context.api.userService.getActiveUser()}
                            onLogout={() => this.context.api.userService.logoutUser()} />
                    </div>
                </Grid>
                {hasCenterContent &&
                    <Grid item className={classes.centerColumn}>
                        {activeMenuType === MENU_TYPE.GROUPS && <GroupViewList groupOrgId={selectedGroupOrgId} activeItemId={selectedGroupItemOrgId} onChangeActiveItemId={(itemId) => this.setState({ selectedGroupItemOrgId: itemId })} />}
                    </Grid>
                }
                {hasRightContent &&
                    <Fragment>
                        <Grid item className={classes.rightColumn}>
                            <ThemeProvider theme={lightTheme}>
                                <Toolbar className={classes.toolbar}>
                                    <Typography variant="subtitle1" color={'inherit'} className={classes.toolbarTitle}>
                                        {activeMenuType === MENU_TYPE.GROUPS && selectedGroupItemOrg && selectedGroupItemOrg.getName()}
                                    </Typography>
                                    <div className={classes.searchContainer}>
                                        <Searchbox
                                            value={this.state.searchTerm}
                                            onChange={(value) => this.setState({ searchTerm: value })} />
                                    </div>
                                    {activeMenuType !== MENU_TYPE.DEVICES && <IconButton
                                        disabled={(isSelectedGroupItemRecipient && selectedTriggerGroupItemIds.length === 0) || (isSelectedGroupItemSupplier && selectedActionGroupItemIds.length === 0) || (activeMenuType === MENU_TYPE.REPLACEMENTS && selectedReplacementItemIds.length === 0) || (activeMenuType === MENU_TYPE.USER_MANAGEMENT && selectedUserListIds.length === 0)}
                                        className={classes.deleteButton}
                                        edge={activeMenuType === MENU_TYPE.REPLACEMENTS ? 'end' : null}
                                        onClick={() => this.onDeleteButtonClick()}>
                                        <DeleteIcon />
                                    </IconButton>}
                                    {isSelectedGroupItemRecipient && <IconButton className={classes.menuButton} onClick={() => this.context.csvExportTriggerEntities(selectedGroupItemOrgId)}>
                                        <DownloadIcon />
                                    </IconButton>}
                                    {isSelectedGroupItemSupplier && <IconButton className={classes.menuButton} onClick={() => {
                                        let actions = []
                                        actionGroupItemEntities.map(entity => {
                                            actions.push(entity.template)
                                        })

                                        if (selectedActionGroupItemIds && selectedActionGroupItemIds.length > 0) {
                                            actions = actions.filter(entity => selectedActionGroupItemIds.indexOf(entity.templateId) >= 0)
                                        }
                                        const replacements = []
                                        actions.map(template => {
                                            template.replacementIds.map(id => {
                                                if (!replacements.find(replacement => replacement.getId() === id)) {
                                                    const replacement = replacementEntities.find(replacement => replacement.getId() === id)
                                                    if (replacement) replacements.push(replacement)
                                                }
                                            })
                                        })
                                        const exportData = { actions, replacements }
                                        this.context.jsonExportActionEntities(exportData)
                                    }}>
                                        <DownloadIcon />
                                    </IconButton>}
                                    {(isSelectedGroupItemRecipient || isSelectedGroupItemSupplier) && <IconButton className={classes.menuButton} onClick={() => {
                                        if (isSelectedGroupItemSupplier) {
                                            this.setState({ actionImportDialogVisible: true })
                                        } else {
                                            this.setState({ triggerImportDialogVisible: true })
                                        }
                                    }}>
                                        <UploadIcon />
                                    </IconButton>}
                                </Toolbar>
                                {isSelectedGroupItemRecipient && this.renderRecipientItemsList()}
                                {isSelectedGroupItemSupplier && this.renderSupplierItemsList()}
                                {isSelectedGroupItemReplacements && activeMenuType === MENU_TYPE.GROUPS && this.renderReplacementList()}
                                {activeMenuType === MENU_TYPE.USER_MANAGEMENT && this.renderUserList()}
                                {activeMenuType === MENU_TYPE.DEVICES && this.renderDevices()}
                            </ThemeProvider>
                        </Grid>
                        {activeMenuType !== MENU_TYPE.USER_MANAGEMENT && activeMenuType !== MENU_TYPE.DEVICES && <Fab disabled={!isSelectedGroupItemReplacements && !isSelectedGroupItemRecipient && !isSelectedGroupItemSupplier} className={classes.fabButton} color={'primary'} onClick={this.onAddButtonClick}>
                            <AddIcon />
                        </Fab>}

                    </Fragment>
                }
                {this.renderModalComponents()}
                {this.renderActionModal()}
                {this.renderTriggerModal()}
                {this.renderUserAccessManagementDialog()}
                {this.renderReplacementDialog()}
                {this.renderSupplierListImportDialog()}
                {this.renderRecipientListImportDialog()}
            </Grid>
        )
    }

    onDeleteButtonClick() {
        const { activeMenuType, selectedGroupItemOrgId } = this.state

        let dialogType = null
        switch (activeMenuType) {
            case MENU_TYPE.GROUPS:
                const selectedGroupItemOrg = this.context.childrenOrgs.find(org => selectedGroupItemOrgId === org.getId())
                const isSelectedGroupItemReplacements = selectedGroupItemOrgId === SPECIAL_ITEM_ID.REPLACEMENTS
                const isSelectedGroupItemRecipient = selectedGroupItemOrg && selectedGroupItemOrg.hasTags([ORG_TAG.RECIPIENT_LOCATION])
                const isSelectedGroupItemSupplier = selectedGroupItemOrg && selectedGroupItemOrg.hasTags([ORG_TAG.SUPPLIER_TARGET])
                if (isSelectedGroupItemReplacements) {
                    dialogType = DIALOG_TYPE.DELETE_REPLACEMENTS
                } else if (isSelectedGroupItemRecipient) {
                    dialogType = DIALOG_TYPE.DELETE_TRIGGERS
                } else if (isSelectedGroupItemSupplier) {
                    dialogType = DIALOG_TYPE.DELETE_ACTIONS
                }
                break;
            case MENU_TYPE.USER_MANAGEMENT:
                this.userListRef.deleteSelectedItems()
                break;

        }
        this.setState({ dialogType })
    }

    onAddButtonClick = () => {
        const { activeMenuType, selectedGroupItemOrgId } = this.state
        let dialogType = null
        switch (activeMenuType) {
            case MENU_TYPE.GROUPS:
                const selectedGroupItemOrg = this.context.childrenOrgs.find(org => selectedGroupItemOrgId === org.getId())
                const isSelectedGroupItemReplacements = selectedGroupItemOrgId === SPECIAL_ITEM_ID.REPLACEMENTS
                const isSelectedGroupItemRecipient = selectedGroupItemOrg && selectedGroupItemOrg.hasTags([ORG_TAG.RECIPIENT_LOCATION])
                const isSelectedGroupItemSupplier = selectedGroupItemOrg && selectedGroupItemOrg.hasTags([ORG_TAG.SUPPLIER_TARGET])
                if (isSelectedGroupItemReplacements) {
                    dialogType = DIALOG_TYPE.ADD_REPLACEMENT
                } else if (isSelectedGroupItemRecipient) {
                    dialogType = DIALOG_TYPE.ADD_TRIGGER
                } else if (isSelectedGroupItemSupplier) {
                    dialogType = DIALOG_TYPE.ADD_ACTION
                }
                break;
        }
        this.setState({ dialogType })
    }

    /**
     * render right content
     */
    renderRecipientItemsList() {
        const { triggerGroupItemEntities, searchTerm, actionGroupItemEntities, selectedGroupOrgId, selectedGroupItemOrgId } = this.state
        return (
            <RecipientItemsList
                orgId={selectedGroupItemOrgId}
                groupOrgId={selectedGroupOrgId}
                actionGroupItemEntities={actionGroupItemEntities}
                triggers={triggerGroupItemEntities}
                searchTerm={searchTerm}
                onDelete={(entity) => this.setState({
                    dialogType: DIALOG_TYPE.DELETE_TRIGGER,
                    triggerToDelete: entity.definition
                })}
                onReset={(entity) => this.setState({
                    dialogType: DIALOG_TYPE.RESET_TRIGGER,
                    triggerToReset: entity.definition
                })}
                triggerLoadTriggers={() => {
                    this.loadTriggerGroupItems(false)
                }}
                onSelectionChange={(selectedItems) => this.setState({ selectedTriggerGroupItemIds: selectedItems })} />
        )
    }

    renderSupplierItemsList() {
        const { actionGroupItemEntities, searchTerm, selectedGroupOrgId, selectedGroupItemOrgId } = this.state
        return (
            <SupplierItemsList
                orgId={selectedGroupItemOrgId}
                groupOrgId={selectedGroupOrgId}
                actions={actionGroupItemEntities}
                searchTerm={searchTerm}
                onEdit={(entity) => this.setState({
                    dialogType: DIALOG_TYPE.EDIT_ACTION,
                    actionToEditOrDelete: entity.template
                })}
                onDelete={(entity) => this.setState({
                    dialogType: DIALOG_TYPE.DELETE_ACTION,
                    actionToEditOrDelete: entity.template
                })}
                onSelectionChange={(selectedItems) => this.setState({ selectedActionGroupItemIds: selectedItems })} />
        )
    }

    renderReplacementList() {
        const { replacementEntities, searchTerm, selectedGroupOrgId, actionGroupItemEntities } = this.state
        return (
            <ReplacementsList
                items={replacementEntities}
                searchTerm={searchTerm}
                onEdit={(entity) => this.setState({
                    dialogType: DIALOG_TYPE.EDIT_REPLACEMENT,
                    replacementToEditOrDelete: entity
                })}
                onDelete={(entity) => this.setState({
                    dialogType: DIALOG_TYPE.DELETE_REPLACEMENT,
                    replacementToEditOrDelete: entity
                })}
                orgId={selectedGroupOrgId}
                onSelectionChange={(selectedItems) => this.setState({ selectedReplacementItemIds: selectedItems })} />
        )
    }

    renderUserList() {
        const { searchTerm } = this.state
        return (
            <UserList
                onRef={(ref) => this.userListRef = ref}
                onSelectionChange={(selectedItems) => {
                    this.setState({ selectedUserListIds: selectedItems })
                }}
                searchTerm={searchTerm} />
        )
    }

    renderDevices() {
        const { searchTerm } = this.state
        return <Devices org={this.context.getSelectedRootOrg()} searchTerm={searchTerm} />
    }

    renderActionModal() {
        const { dialogType, actionToEditOrDelete, actionGroupItemEntities, replacementEntities, selectedGroupItemOrgId, selectedGroupOrgId } = this.state
        return (dialogType === DIALOG_TYPE.ADD_ACTION || dialogType === DIALOG_TYPE.EDIT_ACTION) && (
            <ActionDialog
                open={true}
                orgId={selectedGroupItemOrgId}
                groupOrgId={selectedGroupOrgId}
                template={actionToEditOrDelete}
                replacements={replacementEntities}
                onCancel={() => this.setState({ dialogType: DIALOG_TYPE.NONE, actionToEditOrDelete: null })}
                onSuccess={(template) => {
                    const actionGroupItemEntity = {
                        template,
                        id: template.getId(),
                        title: template.getHeader()
                    }
                    const newActionGroupItemEntities = [...actionGroupItemEntities.filter(entity => entity.template && entity.template.getId() !== template.getId()), actionGroupItemEntity].sort((a, b) => {
                        return a.title.localeCompare(b.title)
                    })
                    this.setState({ dialogType: DIALOG_TYPE.NONE, actionToEditOrDelete: null, actionGroupItemEntities: newActionGroupItemEntities })
                }} />
        )
    }

    renderTriggerModal() {
        const { dialogType, triggerGroupItemEntities, rootEntityId, selectedGroupOrgId, selectedGroupItemOrgId } = this.state
        return (dialogType === DIALOG_TYPE.ADD_TRIGGER) && (
            <TriggerDialog
                open={true}
                rootOrgId={rootEntityId}
                orgId={selectedGroupItemOrgId}
                groupOrgId={selectedGroupOrgId}
                onCancel={() => this.setState({ dialogType: DIALOG_TYPE.NONE })}
                onSuccess={(triggerItems) => {
                    const newActionGroupItemEntities = [...triggerGroupItemEntities, ...triggerItems].sort((a, b) => {
                        return a.title.localeCompare(b.title)
                    })
                    this.setState({ triggerGroupItemEntities: newActionGroupItemEntities, dialogType: DIALOG_TYPE.NONE })
                }} />
        )
    }

    renderUserAccessManagementDialog() {
        const { dialogType, selectedGroupItemOrgId } = this.state
        return (
            <Fragment>
                {dialogType === DIALOG_TYPE.USER_ACCESS_MANAGEMENT && <UserAccessManagementDialog
                    open={true}
                    orgId={selectedGroupItemOrgId}
                    onCancel={() => this.setState({ dialogType: null })}
                    onSuccess={() => this.setState({ dialogType: null })} />}
            </Fragment>
        )
    }

    renderReplacementDialog() {
        const { dialogType, selectedGroupOrgId, replacementEntities, replacementToEditOrDelete } = this.state
        return (dialogType === DIALOG_TYPE.ADD_REPLACEMENT || dialogType === DIALOG_TYPE.EDIT_REPLACEMENT) && (
            <ReplacementDialog
                open={true}
                orgId={selectedGroupOrgId}
                replacement={dialogType === DIALOG_TYPE.EDIT_REPLACEMENT ? replacementToEditOrDelete : null}
                replacementEntities={replacementEntities}
                onCancel={() => this.setState({ dialogType: DIALOG_TYPE.NONE })}
                onSuccess={(replacementItem) => {
                    this.setState({ dialogType: DIALOG_TYPE.NONE }, () => this.loadReplacementEntities(false))
                }} />
        )
    }

    /**
     * render modal components ( edit / delete modals )
     */
    renderModalComponents() {
        const { t } = this.props
        const { dialogType, errorMessage, loading } = this.state

        let title = null
        let description = null
        switch (dialogType) {
            case DIALOG_TYPE.DELETE_ACTION:
                title = t('delete_action_title')
                description = t('delete_action_description')
                break
            case DIALOG_TYPE.DELETE_ACTIONS:
                title = t('delete_services_title')
                description = t('delete_services_description')
                break
            case DIALOG_TYPE.DELETE_TRIGGER:
                title = t('delete_sender_title')
                description = t('delete_sender_description')
                break
            case DIALOG_TYPE.DELETE_TRIGGERS:
                title = t('delete_senders_title')
                description = t('delete_senders_description')
                break
            case DIALOG_TYPE.DELETE_REPLACEMENT:
                title = t('delete_replacement_title')
                description = t('delete_replacement_description')
                break
            case DIALOG_TYPE.DELETE_REPLACEMENTS:
                title = t('delete_replacements_title')
                description = t('delete_replacements_description')
                break
            case DIALOG_TYPE.RESET_TRIGGER:
                title = t('reset_physical_device_title')
                description = t('reset_physical_device_description')
                break

        }

        return (
            <Fragment>
                {(title && description) && <AlertDialog
                    open={true}
                    loading={loading}
                    errorMessage={errorMessage}
                    title={title}
                    message={errorMessage ? null : description}
                    submitButtonTitle={errorMessage ? t('delete') : null}
                    cancelButtonTitle={errorMessage ? t('close') : t('cancel')}
                    buttonVariant={'text'}
                    submitButtonColor={'primary'}
                    onSubmit={errorMessage ? null : [DIALOG_TYPE.RESET_TRIGGER].indexOf(dialogType) >= 0 ? this.onSubmitDialog.bind(this) : this.onSubmitDeleteDialog.bind(this)}
                    onCancel={this.resetLoadingState.bind(this)} />}
            </Fragment>
        )
    }

    /**
     * on submit handler ( miscellaneous )
     * @param {String} name 
     */
    async onSubmitDialog(name) {
        const { dialogType, triggerToReset } = this.state
        if ([DIALOG_TYPE.RESET_TRIGGER].indexOf(dialogType) >= 0) {
            await this.setAsyncState({
                loading: true
            })
            try {
                await this.context.api.deviceService.sendSta2DeviceCommand(triggerToReset.getDeviceId(), 'RESET')
                this.resetLoadingState()
            } catch (error) {
                this.handleResponseError(error)
            }
        }
    }

    /**
     * on delete handler 
     */
    async onSubmitDeleteDialog() {
        const { t } = this.props
        const { dialogType, actionGroupItemEntities, actionToEditOrDelete, triggerGroupItemEntities, triggerToDelete, selectedTriggerGroupItemIds, selectedActionGroupItemIds, replacementEntities, selectedReplacementItemIds, replacementToEditOrDelete } = this.state

        await this.setAsyncState({
            loading: true
        })

        try {
            if (dialogType === DIALOG_TYPE.DELETE_ACTION || dialogType === DIALOG_TYPE.DELETE_ACTIONS) {
                const templates = []
                let newActionGroupItemEntities = actionGroupItemEntities
                if (dialogType === DIALOG_TYPE.DELETE_ACTION) {
                    templates.push(actionToEditOrDelete)
                } else {
                    selectedActionGroupItemIds && selectedActionGroupItemIds.map(id => {
                        const action = actionGroupItemEntities && actionGroupItemEntities.find(entity => entity.template && entity.template.getId() === id)
                        if (action) {
                            templates.push(action.template)
                        }
                    })
                }
                let errorMessage = ''
                for (let index = 0; index < templates.length; index++) {
                    const template = templates[index];
                    try {
                        await this.context.api.appMessageService.deleteTemplate(template)
                        newActionGroupItemEntities = newActionGroupItemEntities.filter(entity => entity.template && entity.template.getId() !== template.getId())
                    } catch (error) {
                        const triggerIds = error.originError && error.originError.detail && JSON.parse(error.originError.detail) //error.originError.detail.match(/[a-zA-Z0-9-!]+!*/g) || []
                        const deviceAssignedOrgIds = await this.context.getDeviceAssignedOrgIdsFromDefinitionIds(triggerIds)
                        const triggerGroupNames = []
                        for (let index = 0; index < deviceAssignedOrgIds.length; index++) {
                            const deviceAssignedOrgId = deviceAssignedOrgIds[index];
                            let triggerGroupEntity = this.context.childrenOrgs.find(trigger => trigger.getId() === deviceAssignedOrgId);
                            if (!triggerGroupEntity) {
                                triggerGroupEntity = await this.context.api.orgService.read(deviceAssignedOrgId)
                            }
                            triggerGroupNames.push(triggerGroupEntity ? triggerGroupEntity.getName() : deviceAssignedOrgId)
                        }
                        if (isResourceInUseError(error)) {
                            const usedByTriggerNames = triggerGroupNames.sort((a, b) => { return a.localeCompare(b) })
                            let triggers = ''
                            if (usedByTriggerNames.length > 1) {
                                triggers = usedByTriggerNames.slice(0, -1).join(', ')
                                triggers = [triggers, usedByTriggerNames[usedByTriggerNames.length - 1]].join(` ${t('and')} `)
                            } else {
                                triggers = usedByTriggerNames.join(', ')
                            }
                            errorMessage += `${errorMessage !== '' ? '\n' : ''}${t('action_in_use_error', { action: template.getName(), triggers: triggers })}`
                        } else {
                            errorMessage += `\n${t(mapErrorMessage(error))}`
                        }
                    }
                }

                await this.setAsyncState({
                    actionGroupItemEntities: newActionGroupItemEntities.sort((a, b) => {
                        return a.title.localeCompare(b.title)
                    })
                })

                if (errorMessage !== '') {
                    this.handleErrorMessage(errorMessage)
                    return
                }

            } else if (dialogType === DIALOG_TYPE.DELETE_TRIGGER || dialogType === DIALOG_TYPE.DELETE_TRIGGERS) {
                const definitions = []
                let newTriggerGroupItemEntities = triggerGroupItemEntities
                if (dialogType === DIALOG_TYPE.DELETE_TRIGGER) {
                    definitions.push(triggerToDelete)
                } else {
                    selectedTriggerGroupItemIds && selectedTriggerGroupItemIds.map(id => {
                        const definition = triggerGroupItemEntities && triggerGroupItemEntities.find(entity => entity.definition && entity.definition.getId() === id)
                        if (definition) {
                            definitions.push(definition.definition)
                        }
                    })
                }

                for (let index = 0; index < definitions.length; index++) {
                    const definition = definitions[index];
                    try {
                        //console.log('deleteTrigger, definition: ', definition)
                        const deviceId = definition.getDeviceId()
                        const device = await this.context.api.deviceService.getDevice(deviceId)
                        const deviceCreator = device.getSetting(APP_MESSAGE_CREATOR)
                        //console.log('deleteTrigger, deviceCreator: ', deviceCreator)
                        const deleteDefinitionResult = await this.context.api.appMessageService.deleteDefinition(definition)
                        //console.log('deleteTrigger, deleteDefinitionResult: ', deleteDefinitionResult)
                        const deactivateResult = await this.context.api.deviceService.deactivateDevice(deviceId)
                        //console.log('deleteTrigger, deactivateResult: ', deactivateResult)
                        const unassignResult = await this.context.api.deviceService.unassignDevice(deviceId)
                        //console.log('deleteTrigger, unassignResult: ', unassignResult)

                        if (device.getDriver() === DRIVER_TYPE.STA2) {
                            await this.context.api.deviceService.updateSettings(deviceId, { [APP_MESSAGE_STA2_LOGMODE]: null, [APP_MESSAGE_STA2_WEIGHT_LOG_ORDER_POINT]: null })
                        } else if (device.getDriver() === DRIVER_TYPE.CODE || device.getDriver() === DRIVER_TYPE.TIMER && deviceCreator === APP_MESSAGE_CREATOR_ADMIN) {
                            const unclaimDeviceResult = await this.context.api.deviceService.unclaimDevice(deviceId)
                            //console.log('deleteTrigger, unclaimDeviceResult: ', unclaimDeviceResult)
                        }
                        newTriggerGroupItemEntities = newTriggerGroupItemEntities.filter(entity => entity.id !== definition.getId())
                    } catch (error) {
                        console.warn("can't delete definition: ", definition, ", error: ", error)
                    }
                }
                await this.setAsyncState({
                    triggerGroupItemEntities: newTriggerGroupItemEntities
                })
            } else if (dialogType === DIALOG_TYPE.DELETE_REPLACEMENT || dialogType === DIALOG_TYPE.DELETE_REPLACEMENTS) {
                const replacements = []
                let newReplacementEntities = replacementEntities
                if (dialogType === DIALOG_TYPE.DELETE_REPLACEMENT) {
                    replacements.push(replacementToEditOrDelete)
                } else {
                    selectedReplacementItemIds && selectedReplacementItemIds.map(id => {
                        const replacement = replacementEntities && replacementEntities.find(entity => entity.getId() === id)
                        if (replacement) {
                            replacements.push(replacement)
                        }
                    })
                }
                for (let index = 0; index < replacements.length; index++) {
                    const replacement = replacements[index];
                    try {
                        await this.context.api.appMessageService.deleteReplacementDef(replacement.getId())
                        newReplacementEntities = newReplacementEntities.filter(entity => entity && entity.getId() !== replacement.getId())
                    } catch (error) {
                        console.warn("can't delete replacement: ", replacement, ", error: ", error)
                        this.handleResponseError(error)
                        return
                    }
                }
                await this.setAsyncState({
                    replacementEntities: newReplacementEntities.sort((a, b) => {
                        return a.title.localeCompare(b.title)
                    })
                })
            }
            this.resetLoadingState()
        } catch (error) {
            this.handleResponseError(error)
        }
    }

    renderSupplierListImportDialog() {
        const { actionImportDialogVisible, actionGroupItemEntities, selectedGroupOrgId, selectedGroupItemOrgId } = this.state
        return actionImportDialogVisible ? (
            <ActionImportDialog
                open={actionImportDialogVisible}
                orgId={selectedGroupItemOrgId}
                groupOrgId={selectedGroupOrgId}
                onCancel={() => this.setState({ actionImportDialogVisible: false })}
                onSuccess={(addedTemplates) => {
                    const addedActionGroupItemEntities = addedTemplates.map(template => ({ template, id: template.getId(), title: template.getHeader() }))
                    const newActionGroupItemEntities = [...actionGroupItemEntities, ...addedActionGroupItemEntities].sort((a, b) => {
                        return a.title.localeCompare(b.title)
                    })
                    this.setState({ actionImportDialogVisible: false, actionGroupItemEntities: newActionGroupItemEntities }, () => this.loadReplacementEntities())
                }} />
        ) : null
    }

    renderRecipientListImportDialog() {
        const { triggerImportDialogVisible, triggerGroupItemEntities, rootEntityId, selectedGroupOrgId, selectedGroupItemOrgId } = this.state
        return triggerImportDialogVisible ? (
            <TriggerImportDialog
                open={triggerImportDialogVisible}
                orgId={selectedGroupItemOrgId}
                groupOrgId={selectedGroupOrgId}
                rootOrgId={rootEntityId}
                onClose={() => this.setState({ triggerImportDialogVisible: false })}
                onSuccess={(addedTriggerItems) => {
                    const newActionGroupItemEntities = [...triggerGroupItemEntities, ...addedTriggerItems].sort((a, b) => {
                        return a.title.localeCompare(b.title)
                    })
                    this.setState({ triggerGroupItemEntities: newActionGroupItemEntities, actionImportDialogVisible: false })
                }} />
        ) : null
    }

}

const styles = theme => ({
    root: {
        height: '100%'
    },
    accessDeniedContainer: {
        ...DefaultStyles.AbsoluteFill,
        display: 'flex',
        alignItems: 'center'
    },
    accessDeniedContainerInner: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    toolbarTitle: {
        flex: 1,
        fontWeight: 500,
        ...DefaultStyles.SingleLineLabel
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        marginRight: theme.spacing(2)
    },
    leftColumn: {
        backgroundColor: theme.palette.common.black,
        position: 'relative',
        width: 360,
        paddingLeft: 1,
        paddingRight: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    centerColumn: {
        backgroundColor: theme.palette.common.darkBackground,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: 320
    },
    rightColumn: {
        backgroundColor: theme.palette.common.white,
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        height: '100%',
        color: theme.palette.secondary.main,
    },
    deleteButton: {
    },
    fabButton: {
        position: 'absolute',
        right: theme.spacing(2),
        bottom: theme.spacing(2)
    },
    userButtonContainer: {
        position: 'absolute',
        left: - theme.spacing(1),
        bottom: theme.spacing(1)
    },
    rootEntityTitle: {
        fontWeight: 500,
        marginTop: 30,
        marginBottom: 30,
        lineHeight: 1,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    menuSectionTitle: {
        display: 'block',
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(1.5)
    },
    administrationMenu: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
    },
    administrationMenuItem: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&.Mui-selected, &:hover.Mui-selected': {
            backgroundColor: theme.palette.primary.main
        }
    },
})

MainScreen.contextType = PageContext

export default withTranslation()(withStyles(styles)(MainScreen))