import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, List, ListItem, TextField, Divider, IconButton } from '@material-ui/core'
import MultilineTextfield from './MultilineTextfield'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

const VALUE_TYPE = {
    key: 'key',
    value: 'value'
}

class PropListDialog extends React.Component {

    constructor(props) {
        super(props)
    
        this.state = {
            params: props.params ? Object.keys(props.params).sort().map( key => ({key, value: props.params[key]})) : [{key: '', value: ''}]
        }
    }
    
    handleChange(itemIndex, valueType, value) {
        const params =  this.state.params
        params[itemIndex][valueType] = value
        this.setState({
            params
        })
    }

    deleteRow(index){
        const params = this.state.params
        delete params[index]
        this.setState({
            params
        })
    }

    addRow() {
        const params = this.state.params
        params.push({key: '', value: ''})
        this.setState({
            params
        })
    }

    render() {
        const { params } = this.state
        const { t, classes, loading, errorMessage } = this.props

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'md'}>
                    <DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        {this.renderList()}
                        {errorMessage && <DialogContentText style={{marginTop: 20}} color="error">{errorMessage}</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={this.props.onCancel}>
                            {t('cancel')}
                        </Button>
                        <Button 
                            disabled={loading} 
                            onClick={() => {
                                let params = {}
                                this.state.params.map( ({key, value}) => {
                                    params[key] = value
                                })
                                this.props.onSubmit(params)
                            }} 
                            color="primary">
                            {t('save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    renderList() {
        const { params } = this.state
        const { t, classes } = this.props
        return (
            <Fragment>
                <List component="nav" className={classes.list} aria-label="params">
                    {  params.map( ({key, value}, index) => (
                        <Fragment>
                            <ListItem className={classes.listItem}>
                                <TextField 
                                    fullWidth
                                    className={[classes.textField, classes.textFieldKey]}
                                    placeholder={t('key')}
                                    value={key}
                                    onChange={(event) => this.handleChange(index, VALUE_TYPE.key, event.target.value)}
                                    InputLabelProps={{ shrink: true }} />
                                {/*<TextField 
                                    fullWidth
                                    className={[classes.textField, classes.textFieldValue]}
                                    placeholder={t('value')}
                                    value={value}
                                    onChange={(event) => this.handleChange(index, VALUE_TYPE.value, event.target.value)}
                                InputLabelProps={{ shrink: true }} />*/}
                                <MultilineTextfield 
                                    value={value}
                                    className={classes.textFieldValue}
                                    onChange={(event) => this.handleChange(index, VALUE_TYPE.value, event.target.value)}/>
                                <IconButton 
                                    className={classes.deleteIcon}
                                    size="small" 
                                    onClick={() => this.deleteRow(index)} 
                                    edge="end">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                            <Divider/>
                        </Fragment>
                    ))}
                    <Button
                        color="primary"
                        className={classes.addButton}
                        onClick={() => this.addRow()} 
                        startIcon={<AddIcon />}>
                        {t('add')}
                    </Button>
                </List>
            </Fragment>
        )
    }
}


PropListDialog.propTypes = {
    open: PropTypes.bool,
    loading: PropTypes.bool,
    errorMessage: PropTypes.string,
    params: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}


const styles = theme => ({
    list: {
        minHeight: 400
    },
    listItem: {
        marginBottom: theme.spacing(1),
        paddingLeft: 0,
        paddingRight: 0
    },
    deleteIcon: {
        marginLeft: theme.spacing(2)
    },
    textFieldKey: {
        marginRight: theme.spacing(2),
        width: 300
    },
    textFieldValue: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    addButton: {
        marginTop: theme.spacing(2)
    }
})


export default withTranslation()(withStyles(styles)(PropListDialog))