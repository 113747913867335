import React, { Fragment } from 'react'
import { List } from '@material-ui/core'


export const ListContainer = ({ listRef, style, children }) => {
    return (
        <List ref={listRef} style={style}>
            {children}
        </List>
    )
}

export const ItemContainer = ({ children, ...props }) => {
    return (
        <div {...props} style={{ margin: 0, paddingLeft: 16, paddingRight: 16, height: props['data-known-size'] || undefined }}>
            {children}
        </div>
    )
}

export const ItemContainerNoLeftPadding = ({ children, ...props }) => {
    return (
        <div {...props} style={{ margin: 0, paddingLeft: 0, paddingRight: 16 }}>
            {children}
        </div>
    )
}

export const ItemContainerNoPadding = ({ children, ...props }) => {
    return (
        <div {...props} style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
            {children}
        </div>
    )
}
