import React, { Suspense, useEffect, useContext, useState } from "react"
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import PageProvider, { PageContext } from './Context/PageProvider'
import DefaultRoute from './DefaultRoute'
import ProtectedRoute from './ProtectedRoute'
import { service, SERVICE_TYPES } from '../../index'
import Loading from '../../Libs/Pages/Loading'

import Auth from '../../Libs/Pages/User/Auth'
import PasswordReset from '../../Libs/Pages/User/PasswordReset'
import Activate from '../../Libs/Pages/User/Activate'
import Signup from '../../Libs/Pages/User/Signup'

import SelectRootScreen from './Pages/SelectRootScreen'
import MainScreen from './Pages/MainScreen'

export const getBasePath = () => {
    return '/admin'
}

export const getPath = (path) => {
    const rootPath = getBasePath()
    if (!path || path === '') return rootPath
    return rootPath === '/' ? rootPath + path : rootPath + '/' + path
}

function AppRouter() {
    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <PageProvider>
                    <Switch>
                        <DefaultRoute path="/auth" exact component={Auth} />
                        <DefaultRoute path="/password-reset/:email?/:code?" exact component={PasswordReset} />
                        <DefaultRoute path="/signup" exact component={Signup} />
                        <DefaultRoute path="/activate/:id/:code?" exact component={Activate} />
                        <ProtectedRoute path={`${getPath()}/:id`} exact component={MainScreen} />
                        <ProtectedRoute path={getPath()} exact component={SelectRootScreen} />
                        <ProtectedRoute path="/">
                            <Redirect to={getPath()} />
                        </ProtectedRoute>
                    </Switch>
                </PageProvider>
            </Router>
        </Suspense>
    )
}

export default AppRouter

export {
    PageContext
}