import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { ApiContext } from '../../../../m2m-cloud-api/MessageLog/ApiContext'
import { REPLACEMENT_TYPE, REPLACEMENT_DATE_TYPE, REPLACEMENT_DATE_FORMAT } from '../../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, LinearProgress, Typography } from '@material-ui/core'
import { mapErrorMessage } from '../../../../Libs/Utilities/ApiHelper'
import ReplacementField from './ReplacementFields'
import moment from 'moment'

const invisibleTypes = [REPLACEMENT_TYPE.CHECKLIST, REPLACEMENT_TYPE.REPORT, REPLACEMENT_TYPE.NOTE]

export const getPreparedDefaultValue = (replacement, value) => {
    if ( replacement?.getType() === REPLACEMENT_TYPE.DATE) {
        const desc = replacement.getDesc()
        let format = ''
        switch (desc.type) {
            case REPLACEMENT_DATE_TYPE.DATE:
                format = REPLACEMENT_DATE_FORMAT.DATE
                break
            case REPLACEMENT_DATE_TYPE.DATETIME:
                format = REPLACEMENT_DATE_FORMAT.DATETIME
                break
            case REPLACEMENT_DATE_TYPE.TIME:
                format = REPLACEMENT_DATE_FORMAT.TIME
                break
            
        }
        if (value && value.trim() !== '') {
            if ( format === REPLACEMENT_DATE_FORMAT.TIME ) {
                const date = moment()
                const [hours, minutes] = value.split(':')
                date.set({ hours, minutes })
                return date
            } else {
                return moment(value, format)
            }
        }
    }
    return value
}

export const prepareReplacementValueBeforeSave = async (context = null, definitionId = null, replacement, value) => {
    let preparedValue = value
    if (replacement && replacement.getType() === REPLACEMENT_TYPE.DATE) {
        if ( value?._isAMomentObject && value.isValid()) {
            const desc = replacement.getDesc()
            switch (desc.type) {
                case REPLACEMENT_DATE_TYPE.DATE:
                    preparedValue = value.format(REPLACEMENT_DATE_FORMAT.DATE)
                    break
                case REPLACEMENT_DATE_TYPE.DATETIME:
                    preparedValue = value.format(REPLACEMENT_DATE_FORMAT.DATETIME)
                    break
                case REPLACEMENT_DATE_TYPE.TIME:
                    preparedValue = value.format(REPLACEMENT_DATE_FORMAT.TIME)
                    break
                default:
                    break
            }
        } else {
            preparedValue = ''   
        }
    }
    if (context && definitionId && replacement && value && replacement.getType() === REPLACEMENT_TYPE.IMAGE) {
        const recipient = context.getSelectedRecipientObjectOrg()
        const orgId = recipient.getId()
        const result = await context.appMessageService.setReplacementFile(orgId, definitionId, value)
        preparedValue= result.fileId
    }
    return preparedValue
}

class ReplacementsModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            errorMessage: null,
            values: {},
            customFieldErrors: {},
            formErrors: [],
            ackedChecklistReplacements: [],
            images: []
        }
        this.inputImageRef = React.createRef()
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('minDecimalValue', (value, min) => {
            const minNumber = Number(min)
            const cleanedValue = value.replace(',', '.')
            const numberValue = Number(cleanedValue)
            if (cleanedValue !== "" && (numberValue === Infinity || Math.floor(numberValue) < minNumber)) {
                return false
            }
            return true
        })
        ValidatorForm.addValidationRule('maxDecimalValue', (value, max) => {
            const maxNumber = Number(max)
            const cleanedValue = value.replace(',', '.')
            const numberValue = Number(cleanedValue)
            if (cleanedValue !== "" && (numberValue === Infinity || Math.ceil(numberValue) > maxNumber)) {
                return false
            }
            return true
        })
    }

    componentWillUnmount() {
        if (ValidatorForm && ValidatorForm.removeValidationRule) {
            ValidatorForm.removeValidationRule('minDecimalValue')
        }
    }

    getNextChecklistReplacement() {
        const { article } = this.props
        const { ackedChecklistReplacements } = this.state

        const replacements = article && article.replacementIds || []
        return replacements.find(replacement => replacement.getType() === REPLACEMENT_TYPE.CHECKLIST && ackedChecklistReplacements.indexOf(replacement.getId()) === -1)
    }

    render() {
        const { visible, onClose, t, classes, article, loading } = this.props
        const { ackedChecklistReplacements, errorMessage } = this.state

        const replacements = article && article.replacementIds || []

        if (this.getNextChecklistReplacement()) {
            return this.renderChecklistReplacement()
        }

        return (
            <Dialog fullWidth classes={{ paper: classes.rootPaper }} open={visible} onClose={() => void (0)}>
                {loading && <LinearProgress color={"secondary"} className={classes.progress} />}
                <DialogTitle className={classes.dialogTitle} variant="h6">
                    {t('replacements')}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit.bind(this)}
                        onError={errors => this.setState({ formErrors: errors })}>
                        {replacements.map(replacement => this.renderField(replacement))}
                        {errorMessage && <DialogContentText className={classes.errorText}>
                            {errorMessage}
                        </DialogContentText>}
                    </ValidatorForm>
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActions }}>
                    <Button disabled={loading} onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <Button disabled={loading} onClick={() => { this.validateCustomFields(() => this.refs.form.submit()) }} color="primary">
                        {t('send')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderChecklistReplacement() {
        const { onClose, t, classes, article } = this.props
        const { ackedChecklistReplacements, errorMessage } = this.state

        const nextChecklistReplacement = this.getNextChecklistReplacement()
        if (!nextChecklistReplacement) return null
        const values = nextChecklistReplacement.desc && nextChecklistReplacement.desc.values

        return (
            <Dialog fullWidth maxWidth={'xs'} open={true} onClose={() => void (0)}>
                <DialogTitle className={classes.dialogTitle} variant="h6">
                    {nextChecklistReplacement.title}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {values.map(value => {
                        if (value) {
                            const { title, content } = value
                            const lines = content && content.split('\n') || []
                            return (
                                <Fragment>
                                    <Typography variant="h6">
                                        {title}
                                    </Typography>
                                    {lines.map(line => (
                                        <Typography variant="body2">
                                            {line}
                                        </Typography>
                                    ))}
                                </Fragment>
                            )
                        }
                    })}
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActions }}>
                    <Button onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={() => {
                        this.setState({ ackedChecklistReplacements: [...ackedChecklistReplacements, nextChecklistReplacement.id] }, () => {
                            const nextChecklistReplacement = this.getNextChecklistReplacement()
                            if (!nextChecklistReplacement) {
                                const replacements = article && article.replacementIds || []
                                const hasAnotherReplacementTypes = replacements.find(replacement => !replacement.desc?.serviceProvider && invisibleTypes.indexOf(replacement.getType()) === -1)
                                if (!hasAnotherReplacementTypes) {
                                    this.props.onSuccess(null)
                                }
                            }
                        })
                    }}
                        color="primary">
                        {t('continue')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderField(replacement) {
        const { loading } = this.props
        const { values } = this.state
        const value = values[replacement.getId()] ? values[replacement.getId()] : ''
        const key = replacement.getId()

        if (replacement.desc?.serviceProvider) return (null)

        return <ReplacementField
            replacement={replacement}
            loading={loading}
            onValueChange={this.onValueChange.bind(this)}
            value={value}
            onDateFieldError={this.handleDateFieldError.bind(this)}
            customFieldError={this.state.customFieldErrors[key]}
            onDeleteImage={(value) => {
                const newValues = { ...this.state.values }
                delete newValues[value]
                this.setState({ values: newValues })
            }}
        />
    }

    handleDateFieldError(replacement, error) {
        const { values } = this.state
        const key = replacement.getId()
        const value = values[key] ? values[key] : null
        const customFieldErrors = this.state.customFieldErrors
        const newError = error && error.trim() !== "" ? error : null
        if (value !== null && customFieldErrors[key] !== newError) {
            customFieldErrors[key] = newError
            this.setState({ customFieldErrors })
        }
    }

    validateCustomFields(callback) {
        const { article, t } = this.props
        const { values } = this.state

        const customFieldErrors = this.state.customFieldErrors

        const replacements = article && article.replacementIds || []
        replacements.map(replacement => {
            if (this.isFieldRequired(replacement) && replacement.getType() === REPLACEMENT_TYPE.DATE) {
                const key = replacement.getId()
                const value = values[key] ? values[key] : null
                if (!value) {
                    customFieldErrors[key] = t('this_field_is_required')
                }
            }
            if (this.isFieldRequired(replacement) && replacement.getType() === REPLACEMENT_TYPE.IMAGE) {
                const key = replacement.getId()
                const value = values[key] ? values[key] : null
                if (!value) {
                    customFieldErrors[key] = t('image_field_is_required')
                } else if (customFieldErrors[key]) {
                    delete customFieldErrors[key]
                }
            }
        })

        this.setState({ customFieldErrors }, callback)
    }

    isFieldRequired(replacement) {
        const desc = replacement.getDesc()
        return desc.optional === true ? false : true
    }

    onValueChange(replacement, value) {
        const key = replacement.getId()
        let values = this.state.values
        values[key] = value
        this.setState({ values, formErrors: [] })
    }

    handleSubmit() {
        const { article } = this.props

        const dateErrorCount = Object.values(this.state.customFieldErrors).filter(value => value !== null).length
        if (dateErrorCount > 0) {
            console.warn('date error count: ', dateErrorCount)
            return
        }
        this.setState({ formErrors: [] }, async () => {
            const replacements = article && article.replacementIds || []
            const values = { ...this.state.values }
            const replacementIds = Object.keys(values)

            this.setState({ loading: true })

            try {
                for (let i = 0; i < replacementIds.length; i++) {
                    const replacementId = replacementIds[i];
                    const value = values[replacementId]
                    const replacement = replacements.find(rep => rep.getId() === replacementId)
                    values[replacementId] = await prepareReplacementValueBeforeSave(this.context, article.definitionId, replacement, value)
                }
                this.setState({ loading: false }, () => this.props.onSuccess(values))
            } catch (error) {
                this.setState({
                    loading: false,
                    errorMessage: mapErrorMessage(error)
                })
            }
        })
    }

}

ReplacementsModal.contextType = ApiContext

ReplacementsModal.propTypes = {
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    article: PropTypes.any,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func
}


const styles = theme => ({
    rootPaper: {
        maxWidth: 340,
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    dialogContent: {
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    dialogActions: {
        justifyContent: 'flex-end'
    },
    progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0
    },
    imageCard: {
        height: 200,
        marginBottom: theme.spacing(1),
    },
    imageRemoveButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.primary.main
    }
})

export default withTranslation()(withStyles(styles)(ReplacementsModal))