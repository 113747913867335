export default class CmdEvtDesc {
  /**
   * @param {Object<String>}
   */
  constructor({ name }) {
    this.name = name;
  }

  /**
   * Get name
   * @returns {String}
   */
  getName() {
    return this.name;
  }
}
