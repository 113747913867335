import React, { Fragment } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Select, MenuItem, IconButton, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import i18n from '../../i18n'
import PropTypes from 'prop-types'

import { PARAM_FORM_USER_FIELDS, PARAM_FORM_TECHNICAL_USER_FIELDS, PARAM_FORM_NICKNAME_FIELD, PARAM_FORM_LANGUAGE_FIELD, PARAM_LANGUAGE, PARAM_FORM_PN_SOUNDS_FIELD, PARAM_PN_SOUND } from '../../m2m-cloud-api/Api/UserService/Models/User'
import { mapErrorMessage } from '../Utilities/ApiHelper'
import ReactSVG from 'react-svg'
import PNSounds from '../Utilities/PNSounds'

class EditProfile extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            user: null,
            params: {},
            loading: true,
            errorMessage: null,
            isSoundPlaying: false
        }
    }

    async componentDidMount() {
        const { t, userService, userId } = this.props

        const user = userId ? await userService.loadUser(userId) : userService.getActiveUser()

        const me = this
        this.setState({ user }, () => {
            const defaultValues = this.getDefaultValues()
            this.setState({
                params: defaultValues,
                loading: false
            })

            const defaultPnValue = defaultValues[PARAM_PN_SOUND] ? PNSounds.find(sound => sound.name === defaultValues[PARAM_PN_SOUND]) : PNSounds.find(sound => sound.name === 'Default')
            me.audio = new Audio(defaultPnValue.filePath)
            me.audio.addEventListener('ended', me.onStopSound.bind(me))
        })


    }

    getFormFields() {
        const { t, userService } = this.props
        const { user } = this.state

        PARAM_FORM_PN_SOUNDS_FIELD.options = PNSounds.map(sound => sound.name)

        let fields = []
        if (user.isTechnicalUser()) {
            fields = [PARAM_FORM_NICKNAME_FIELD, ...PARAM_FORM_TECHNICAL_USER_FIELDS, PARAM_FORM_LANGUAGE_FIELD, PARAM_FORM_PN_SOUNDS_FIELD]
        } else {
            fields = [PARAM_FORM_NICKNAME_FIELD, ...PARAM_FORM_USER_FIELDS, PARAM_FORM_LANGUAGE_FIELD, PARAM_FORM_PN_SOUNDS_FIELD]
        }
        return fields
    }


    getDefaultValues() {
        const { user } = this.state
        let params = {}
        this.getFormFields().map(field => {
            params[field.id] = user.getParam(field.id)
        })
        return params
    }

    buildApiUpdateRequests() {
        const { user } = this.state
        const { params } = this.state
        const promisses = []
        this.getFormFields().map(field => {
            const fieldValue = params[field.id]
            if (!fieldValue || fieldValue.trim() === "") {
                promisses.push(this.props.userService.deleteParam(user.getUserId(), field.id))
            } else {
                promisses.push(this.props.userService.putParam(user.getUserId(), field.id, params[field.id]))
            }
        })
        return promisses
    }

    handleSubmit() {
        const { t } = this.props

        this.setState({ loading: true, errorMessage: null })

        return Promise.all(this.buildApiUpdateRequests())
            .then(async results => {
                const lang = this.state.params[PARAM_LANGUAGE]
                if (lang && lang.trim() !== '') {
                    i18n.changeLanguage(lang)
                }
                this.props.onSuccess()
            })
            .catch(error => {
                this.setState({
                    errorMessage: mapErrorMessage(error)
                })
            })
            .finally(() => this.setState({ loading: false }))
    }

    onPlaySound() {
        this.setState({ isSoundPlaying: true })
        this.audio.play()

    }
    onStopSound() {
        this.audio.pause()
        this.audio.currentTime = 0
        this.setState({ isSoundPlaying: false })
    }


    onChangePNSound(event) {
        const value = event.target.value
        const { params } = this.state
        this.onStopSound()

        const sound = PNSounds.find(sound => sound.name === value)
        this.audio = new Audio(sound.filePath)
        this.audio.addEventListener('ended', this.onStopSound.bind(this))
        let _params = params
        _params[PARAM_PN_SOUND] = value
        this.setState({ params: _params })
    }

    onChangeDefaultSelectField(event, field) {
        const { params } = this.state
        let _params = params
        _params[field.id] = event.target.value
        this.setState({ params: _params, errorMessage: null })
    }

    render() {
        const { params, loading, errorMessage, user, isSoundPlaying } = this.state
        const { t, classes } = this.props

        if (!user) return (null)

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('edit_profile')}</DialogTitle>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit.bind(this)}
                        onError={errors => console.log("form error:", errors)}>
                        <DialogContent>
                            {this.getFormFields().map(field => {
                                const value = params[field.id]
                                const isPnSoundField = field.id === PARAM_PN_SOUND
                                if (field.type === 'select') {
                                    return <FormControl className={classes.formControl} fullWidth>
                                        <InputLabel htmlFor="user-lang">{t(field.title)}</InputLabel>
                                        <Grid container className={classes.selectFieldContainer}>
                                            <Select
                                                value={value}
                                                className={classes.field}
                                                onChange={isPnSoundField ? this.onChangePNSound.bind(this) : (event) => this.onChangeDefaultSelectField(event, field)}
                                                inputProps={{
                                                    name: field.id,
                                                    id: 'user-lang',
                                                }}>
                                                {field.options.map(option => (
                                                    <MenuItem key={option} value={option}>{t(option)}</MenuItem>
                                                ))}
                                            </Select>
                                            {isPnSoundField && <Fragment>
                                                {isSoundPlaying ? <IconButton className={classes.soundActionButton} onClick={this.onStopSound.bind(this)}>
                                                    <ReactSVG src={"/assets/light/stop_circle.svg"} />
                                                </IconButton>
                                                    : <IconButton className={classes.soundActionButton} onClick={this.onPlaySound.bind(this)}>
                                                        <ReactSVG src={"/assets/light/play_circle.svg"} />
                                                    </IconButton>}
                                            </Fragment>}
                                        </Grid>
                                    </FormControl>
                                } else {
                                    return <TextValidator
                                        className={classes.field}
                                        key={field.id}
                                        value={value}
                                        onChange={(event) => {
                                            let _params = params
                                            _params[field.id] = event.target.value
                                            this.setState({ params: _params, errorMessage: null })
                                        }}
                                        margin="dense"
                                        id={field.id}
                                        multiline={field.type === 'Text' ? true : false}
                                        label={t(field.title)}
                                        validators={field.validators}
                                        errorMessages={field.errorMessages.map(message => t(message))}
                                        fullWidth
                                    />
                                }
                            })}

                            {errorMessage && <DialogContentText className={classes.errorText}>
                                {errorMessage}
                            </DialogContentText>}
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button disabled={loading} type="submit" color="primary">
                                {t('save')}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        )
    }
}


EditProfile.propTypes = {
    userService: PropTypes.any.isRequired,
    userId: PropTypes.string,
    open: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}


const styles = theme => ({
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20
    },
    field: {
        marginTop: 10,
        marginBottom: 10
    },
    selectFieldContainer: {
        alignItems: 'center',
        '& div': {
            flex: 1
        }
    },
    soundActionButton: {
        width: 36,
        height: 36,
        marginLeft: theme.spacing(2),
        '& span > div': {
            width: 36,
            height: 36
        }
    },
})


export default withTranslation()(withStyles(styles)(EditProfile))