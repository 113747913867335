import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { withRouter, NavLink } from 'react-router-dom'
import { List, ListItem, ListItemText, Drawer, Divider } from '@material-ui/core'

export const DRAWER_WIDTH = 280
export const MOBILE_MENU_BREAKPOINT = 'md'

function DrawerMenu({ open, onOpen, onClose, history, location, match, children }) {
	const classes = useStyles()
	const { t } = useTranslation()

	const toggleDrawer = (open) => event => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}
		if (open) {
			onOpen()
		} else {
			onClose()
		}
	}

	const drawer = (
		<div
			className={classes.drawer}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}>
			<List className={classes.drawerList}>
				{children}
			</List>
		</div>
	)

	return (
		<nav className={classes.drawer} >
			<Drawer
				variant="temporary"
				open={open}
				classes={{
					root: classes.menuMobile,
					paper: classes.drawerPaper,
				}}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				open={false}
				classes={{
					root: classes.desktopMenu,
					paper: [classes.drawerPaper, classes.drawerPaperDesktop].join(' '),
				}}>
				{drawer}
			</Drawer>
		</nav>
	)
}


const useStyles = makeStyles(theme => ({
	drawer: {
		[theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
			width: DRAWER_WIDTH,
			flexShrink: 0,
		},
	},

	drawerPaper: {
		width: DRAWER_WIDTH,
		backgroundColor: theme.palette.common.black,

		color: theme.palette.primary.contrastText
	},
	drawerPaperDesktop: {
		marginTop: theme.spacing(6),
	},
	menuMobile: {
		[theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
			display: 'none'
		}
	},
	desktopMenu: {
		display: 'none',
		[theme.breakpoints.between(MOBILE_MENU_BREAKPOINT, 'xl')]: {
			display: 'block'
		}
	},
	divider: {
		backgroundColor: 'rgba(255,255,255,0.2)'
	},
}))

export default withRouter(DrawerMenu)