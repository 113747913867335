import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { ThemeProvider } from '@material-ui/styles'
import { lightTheme, DefaultStyles } from '../../../theme'
import { getPath } from '../App'
import { PageContext } from '../Context/PageProvider'
import { Toolbar, IconButton, Typography, ListItemIcon, Menu, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core'
import DateRangePicker from '../../../Libs/Components/DateRangePicker'
import HttpCallbackModal from './HttpCallbackModal'
import RotationModalDialog from './RotationModalDialog'
import { downloadCsvFile } from '../../../Libs/Utilities/CSVExport'
import MenuIcon from '@material-ui/icons/Menu'
import MoreIcon from '@material-ui/icons/MoreVert'
import DownloadIcon from '@material-ui/icons/GetApp'
import HttpIcon from '@material-ui/icons/Http'
import ReactSVG from 'react-svg'
import LayersIcon from '@material-ui/icons/Layers'
import ThemesModal from './Themes/ThemesModal'

let moment = require('moment')

function RootOrgToolbar({ countRootOrgs, rootEntity, groupId, history }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const context = useContext(PageContext)
    const [menuAnchorEl, setMenuAnchorEl] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [exportModalVisible, setExportModalVisible] = useState(false)
    const [exportDetails, setExportDetails] = useState(false)
    const [httpCallbackDialogVisible, setHttpCallbackDialogVisible] = useState(false)
    const [rotationModalDialogVisible, setRotationModalDialogVisible] = useState(false)
    const [themesModalVisible, setThemesModalVisible] = useState(false)

    return (
        <Toolbar className={classes.toolbarDark}>
            { countRootOrgs > 1 && <IconButton edge="start" className={classes.menuButton} onClick={() => history.push(`${getPath()}`)}>
                <MenuIcon />
            </IconButton> }
            <ReactSVG className={classes.logo} src="/assets/logo.svg" />
            {/* <Typography variant="subtitle1" color={'inherit'} className={classes.toolbarTitle}>
                { rootEntity && rootEntity.getName() }
            </Typography> */}
            <IconButton 
                className={classes.moreIconButton} 
                size={'small'} 
                onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                edge="end">
                <MoreIcon />
            </IconButton>
            <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}>
                <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setExportModalVisible(true)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <DownloadIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('export_usage_reports')}</Typography>
                </MenuItem>
                <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setHttpCallbackDialogVisible(true)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <HttpIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('http_callback')}</Typography>
                </MenuItem>
                <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setRotationModalDialogVisible(true)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <ReactSVG className={classes.menuIcon} src="/assets/presentation_start.svg" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('rotation_mode')}</Typography>
                </MenuItem>
                <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setThemesModalVisible(true)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <LayersIcon className={classes.menuIcon} />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('themes')}</Typography>
                </MenuItem>
            </Menu>
            <ThemeProvider theme={lightTheme}>
                <DateRangePicker
                    open={exportModalVisible}
                    loading={loading}
                    title={t('usage_report_export_modal_title')}
                    description={t('usage_report_export_modal_description')}
                    onSubmitLabel={t('export')}
                    value={[moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]}
                    footerContent={
                        <FormControlLabel
                            style={{flex:1, fontSize: 10}}
                            control={
                                <Checkbox
                                    checked={exportDetails}
                                    onChange={(event) => setExportDetails(event.target.checked)}/>
                            }
                            label={t('detailed')}/>
                    }
                    onClose={() => setExportModalVisible(false)}
                    onSubmit={ async (dates) => {
                        setLoading(true)
                        const start = dates[0].clone().startOf('day').toDate()
                        const end = dates[1].clone().endOf('day').toDate()
                        let queryParams = null
                        if (exportDetails) {
                            queryParams = { detailed: true }
                        }
                        try {
                            const csvString = await context.api.appMessageService.getTxCountByOrg(rootEntity.getId(), start, end, queryParams )
                            const fileName = t('usage_report_export_file', { org: rootEntity.getName(), start: `${start.getFullYear()}-${start.getMonth()+1}-${start.getDate()}`, end: `${end.getFullYear()}-${end.getMonth()+1}-${end.getDate()}` })
                            downloadCsvFile(fileName, csvString)
                        } catch (error) {
                            console.log("[getTxCountByOrg] error: ", error)
                        }
                        setLoading(false)
                    }} />
            </ThemeProvider>
            { httpCallbackDialogVisible && <HttpCallbackModal open={true} rootOrgId={rootEntity.getId()} onClose={() => setHttpCallbackDialogVisible(false)}/> }
            { rotationModalDialogVisible && <RotationModalDialog open={true} rootOrgId={rootEntity.getId()} onClose={() => setRotationModalDialogVisible(false)}/> }
            { themesModalVisible && <ThemesModal org={rootEntity} open={true}  onClose={()=> setThemesModalVisible(false)} /> }
        </Toolbar>
    )
}

const useStyles = makeStyles(theme => ({
    toolbarDark: {
        color: '#fff',
        padding: 0,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    logo: {
        flex: 1,
        height: 36,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        '& svg': {
            width: 48,
            height: 36
        }
    },
    menuIcon: {
        height: 20,
        '& svg': {
            width: 20,
            height: 20
        }
    }
    // toolbarTitle: {
    //     flex: 1,
    //     fontWeight: 500,
    //     ...DefaultStyles.SingleLineLabel
    // },
}))

export default RootOrgToolbar