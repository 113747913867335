import Device from '../DeviceService/Models/Device';

const { getInvalidParameterError, ApiError } = require('../Errors');

const API_SERVICE_KEY_CODE_DRIVER = 'drv-code';
export const CODE_DEVICE_DRIVER_NAME = 'code';
export const DEFAULT_EVENT_NAME = 'TRIGGER1';
export const SETTINGS_CODE_TOKEN = `drv.code.token`;

export default class CodeDriver {
  constructor(api, apiCache) {
    this.api = api;
    this.apiCache = apiCache;
  }

  /**
   * Register code with Org namespace and code
   * @param {String} nameSpace
   * @param {String} code
   * @param {String} token
   * @returns {Uid}
   */
  registerCode(nameSpace, code, token = null) {
    const url = this.api.buildRequestURL('/:serviceKey/api/:namespace/:code', {
      ':serviceKey': API_SERVICE_KEY_CODE_DRIVER,
      ':namespace': nameSpace,
      ':code': code,
    });
    const body = { token };
    return this.api.request('POST', url, body).then(result => {
      const createdDevice = new Device(result);
      this.apiCache.add(createdDevice);
      return createdDevice;
    });
  }

  /**
   * Emit event with Org namespace, code, eventName, fields
   * @param {String} nameSpace
   * @param {String} code
   * @param {String} eventName
   * @param {String} token - Optional
   * @param {String} data0 - Optional
   * @param {String} data1 - Optional
   * @param {String} fields - Optional
   * @returns {Boolean}
   */
  emitEvent(nameSpace, code, eventName, token = null, data0 = null, data1 = null, fields = null) {
    const url = this.api.buildRequestURL('/:serviceKey/api/:namespace/:code/event', {
      ':serviceKey': API_SERVICE_KEY_CODE_DRIVER,
      ':namespace': nameSpace,
      ':code': code,
    });

    const body = { eventName, data0, data1, token, fields };
    return this.api.request('POST', url, body).then(result => result.done);
  }

  /**
   * Emit event with Org namespace, code, eventName
   * @param {String} nameSpace
   * @param {String} code
   * @param {String} eventName
   * @param {String} token - Optional
   * @param {String} data0 - Optional
   * @param {String} data1 - Optional
   * @returns {Boolean}
   */
  emitEventGet(nameSpace, code, eventName, token = null, data0 = null, data1 = null) {
    const url = this.buildEventGetUrl(nameSpace, code, eventName, token, data0, data1);
    return this.api.request('GET', url, null).then(result => result.done);
  }

  /**
   * Build event url with Org namespace, code, eventName
   * @param {String} nameSpace
   * @param {String} code
   * @param {String} eventName
   * @param {String} token - Optional
   * @param {String} data0 - Optional
   * @param {String} data1 - Optional
   * @returns {String}
   */
  buildEventGetUrl(nameSpace, code, eventName, token = null, data0 = null, data1 = null) {
    const url = this.api.buildRequestURL(
      '/:serviceKey/api/:namespace/:code/event',
      {
        ':serviceKey': API_SERVICE_KEY_CODE_DRIVER,
        ':namespace': nameSpace,
        ':code': code,
      },
      {
        eventName: eventName,
        token: token,
        data0: data0,
        data1: data1,
      },
    );
    return url;
  }
}
