import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withDarkTheme } from '../../../theme'
import { Button, TextField, Typography, Grid, InputAdornment, IconButton, Link } from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { withTranslation } from 'react-i18next'
import Config from '../../../config/Config'
import Logo from '../../../Libs/Components/Logo'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import Footer from '../../../Libs/Components/Footer'
import { isNotFoundError, isInvalidCodeError, isMultiFactorRequiredError } from '../../../m2m-cloud-api/Api/Helper'
import { ORG_TAG } from '../../../m2m-cloud-api'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import { getURLParameters } from '../../../Helper'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const nl2br = require('react-nl2br')

class Authentification extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            showPasswordResetEmailSentMessage: false,
            showRegisterEmailSentMessage: false,
            showTwoFactorAuthLoginPage: false,
            showRecoveryCodeLoginPage: false,
            loginData: {
                username: '', //mail,
                password: '', //pw,
                authCode: '',
                recoveryCode: ''
            },
            errorMessage: null,
            showPassword: false
        }
    }

    handleKeyPress(event) {
        if (event.charCode === 13) { // enter key
            event.preventDefault()
            this.handleSubmit()
        }
    }

    handleSubmit() {
        const { t } = this.props
        this.setState({ loading: true })
        const { username, password, authCode, recoveryCode } = this.state.loginData
        const mfaCode = authCode ? authCode : recoveryCode ? recoveryCode : null
        const mfaType = recoveryCode ? 'recovery' : 'totp'

        this.props.context.userService.loginUser(username, password, mfaCode, mfaType)
            .then(async user => {
                const rootOrgs = await this.props.context.orgService.searchOrg({ tags: [ORG_TAG.ROOT] })
                if (rootOrgs && rootOrgs.length === 0) {
                    window.location.href = Config.paths['messagelog-admin']
                } else {
                    const urlParams = getURLParameters()
                    if (urlParams.redirect) {
                        window.location.href = urlParams.redirect
                    } else {
                        this.props.history.push('/')
                    }
                }
            })
            .catch(error => {
                let message = mapErrorMessage(error)
                let isMultiFactorRequired = isMultiFactorRequiredError(error)

                if (isNotFoundError(error)) {
                    message = t('error_user_not_found')
                } else if (isInvalidCodeError(error) && (authCode || recoveryCode)) {
                    message = t('invalid_code')
                    isMultiFactorRequired = true
                } else if (isInvalidCodeError(error)) {
                    message = t('error_wrong_password')
                }
                this.setState({
                    errorMessage: isMultiFactorRequired ? message === t('invalid_code') ? message : null : message,
                    loading: false,
                    showTwoFactorAuthLoginPage: recoveryCode ? false : isMultiFactorRequired
                })
            })
    }

    handleResetPassword(event) {
        const { t } = this.props
        this.setState({ loading: true })

        this.props.context.userService.requestPasswordResetByMail(this.state.loginData.username)
            .then(result => {
                this.setState({ errorMessage: null, loading: false, showPasswordResetEmailSentMessage: true })
            })
            .catch(error => {
                let message = mapErrorMessage(error)
                if (isNotFoundError(error)) {
                    message = t('error_user_not_found')
                }
                this.setState({ errorMessage: message, loading: false })
            })
    }

    handleChange(event) {
        const { loginData } = this.state
        loginData[event.target.name] = event.target.value
        this.setState({ loginData, errorMessage: null })
    }

    handleShowForgotPassword() {
        const { loginData } = this.state
        loginData.password = ''
        this.setState({ passwordResetPage: true, errorMessage: null, loginData })
    }

    handleShowLogin() {
        const { loginData } = this.state
        loginData.password = ''
        loginData.recoveryCode = ''
        loginData.authCode = ''
        this.setState({ passwordResetPage: false, errorMessage: null, loginData, showTwoFactorAuthLoginPage: false, showRecoveryCodeLoginPage: false })
    }

    handleLoginRecoveryCode() {
        const { loginData } = this.state
        loginData.authCode = null
        this.setState({ showRecoveryCodeLoginPage: true, showTwoFactorAuthLoginPage: false, errorMessage: null, loginData })
    }

    onFocusInput() {
        this.setState({ errorMessage: null })
    }

    renderLogin() {
        const classes = this.props.classes
        const usernameKey = "username"
        const passwordKey = "password"
        const { showPassword } = this.state

        return (
            <Fragment>
                <Typography variant="h4" color="secondary" className={classes.title}>
                    {this.props.t('login')}
                </Typography>
                <ValidatorForm
                    ref="form"
                    className={classes.form}
                    onSubmit={this.handleSubmit.bind(this)}
                    onError={errors => console.log(errors)}
                >
                    <TextValidator
                        id={usernameKey}
                        key={usernameKey}
                        name={usernameKey}
                        label={this.props.t('email')}
                        className={classes.textField}
                        value={this.state.loginData.username}
                        onKeyPress={this.handleKeyPress.bind(this)}
                        onChange={this.handleChange.bind(this)}
                        validators={['required', 'isEmail']}
                        fullWidth={true}
                        errorMessages={[]}
                    />
                    <TextField
                        id={passwordKey}
                        key={passwordKey}
                        name={passwordKey}
                        type={showPassword ? 'text' : 'password'}
                        label={this.props.t('password')}
                        className={classes.textField}
                        value={this.state.loginData.password}
                        onKeyPress={this.handleKeyPress.bind(this)}
                        onChange={this.handleChange.bind(this)}
                        validators={['required']}
                        fullWidth={true}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        size={'small'}
                                        aria-label="toggle password visibility"
                                        onClick={() => this.setState({ showPassword: !showPassword })}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />

                    {this.state.errorMessage && (
                        <Typography variant="body1" color="error" gutterBottom>
                            {this.state.errorMessage}
                        </Typography>
                    )}

                    <Button disabled={this.state.loading || this.state.loginData.username.trim() === "" || this.state.loginData.password.trim() === ""} onClick={this.handleSubmit.bind(this)} color="primary" variant="contained" className={classes.submitButton}>
                        {this.props.t('login')}
                    </Button>

                    <Typography gutterBottom className={classes.plainButton} onClick={this.handleShowForgotPassword.bind(this)} color="secondary" variant="body1" gutterBottom>
                        {this.props.t('forgot_password')}
                    </Typography>
                    <Typography gutterBottom className={classes.plainButton} onClick={() => this.props.history.push(`/signup${window.location.search || ''}`)} color="secondary" variant="body1" gutterBottom>
                        {this.props.t('signup')}
                    </Typography>

                </ValidatorForm>
            </Fragment>
        )
    }

    renderForgotPassword() {
        const classes = this.props.classes
        const usernameKey = "username"

        return (
            <Fragment>
                <Typography variant="h4" color="secondary" className={classes.title}>
                    {nl2br(this.props.t('forgot_your_password_title'))}
                </Typography>

                <Typography className={classes.subTitle} variant="body2" color="textSecondary">
                    {this.props.t('forgot_your_password_description')}
                </Typography>

                <ValidatorForm
                    ref="form"
                    className={classes.form}
                    onSubmit={this.handleSubmit.bind(this)}
                    onError={errors => console.log(errors)}
                >
                    <TextValidator
                        id={usernameKey}
                        key={usernameKey}
                        name={usernameKey}
                        label={this.props.t('email')}
                        className={classes.textField}
                        value={this.state.loginData.username}
                        onKeyPress={this.handleKeyPress.bind(this)}
                        onChange={this.handleChange.bind(this)}
                        validators={['required', 'isEmail']}
                        fullWidth={true}
                        errorMessages={[]}
                    />

                    {this.state.errorMessage && (
                        <Typography variant="body1" color="error" gutterBottom>
                            {this.state.errorMessage}
                        </Typography>
                    )}

                    <div className={classes.buttonContainer}>
                        <Button onClick={this.handleResetPassword.bind(this)} disabled={this.state.loading || this.state.loginData.username.trim() === ""} color="primary" variant="contained" className={classes.submitButton}>
                            {this.props.t('reset_password')}
                        </Button>
                        <Typography className={classes.backButton} onClick={this.handleShowLogin.bind(this)} color="secondary" variant="body1" gutterBottom>
                            {this.props.t("back")}
                        </Typography>
                    </div>
                </ValidatorForm>
                <AlertDialog
                    open={this.state.showPasswordResetEmailSentMessage}
                    title={this.props.t('password_reset_email_sent_message_title')}
                    message={this.props.t('password_reset_email_sent_message_description')}
                    onSubmit={() => this.props.history.push(`/password-reset${window.location.search || ''}`, { email: this.state.loginData.username })} />
            </Fragment>
        )
    }

    renderTwoFactorAuthLogin() {
        const classes = this.props.classes
        const authCode = "authCode"

        return (
            <Fragment>
                <Typography variant="h4" color="textPrimary" className={classes.title}>
                    {nl2br(this.props.t('multi_factor_authentication_title'))}
                </Typography>

                <ValidatorForm
                    ref="form"
                    className={classes.form}
                    onSubmit={this.handleSubmit.bind(this)}
                    onError={errors => console.log(errors)}
                >
                    <TextValidator
                        id={authCode}
                        key={authCode}
                        name={authCode}
                        label={this.props.t('authentication_code')}
                        helperText={this.props.t('6_digit_code')}
                        className={classes.textField}
                        value={this.state.loginData.authCode}
                        onKeyPress={this.handleKeyPress.bind(this)}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.onFocusInput.bind(this)}
                        validators={['required']}
                        fullWidth={true}
                        errorMessages={[]}
                    />

                    <Typography className={classes.subTitle} variant="body2" color="textSecondary">
                        {this.props.t('multi_factor_authentication_description')}
                    </Typography>

                    {this.state.errorMessage && (
                        <Typography variant="body1" color="error" gutterBottom>
                            {this.state.errorMessage}
                        </Typography>
                    )}

                    <Typography variant="body2" color="textSecondary">
                        <Link component={'button'} variant={'body2'} onClick={this.handleLoginRecoveryCode.bind(this)}>
                            {this.props.t('login_with_recovery_code')}
                        </Link>
                    </Typography>

                    <div className={classes.buttonContainer}>
                        <Button onClick={this.handleSubmit.bind(this)} disabled={this.state.loading || this.state.loginData.authCode?.trim() === ""} color="primary" variant="contained" className={classes.submitButton}>
                            {this.props.t('verify')}
                        </Button>
                        <Typography className={classes.backButton} onClick={this.handleShowLogin.bind(this)} color="secondary" variant="body1" gutterBottom>
                            {this.props.t("cancel")}
                        </Typography>
                    </div>
                </ValidatorForm>
            </Fragment>
        )
    }

    renderRecoveryCodeLogin() {
        const classes = this.props.classes
        const authCode = "recoveryCode"

        return (
            <Fragment>
                <Typography variant="h4" color="textPrimary" className={classes.title}>
                    {nl2br(this.props.t('recovery_code_title'))}
                </Typography>

                <ValidatorForm
                    ref="form"
                    className={classes.form}
                    onSubmit={this.handleSubmit.bind(this)}
                    onError={errors => console.log(errors)}
                >
                    <TextValidator
                        id={authCode}
                        key={authCode}
                        name={authCode}
                        label={this.props.t('recovery_code')}
                        helperText={this.props.t('16_digit_code')}
                        className={classes.textField}
                        value={this.state.loginData.recoveryCode}
                        onKeyPress={this.handleKeyPress.bind(this)}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.onFocusInput.bind(this)}
                        validators={['required']}
                        fullWidth={true}
                        errorMessages={[]}
                    />

                    <Typography className={classes.subTitle} variant="body2" color="textSecondary">
                        {this.props.t('recovery_code_description')}
                    </Typography>

                    {this.state.errorMessage && (
                        <Typography variant="body1" color="error" gutterBottom>
                            {this.state.errorMessage}
                        </Typography>
                    )}

                    <div className={classes.buttonContainer}>
                        <Button onClick={this.handleSubmit.bind(this)} disabled={this.state.loading || this.state.loginData.recoveryCode?.trim() === ""} color="primary" variant="contained" className={classes.submitButton}>
                            {this.props.t('verify')}
                        </Button>
                        <Typography className={classes.backButton} onClick={this.handleShowLogin.bind(this)} color="secondary" variant="body1" gutterBottom>
                            {this.props.t("cancel")}
                        </Typography>
                    </div>
                </ValidatorForm>
            </Fragment>
        )
    }


    render() {
        const classes = this.props.classes

        return (
            <Grid container className={classes.container}>
                <Grid item sm={6} xs={12} className={classes.leftColumn}>
                    <Logo />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.rightColumn}>
                    <div className={classes.inputContainer}>
                        {!this.state.passwordResetPage && !this.state.showTwoFactorAuthLoginPage && !this.state.showRecoveryCodeLoginPage && this.renderLogin()}
                        {this.state.passwordResetPage && this.renderForgotPassword()}
                        {this.state.showTwoFactorAuthLoginPage && this.renderTwoFactorAuthLogin()}
                        {this.state.showRecoveryCodeLoginPage && this.renderRecoveryCodeLogin()}
                    </div>
                    <Footer />
                </Grid>
            </Grid>
        )
    }

}


const styles = theme => ({
    container: {
        height: '100%',
        alignItems: 'center',
    },
    leftColumn: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        }
    },
    rightColumn: {
        backgroundColor: 'black',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            paddingTop: theme.spacing(5)
        }
    },
    inputContainer: {
        maxWidth: 260,
        width: '100%'
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        marginBottom: theme.spacing(4)
    },
    subTitle: {
        marginBottom: theme.spacing(4),
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    backButton: {
        marginTop: 10,
        cursor: 'pointer',
        textTransform: 'uppercase'
    },
    plainButton: {
        textAlign: 'center',
        marginTop: 10,
        cursor: 'pointer',
        textTransform: 'uppercase'
    },
    submitButton: {
        flex: 1,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
})

export default withTranslation()(withDarkTheme(withStyles(styles)(Authentification)))