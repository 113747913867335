export default class MessageDefinition {
  /**
   * @param {Object<Uid,Uid,Uid,Uid,Uid,Array<String>,Array<String>>}
   */
  constructor({ definitionId, deviceId, actionId, templateId, orgId, events, params, log2phyMappingCreated = null, phy2logMappingCreated = null }) {
    this.definitionId = definitionId;
    this.deviceId = deviceId;
    this.actionId = actionId;
    this.templateId = templateId;
    this.orgId = orgId;
    this.events = events;
    this.params = params;
    this.log2phyMappingCreated = log2phyMappingCreated;
    this.phy2logMappingCreated = phy2logMappingCreated;
  }

  /**
   * Get id
   * @returns {Uid}
   */
  getId() {
    return this.definitionId;
  }

  /**
   * Get device id
   * @returns {Uid}
   */
  getDeviceId() {
    return this.deviceId;
  }

  /**
   * Get action id
   * @returns {Uid}
   */
  getActionId() {
    return this.actionId;
  }

  /**
   * Get template id
   * @returns {Uid}
   */
  getTemplateId() {
    return this.templateId;
  }

  /**
   * Get org id
   * @returns {Uid}
   */
  getOrgId() {
    return this.orgId;
  }

  /**
   * Get events
   * @returns {Array<String>}
   */
  getEvents() {
    return this.events;
  }

  /**
   * Get params
   * @returns {Array<Object>}
   */
  getParams() {
    return this.params;
  }

  /**
   * Get param
   * @param {String} name
   * @returns {String}
   */
  getParam(name) {
    return this.params && this.params[name];
  }

  /**
   * Set params
   * @param {Array<String>} params
   * @returns {String}
   */
  setParam(params) {
    this.params = params;
  }

  /**
   * Set param
   * @param {String} name
   * @returns {String}
   */
  setParam(name, value) {
    if (!this.params) this.params = [];
    this.params[name] = value;
  }
}
