const configJSON = require('./default.json')

let Config = configJSON
try {
    if (window && window.config) {
        Config = window.config
    }
} catch (error) {
}

const SERVICES = Config.web_services
const hostname = window.location.hostname // window.location.hostname // www-dev.mbsn.io, www-rad.mbsn.io

let service = SERVICES.find( service => {
    let matched = false
    for (let index = 0; index < service.domains.length; index++) {
        const domain = service.domains[index]
        if ( domain.substr(0,1) === '.') {
            matched = domain.substr(1) === hostname || domain ===  hostname.substr( hostname.length - domain.length )
        } else {
            matched = hostname === domain
        }
        if (matched) return true
    }
    return false
})
if (!service) {
    service = SERVICES.find( service => service.fallback )
}

service.hostname = hostname

export default service
