import React, { useContext, useCallback, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Typography, ListItem, ListItemText, CircularProgress, ListItemIcon, IconButton, Tooltip } from '@material-ui/core'
import { ApiContext, APP_MODE } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { REPLACEMENT_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import { getDateFormat } from '../../../Libs/Utilities/DateHelper'
import { theme } from '../../../theme'
import ReactSVG from 'react-svg'
import clsx from 'clsx'
import moment from 'moment'
import { PARAM_ON_HOLD } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Message'
import { MESSAGE_TYPE, mapReplacements, fetchItemDisplayOrgNames } from './MessageList'

const IMAGE_ICON_SIZE = 15
const LIST_ITEM_STATUS_ICON_SIZE = 24

const ITEM_FONT_SIZES = {
    1: theme.font.small,
    2: theme.font.medium,
    3: theme.font.large
}

const CheckIcon = ({ message, processingMessage }) => {
    const classes = useStyles()
    if (processingMessage && processingMessage.getId() === message.getId()) {
        return (
            <div className={classes.loadingCheckIcon} >
                <ReactSVG src="/assets/light/icon-check-empty.svg" />
                <div className={classes.loadingCheckIconLoaderContainer}>
                    <CircularProgress className={classes.loadingCheckIconLoader} />
                </div>
            </div>
        )
    } else {
        return (
            <Fragment>
                {(!message.isProcessing() && !message.isAcknowledged()) && <ReactSVG src="/assets/light/icon-light-job-processing.svg" />}
                {message.isProcessing() && <ReactSVG src="/assets/light/icon-light-job-done.svg" />}
            </Fragment>
        )
    }
}

const OrgName = ({ t, message, messageType, size, bold }) => {
    const classes = useStyles()
    const context = useContext(ApiContext)

    const fontSize = ITEM_FONT_SIZES[size] + (context.userFontSize || 0)
    const displayOrg = context.fetchKnownOrg(messageType === MESSAGE_TYPE.RECIPIENT ? message.getDefinitionOrgId() : message.getDeviceAssignedOrgId())
    const orgNames = fetchItemDisplayOrgNames(context, displayOrg)

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {message.getPriority() && message.getPriority() > 1 && <Tooltip title={message.getPriority() === 3 ? t('highest_priority') : t('high_priority')}>
                <Typography
                    component="div"
                    variant="body2"
                    style={{ fontSize: fontSize, fontWeight: bold ? 'bold' : 'normal' }}
                    className={message.getPriority() === 3 ? [classes.listBoxItemPriority, classes.listBoxItemPriorityHighest].join(' ') : classes.listBoxItemPriority}
                    color="textPrimary">
                    {message.getPriority() === 3 ? '!!' : '!'}
                </Typography>
            </Tooltip>}
            {orgNames.length > 0 && <Typography
                component="div"
                variant="body2"
                style={{ fontSize: fontSize, fontWeight: bold ? 'bold' : 'normal' }}
                className={classes.listBoxItemRecipient}>
                {orgNames.join(' | ')}
            </Typography>}
        </div>
    )
}

const MetaData = ({ message, publicUsers }) => {
    const classes = useStyles()
    const context = useContext(ApiContext)

    const isOpen = !message.isProcessing() && !message.isAcknowledged()
    const isProcessing = message.isProcessing()
    const isCompleted = message.isAcknowledged()
    const isHold = message.getParam(PARAM_ON_HOLD) === 'true' ? true : false

    const ordererUserId = message.getTriggeringUserId()
    const processingUserId = message.isProcessing() ? message.getProcessingUserId() : message.getAcknowledgedUserId()
    let ordererUser = ordererUserId && publicUsers && publicUsers.find(publicUser => publicUser.getId() === ordererUserId)
    let processingUser = processingUserId && publicUsers && publicUsers.find(publicUser => publicUser.getId() === processingUserId)
    if (!ordererUser && ordererUserId) {
        ordererUser = context.getPublicUser(ordererUserId)
    }
    if (!processingUser && processingUserId) {
        processingUser = context.getPublicUser(processingUserId)
    }

    const nickname = processingUser ? processingUser.getNickname() : ordererUser ? ordererUser.getNickname() : null
    const ackedUserId = message.getAcknowledgedUserId()
    const ackedUser = ackedUserId && publicUsers && publicUsers.find(publicUser => publicUser.getId() === ackedUserId)
    const startDate = isProcessing ? message.getProcessingDate() : message.getCreatedDate()
    const endDate = message.getAcknowledgedDate() ? message.getAcknowledgedDate() : null

    return (
        <div className={classes.listItemRightContent}>
            {!isHold && isOpen && <ReactSVG src={"/assets/light/icon-light-job-added-filled.svg"} className={classes.listItemStatusIcon} />}
            {!isHold && isProcessing && <ReactSVG src={"/assets/light/icon-light-job-processing.svg"} className={classes.listItemStatusIcon} style={{ backgroundColor: '#e63a1b', color: '#fff' }} />}
            {isHold && <ReactSVG src={"/assets/job_on_hold_filled.svg"} className={classes.listItemStatusIcon} style={{}} />}
            {isCompleted && <ReactSVG src={"/assets/light/icon-light-job-done.svg"} className={classes.listItemStatusIcon} style={{ backgroundColor: '#e63a1b', color: '#fff' }} />}

            {nickname && <Typography className={classes.listBoxItemDate} >{`${nickname}`}</Typography>}
            {startDate &&
                <Typography className={classes.listBoxItemDate} >
                    {moment(startDate).format(getDateFormat(startDate)) + (endDate ? ` - ${moment(endDate).format(getDateFormat(endDate))}` : ackedUser ? ' - ' : '')}
                </Typography>
            }
            {ackedUser && <Typography className={classes.listBoxItemDate} >{`${ackedUser.getNickname()}`}</Typography>}
        </div>
    )
}

const Title = ({ title, size, bold }) => {
    const classes = useStyles()
    const context = useContext(ApiContext)
    const fontSize = ITEM_FONT_SIZES[size] + (context.userFontSize || 0)

    return (
        <Typography
            component="div"
            variant="body2"
            style={{ fontSize, fontWeight: bold ? 'bold' : 'normal' }}
            className={classes.listBoxItemTitle}>
            {title}
        </Typography>
    )
}


const Replacements = ({ t, replacements, message, messageType, size, bold }) => {
    const classes = useStyles()
    const context = useContext(ApiContext)

    const changedParamValues = context.getChangedParamValues(message.getId())
    const fontSize = ITEM_FONT_SIZES[size] + (context.userFontSize || 0)
    const imageIconSize = IMAGE_ICON_SIZE + (context.userFontSize || 0)
    const filteredReplacements = message.isAcknowledged() ? replacements : replacements.filter(replacement => replacement.replacement.getType() !== REPLACEMENT_TYPE.REPORT && replacement.value !== '')

    return (
        <Fragment>
            <Typography component="div" variant="body2" className={clsx(classes.listBoxItemLine, classes.messageListBoxItemLine)}>
                {filteredReplacements.map((replacement, index) => {
                    const isSupplierReplacement = replacement.replacement.desc?.serviceProvider ? true : false
                    const dontRenderReplacement = (isSupplierReplacement && messageType !== MESSAGE_TYPE.SUPPLIER) || (!replacement.value || replacement.value === '') ? true : false
                    const isEditable = messageType === MESSAGE_TYPE.SUPPLIER && message.isProcessing() && replacement.replacement.getType() === REPLACEMENT_TYPE.INTEGER && replacement.replacement.desc?.editable
                    const changedParamValue = changedParamValues && changedParamValues[replacement.replacement.getId()]
                    const reportReplacement = replacement.replacement.getType() === REPLACEMENT_TYPE.REPORT && message.isAcknowledged() ? replacement.value : null
                    const isNoteType = replacement.replacement.getType() === REPLACEMENT_TYPE.NOTE
                    if (isNoteType) return null
                    if (dontRenderReplacement) return null
                    return (
                        (
                            <Fragment key={replacement.replacement.getId()}>
                                {replacement.replacement.getType() === REPLACEMENT_TYPE.IMAGE ?
                                    (replacement.value && replacement.value !== '') ? <IconButton size="small" className={classes.listImageButton}>
                                        <ReactSVG src={"/assets/photo.svg"} style={{ height: imageIconSize, display: 'inline-flex', marginTop: -2 }} />
                                    </IconButton> : null
                                    :
                                    <Typography
                                        component="span"
                                        variant="body1"
                                        onClick={() => isEditable && this.setState({ editableReplacement: { replacement, message } })}
                                        className={clsx(classes.listBoxItemLineValue, classes.listItemBadge, isEditable && classes.listBoxItemLineEditable)}
                                        style={{
                                            textDecoration: changedParamValue && 'line-through',
                                            fontSize: fontSize,
                                            fontWeight: bold ? 'bold' : 'normal'
                                        }}>
                                        {reportReplacement ? JSON.parse(reportReplacement)?.text : replacement.value}
                                    </Typography>}
                                {changedParamValue && <Typography
                                    component="span"
                                    variant="body1"
                                    style={{ fontSize: fontSize, fontWeight: bold ? 'bold' : 'normal' }}
                                    className={classes.listBoxItemLineValue}>
                                    {` → ${changedParamValue}`}
                                </Typography>}
                            </Fragment>
                        )
                    )
                })}
            </Typography>
            {(message.getAcknowledgedReport() && message.getAcknowledgedReport().text) && <Typography component="div" variant="body2" className={classes.badge}>
                <Typography
                    component="span"
                    variant="body2"
                    style={{ fontSize: fontSize, fontWeight: bold ? 'bold' : 'normal' }}
                    className={classes.listBoxItemLineLabel}>
                    {t('report') + ': '}
                </Typography>
                <Typography
                    component="span"
                    variant="body2"
                    style={{ fontSize: fontSize, fontWeight: bold ? 'bold' : 'normal' }}
                    className={classes.listBoxItemLineValue}>
                    {message.getAcknowledgedReport().text}
                </Typography>
            </Typography>}
        </Fragment>
    )
}



const MessageListItem = ({ t, message, messageType, isSelected, onSelectMessage, publicUsers, processingMessage, onSetProcessingMessage }) => {
    const classes = useStyles()
    const context = useContext(ApiContext)
    const replacements = mapReplacements(context, message)

    const theme = context.getRootOrgTheme()
    let rows = theme?.rows || [
        { field: 'organization', size: 1, bold: false },
        { field: 'name', size: 3, bold: false },
        { field: 'parameters', size: 1, bold: false }
    ]

    const isOpen = !message.isProcessing() && !message.isAcknowledged()
    const isProcessing = message.isProcessing()
    const isCompleted = message.isAcknowledged()

    const startDate = isProcessing ? message.getProcessingDate() : message.getCreatedDate()
    const disabled = (processingMessage && processingMessage.getId() === message.getId()) || message.isAcknowledged()

    const renderListItemSecondary = (messageType === MESSAGE_TYPE.SUPPLIER || (messageType === MESSAGE_TYPE.RECIPIENT && startDate))
    const deleteOrderSupported = (messageType === MESSAGE_TYPE.SUPPLIER && message.isProcessing() && !message.isAcknowledged())

    const appMode = context.appMode
    const isHold = message.getParam(PARAM_ON_HOLD) === 'true' ? true : false

    const renderField = useCallback((row) => {
        if (!row) return (null)
        const { field, size, bold } = row
        switch (field) {
            case 'organization':
                return <OrgName message={message} messageType={messageType} t={t} size={size} bold={bold} />
            case 'name':
                return <Title title={message.getHeader()} size={size} bold={bold} />
            case 'parameters':
                return <Replacements t={t} replacements={replacements} message={message} messageType={messageType} size={size} bold={bold} />
            default:
                return (null)
        }
    }, [message])

    return (
        <Fragment key={message.getId()}>
            {messageType === MESSAGE_TYPE.SUPPLIER && <ListItemIcon className={classes.listItemIcon}>
                <div onClick={() => disabled || isHold ? null : onSetProcessingMessage(message)} className={disabled ? classes.listItemButtonDisabled : classes.listItemButton} >
                    <CheckIcon message={message} processingMessage={processingMessage} />
                </div>
            </ListItemIcon>}

            <ListItem className={[classes.listItem, isOpen && classes.listItemOpen, isProcessing && classes.listItemProcessing, isHold && classes.holdMessageItem, (appMode === APP_MODE.SUPPLIER && !isCompleted) && classes.listItemAction, isSelected ? classes.listItemActive : ''].join(' ')} style={!renderListItemSecondary ? { paddingTop: 6, paddingBottom: 6 } : {}} onClick={onSelectMessage}>
                <ListItemText
                    className={classes.listItemText}
                    disableTypography={true}
                    primary={
                        <Fragment>
                            {renderField(rows[0])}
                            <div className={classes.listItemInfoTextWrapper} style={{ paddingRight: deleteOrderSupported ? 50 : 0 }}>
                                <div style={{ flex: 1 }}>
                                    {renderField(rows[1])}
                                    {renderField(rows[2])}
                                </div>
                            </div>
                        </Fragment>
                    }
                />
                <MetaData message={message} publicUsers={publicUsers} />
            </ListItem>
        </Fragment >
    )

}

export default withTranslation()(MessageListItem)


const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        // whiteSpace: 'nowrap',
        backgroundColor: '#f6f6f6',
        transition: 'box-shadow .2s',
    },
    listItemOpen: {
        border: '1px solid rgba(26,26,26,.4)',
        backgroundColor: theme.palette.common.white,
        minHeight: 56,
    },
    listItemProcessing: {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.common.white,
        '& p': {
            color: theme.palette.primary.main
        },
        minHeight: 56,
    },
    listItemActive: {
        boxShadow: '0px 0px 12px rgba(0,0,0,.5)',
    },
    listItemAction: {
        paddingLeft: theme.spacing(7),
    },
    listItemButton: {
        cursor: 'pointer',
        width: 40,
        height: 40,
        position: 'relative',
        border: '1px solid #1a1a1a',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 0,
        '&:active': {
            backgroundColor: 'rgba(0,0,0,0.1)'
        },
        '& svg': {
            opacity: .35
        }
    },
    listItemButtonDisabled: {
        width: 40,
        height: 40,
        position: 'relative',
    },
    listBoxItemTitle: {
        fontSize: theme.font.large,
        fontWeight: 'normal',
        lineHeight: 1.2,
        marginBottom: 4
    },
    listBoxItemLine: {
        lineHeight: 1.2,
        flex: 1
    },
    listBoxItemLineLabel: {
        fontSize: theme.font.medium,
        fontWeight: '300',
        lineHeight: 1.2,
    },
    listBoxItemLineValue: {
        fontSize: theme.font.medium,
        fontWeight: '500',
        lineHeight: 1.2,
        marginBottom: theme.margin.small
    },
    listBoxItemLineEditable: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    listBoxItemRecipient: {
        fontSize: theme.font.extraSmall,
        lineHeight: 1.33,
        flex: 1,
        marginBottom: theme.margin.small
    },
    listBoxItemPriority: {
        fontSize: theme.font.medium,
        backgroundColor: theme.palette.primary.lightBadge,
        color: theme.palette.common.white,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingTop: 2,
        borderRadius: theme.shape.borderRadius,
        fontWeight: '500'
    },
    listBoxItemPriorityHighest: {
        backgroundColor: theme.palette.primary.main,
    },
    listBoxItemDate: {
        fontSize: theme.font.extraSmall,
        lineHeight: 1.33,
        fontWeight: 500
    },
    listItemBadge: {
        fontSize: theme.font.extraSmall,
        fontWeight: 'normal',
        lineHeight: 1,
        color: theme.palette.common.white,
        flexDirection: 'row',
        display: 'inline-flex',
        padding: '1.5px 4px',
        marginRight: 4,
        backgroundColor: '#1a1a1a !important'
    },
    listItemIcon: {
        alignSelf: 'flex-start',
        minWidth: 'unset',
        width: 40,
        height: 40,
        position: 'absolute',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        zIndex: 2
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0
    },
    listImageButton: {
        padding: 0,
        marginRight: 4
    },
    listImage: {
        width: 15,
        height: 15
    },
    holdMessageItem: {
        opacity: .5
    },
    loadingCheckIcon: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingCheckIconLoaderContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 40,
        height: 40,
    },
    loadingCheckIconLoader: {
        position: 'absolute',
        left: 6,
        top: 6,
        width: '24px !important',
        height: '24px !important',
    },
    listItemStatusIcon: {
        height: LIST_ITEM_STATUS_ICON_SIZE,
        position: 'absolute',
        top: 0,
        right: 0,
        '& > div': {
            height: LIST_ITEM_STATUS_ICON_SIZE
        }
    },
    listItemRightContent: {
        paddingTop: 20,
        alignSelf: 'flex-start',
        whiteSpace: 'nowrap',
        textAlign: 'right'
    },
    listItemInfoTextWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}))
