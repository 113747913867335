import React, {Fragment, useState, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../Context/PageProvider'
import { ListItem, ListItemSecondaryAction, IconButton, ListItemText, Box, Divider, Menu, MenuItem, ListItemIcon, Typography, Checkbox } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreIcon from '@material-ui/icons/MoreVert'
import LinkIcon from '@material-ui/icons/Link'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { PARAM_APP_MSG_CALLBACK_URL } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import ReactSVG from 'react-svg'

function RelacementsListItem({ entity, checked, onClick, onEditClick, onDeleteClick, badgeLabel, titleBadgeLabel,customSortEnabled, entityType, ...otherProps }) {
    const classes = useStyles()
    const [ menuAnchorEl, setMenuAnchorEl ] = useState(null)
    const { t } = useTranslation()
    const context = useContext(PageContext)

    const contextRootOrg = context.getSelectedRootOrg()
    const rootOrg = context.api.orgService.getOrgFromCache( contextRootOrg.getId() ) || contextRootOrg
    const copyUidEnabled = rootOrg.getParam(PARAM_APP_MSG_CALLBACK_URL) ? true : false

	return (
        <Fragment>
           <ListItem {...otherProps} className={classes.listItem}>
                {customSortEnabled && <ListItemIcon className={classes.dragListItemIcon}>
                    <ReactSVG src={"/assets/light/drag.svg"} className={classes.dragIcon} />
                </ListItemIcon>}
                <ListItemIcon style={{minWidth: 'auto'}}>
                    <Checkbox
                        edge="start"
                        color="primary"
                        checked={checked}
                        onClick={() => onClick(entity)}
                        tabIndex={-1}
                        inputProps={{ 'aria-labelledby': entity.id }}
                    />
                    </ListItemIcon>
                <ListItemText 
                className={classes.listItemText}
                primary={
                    <Typography variant="subtitle2">
                        <Box display={'flex'} alignItems={'center'}>
                            <div>{entity.title}</div>
                            {entityType && <Typography className={`${classes.listItemTextSecondary} entityType`} variant="subtitle2">{entityType}</Typography>}
                        </Box>
                        {titleBadgeLabel && <div className={classes.titleBadge}>{titleBadgeLabel}</div>}
                    </Typography>
                }
                secondary={badgeLabel && <Typography className={classes.listItemTextSecondary} variant="subtitle2">{badgeLabel}</Typography>} />
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>

                    <IconButton 
                        className={classes.moreIconButton} 
                        size={'small'} 
                        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                        edge="end">
                      <MoreIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            { menuAnchorEl && <Menu
                id="menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}>
                { onEditClick && <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onEditClick(entity)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('edit')}</Typography>
                </MenuItem> }
                { copyUidEnabled && 
                    <CopyToClipboard text={ `$param:${entity.getId()}` }>
                        <MenuItem 
                            onClick={(event) => {
                                setMenuAnchorEl(null)
                            }}>
                                <ListItemIcon style={{minWidth: 35}}>
                                    <LinkIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit"> {t('copy_uuid')}</Typography>
                            </MenuItem>
                    </CopyToClipboard>}
                <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onDeleteClick(entity)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('delete')}</Typography>
                </MenuItem>
            </Menu>}
        </Fragment>
 	)
}


const useStyles = makeStyles(theme => ({
	listItem: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25)
    },
    listItemText: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 40,
        alignItems: 'center'
    },
    listItemTextSecondary: {
        backgroundColor: theme.palette.grey['100'],
        textAlign: 'right',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        '&.entityType': {
            fontStyle: 'italic',
            marginLeft: theme.spacing(1)
        }
    },
    listItemSecondaryAction: {
        right: theme.spacing(1)
    },
    moreIconButton: {
        color: 'inherit',
    },
    titleBadge: {
        display: 'inline-block',
        backgroundColor: theme.palette.secondary.main,
        padding: '0px 4px',
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        fontSize: theme.font.small,
        marginRight: theme.spacing(0.5)
    },
    dragListItemIcon: {
        minWidth: 'auto', 
        marginRight: 8
    },
    dragIcon: {
        width: 24, 
        height: 24
    }

}))

export default RelacementsListItem