import React, {Fragment, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemSecondaryAction, IconButton, ListItemText, Drawer, Divider, Menu, MenuItem, ListItemIcon, Typography, Checkbox } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreIcon from '@material-ui/icons/MoreVert'
import ReactSVG from 'react-svg'

function DefaultListItem({ entity, checked, onClick, onEditProfileClick, onDeleteClick, onConvertRoleClick, invited, isAdmin, ...otherProps }) {
    const classes = useStyles()
    const [ menuAnchorEl, setMenuAnchorEl ] = useState(null)
    const { t } = useTranslation()

    const badge = <div style={{display: 'flex'}}>
        {
            invited && <Typography className={classes.listItemTextSecondary} variant="subtitle2">{t('invited')}</Typography>
        }
        {
            isAdmin && <Typography className={[classes.listItemTextSecondary, classes.listItemTextSecondaryHighlighed].join(' ')} variant="subtitle2">{t('admin')}</Typography>
        }
    </div>

	return (
        <Fragment>
           <ListItem {...otherProps} className={classes.listItem}>
                <ListItemIcon style={{minWidth: 'auto'}}>
                    <Checkbox
                        edge="start"
                        color="primary"
                        checked={checked}
                        onClick={() => onClick(entity)}
                        tabIndex={-1}
                        inputProps={{ 'aria-labelledby': entity.id }}
                    />
                    </ListItemIcon>
                <ListItemText className={classes.listItemText} primary={<Typography variant="subtitle2"> {entity.title}</Typography>} secondary={badge} />
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>

                    <IconButton 
                        className={classes.moreIconButton} 
                        size={'small'} 
                        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                        edge="end">
                      <MoreIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            { menuAnchorEl && <Menu
                id="menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}>
                { !entity.invitation && onEditProfileClick && <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onEditProfileClick(entity)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('edit_nickname')}</Typography>
                </MenuItem> }
                <MenuItem 
                    disabled={!onDeleteClick}
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onDeleteClick(entity)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {entity.invitation ? t('revoke') : t('remove')}</Typography>
                </MenuItem>
                {!invited && <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onConvertRoleClick(entity, isAdmin)
                    }}>
                    <ListItemIcon style={{minWidth: 35}}>
                        <ReactSVG src={isAdmin ? "/assets/user.svg" : "/assets/admin.svg"} />
                    </ListItemIcon>
                    <Typography variant="inherit"> {isAdmin ? t('convert_to_user') : t('convert_to_admin')}</Typography>
                </MenuItem>}
            </Menu>}
        </Fragment>
 	)
}


const useStyles = makeStyles(theme => ({
	listItem: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25)
    },
    listItemText: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 40
    },
    listItemTextSecondary: {
        backgroundColor: theme.palette.grey['100'],
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        marginLeft: 10
    },
    listItemTextSecondaryHighlighed: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    listItemSecondaryAction: {
        right: theme.spacing(1)
    },
    moreIconButton: {
        color: 'inherit',
    }

}))

export default DefaultListItem