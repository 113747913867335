import { PARAM_CODE_NAME_SPACE, PARAM_NAME } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { DRIVER_TYPE, SETTING_KEY_CRON } from '../../../m2m-cloud-api/Api/DeviceService/Models/Device'
import { MESSAGE_ACTION_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/MessageAction'
import { v4 as uuidv4 } from 'uuid'
import { ORG_TAG, ERROR } from '../../../m2m-cloud-api/index'
import { delay, buildOrgTreeFromChildOrg } from '../../../m2m-cloud-api/Api/Helper'
import { VISIBILITY_MESSAGE_LOG_TEMPLATE } from '../../../m2m-cloud-api/MessageLog/Contants'
import { PARAM_ON_HOLD } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Message'

export const APP_MESSAGE_CREATOR = 'app-msg.creator'
export const APP_MESSAGE_CREATOR_ADMIN = 'admin'

/**
 * clone group with all child orgs, devices, templates, actions, definitions and replacements
 * @param {Object} pageContext 
 * @param {Org} sourceRootOrg 
 * @param {Org} sourceGroupOrg 
 * @param {Org} destinationRootOrg 
 * @param {Uid} destinationParentOrgId 
 * @returns {undefined}
 */
export const cloneGroup = async (pageContext, sourceRootOrg, sourceGroupOrg, destinationRootOrg, destinationParentOrgId, addSuffixToRootGroupOrgName = false) => {
    const context = pageContext.api
    //console.log('cloneGroup', sourceRootOrg.getName() + '( ' + sourceRootOrg.getId() + ')', sourceGroupOrg.getName() + '( ' + sourceGroupOrg.getId() + ')' ) 
    //return
    const sourceRootOrgId = sourceRootOrg.getId()
    const destinationRootOrgId = destinationRootOrg.getId()
    const groupOrg = sourceGroupOrg // await context.orgService.read(sourceGroupOrg.getId())

    // load all group children orgs from server
    const params = {searchRootOrg: [groupOrg.getId()]}
    if (sourceGroupOrg.getVis().indexOf(VISIBILITY_MESSAGE_LOG_TEMPLATE) !== -1) {
        params.visibility= [VISIBILITY_MESSAGE_LOG_TEMPLATE]
    }
    const childrenOrgs = await context.orgService.searchOrg(params)
    /*for (let i = 0; i < childrenOrgs.length; i++) {
        const org = childrenOrgs[i];
        console.log('org', org.getName())
    }*/

    const createOrg = async (parentId, params, tags) => {
        let createdOrg = null
        // create group org ( wait until new created org is ready )
        for (let i = 1; i <= 15; i++) {
            await delay(1000)
            try {
                createdOrg = await pageContext.createAndCacheTopOrg(parentId, params, tags)
                break
            } catch (error) {
                const errorType = error.getErrorType()
                if (errorType === ERROR.FORBIDDEN) {
                    console.log("new created root org not ready, " + (i === 15 ? " after 15s." : "will retry after 1s"), "error: ", error)
                } else {
                    break
                }
            }
        }
        return createdOrg
    }

    // create first group org
    const rootGroupOrgParams = addSuffixToRootGroupOrgName ? { ...groupOrg.getParams(), [PARAM_NAME]: `${groupOrg.getParam(PARAM_NAME)} - 2` } : groupOrg.getParams()
    let createdRootGroupOrg = await createOrg(destinationParentOrgId, rootGroupOrgParams, groupOrg.getTags())
    // set namespace
    const rootOrgNameSpace = destinationRootOrgId
    await pageContext.updateAndCacheTopOrg(destinationRootOrgId, [context.orgService.putParam(destinationRootOrgId, PARAM_CODE_NAME_SPACE, rootOrgNameSpace)])

    const createdOrgs = [{
        id: createdRootGroupOrg.getId(),
        parentId: destinationParentOrgId,
        originId: groupOrg.getId(),
        originParentId: groupOrg.getParentId(),
        params: groupOrg.getParams(),
        tags: groupOrg.getTags(),
        newOrg: createdRootGroupOrg
    }]
    const endOrgs = childrenOrgs.filter( org => !org.isDeactivated() && org.hasTags([ORG_TAG.RECIPIENT_LOCATION, ORG_TAG.SUPPLIER_TARGET]) )
    for (let i = 0; i < endOrgs.length; i++) {
        const endOrg = endOrgs[i];
        const orgTree = buildOrgTreeFromChildOrg(childrenOrgs, endOrg)
        //console.log('orgTree', orgTree.map( org => org.getName()), createdOrgs)
        for (let ii = 0; ii < orgTree.length; ii++) {
            const org = orgTree[ii];
            if ( !createdOrgs.find( orgToClone => orgToClone.originId === org.getId() ) ) {
                // create org 
                const newParent = createdOrgs.find( createdOrg => createdOrg.originId === org.getParentId())
                //console.log('newParent', newParent)
                const createdOrg = await createOrg(newParent.id, org.getParams(), org.getTags())
                //console.log('create org', newParent.id, org.getParams(), org.getTags())
                createdOrgs.push({
                    id: createdOrg.getId(),
                    parentId: newParent.id,
                    originId: org.getId(),
                    originParentId: org.getParentId(),
                    params: org.getParams(),
                    tags: org.getTags(),
                    newOrg: createdOrg 
                })
            }
        }
    }
    //console.log('createdOrgs', createdOrgs.map( org => org.params['core-org.param.name'] + ' ( ' + org.id + ' ) parent : ' + createdOrgs.find( createdOrg => createdOrg.id === org.parentId)?.params['core-org.param.name'] + ' (' + org.parentId + ')' ), createdOrgs)
    
    // build devices
    const devicesToClone = []
    let devices = await context.deviceService.searchDevices({ org: sourceRootOrgId, orgVisibility: VISIBILITY_MESSAGE_LOG_TEMPLATE })
    const recipientOrgs = childrenOrgs.filter( org => !org.isDeactivated() && org.hasTags([ORG_TAG.RECIPIENT_LOCATION]) )
    for (let i = 0; i < recipientOrgs.length; i++) {
        const recipientOrg = recipientOrgs[i]
        // assigned devices
        const recipientOrgAssignedDevices = devices.items.filter( device => device.getId() && device.getAssignedOrg() === recipientOrg.getId() )
        for (let ii = 0; ii < recipientOrgAssignedDevices.length; ii++) {
            const device = recipientOrgAssignedDevices[ii];
            const createdOrg = createdOrgs.find( org => org.originId === device.getAssignedOrg() )
            if ( !devicesToClone.find( _device => _device.originId === device.getId() ) ) {
                devicesToClone.push({
                    originId: device.getId(),
                    ownerOrg: destinationRootOrgId,
                    settings: device.getSettings(),
                    assignedOrg: createdOrg.id,
                    assignedOrgOrigin: device.getAssignedOrg(),
                    driver: device.getDriver()
                })
            }   
        }
    }
    
    // build definitions
    const deviceOriginIdsToClone = devicesToClone.map( item => item.originId)
    const definitions = await context.appMessageService.getDefinitionsByMultipleDeviceId(deviceOriginIdsToClone)
    //console.log('definitions', definitions)
    const templateIds = []
    const actionIds = []
    for (let i = 0; i < definitions.length; i++) {
        const definition = definitions[i]
        if (definition.getTemplateId() && templateIds.indexOf(definition.getTemplateId()) === -1) {
            templateIds.push(definition.getTemplateId())
        }
        if (definition.getActionId() && actionIds.indexOf(definition.getActionId()) === -1) {
            actionIds.push(definition.getActionId())
        }
    }

    // build templates
    const templatesToClone = []
    const templates = await context.appMessageService.getMultipleTemplates(templateIds)
    for (let i = 0; i < templates.length; i++) {
        const template = templates[i]
        const createdOrg = createdOrgs.find( _ => _.originId === template.getOrgId())
        templatesToClone.push({
            id: uuidv4(),
            originId: template.getId(),
            orgId: createdOrg.id,
            originOrgId: template.getOrgId(),
            name: template.getName(),
            header: template.getHeader(),
            body: template.getBody(),
            replacements: [], // fill replacements below
            originReplacements: template.getReplacementIds()
        })
    }

    // build replacements
    const replacementsToClone = []
    const replacementIds = []
    const replacementCloneIdMappings = {}
    for (let i = 0; i < templatesToClone.length; i++) {
        const templateToClone = templatesToClone[i]
        const templateReplacementIds = templateToClone.originReplacements
        if (templateReplacementIds && templateReplacementIds.length > 0) {
            for (let ii = 0; ii < templateReplacementIds.length; ii++) {
                const templateReplacementId = templateReplacementIds[ii]
                if (replacementIds.indexOf(templateReplacementId) === -1 && templateReplacementId.indexOf(PARAM_ON_HOLD) !== 0) {
                    replacementIds.push(templateReplacementId)
                    replacementCloneIdMappings[templateReplacementId] = uuidv4()
                }
            }
        }
        // fill replacements
        templatesToClone[i].replacements = templatesToClone[i].originReplacements.map( originReplacementId => replacementCloneIdMappings[originReplacementId])
    }
    const replacements = await context.appMessageService.readMultipleReplacementDefs(replacementIds)
    for (let i = 0; i < replacements.length; i++) {
        const replacement = replacements[i]
        const createdOrg = createdOrgs.find( _ => _.originId === replacement.getOrgId())
        replacementsToClone.push({
            id: replacementCloneIdMappings[replacement.getId()],
            originId: replacement.getId(),
            orgId: createdOrg.id,
            originOrgId: replacement.getOrgId(),
            name: replacement.getName(),
            type: replacement.getType(),
            desc: replacement.getDesc()
        })
    }

    // build actions
    const actionsToClone = []
    const actions = await context.appMessageService.getMultipleActions(actionIds)
    //console.log('actions', actions)
    for (let i = 0; i < actions.length; i++) {
        const action = actions[i]
        const createdOrg = createdOrgs.find( _ => _.originId === action.getOrgId())
        //console.log('actionsToClone action', action)
        actionsToClone.push({
            id: uuidv4(),
            originId: action.getId(),
            data: action.getData(),
            name: action.getName(),
            type: action.getType(),
            orgId: createdOrg.id,
            originOrgId: action.getOrgId()
        })
    }

    //console.log('actionsToClone', actionsToClone)

    // build definitions
    const definitionsToClone = []
    for (let i = 0; i < definitions.length; i++) {
        const definition = definitions[i]
        const createdOrg = createdOrgs.find( _ => _.originId === definition.getOrgId())
        const templateToClone = templatesToClone.find( _ => _.originId === definition.getTemplateId())
        const actionToClone = actionsToClone.find( _ => _.originId === definition.getActionId())
        definitionsToClone.push({
            id: uuidv4(),
            originId: definition.getId(),
            events: definition.getEvents(),
            params: definition.getParams(),
            orgId: createdOrg.id,
            originOrgId: definition.getOrgId(),
            templateId: templateToClone.id,
            originTemplateId: definition.getTemplateId(),
            actionId: actionToClone.id,
            originActionId: definition.getActionId(),
            originDeviceId: definition.getDeviceId()
        })
    }

    //console.log('templatesToClone', templatesToClone, 'replacementsToClone', replacementsToClone)
    
    
    // create replacements
    for (let i = 0; i < replacementsToClone.length; i++) {
        const replacementToClone = replacementsToClone[i]
        //console.log('upsertTextReplacement', replacementToClone.id, replacementToClone.orgId, replacementToClone.name, replacementToClone.type, replacementToClone.desc)
        await context.appMessageService.upsertTextReplacement(replacementToClone.id, replacementToClone.orgId, replacementToClone.name, replacementToClone.type, replacementToClone.desc)
    }
    // create templates
    for (let i = 0; i < templatesToClone.length; i++) {
        const templateToClone = templatesToClone[i]
        //console.log('create template', templateToClone.id, templateToClone.name, templateToClone.header, templateToClone.body, templateToClone.orgId, templateToClone.replacements)
        await context.appMessageService.createTemplate(templateToClone.id, templateToClone.name, templateToClone.header, templateToClone.body, templateToClone.orgId, templateToClone.replacements)
    }
    // create actions
    for (let i = 0; i < actionsToClone.length; i++) {
        const actionToClone = actionsToClone[i];
        //console.log('create action', actionToClone.id, actionToClone.name, actionToClone.data, actionToClone.orgId, MESSAGE_ACTION_TYPE.MESSAGE)
        await context.appMessageService.upsertAction(actionToClone.id, actionToClone.name, actionToClone.data, actionToClone.orgId, MESSAGE_ACTION_TYPE.MESSAGE)
    }
    // create definition
    for (let i = 0; i < definitionsToClone.length; i++) {
        const definitionToClone = definitionsToClone[i]

        const deviceToClone = devicesToClone.find( device => device.originId === definitionToClone.originDeviceId )
        let device = null
        if ( deviceToClone.driver ===  DRIVER_TYPE.TIMER) {
            device = await context.deviceService.timerDriver.registerTimer(destinationRootOrgId, deviceToClone.settings[SETTING_KEY_CRON])
        } else {
            if ( deviceToClone.driver !==  DRIVER_TYPE.CODE) {
                console.warn('device type not supported: ', deviceToClone.driver, deviceToClone, ' create CODE DRIVER device type instead.')
            }
            const name = `${new Date().getTime()}`
            const token = uuidv4().replace(new RegExp('-', 'g'), '')
            device = await context.deviceService.codeDriver.registerCode(rootOrgNameSpace, name, token)
        }

        await context.deviceService.updateSetting(device.getId(), APP_MESSAGE_CREATOR, APP_MESSAGE_CREATOR_ADMIN)
        await context.deviceService.assignDevice(device.getId(), deviceToClone.assignedOrg )
        await context.deviceService.activateDevice(device.getId())

        //console.log('create definition', definitionToClone.id, definitionToClone.actionId, device.getId(), definitionToClone.events, definitionToClone.params, definitionToClone.orgId, definitionToClone.templateId)
        await context.appMessageService.registerDefinition(definitionToClone.id, definitionToClone.actionId, device.getId(), definitionToClone.events, definitionToClone.params, definitionToClone.orgId, definitionToClone.templateId)
    }

    return null
}