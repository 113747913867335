import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import webServiceConfig from './config/Config'
import MessagingApp from './Apps/MessageLog/App'
import MessagingAppAdmin from './Apps/MessageLogAdmin/App'
import { lightTheme, darkTheme } from './theme'
import './i18n'

export const SERVICE_TYPES = {
    MESSAGE_LOG_WEB : 'messagelog-web',
    MESSAGE_LOG_ADMIN: 'messagelog-admin'
}

let paths = []
Object.keys(webServiceConfig.paths).map( serviceType => {
    if (serviceType && webServiceConfig.paths[serviceType]) {
        paths.push({serviceType, path: webServiceConfig.paths[serviceType]})
    }
})
paths = paths.sort( (a,b) => b.path.localeCompare(a.path ))

const matchedServiceType = paths.find( ({serviceType, path}) => path && window.location.pathname.substr(0,path.length) === path )

const isMessagingAdminApp = matchedServiceType.serviceType === SERVICE_TYPES.MESSAGE_LOG_ADMIN
const isMessagingWebApp = matchedServiceType.serviceType === SERVICE_TYPES.MESSAGE_LOG_WEB
let titlePrefix = ''
if (webServiceConfig && webServiceConfig.name.toLowerCase().indexOf('rad') >= 0) {
    titlePrefix = '[RAD] '
}
if (webServiceConfig && webServiceConfig.name.toLowerCase().indexOf('dev') >= 0) {
    titlePrefix = '[DEV] '
}
switch (matchedServiceType.serviceType) {
    case SERVICE_TYPES.MESSAGE_LOG_ADMIN:
        document.title = `${titlePrefix}messageLOG - Admin`
        break
    case SERVICE_TYPES.MESSAGE_LOG_WEB:
        document.title = `${titlePrefix}messageLOG`
        break

    default:
        document.title = ``
        break
}

export const registrationAppSetting = webServiceConfig.settings && webServiceConfig.settings.registrationApp

let theme = darkTheme
if (isMessagingWebApp) {
    theme = lightTheme
}

const packageJSON = require('../package.json')
console.log('%c eLog4 Version: ' + packageJSON.version + ' ', 'background: #E63A1B; color: #fff; font-size: 16px')

export const service = webServiceConfig

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
            { isMessagingAdminApp && <MessagingAppAdmin /> } 
            { isMessagingWebApp && <MessagingApp /> }     
    </ThemeProvider>,
    document.querySelector('#root'),
)


