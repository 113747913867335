import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { PageContext } from '../Context/PageProvider'
import { getPath } from '../App'
import { AppBar, Grid, Container, Toolbar, IconButton, Typography, Card, CardHeader, CardContent, CardActions, Collapse, CardActionArea, Divider, Menu, MenuItem, ListItemIcon } from '@material-ui/core'
import { ORG_TAG, ERROR } from '../../../m2m-cloud-api'
import { PARAM_NAME, PARAM_CODE_NAME_SPACE, PARAM_CUSTOMER_ID, PARAM_RESPONSIBLE, PARAM_INVOICE_BLOCK, PARAM_CNR } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { cloneGroup } from '../Context/MessageLogHelper'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import ReactSVG from 'react-svg'
import RootOrgDialog from '../Components/RootOrgDialog'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import UserButton from '../../../Libs/Components/UserButton'
import Searchbox from '../../../Libs/Components/Searchbox'


const asyncWait = (waitTime) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve()
        }, waitTime)
    })
}

class SelectRootScreen extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            entityDeleteDialogOpen: false,
            entityDialogOpen: false,
            loading: false,
            entityDialogError: null,
            menuAnchorEl: null,
            selectedEntity: null,
            groupOrgs: null,
            searchTerm: '',

        }

    }

    async componentDidMount() {
        const entities = this.context.getRootOrgs()
        if (entities && entities.length === 0) {
            this.setState({entityDialogOpen: true})
        } else if (entities && entities.length === 1) {
            const { history } = this.props
            history.push(`${getPath()}/${entities[0].getId()}`)
        } 
        const groupOrgs = await this.context.api.orgService.searchOrg({ tags: [ORG_TAG.GROUP, ORG_TAG.CONFIG] })
        this.setState({groupOrgs})

        /*const orgs = await this.context.api.orgService.searchOrg({ visibility: ['messagelog-demo'] })
        const rootOrg = orgs.find( org => org.hasTags([ORG_TAG.ROOT]))
        const groupOrg = orgs.find( org => org.hasTags([ORG_TAG.GROUP]) && org.getParentId() === rootOrg.getId())
        const templateRootOrgs = await cloneGroup(this.context.api, rootOrg, groupOrg)
        //console.log('templateRootOrgs', templateRootOrgs)*/

        /*const rootOrg = await this.context.api.orgService.read('d4c12453-ea08-4e6e-b4ad-9389318d18fd')
        const groupOrg = await this.context.api.orgService.read('377de622-3b6b-4fe2-a851-ea7faf7e6d7b')
        console.log('rootOrg', rootOrg, groupOrg)
        const templateRootOrgs = await cloneGroup(this.context.api, rootOrg, groupOrg, groupOrg.getParentId() )*/
    }

    onSubmitEntityDialog({ name, customerId, contactPerson, billingInfos, templateRootOrg, selectedTemplateOrg }) {
        const { selectedEntity } = this.state
        if (selectedEntity) {
            this.updateEntity({ name, customerId, contactPerson, billingInfos })
        } else {
            this.addNewEntity({ name, customerId, contactPerson, billingInfos, templateRootOrg, selectedTemplateOrg })
        }
    }

    addNewEntity({ name, customerId, contactPerson, billingInfos, templateRootOrg, selectedTemplateOrg }) {
        const { t } = this.props

        this.setState({
            loading: true
        }, async () => {
            try {
                const createdRootOrg = await this.context.createAndCacheTopOrg(null, { [PARAM_NAME]: name, [PARAM_CUSTOMER_ID]: customerId, [PARAM_RESPONSIBLE]: contactPerson, [PARAM_INVOICE_BLOCK]: billingInfos, [PARAM_CNR]: '' }, [ORG_TAG.ROOT])
                if (selectedTemplateOrg) { // create from template
                    await cloneGroup(this.context, templateRootOrg, selectedTemplateOrg, createdRootOrg, createdRootOrg.getId(), false )
                } else {
                    let groupOrg = null
                    // create group org ( wait until new created org is ready )
                    for (let index = 1; index <= 15; index++) {
                        await asyncWait(1000)
                        try {
                            groupOrg = await this.context.createAndCacheTopOrg(createdRootOrg.getId(), { [PARAM_NAME]: t('default_group') }, [ORG_TAG.GROUP])
                            break
                        } catch (error) {
                            const errorType = error.getErrorType()
                            if (errorType === ERROR.FORBIDDEN) {
                                console.log("new created root org not ready, " + (index === 15 ? " after 10s." : "will retry after 1s"), "error: ", error)
                            } else {
                                break
                            }
                        }
                    }
                    // set namespace
                    await this.context.updateAndCacheTopOrg(createdRootOrg.getId(), [this.context.api.orgService.putParam(createdRootOrg.getId(), PARAM_CODE_NAME_SPACE, createdRootOrg.getId())])
                }

                // create root org groups
                const readRootOrg = await this.context.api.orgService.read(createdRootOrg.getId())
                await this.context.createRootOrgGroupsIfNotExists(readRootOrg, true)

                await this.context.reloadRootOrgs()
                this.setState({
                    loading: false,
                    selectedEntity: null,
                    entityDialogOpen: false
                })
            } catch (error) {
                await this.context.reloadRootOrgs()
                this.setState({
                    entityDialogError: t(mapErrorMessage(error)),
                    loading: false
                })
            }
        })
    }

    updateEntity({ name, customerId, contactPerson, billingInfos }) {
        const { selectedEntity } = this.state

        this.setState({
            loading: true
        })

        this.context.updateAndCacheTopOrg(selectedEntity.getId(), [this.context.api.orgService.putParam(selectedEntity.getId(), PARAM_NAME, name), this.context.api.orgService.putParam(selectedEntity.getId(), PARAM_CUSTOMER_ID, customerId), this.context.api.orgService.putParam(selectedEntity.getId(), PARAM_RESPONSIBLE, contactPerson), /*this.context.api.orgService.putParam(selectedEntity.getId(), PARAM_INVOICE_BLOCK, billingInfos)*/])
            .then(() => {
                return this.context.reloadRootOrgs()
                    .then(() => {
                        this.setState({ entityDialogOpen: false, loading: false, selectedEntity: null })
                    })
            }).catch(error => {
                console.log('updateEntity, error', error)
                this.setState({
                    entityDialogError: mapErrorMessage(error),
                    loading: false
                })
            })
    }

    onSubmitEntityDeleteDialog() {
        const { selectedEntity } = this.state

        this.setState({
            loading: true
        })

        this.context.updateAndCacheTopOrg(selectedEntity.getId(), [this.context.api.orgService.deactivate(selectedEntity.getId())])
            .then(() => {
                return this.context.reloadRootOrgs()
                    .then(() => {
                        this.setState({ entityDeleteDialogOpen: false, loading: false })
                    })
            }).catch(error => {
                console.log('onSubmitEntityDeleteDialog, error', error)
                this.setState({
                    entityDialogError: mapErrorMessage(error),
                    loading: false
                })
            })
    }

    onSubmitEntityActivateDialog() {
        const { selectedEntity } = this.state

        this.setState({
            loading: true
        })

        this.context.updateAndCacheTopOrg(selectedEntity.getId(), [this.context.api.orgService.reactivate(selectedEntity.getId())])
            .then(() => {
                return this.context.reloadRootOrgs()
                    .then(() => {
                        this.setState({
                            loading: false
                        })
                    })
            }).catch(error => {
                console.log('onSubmitEntityActivateDialog, error', error)
                this.setState({
                    entityDialogError: mapErrorMessage(error),
                    loading: false
                })
            })
            .finally(() => {
                this.setState({
                    menuAnchorEl: null
                })
            })
    }

    render() {
        const { t, classes } = this.props
        const { entityDialogOpen, loading, entityDialogError, selectedEntity, entityDeleteDialogOpen, searchTerm } = this.state
        let entities = this.context.getRootOrgs()
        if ( searchTerm.trim() !== "" ) {
            entities = entities.filter( entity => `${entity.getName()}`.toLowerCase().indexOf(  searchTerm.trim().toLowerCase() ) !== -1)
        }

        return (
            <div className={classes.root}>
                <AppBar position="fixed" color="secondary">
                    <Toolbar className={classes.toolbar}>
                        <ReactSVG className={classes.logo} src="/assets/logo.svg" />
                        <div style={{ flexGrow: 1 }}>
                        </div>
                        <UserButton
                            userService={this.context.api.userService}
                            authzService={this.context.api.authzService}
                            pendingInvitations={this.context.pendingInvitations}
                            user={this.context.api.userService.getActiveUser()}
                            onInvitationAccepted={() => setTimeout(() => {
                                this.context.loadPendingInvitations()
                                this.context.reloadRootOrgs()
                            }, 1000)}
                            onLogout={() => this.context.api.userService.logoutUser()} />
                        <Searchbox
                            className={classes.searchbox}
                            value={searchTerm}
                            onChange={(value) => this.setState({ searchTerm: value})}/>
                    </Toolbar>
                </AppBar>
                <div className={classes.offset} />
                <Container>
                    <Grid container spacing={2}>
                        {this.renderAddNewEntityButton()}
                        {this.renderEntityMenu()}
                        {entities && entities.map((entity) => this.renderEntity(entity))}
                    </Grid>
                </Container>
                { entityDialogOpen && <RootOrgDialog
                    loading={loading}
                    rootEntity={selectedEntity}
                    errorMessage={entityDialogError}
                    onSubmit={this.onSubmitEntityDialog.bind(this)}
                    onCancel={() => this.setState({ entityDialogOpen: false })}
                />}
                { entityDeleteDialogOpen && selectedEntity && <AlertDialog
                    open={true}
                    loading={loading}
                    errorMessage={entityDialogError}
                    title={t('delete_root_org_comfirmation_title')}
                    message={t('delete_root_org_comfirmation_description')}
                    onCancel={() => this.setState({ orgToDeactivate: null })}
                    submitButtonTitle={t('delete')}
                    buttonVariant={'text'}
                    submitButtonColor={'primary'}
                    onSubmit={this.onSubmitEntityDeleteDialog.bind(this)}
                    onCancel={() => this.setState({ entityDeleteDialogOpen: false, selectedEntity: null })}
                />}
            </div>
        )
    }

    renderAddNewEntityButton() {
        const { t, classes } = this.props
        return (
            <Grid item xs={12} sm={6} md={4} lg={3} spacing={3}>
                <Card className={classes.card}>
                    <CardActionArea className={classes.cardActionArea} onClick={() => this.setState({ entityDialogOpen: true, selectedEntity: null })}>
                        <CardContent className={[classes.cardContent, classes.cardContentAddButton].join(' ')}>
                            <AddIcon className={classes.addIcon} />
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        )
    }

    renderEntity(entity) {
        const { groupOrgs } = this.state
        const { t, classes, history } = this.props

        const groupEntities = groupOrgs?.filter( org => org.getParentId() === entity.getId() && org.hasTags([ORG_TAG.GROUP]) )
        let customerID = entity.getParam(PARAM_CUSTOMER_ID)
        if (!customerID || customerID.trim() === '') customerID = "\u00a0"

        return (
            <Grid item xs={12} sm={6} md={4} lg={3} spacing={3}>
                <Card className={entity.isDeactivated() ? [classes.card, classes.cardDeactivated].join(' ') : classes.card}>
                    <CardActionArea disableRipple={true} className={classes.cardActionArea} onClick={() => history.push(`${getPath()}/${entity.getId()}`)}>
                        <CardHeader
                            action={
                                <Fragment>
                                    <IconButton
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            this.setState({ menuAnchorEl: event.currentTarget, selectedEntity: entity, entityDialogError: null })
                                        }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Fragment>
                            }
                            title={entity.isDeactivated() ? <Typography className={classes.stateLabel} variant="body1"> {t('deactivated')}</Typography> : null}
                        />
                        <CardContent className={classes.cardContent}>
                            <Typography component="div" className={classes.customerNumber}>
                                {customerID}
                            </Typography>
                            <Typography variant="h5" component="p" className={classes.title}>
                                {entity.getName()}
                            </Typography>
                            {groupEntities && groupEntities.length > 0 && <Divider />}
                            {groupEntities && groupEntities.length > 0 &&
                                <div className={classes.groupItemContent}>
                                    {
                                        groupEntities.map(entity => (
                                            <div>
                                                <Typography variant="body1" className={classes.groupItem}>
                                                    {entity.getName()}
                                                </Typography>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </CardContent>
                    </CardActionArea>

                </Card>
            </Grid>
        )
    }

    renderEntityMenu() {
        const { t, classes } = this.props
        const { menuAnchorEl, selectedEntity, loading } = this.state
        return (
            <Menu
                id="menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => this.setState({ menuAnchorEl: null })}>
                <MenuItem disabled={loading}
                    onClick={(e) => {
                        e.stopPropagation()
                        this.setState({ menuAnchorEl: null, entityDialogOpen: true })
                    }}>
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('edit')}</Typography>
                </MenuItem>
                { selectedEntity && !selectedEntity.isDeactivated() && <MenuItem onClick={() => this.setState({ menuAnchorEl: null, entityDeleteDialogOpen: true })}>
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('delete')}</Typography>
                </MenuItem>}
                { selectedEntity && selectedEntity.isDeactivated() && <MenuItem onClick={this.onSubmitEntityActivateDialog.bind(this)}>
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <CheckIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('activate')}</Typography>
                </MenuItem>}
            </Menu>
        )
    }
}

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.common.black,
        minHeight: '100%',
        padding: theme.spacing(2)
    },
    searchbox: {
        position: 'absolute',
        left: '50%',
        maxWidth: 250,
        width: 250,
        marginLeft: -125
    },
    offset: theme.mixins.toolbar,
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardDeactivated: {
        opacity: 0.5
    },
    addIcon: {
        fontSize: 100
    },
    cardActionArea: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
    },
    cardContent: {
        paddingTop: 0,
        flex: 1
    },
    cardContentAddButton: {
        alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        minHeight: 200
    },
    title: {
        minHeight: 100
    },
    customerNumber: {
        opacity: 0.4,
        fontSize: 12,
        lineHeight: '12px'
    },
    groupItemContent: {
        marginTop: theme.spacing(2)
    },
    groupItem: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.dark,
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        paddingLeft: 10,
        paddingRight: 10,
        color: theme.palette.grey[900],
        display: 'inline-block'
    },
    stateLabel: {
        borderRadius: theme.shape.borderRadius,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: theme.palette.error.dark,
        display: 'inline-block'
    }
})

SelectRootScreen.contextType = PageContext

export default withTranslation()(withStyles(styles)(SelectRootScreen))