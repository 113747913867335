export const DEFAULT_MEMORY_CACHE_DURATION = 30 * 1000;
export const DEFAULT_CACHE_KEY = 'default';

export default class MemoryCache {
  constructor(cacheDuration) {
    this.cacheDuration = cacheDuration || DEFAULT_MEMORY_CACHE_DURATION;
    this.cache = {};
  }

  /**
   * set list of items
   * @param {Array} items
   * @returns {undefined}
   */
  setList(items, cacheKey = DEFAULT_CACHE_KEY) {
    //console.log('setList', items, cacheKey)
    this._removeExpiredCacheItems();
    if (items) {
      const expire = new Date(new Date().getTime() + this.cacheDuration);
      this.cache[cacheKey] = { expire: expire, items: items };
    } else {
      delete this.cache[cacheKey];
    }
    //console.log('setList - END', this.cache)
  }

  /**
   * get list of items
   * @param {string} cacheKey
   * @returns {Array | undefined}
   */
  getList(cacheKey = DEFAULT_CACHE_KEY) {
    this._removeExpiredCacheItems();
    //console.log('getList - START', cacheKey, this.cache)
    const cachedList = this.cache[cacheKey] && this.cache[cacheKey]['items'] ? this.cache[cacheKey]['items'] : undefined;
    //console.log('getList', cacheKey, cachedList)
    return cachedList;
  }

  /**
   * reset list
   * @param {string} cacheKey
   */
  resetList(cacheKey = DEFAULT_CACHE_KEY) {
    delete this.cache[cacheKey];
  }

  /**
   * reset list
   */
  resetCache() {
    this.cache = {};
  }

  /**
   * update item
   * @param {any} item
   * @returns {undefined}
   */
  updateItem(item, cacheKey = DEFAULT_CACHE_KEY) {
    this._removeExpiredCacheItems();
    if (this.cache[cacheKey] && this.cache[cacheKey]['items']) {
      const foundIndex = this.cache[cacheKey]['items'].findIndex(searchItem => searchItem.getId() === item.getId());
      if (foundIndex >= 0) {
        this.cache[cacheKey]['items'][foundIndex] = item;
      } else {
        console.log('[MemoryCache][updateItem] - item not found, id: ' + item.getId());
      }
    } else {
      console.log('[MemoryCache][updateItem] - cache not found or expired for key: ' + cacheKey);
    }
  }

  /**
   * upsert item
   * @param {any} item
   * @returns {undefined}
   */
  upsertItem(item, cacheKey = DEFAULT_CACHE_KEY) {
    const foundItem = this.getItem(item, cacheKey);
    if (foundItem) {
      this.updateItem(item, cacheKey);
    } else {
      if (!this.cache[cacheKey]) {
        const expire = new Date(new Date().getTime() + this.cacheDuration);
        this.cache[cacheKey] = { expire: expire, items: [item] };
      } else {
        this.cache[cacheKey]['items'].push(item);
      }
    }
  }

  /**
   * remove item
   * @param {string} id
   * @returns {undefined}
   */
  removeItem(id, cacheKey = DEFAULT_CACHE_KEY) {
    this._removeExpiredCacheItems();
    if (this.cache[cacheKey] && this.cache[cacheKey]['items']) {
      const foundIndex = this.cache[cacheKey]['items'].findIndex(searchItem => searchItem.getId() === id);
      if (foundIndex >= 0) {
        this.cache[cacheKey] = this.cache[cacheKey]['items'].splice(foundIndex, 1);
      } else {
        console.log('[MemoryCache][removeItem] - item not found, id: ' + id);
      }
    } else {
      console.log('[MemoryCache][removeItem] - cache not found or expired for key: ' + cacheKey);
    }
  }

  /**
   * get cached item
   * @param {string} key
   * @returns {any | undefined}
   */
  getItem(id, cacheKey = DEFAULT_CACHE_KEY) {
    this._removeExpiredCacheItems();
    if (this.cache[cacheKey] && this.cache[cacheKey]['items']) {
      const foundIndex = this.cache[cacheKey]['items'].findIndex(searchItem => searchItem.getId() === id);
      if (foundIndex >= 0) {
        return this.cache[cacheKey]['items'][foundIndex];
      }
    }
    return null;
  }

  /**
   * remove expired items
   */
  _removeExpiredCacheItems() {
    Object.keys(this.cache).map(cacheKey => {
      const cacheItem = this.cache[cacheKey];
      if (cacheItem && cacheItem.expire && cacheItem.expire.getTime() < new Date().getTime()) {
        console.warn('[MemoryCache][_removeExpiredCacheItems] - remove expired cacheKey: ' + cacheKey);
        delete this.cache[cacheKey];
      }
    });
  }
}
