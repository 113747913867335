import React, {useState, useContext, useCallback } from 'react'
import { PageContext } from '../Context/PageProvider'
import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { lightTheme } from '../../../theme'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import DateRangePicker from '../../../Libs/Components/DateRangePicker'
import { downloadCsvFile } from '../../../Libs/Utilities/CSVExport'
import { ORG_TAG } from '../../../m2m-cloud-api'

let moment = require('moment')

function UsageReportExportDialog({ open, org, rootOrg, visible, onClose }) {
    const { t } = useTranslation()
    const context = useContext(PageContext)

    const [loading, setLoading] = useState(false)
    const [exportDetails, setExportDetails] = useState(false)

    const onSubmit = useCallback(async (dates)=> {
        setLoading(true)
        const start = dates[0].clone().startOf('day').toDate()
        const end = dates[1].clone().endOf('day').toDate()
        try {
            const queryParams = {}
            if (org.hasTags([ORG_TAG.SUPPLIER_TARGET])) {
                queryParams['targetId'] = org.getId()
            }
            if (org.hasTags([ORG_TAG.RECIPIENT_LOCATION])) {
                queryParams['sourceId'] = org.getId()
            }
            if (exportDetails) {
                queryParams['detailed'] = true
            }
            const csvString = await context.api.appMessageService.getTxCountByOrg(rootOrg.getId(), start, end, queryParams)
            const fileName = t('usage_report_export_file', { org: org.getName(), start: `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`, end: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}` })
            downloadCsvFile(fileName, csvString)
        } catch (error) {
            console.log("[getTxCountByOrg] error: ", error)
        }
        setLoading(false)
        onClose()
    },[org && org.getId()])


    return (
        <ThemeProvider theme={lightTheme}>
            <DateRangePicker
                open={open}
                loading={loading}
                title={t('usage_report_export_modal_title')}
                description={t('usage_report_export_modal_description')}
                onSubmitLabel={t('export')}
                value={[moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]}
                onClose={onClose}
                footerContent={
                    <FormControlLabel
                        style={{ flex: 1, fontSize: 10 }}
                        control={
                            <Checkbox
                                checked={exportDetails}
                                onChange={(event) => setExportDetails(event.target.checked)} />
                        }
                        label={t('detailed')} />
                }
                onSubmit={onSubmit} />
        </ThemeProvider>
    )
}


const useStyles = makeStyles(theme => ({
}))

export default UsageReportExportDialog