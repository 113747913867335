import React, { Fragment, useState, useRef, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, FormControl, InputAdornment } from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { PARAM_APP_MSG_ROTATION_MODE, PARAM_APP_MSG_ROTATION_STAY_TIME } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { PageContext } from '../Context/PageProvider'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'


const DEFAULT_ROTATION_STAY_TIME = 10
const MIN_ROTATION_STAY_TIME = 3

const useStyles = makeStyles(theme => ({
    dialogContent: {
        overflowY: 'auto',
        maxHeight: '90%'
    }
}))

function RotationModalDialog({ open, rootOrgId, onClose }) {

    const { t } = useTranslation()
    const classes = useStyles()
    const formRef = useRef()
    const context = useContext(PageContext)

    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(true)
    const [rotatonModeEnabled, setRotationModeEnabled] = useState(false)
    const [rotationStayTime, setRotationStayTime] = useState(DEFAULT_ROTATION_STAY_TIME)
    const [errorMessage, setErrorMessage] = useState(null)

    const loadSettings = async () => {
        try {
            const org = await context.api.orgService.read(rootOrgId)
            setRotationModeEnabled(org && org.getParam(PARAM_APP_MSG_ROTATION_MODE) ? true : false)
            setRotationStayTime(org && org.getParam(PARAM_APP_MSG_ROTATION_STAY_TIME) || DEFAULT_ROTATION_STAY_TIME)
            setReady(true)
            setLoading(false)
        } catch (error) {
            setErrorMessage(mapErrorMessage(error))
            setLoading(false)
        }

    }

    useEffect(() => {
        loadSettings()
    }, [])

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const promisses = rotatonModeEnabled ? [
                context.api.orgService.putParam(rootOrgId, PARAM_APP_MSG_ROTATION_MODE, rotatonModeEnabled),
                context.api.orgService.putParam(rootOrgId, PARAM_APP_MSG_ROTATION_STAY_TIME, rotationStayTime)
            ] : [
                    context.api.orgService.deleteParam(rootOrgId, PARAM_APP_MSG_ROTATION_MODE),
                    context.api.orgService.deleteParam(rootOrgId, PARAM_APP_MSG_ROTATION_STAY_TIME)
                ]
            await context.api.orgService.updateOrg(rootOrgId, promisses)
            setLoading(false)
            onClose()
        } catch (error) {
            setErrorMessage(mapErrorMessage(error))
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} fullWidth maxWidth={'xs'} maxHeight={'80%'} aria-labelledby="alert">
            { loading && <LinearProgress />}

            <DialogTitle id="alert-title">{t('rotation_mode')}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <ValidatorForm
                    ref={formRef}
                    onSubmit={handleSubmit}
                    onError={errors => console.log("form error:", errors)}>
                    {ready && <Fragment>
                        <FormControl className={classes.formControl} fullWidth margin="dense">
                            <FormControlLabel
                                control={<Checkbox name="custom-color" color="primary" checked={rotatonModeEnabled} onChange={({ target }) => setRotationModeEnabled(target.checked)} />}
                                label={t('rotation_mode_enabled')}
                            />
                        </FormControl>
                        {rotatonModeEnabled && <Fragment>
                            <TextValidator
                                autoFocus
                                margin="dense"
                                id="rotation-stay-time"
                                value={rotationStayTime}
                                disabled={loading}
                                onChange={(event) => setRotationStayTime(event.target.value)}
                                label={t('rotation_stay_time')}
                                instantValidate={true}
                                validators={['required', 'isNumber', `minNumber:${MIN_ROTATION_STAY_TIME}`]}
                                errorMessages={[t('this_field_is_required'), t('this_field_must_be_a_number'), t('allowed_minimum_value', { min: MIN_ROTATION_STAY_TIME })]}
                                fullWidth 
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{t('second')}</InputAdornment>
                                }}/>
                        </Fragment>}
                    </Fragment>}
                    {errorMessage && <DialogContentText style={{ marginTop: 20, whiteSpace: 'pre-line' }} color="error">{errorMessage}</DialogContentText>}
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
                {<Button disabled={loading} onClick={onClose} color="default">
                    {t('cancel')}
                </Button>}
                <Button
                    disabled={!ready || loading}
                    onClick={() => formRef.current.submit()}
                    color={"primary"}>
                    {t('ok')}
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default RotationModalDialog
