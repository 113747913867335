import { prepareTemplateContent, buildOrgTreeNames } from '../../m2m-cloud-api/Api/Helper'
import { REPLACEMENT_TYPE } from '../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import { PARAM_BILLING_POINT } from '../../m2m-cloud-api/Api/OrgService/Models/Org'
import { PARAM_ON_HOLD } from '../../m2m-cloud-api/Api/AppMesageService/Models/Message'

import FileSaver from 'file-saver'
import toCsv from 'react-csv-downloader/dist/lib/csv'
import * as moment from 'moment'

const prepareTextValue = (text) => {
    if (text && text !== "") {
        return `"${text}"`.replace(/^\s+|\s+$/g, '').replace(/(\r\n|\n|\r)/gm, ' ') // remove nl
    }
    return ''
}

const keyForReplacementName = (replacementName) => replacementName.indexOf(PARAM_ON_HOLD) === 0 ? replacementName : `ordered:${replacementName}`

export const csvExportDefinitions = (definitions, templates, actions, devices, fileName = null) => {

    let columns = [
        {
            id: 'definitionId',
            displayName: prepareTextValue('Definition-ID')
        },
        {
            id: 'templateName',
            displayName: prepareTextValue('Template-Name')
        },
        {
            id: 'actionName',
            displayName: prepareTextValue('Action-Name')
        },
        {
            id: 'definitionText',
            displayName: prepareTextValue('Definition-Text')
        },
        {
            id: 'deviceDriver',
            displayName: prepareTextValue('Device-Driver')
        },
        {
            id: 'devicePhysicalId',
            displayName: prepareTextValue('Device-PhysicalId')
        }
    ]

    let datas = []
    let customColumns = []
    definitions.map(definition => {
        const template = templates && templates.find(template => template.getId() === definition.getTemplateId())
        const action = actions && actions.find(action => action.getId() === definition.getActionId())
        const device = devices && devices.find(device => device.getId() === definition.getDeviceId())

        const physicalId = device ? device.getPhysicalId() : '-'
        const driver = device ? device.getDriver() : '-'
        const definitionText = template && action && device ? prepareTemplateContent(template, definition, device, null) : null

        const data = {
            definitionId: prepareTextValue(definition.getId()),
            templateName: prepareTextValue(template.getName()),
            actionName: prepareTextValue(action.getName()),
            definitionText: definitionText && prepareTextValue(definitionText.header),
            deviceDriver: prepareTextValue(driver),
            devicePhysicalId: prepareTextValue(physicalId)
        }

        const headerAndBody = template ? `${template.getHeader()} ${template.getBody()}` : ''
        const matchedVariables = headerAndBody.match(/(\$def:[a-zA-ZÀ-ÿ0-9-_]+)/g)
        if (matchedVariables && matchedVariables.length > 0) {
            matchedVariables.map(variable => {
                const variableName = variable.substr(5)
                const title = `${variableName}`
                const hasColumn = customColumns.findIndex(column => column.id === variableName) >= 0
                if (!hasColumn) {
                    customColumns.push({ id: variableName, displayName: prepareTextValue(title) })
                }
                data[variableName] = prepareTextValue(definition.getParam(variableName))
            })
        }


        datas.push(data)

    })

    datas = datas.sort((a, b) => a.actionName.localeCompare(b.actionName) || a.templateName.localeCompare(b.templateName))
    customColumns.sort((a, b) => a.id.localeCompare(b.id))
    columns = [...columns, ...customColumns]
    exportCsv(fileName || 'Export.csv', columns, datas)
}

/**
 * csv export completed messages
 * @param {Array<Message>} messages 
 * @param {Array<PublicUser>} publicUsers 
 * @param {Array<Org>} orgs 
 * @param {String} fileName 
 */
export const csvExportCompletedMessages = (messages, publicUsers, orgs, replacements, fileName = null, exportIdFields = true) => {

    const fetchPublicUsername = (publicUserId) => {
        if (publicUserId) {
            const publicUser = publicUsers.find(user => user.getId() === publicUserId)
            if (publicUser) {
                return publicUser.getNickname()
            }
        }
        return ''
    }

    let columns = null

    if (exportIdFields) {
        columns = [
            {
                id: 'messageId',
                displayName: prepareTextValue('Message-ID')
            },
            {
                id: 'definitionOrgId',
                displayName: prepareTextValue('Definition-ORG-ID')
            },
            {
                id: 'definitionOrgName',
                displayName: prepareTextValue('Definition-ORG')
            },
            {
                id: 'definitionOrgLevel4Name',
                displayName: prepareTextValue('Def-Org-Level4')
            },
            {
                id: 'definitionId',
                displayName: prepareTextValue('Definition-ID')
            },
            {
                id: 'deviceAssignedOrgId',
                displayName: prepareTextValue('Device-Assigned-ORG-ID')
            },
            {
                id: 'deviceAssignedOrgName',
                displayName: prepareTextValue('Device-Assigned-ORG')
            },
            {
                id: 'deviceAssignedOrgLevel4Name',
                displayName: prepareTextValue('Dev-Ass-Level4')
            },
            {
                id: 'deviceAssignedOrgLevel3Name',
                displayName: prepareTextValue('Dev-Ass-Level3')
            },
            {
                id: 'deviceAssignedOrgLevel2Name',
                displayName: prepareTextValue('Dev-Ass-Level2')
            },
            {
                id: 'deviceAssignedCostCenter',
                displayName: prepareTextValue('Cost-Center')
            },
            {
                id: 'ts',
                displayName: prepareTextValue('Triggering-Timestamp')
            },
            {
                id: 'triggeringUser',
                displayName: prepareTextValue('Triggering User')
            },
            {
                id: 'header',
                displayName: prepareTextValue('Header')
            },
            {
                id: 'body',
                displayName: prepareTextValue('Body')
            },
            {
                id: 'ackUserId',
                displayName: prepareTextValue('Acknowledge-User-ID')
            },
            {
                id: 'processingTs',
                displayName: prepareTextValue('Processing-Timestamp')
            },
            {
                id: 'processingUserId',
                displayName: prepareTextValue('Processing-User-ID')
            },
            {
                id: 'processingUser',
                displayName: prepareTextValue('Processing User')
            },
            {
                id: 'ackTs',
                displayName: prepareTextValue('Acknowledge-Timestamp')
            },
            {
                id: 'ackUser',
                displayName: prepareTextValue('Acknowledge-User')
            },
            {
                id: 'triggeringUserId',
                displayName: prepareTextValue('Triggering-User-Id')
            },
            {
                id: 'processingTime',
                displayName: prepareTextValue('proc time')
            },
            {
                id: 'confTime',
                displayName: prepareTextValue('conf time')
            },
            {
                id: 'workTime',
                displayName: prepareTextValue('total time')
            },

        ]
    } else {
        columns = [
            {
                id: 'definitionOrgName',
                displayName: prepareTextValue('Definition-ORG')
            },
            {
                id: 'definitionOrgLevel4Name',
                displayName: prepareTextValue('Def-Org-Level4')
            },
            {
                id: 'deviceAssignedOrgName',
                displayName: prepareTextValue('Device-Assigned-ORG')
            },
            {
                id: 'deviceAssignedOrgLevel4Name',
                displayName: prepareTextValue('Dev-Ass-Level4')
            },
            {
                id: 'deviceAssignedOrgLevel3Name',
                displayName: prepareTextValue('Dev-Ass-Level3')
            },
            {
                id: 'deviceAssignedOrgLevel2Name',
                displayName: prepareTextValue('Dev-Ass-Level2')
            },
            {
                id: 'deviceAssignedCostCenter',
                displayName: prepareTextValue('Cost-Center')
            },
            {
                id: 'ts',
                displayName: prepareTextValue('Triggering-Timestamp')
            },
            {
                id: 'triggeringUser',
                displayName: prepareTextValue('Triggering User')
            },
            {
                id: 'header',
                displayName: prepareTextValue('Header')
            },
            {
                id: 'body',
                displayName: prepareTextValue('Body')
            },
            {
                id: 'processingTs',
                displayName: prepareTextValue('Processing-Timestamp')
            },
            {
                id: 'processingUser',
                displayName: prepareTextValue('Processing User')
            },
            {
                id: 'ackTs',
                displayName: prepareTextValue('Acknowledge-Timestamp')
            },
            {
                id: 'ackUser',
                displayName: prepareTextValue('Acknowledge-User')
            },
            {
                id: 'processingTime',
                displayName: prepareTextValue('proc time')
            },
            {
                id: 'confTime',
                displayName: prepareTextValue('conf time')
            },
            {
                id: 'workTime',
                displayName: prepareTextValue('total time')
            },
        ]
    }

    const addedParamKeys = []

    let datas = []
    messages.map(message => {

        const definitionOrg = orgs && orgs.find(org => org.getId() === message.getDefinitionOrgId())
        const deviceAssignedOrg = orgs && orgs.find(org => org.getId() === message.getDeviceAssignedOrgId())
        let data = null

        const processingTime = moment(new Date(message.ackTs)).diff(moment(new Date(message.processingTs)), 'seconds')
        const confTime = moment(new Date(message.processingTs)).diff(moment(new Date(message.ts)), 'seconds')
        const workTime = moment(new Date(message.ackTs)).diff(moment(new Date(message.ts)), 'seconds')

        const processingTimeText = prepareTextValue(moment.duration(processingTime, 'seconds').asSeconds())
        const confTimeText = prepareTextValue(moment.duration(confTime, 'seconds').asSeconds())
        const workTimeText = prepareTextValue(moment.duration(workTime, 'seconds').asSeconds())

        const definitionOrgTreeNames = buildOrgTreeNames(orgs, definitionOrg)
        const deviceAssignedOrgTreeNames = buildOrgTreeNames(orgs, deviceAssignedOrg)

        const definitionOrgLevel4Name = definitionOrgTreeNames.length > 0 ? definitionOrgTreeNames[definitionOrgTreeNames.length-1] : ''
        const deviceAssignedOrgLevel4Name = deviceAssignedOrgTreeNames.length >= 4 ? deviceAssignedOrgTreeNames[3] : ''
        const deviceAssignedOrgLevel3Name = deviceAssignedOrgTreeNames.length >= 3 ? deviceAssignedOrgTreeNames[2] : ''
        const deviceAssignedOrgLevel2Name = deviceAssignedOrgTreeNames.length >= 2 ? deviceAssignedOrgTreeNames[1] : ''

        if (exportIdFields) {
            data = {
                messageId: prepareTextValue(message.getId()),
                definitionOrgId: prepareTextValue(message.getDefinitionOrgId()),
                definitionOrgName: definitionOrgTreeNames.join(' / '),
                definitionOrgLevel4Name,
                definitionId: prepareTextValue(message.getDefinitionId()),
                deviceAssignedOrgId: prepareTextValue(message.getDeviceAssignedOrgId()),
                deviceAssignedOrgName: deviceAssignedOrgTreeNames.join(' / '),
                deviceAssignedOrgLevel4Name,
                deviceAssignedOrgLevel3Name,
                deviceAssignedOrgLevel2Name,
                deviceAssignedCostCenter: deviceAssignedOrg && deviceAssignedOrg.getParam(PARAM_BILLING_POINT) || '',
                ts: prepareTextValue(moment(new Date(message.ts)).format("YYYY-MM-DD HH:mm:ss")),
                triggeringUser: prepareTextValue(fetchPublicUsername(message.getTriggeringUserId())),
                header: prepareTextValue(message.getHeader()),
                body: prepareTextValue(message.getBody()),
                ackUserId: prepareTextValue(message.getAcknowledgedUserId()),
                processingTs: prepareTextValue(message.processingTs ? moment(new Date(message.processingTs)).format("YYYY-MM-DD HH:mm:ss") : ''),
                processingUserId: prepareTextValue(message.getProcessingUserId()),
                processingUser: prepareTextValue(fetchPublicUsername(message.getProcessingUserId())),
                ackTs: prepareTextValue(moment(new Date(message.ackTs)).format("YYYY-MM-DD HH:mm:ss")),
                ackUser: prepareTextValue(fetchPublicUsername(message.getAcknowledgedUserId())),
                triggeringUserId: prepareTextValue(message.getTriggeringUserId()),
                processingTime: processingTimeText,
                confTime: confTimeText,
                workTime: workTimeText,

            }
        } else {
            data = {
                definitionOrgName: definitionOrgTreeNames.join(' / '),
                definitionOrgLevel4Name,
                deviceAssignedOrgName: deviceAssignedOrgTreeNames.join(' / '),
                deviceAssignedOrgLevel4Name,
                deviceAssignedOrgLevel3Name,
                deviceAssignedOrgLevel2Name,
                deviceAssignedCostCenter: deviceAssignedOrg && deviceAssignedOrg.getParam(PARAM_BILLING_POINT) || '',
                ts: prepareTextValue(moment(new Date(message.ts)).format("YYYY-MM-DD HH:mm:ss")),
                triggeringUser: prepareTextValue(fetchPublicUsername(message.getTriggeringUserId())),
                header: prepareTextValue(message.getHeader()),
                body: prepareTextValue(message.getBody()),
                processingTs: prepareTextValue(message.processingTs ? moment(new Date(message.processingTs)).format("YYYY-MM-DD HH:mm:ss") : ''),
                processingUser: prepareTextValue(fetchPublicUsername(message.getProcessingUserId())),
                ackTs: prepareTextValue(moment(new Date(message.ackTs)).format("YYYY-MM-DD HH:mm:ss")),
                ackUser: prepareTextValue(fetchPublicUsername(message.getAcknowledgedUserId())),
                processingTime: processingTimeText,
                confTime: confTimeText,
                workTime: workTimeText,
            }
        }

        const params = message.getParams()
        const paramsOrigin = message.getParamsOrigin()

        params && Object.keys(params).map(paramKey => {
            const replacement = replacements.find(_replacement => paramKey === _replacement.getId())
            let value = params[paramKey]
            if ( replacement &&  replacement.getType() === REPLACEMENT_TYPE.REPORT) {
                // report data can be in json format, like: {"text":"Report...","changedParams":null}
                try {
                    const valueObj = JSON.parse(value)
                    if (valueObj.text) {
                        value = prepareTextValue(valueObj.text)
                    }
                } catch (error) {
                }
            }
            data[paramKey] = value
            if (addedParamKeys.indexOf(paramKey) === -1) addedParamKeys.push(paramKey)
            if (paramsOrigin && data[paramKey] !== paramsOrigin[paramKey]) {
                const replacementName = replacement ? replacement.getName() : paramKey
                data[keyForReplacementName(replacementName)] = prepareTextValue(paramsOrigin[paramKey])
            }
        })
        datas.push(data)
    })

    addedParamKeys.map(addedParamKey => {
        const replacement = replacements.find(_replacement => addedParamKey === _replacement.getId())
        const replacementName = replacement ? replacement.getName() : addedParamKey
        const orderedReplacement = datas.find(data => Object.keys(data).indexOf(keyForReplacementName(replacementName)) > -1)
        columns.push({
            id: addedParamKey,
            displayName: prepareTextValue(replacementName)
        })
        if (orderedReplacement) {
            const columnId = keyForReplacementName(replacementName)
            columns.push({
                id: columnId,
                displayName: prepareTextValue(columnId)
            })
        }
    })


    exportCsv(fileName || 'Export.csv', columns, datas)
}

function exportCsv(filename, columns, datas, separator = ";", noHeader = false, bom = true) {
    const csv = toCsv(columns, datas, separator, noHeader)

    if (filename.indexOf('.csv') < 0) {
        filename += '.csv';
    }

    downloadCsvFile(filename, csv, bom)
}

export function downloadCsvFile(filename, csvString, bom = true) {
    const bomCode = bom ? '\ufeff' : '';
    const blob = new Blob([`${bomCode}${csvString}`], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, filename);
}