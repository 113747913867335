export const PERMISSION_ACTION_TYPE = {
  READ: 'r',
  WRITE: 'w',
  PERMIT: 'p',
  MANAGE_USERS: 'manage_users',
  WRITE_IMAGES: 'w.img',
  FULL_ACCESS: '*',
};

export const mapPermissionActionTypeTranslationKey = actionType => {
  switch (actionType) {
    case PERMISSION_ACTION_TYPE.READ:
      return 'read_access';
    case PERMISSION_ACTION_TYPE.WRITE:
      return 'write_access';
    case PERMISSION_ACTION_TYPE.PERMIT:
      return 'permit_access';
    case PERMISSION_ACTION_TYPE.WRITE_IMAGES:
      return 'write_images_access';
    case PERMISSION_ACTION_TYPE.MANAGE_USERS:
      return 'manage_users';
    case PERMISSION_ACTION_TYPE.FULL_ACCESS:
      return 'full_access';
  }
  return null;
};

export const ENTITY_GROUP_TYPE = {
  ORG: 'org',
  ROLE: 'role',
  GROUP: 'grp',
};

export const mapPermissionEntityGroupTypeTranslationKey = entityGroupType => {
  switch (entityGroupType) {
    case ENTITY_GROUP_TYPE.ORG:
      return 'org';
    case ENTITY_GROUP_TYPE.ROLE:
      return 'role';
    case ENTITY_GROUP_TYPE.GROUP:
      return 'group';
  }
  return null;
};

export default class Permission {
  /**
   * @param {Object<String,String,Uid>}
   */
  constructor({ entityGroup, action, resource }) {
    this.entityGroup = entityGroup;
    this.action = action;
    this.resource = resource;
  }

  /**
   * Get entity group
   * @returns {String}
   */
  getEntityGroup() {
    return this.entityGroup;
  }

  /**
   * Get action
   * @returns {String}
   */
  getAction() {
    return this.action;
  }

  /**
   * Get resource
   * @returns {Uid}
   */
  getResource() {
    return this.resource;
  }
}
