import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withDarkTheme } from '../../../theme'
import { Button, Grid, InputAdornment, IconButton, Checkbox, FormControlLabel, Link} from '@material-ui/core'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import Logo from '../../../Libs/Components/Logo'
import Typography from '@material-ui/core/Typography'
import { withTranslation } from 'react-i18next'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import Footer from '../../../Libs/Components/Footer'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import I18n from '../../../i18n'

const styles = theme => ({
    contentContainer: {
        maxWidth: 350,
        width: '100%'
    },
    container: {
        height: '100%',
        alignItems: 'center',
    },
    leftColumn: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        }
    },
    rightColumn: {
        backgroundColor: 'black',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            paddingTop: theme.spacing(5)
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    checkboxContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        alignItems: 'flex-start'
    },
    checkbox: {
        marginTop: - theme.spacing(1)
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    backButton: {
        marginTop: 10,
        cursor: 'pointer'
    },
    submitButton: {
        flex: 1,
        marginTop: 10
    }
})

const TocLabel = ({}) => {
    switch (I18n.language) {
        case 'de':
            return (
                <Typography variant="body2" style={{color: '#fff'}}>
                    Ich akzeptiere die <Link href={'https://www.sfs.ch/de/Datenschutz'} target="_blank">Datenschutzrichtlinie</Link> und die <Link href={'https://www.sfs.ch/de/AGB'} target="_blank">AGB für Geschäftskunden</Link> oder <Link href={'https://www.sfs.ch/de/AGB_Privatkunden'} target="_blank">Privatkunden</Link>
                </Typography>
            )
        case 'es':
            return (
                <Typography variant="body2" style={{color: '#fff'}}>
                    Acepto la <Link href={'https://www.sfs.ch/de/Datenschutz'} target="_blank">directriz de protección de datos</Link> y los términos y condiciones para <Link href={'https://www.sfs.ch/de/AGB'} target="_blank">clientes comerciales</Link> o <Link href={'https://www.sfs.ch/de/AGB_Privatkunden'} target="_blank">particulares</Link>
                </Typography>
            )
        default:
            return (
                <Typography variant="body2" style={{color: '#fff'}}>
                    I accept the <Link href={'https://www.sfs.ch/de/Datenschutz'} target="_blank">data protection guideline</Link> and the terms and conditions for <Link href={'https://www.sfs.ch/de/AGB'} target="_blank">business customers</Link> or <Link href={'https://www.sfs.ch/de/AGB_Privatkunden'} target="_blank">private customers</Link>
                </Typography>
            )
    }
}

class Activate extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            password: '',
            passwordConfirm: '',
            activationCode: this.props.match.params.code || '',
            showSusccessAlert: false,
            error: null,
            showPassword: false,
            tocConfirmed: false
        }
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        })
    }

    componentWillUnmount() {
        if (ValidatorForm && ValidatorForm.removeValidationRule) {
            ValidatorForm.removeValidationRule('isPasswordMatch')
        }
    }

    handleKeyPress(event) {
        if (event.charCode === 13) { // enter key
            event.preventDefault()
            this.handleSubmit()
        }
    }

    handleSubmit() {
        this.setState({ loading: true })
        const { activationCode, password } = this.state
        this.props.context.userService.activateUser(this.props.match.params.id, Number(activationCode), password)
        .then( result => {
            this.setState({showSusccessAlert: true})
        })
        .catch( error => {
            console.log('error', error)
            this.setState({error: error, loading: false})
        })

    }

    render() {
        const { activationCode, password, passwordConfirm, loading, error, showPassword } = this.state
        const {t, classes } = this.props
        return (
            <Grid container className={classes.container}>
                <Grid item sm={6} xs={12} className={classes.leftColumn}>
                    <Logo />
                </Grid>
                <Grid item sm={6} xs={12} className={classes.rightColumn}>
                    <div className={classes.contentContainer}>
                        <Typography style={{ textAlign: 'center', marginTop: 30 }} variant="h5" color="secondary" gutterBottom>
                            {this.props.t('account_activation_title')}
                        </Typography>
                        <Typography style={{ textAlign: 'center', marginTop: 30 }} variant="body1" color="secondary" gutterBottom>
                            {this.props.t('account_activation_description')}
                        </Typography>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit.bind(this)}
                            onError={errors => console.log("form error:", errors)}>
                                
                            <TextValidator
                                className={classes.field}
                                value={activationCode}
                                onChange={(event) => {
                                    this.setState({activationCode: event.target.value, error: null})
                                }}
                                margin="dense"
                                label={ t('activation_code') }
                                validators={['required', 'isNumber']}
                                errorMessages={[t('this_field_is_required'), t('this_field_must_be_a_number')]}
                                fullWidth/>

                            <TextValidator
                                className={classes.field}
                                value={password}
                                onChange={(event) => {
                                    this.setState({password: event.target.value, error: null})
                                }}
                                margin="dense"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                size={'small'}
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({ showPassword: !showPassword })}
                                            >
                                                {showPassword ? <VisibilityOff /> :  <Visibility /> }
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                label={ t('password') }
                                validators={['required', 'matchRegexp:((?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%!]).{8,40})']}
                                errorMessages={[t('this_field_is_required'), t('password_complexity_error')]}
                                fullWidth/>

                            <TextValidator
                                className={classes.field}
                                value={passwordConfirm}
                                onChange={(event) => {
                                    this.setState({passwordConfirm: event.target.value, error: null})
                                }}
                                margin="dense"
                                type={showPassword ? 'text' : 'password'}
                                label={ t('password_confirm') }
                                validators={['isPasswordMatch']}
                                errorMessages={[t('passwords_do_not_match')]}
                                fullWidth 
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                size={'small'}
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({ showPassword: !showPassword })}
                                            >
                                                {showPassword ? <VisibilityOff /> :  <Visibility /> }
                                            </IconButton>
                                        </InputAdornment>
                                }} />

                                { error && <div className={classes.errorText}>
                                    <Typography variant="body1" color="error" gutterBottom>
                                        {error.message}
                                    </Typography>
                                </div> }
                            <FormControlLabel
                                className={classes.checkboxContainer}
                                control={<Checkbox className={classes.checkbox} checked={this.state.tocConfirmed} onChange={(event) => this.setState({tocConfirmed: event.target.checked})} name="toc" />}
                                label={<TocLabel/>} />
                            <div className={classes.buttonContainer}>
                                <Button onClick={() => {}} disabled={loading || !this.state.tocConfirmed} type="submit" color="primary" variant="contained" className={classes.submitButton}>
                                    {t('activate')}
                                </Button>
                                <Typography className={classes.backButton} onClick={() => this.props.history.push('/')} color="secondary" variant="body1" gutterBottom>
                                    {this.props.t("cancel")}
                                </Typography>
                            </div>
                        </ValidatorForm>
                        <AlertDialog
                            open={this.state.showSusccessAlert}
                            title={this.props.t('activation_successfully_message_title')}
                            message={this.props.t('activation_successfully_message_description')}
                            onSubmit={() => this.props.history.push('/')}/>
                    </div>
                </Grid>
                <Footer />
            </Grid>
        )
    }

}


export default withTranslation()(withDarkTheme(withStyles(styles)(Activate)))