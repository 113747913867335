import React, { Fragment, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { SETTINGS_CODE_TOKEN, DEFAULT_EVENT_NAME, CODE_DEVICE_DRIVER_NAME } from '../../../m2m-cloud-api/Api/Drivers/CodeDriver'
import { IconButton, Fab, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Menu, MenuItem, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import RemoveIcon from '@material-ui/icons/Remove'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const LightTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip)

const useStyles = makeStyles(theme => ({
    root: {
        maxHeight: '100%',
        overflow: 'auto',
        paddingBottom: 80
    },
    table: {
        maxHeight: '100%',
        overflow: 'auto',
    },
    fab: {
        position: 'fixed',
        right:  theme.spacing(2),
        bottom:  theme.spacing(2)
    },
    deviceType: {
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        borderRadius: 2,
        minWidth: 60,
        textAlign: 'center',
        display: 'inline-block',
        marginRight: theme.spacing(1),
    }
}))


function DeviceListView({ api, items, itemOrgs, org, onAdd, onActivate, onDeactivateDevice, onAssign, onUnassign, onTriggerEvent, onShowEventData, onEditSettings, onUnclaim, onReset }) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [ menuAnchorEl, setMenuAnchorEl ] = useState(null)
    const [ selectedDevice, setSelectedDevice ] = useState(null)
    const context = api

    const devices = items && items.sort( (deviceA, deviceB) => {
        const physicalIdA = deviceA.getPhysicalId()
        const codeA = physicalIdA.substring(physicalIdA.indexOf(':') + 1)
        const physicalIdB = deviceB.getPhysicalId()
        const codeB = physicalIdB.substring(physicalIdB.indexOf(':') + 1)
        return codeA.localeCompare(codeB)
    })

    const buildTriggerUrl = (device) => {
        const physicalId = device.physicalId
        const code = physicalId.substring(physicalId.indexOf(':') + 1)
        const nameSpace = physicalId.substring(0, physicalId.indexOf(':'))
        const token = device.getSetting(SETTINGS_CODE_TOKEN)

        const url = context.deviceService.codeDriver.buildEventGetUrl(nameSpace, code, DEFAULT_EVENT_NAME, token, '1')
        return url
    }



    const renderDevice = (device) => {

        const driver = device.getDriver()
        const id = device.getId()
        const isActivated = device.isActivated()
        const ownerOrgId = device.getOwnerOrg()
        const assignedOrgId = device.getAssignedOrg()
        const assignedOrg = itemOrgs.find( org => org.getId() === assignedOrgId )


        let isOwnerSelf = ownerOrgId === org.getId() ? true :false
        let isAssignedSelf = assignedOrgId === org.getId() ? true : false
        const physicalId = device.getPhysicalId()
        const code = physicalId.substring(physicalId.indexOf(':') + 1)
        const nameSpace = physicalId.substring(0, physicalId.indexOf(':'))

        return (
            <Fragment key={id}>
                <TableRow key={driver} className={classes.tableRow}>
                    <TableCell component="th" scope="row" style={{width: 50}}>
                        { !driver || driver !== CODE_DEVICE_DRIVER_NAME && <span className={classes.deviceType}>{driver.toUpperCase()}</span> }
                        { driver && driver === CODE_DEVICE_DRIVER_NAME && <LightTooltip color={'secondary'} title={nameSpace ? t('namespace', { namespace: nameSpace }) : t('no_namespace')}><span className={classes.deviceType}>{driver.toUpperCase()}</span></LightTooltip> }
                    </TableCell>
                    <TableCell component="td" scope="row">
                        <Typography>{code}</Typography>
                        <Typography variant="caption" color="textSecondary">{id}</Typography>
                    </TableCell>
                    <TableCell align="right">
                        {isAssignedSelf ? <CheckIcon /> : <span>{ assignedOrg ? assignedOrg.getName() : assignedOrgId ? assignedOrgId : <RemoveIcon /> }</span>}
                    </TableCell>
                    <TableCell align="right">
                        {isActivated ? <CheckIcon /> : <RemoveIcon /> }
                    </TableCell>
                    <TableCell align="right">
                        <IconButton 
                            size="small"
                            disabled={device && (device.getDriver() !== 'sta2' && device.getDriver() !== 'espi')}
                            onClick={(event) => {
                                setMenuAnchorEl(event.currentTarget)
                                setSelectedDevice(device)
                            }}>
                            <MoreVertIcon />
                        </IconButton>
                        </TableCell>
                </TableRow>
            </Fragment>
        )
    }
    
    return (
        <div className={classes.root}>
            { devices && devices.length > 0 && <Table stickyHeader className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('device_type')}</TableCell>
                        <TableCell>{t('device')}</TableCell>
                        <TableCell align="right">{t('assigned')}</TableCell>
                        <TableCell align="right">{t('activated')}</TableCell>
                        <TableCell align="right">{t('row_actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        devices && devices.map(device => renderDevice(device))
                    }
                </TableBody>
            </Table>}
            {onAdd && <Fragment>
                <Fab onClick={(event) => onAdd() } color="primary" aria-label="Add" className={classes.fab}>
                    <AddIcon />
                </Fab>
            </Fragment> }
            { menuAnchorEl && selectedDevice && <Menu
                id="menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}>
                { onEditSettings && <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setSelectedDevice(null)
                        onEditSettings(selectedDevice)
                    }}>
                    <Typography variant="inherit"> {t('edit_settings')}</Typography>
                </MenuItem> }
                { onAssign && !selectedDevice.getAssignedOrg() && <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setSelectedDevice(null)
                        onAssign(selectedDevice)
                    }}>
                    <Typography variant="inherit"> {t('assign')}</Typography>
                </MenuItem> }
                {onUnassign && <MenuItem 
                    disabled={!(selectedDevice.getAssignedOrg() && !selectedDevice.isActivated())}
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setSelectedDevice(null)
                        onUnassign(selectedDevice)
                    }}>
                    <Typography variant="inherit"> {t('unassign')}</Typography>
                </MenuItem>}
                {onActivate && <MenuItem 
                    disabled={!(selectedDevice.getAssignedOrg() && !selectedDevice.isActivated())}
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setSelectedDevice(null)
                        onActivate(selectedDevice)
                    }}>
                    <Typography variant="inherit"> {t('activate')}</Typography>
                </MenuItem> }
                {onDeactivateDevice && <MenuItem 
                    disabled={!(selectedDevice.getAssignedOrg() && selectedDevice.isActivated())}
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setSelectedDevice(null)
                        onDeactivateDevice(selectedDevice)
                    }}>
                    <Typography variant="inherit"> {t('deactivate')}</Typography>
                </MenuItem>}
                { onTriggerEvent && selectedDevice.getDriver() === CODE_DEVICE_DRIVER_NAME && 
                    <CopyToClipboard className={classes.button} text={buildTriggerUrl(selectedDevice)}>
                        <MenuItem 
                            disabled={!(selectedDevice.getAssignedOrg() && selectedDevice.isActivated())}
                            onClick={(event) => {
                                setMenuAnchorEl(null)
                                setSelectedDevice(null)
                            }}>
                                <Typography variant="inherit"> {t('copy_event_trigger_link')}</Typography>
                            </MenuItem>
                    </CopyToClipboard>}
                {onTriggerEvent && <MenuItem 
                    disabled={!(selectedDevice.getAssignedOrg() && selectedDevice.isActivated())}
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setSelectedDevice(null)
                        onTriggerEvent(selectedDevice)
                    }}>
                    <Typography variant="inherit"> {selectedDevice.getDriver() === CODE_DEVICE_DRIVER_NAME ? t('trigger_event') : t('send_command')}</Typography>
                </MenuItem> }
                { selectedDevice && (selectedDevice.getDriver() === 'sta2' || selectedDevice.getDriver() === 'espi') && onReset && <MenuItem 
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setSelectedDevice(null)
                        onReset(selectedDevice)
                    }}>
                    <Typography variant="inherit"> {t('reset')}</Typography>
                </MenuItem> }
                {onShowEventData && <MenuItem 
                    disabled={!(selectedDevice.getAssignedOrg() && selectedDevice.isActivated())}
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        setSelectedDevice(null)
                        onShowEventData(selectedDevice)
                    }}>
                    <Typography variant="inherit"> {t('show_event_data')}</Typography>
                </MenuItem> }
                {onUnclaim && <MenuItem 
                    disabled={selectedDevice.getAssignedOrg() && !selectedDevice.isActivated()}
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onUnclaim(selectedDevice)
                        setSelectedDevice(null)
                    }}>
                    <Typography variant="inherit"> {t('unclaim_device')}</Typography>
                </MenuItem> }
            </Menu>}
        </div>
    )
}

export default DeviceListView