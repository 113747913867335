import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemSecondaryAction, IconButton, ListItemText, Box, Divider, Menu, MenuItem, ListItemIcon, Typography, Checkbox } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreIcon from '@material-ui/icons/MoreVert'
import ResetIcon from '@material-ui/icons/SettingsBackupRestore'

function DefaultListItem({ entity, checked, onClick, onEditClick, onDeleteClick, onResetClick, badgeLabel, titleBadgeLabel, paramsLabel, ...otherProps }) {
    const classes = useStyles()
    const [menuAnchorEl, setMenuAnchorEl] = useState(null)
    const { t } = useTranslation()

    return (
        <Fragment>
            <ListItem {...otherProps} className={classes.listItem}>
                <ListItemIcon style={{ minWidth: 'auto' }}>
                    <Checkbox
                        edge="start"
                        color="primary"
                        checked={checked}
                        onClick={() => onClick(entity)}
                        tabIndex={-1}
                        inputProps={{ 'aria-labelledby': entity.id }}
                    />
                </ListItemIcon>
                <ListItemText
                    className={classes.listItemText}
                    primary={
                        <Typography variant="subtitle2">
                            <Box display={'flex'} alignItems={'center'}>
                                <div>{entity.title}</div>
                                {paramsLabel && <Typography className={`${classes.listItemTextSecondary} paramsLabel`} variant="subtitle2">{paramsLabel}</Typography>}
                            </Box>
                            {titleBadgeLabel && <div className={classes.titleBadge}>{titleBadgeLabel}</div>}
                        </Typography>
                    }
                    secondary={badgeLabel && <Typography className={classes.listItemTextSecondary} variant="subtitle2">{badgeLabel}</Typography>}
                />
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>

                    <IconButton
                        className={classes.moreIconButton}
                        size={'small'}
                        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                        edge="end">
                        <MoreIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            { menuAnchorEl && <Menu
                id="menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}>
                {onEditClick && <MenuItem
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onEditClick(entity)
                    }}>
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('edit')}</Typography>
                </MenuItem>}
                {onResetClick && <MenuItem
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onResetClick(entity)
                    }}>
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <ResetIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('reset')}</Typography>
                </MenuItem>}
                <MenuItem
                    onClick={(event) => {
                        setMenuAnchorEl(null)
                        onDeleteClick(entity)
                    }}>
                    <ListItemIcon style={{ minWidth: 35 }}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('delete')}</Typography>
                </MenuItem>
            </Menu>}
        </Fragment>
    )
}


const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25)
    },
    listItemText: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 40,
        alignItems: 'center'
    },
    listItemTextSecondary: {
        backgroundColor: theme.palette.grey['100'],
        textAlign: 'right',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        '&.paramsLabel': {
            fontStyle: 'italic',
            marginLeft: theme.spacing(1)
        }
    },
    listItemSecondaryAction: {
        right: theme.spacing(1)
    },
    moreIconButton: {
        color: 'inherit',
    },
    titleBadge: {
        display: 'inline-block',
        backgroundColor: theme.palette.secondary.main,
        padding: '0px 4px',
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        fontSize: theme.font.small,
        marginRight: theme.spacing(0.5)
    }

}))

export default DefaultListItem