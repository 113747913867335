import React, { useCallback, useContext, useState } from 'react'
import { withRouter } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import ReactSVG from 'react-svg'
import LayersIcon from '@material-ui/icons/Layers'

const ThemeSelector = ({ t }) => {
    const context = useContext(ApiContext)
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    
    const selectedTheme = context.getRootOrgTheme()
    const themes = context.getRootOrgThemes()

    const onClick = useCallback((themeId) => {
        setAnchorEl(null)
        context.setRootOrgTheme(themeId)
    }, [])

    return (
        <>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                <LayersIcon className={classes.button} />
            </IconButton>
           {<Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={() => setAnchorEl(null)}>
                    <MenuItem selected={!selectedTheme} onClick={() => onClick(null)}>{t('default')}</MenuItem>
                    { themes?.map(theme => <MenuItem selected={selectedTheme?.id === theme.id} onClick={() => onClick(theme.id)}>{theme.title}</MenuItem>)}
           </Menu>}
        </>
    )
}

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.common.white,
        marginRight: - theme.spacing(1)
    }
}))

export default withRouter(withTranslation()(ThemeSelector))