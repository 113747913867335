import UpdateMeta from '../../Models/UpdateMeta';

export default class Group {
  /**
   * @param {Object<Uid,String,Array,Array,Object,Object>}
   */
  constructor({ id, name, roles, users, creator, lastUpd }) {
    this.id = id;
    this.name = name;
    this.roles = roles;
    this.users = users;
    this.creator = creator ? new UpdateMeta(creator) : null;
    this.lastUpd = lastUpd ? new UpdateMeta(lastUpd) : null;
  }

  /**
   * Get group id
   * @returns {Uid}
   */
  getId() {
    return this.id;
  }

  /**
   * Get group name
   * @returns {String}
   */
  getName() {
    return this.name;
  }

  /**
   * Get group roles
   * @returns {Array<Uid>}
   */
  getRoles() {
    return this.roles;
  }

  /**
   * Get group users
   * @returns {Array<Uid>}
   */
  getUsers() {
    return this.users;
  }

  /**
   * Get creator
   * @returns {UpdateMeta}
   */
  getCreator() {
    return this.creator;
  }

  /**
   * Get last updater
   * @returns {UpdateMeta}
   */
  getLastUpdater() {
    return this.lastUpd;
  }
}
