import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { darkTheme, lightTheme } from '../../../theme'
import { withTranslation } from 'react-i18next'
import { APP_MODE, ORG_TAG } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { Grid, IconButton, Typography, Button } from '@material-ui/core'
import HeaderBar from '../Components/HeaderBar'
import ReactSVG from 'react-svg'
import GroupSelectionModal, { ORG_SELECTION_TYPE } from '../Components/SelectScreen/GroupSelectionModal'
import UserButton from '../../../Libs/Components/UserButton'
import { getPath } from '../App'

class SelectScreen extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            groupSelectionModalVisible: false,
            groupSelectionOrgType: null,
            accessibleAppMode: []
        }
        this.setAsyncState = async (state) => {
            return new Promise((resolve) => {
                this.setState(state, resolve)
            })
        }
    }

    async componentDidMount() {
        this.props.context.setAppMode(APP_MODE.SELECT_SUPPLIER_OR_RECIPIENT, async () => {
            const accessibleAppMode = []
            const orgs = this.props.context.allAccessibleOrgs
            orgs?.map(org => {
                if (org.getTags().indexOf(ORG_TAG.RECIPIENT_LOCATION) > -1) {
                    if (accessibleAppMode.indexOf(APP_MODE.RECIPIENT) === -1) {
                        accessibleAppMode.push(APP_MODE.RECIPIENT)
                    }
                }
                else if (org.getTags().indexOf(ORG_TAG.SUPPLIER_TARGET) > -1) {
                    if (accessibleAppMode.indexOf(APP_MODE.SUPPLIER) === -1) {
                        accessibleAppMode.push(APP_MODE.SUPPLIER)
                    }
                }
            })
            await this.setAsyncState({ accessibleAppMode, loading: false })
        })
    }

    onCloseGroupSelectionDialog = async (orgIds) => {
        const { groupSelectionOrgType } = this.state
        this.setState({ groupSelectionModalVisible: false, groupSelectionOrgType: null, loading: orgIds ? true : false })
        if (orgIds) {
            if (groupSelectionOrgType === ORG_SELECTION_TYPE.RECIPIENT_OBJECT) {
                await this.props.context.setRecipientOrgIds(orgIds, true)
                this.props.history.push(getPath('recipient'))
            } else {
                await this.props.context.setSupplierParentOrgIds(orgIds, true)
                this.props.history.push(getPath('supplier'))
            }
        }
    }

    async selectSupplierAppMode(animated = true) {
        const supplierSelectedOrgs = this.props.context.getSupplierParentOrgIds()
        if (supplierSelectedOrgs && supplierSelectedOrgs.length > 0) {
            this.props.context.setSupplierParentOrgIds(supplierSelectedOrgs, false)
            this.props.history.push(getPath('supplier'))
            this.props.context.setAppMode(APP_MODE.SUPPLIER)
        } else {
            this.setState({ groupSelectionModalVisible: true, groupSelectionOrgType: ORG_SELECTION_TYPE.SUPPLIER_GROUP })
        }
    }

    async selectRecipientAppMode() {
        const recipientSelectedOrgs = this.props.context.getRecipientOrgIds()
        if (recipientSelectedOrgs && recipientSelectedOrgs.length > 0) {
            this.props.context.setRecipientOrgIds(recipientSelectedOrgs, true)
            this.props.history.push(getPath('recipient'))
            this.props.context.setAppMode(APP_MODE.RECIPIENT)
        } else {
            this.setState({ groupSelectionModalVisible: true, groupSelectionOrgType: ORG_SELECTION_TYPE.RECIPIENT_OBJECT })
        }
    }

    render() {
        const { classes, context, t } = this.props
        const { accessibleAppMode, groupSelectionModalVisible, groupSelectionOrgType, loading } = this.state

        const recipientName = t('i_am_a_recipient')
        const supplierName = t('i_am_a_supplier')

        return (
            <div className={classes.container}>
                <div className={accessibleAppMode.length === 2 ? classes.headerCustom : null}>
                    <HeaderBar leftContent={null} />
                </div>
                {!loading && accessibleAppMode && accessibleAppMode.length > 0 && <Grid container className={classes.containerContent}>
                    {accessibleAppMode.indexOf(APP_MODE.RECIPIENT) > -1 && <ThemeProvider theme={darkTheme}>
                        <Grid item sm={12 / accessibleAppMode.length} xs={12} className={[classes.column, 'dark'].join(' ')}>
                            <IconButton
                                onClick={this.selectRecipientAppMode.bind(this)}
                                className={classes.selectIcon} >
                                <ReactSVG src="/assets/light/delivery.svg" />
                            </IconButton>
                            <Button
                                onClick={this.selectRecipientAppMode.bind(this)}
                                className={classes.selectButton}>
                                <Typography variant='h5' className={[classes.selectButtonText, classes.recipientNameText].join(' ')}>{recipientName}</Typography>
                            </Button>
                        </Grid>
                    </ThemeProvider>}
                    {accessibleAppMode.indexOf(APP_MODE.SUPPLIER) > -1 && <ThemeProvider theme={accessibleAppMode.length === 1 ? darkTheme : lightTheme}>
                        <Grid item sm={12 / accessibleAppMode.length} xs={12} className={[classes.column, accessibleAppMode.length === 1 && 'dark'].join(' ')}>
                            <IconButton
                                onClick={() => this.selectSupplierAppMode()}
                                className={classes.selectIcon}>
                                <ReactSVG src="/assets/light/recipient.svg" />
                            </IconButton>
                            <Button
                                onClick={() => this.selectSupplierAppMode()}
                                className={classes.selectButton}>
                                <Typography variant='h5' className={[classes.selectButtonText, classes.supplierNameText].join(' ')}>{supplierName}</Typography>
                            </Button>
                        </Grid>
                    </ThemeProvider>}
                    {groupSelectionModalVisible && <GroupSelectionModal visible={true} orgSelectionType={groupSelectionOrgType} onClose={this.onCloseGroupSelectionDialog} />}
                </Grid>}

                {(loading || (accessibleAppMode && accessibleAppMode.length === 0)) && <Grid className={classes.notFoundScreenContainer}>
                    <ThemeProvider theme={darkTheme}>
                        {!loading && <Typography variant='h5' color={'textPrimary'}>{t('no_configuration_available')}</Typography>}
                    </ThemeProvider>
                </Grid>
                }
                <div className={classes.userButtonSelect}>
                    <UserButton
                        userService={context.userService}
                        authzService={context.authzService}
                        pendingInvitations={context.pendingInvitations}
                        onInvitationAccepted={() => {
                            context.loadPendingInvitations()
                        }}
                        user={context.userService.getActiveUser()}
                        onLogout={() => context.userService.logoutUser()} />
                </div>
            </div >
        )
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        alignItems: 'stretch'
    },
    headerCustom: {
        width: '50%',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    containerContent: {
        flex: 1
    },
    column: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            height: '50%',
        },
        '& svg': {
            width: 150,
            height: 150
        },
        '&.dark': {
            backgroundColor: theme.palette.common.black,
        }
    },
    userButtonSelect: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        [theme.breakpoints.down('xs')]: {
            backgroundColor: theme.palette.common.black,
            width: '100%'
        },
    },
    selectIcon: {
        backgroundColor: theme.palette.primary.main,
        padding: 48,
        fontSize: 0,
        '& svg': {
            width: 96,
            height: 96
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    selectButton: {
        marginTop: theme.spacing(3),
        textTransform: 'none',
    },
    selectButtonText: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 2,
        fontWeight: 500
    },
    notFoundScreenContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.black,
    }
})
export default withTranslation()(withStyles(styles)(SelectScreen))