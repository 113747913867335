import React, { useState, useEffect, useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, Select, MenuItem, IconButton, ThemeProvider } from '@material-ui/core'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import clsx from 'clsx'
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { darkTheme } from '../../../theme'
import { REPLACEMENT_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import ReplacementField, { ReplacementTextField } from './Recipient/ReplacementFields'
import { PARAM_ADVANCED_SEARCH_FILTER } from '../../../m2m-cloud-api/Api/UserService/Models/User'
import { fetchItemDisplayOrgNames, MESSAGE_TYPE } from './MessageList'
import { getPreparedDefaultValue, prepareReplacementValueBeforeSave } from './Recipient/ReplacementsModal'
import moment from 'moment'

export const FILTER_KEYS = {
    CONDITION: 'cond',
    ITEM: 'item',
    OP: 'op',
    VALUE: 'value'
}
export const FILTER_OP_KEYS = {
    IS: 'is',
    IS_NOT: 'is_not',
    CONTAIN: 'contains',
    DOES_NOT_CONTAIN: 'does_not_contain',
    FROM: 'from',
    TO: 'to'
}
const FILTER_CONDITION_OR = 'or'
const FILTER_CONDITION_AND = 'and'
const FILTER_CONDITION_OPTIONS = [{ label: 'and', value: FILTER_CONDITION_AND }, { label: 'or', value: FILTER_CONDITION_OR }]

const FILTER_TYPE_OPTIONS = [FILTER_OP_KEYS.IS, FILTER_OP_KEYS.IS_NOT, FILTER_OP_KEYS.CONTAIN, FILTER_OP_KEYS.DOES_NOT_CONTAIN].map( key => ({ label: key, value: key }))
const FILTER_DATE_TYPE_OPTIONS = [FILTER_OP_KEYS.IS, FILTER_OP_KEYS.IS_NOT, FILTER_OP_KEYS.FROM, FILTER_OP_KEYS.TO].map( key => ({ label: key, value: key }))

const DEFAULT_FILTER_ITEM_OPTION_NAME = 'name'
const DEFAULT_FILTER_ITEM_OPTION_ORG = 'org'
const DEFAULT_FILTER_ITEM_OPTIONS = [{ label: 'name', value: DEFAULT_FILTER_ITEM_OPTION_NAME }, { label: 'organization', value: DEFAULT_FILTER_ITEM_OPTION_ORG }]
const EXCLUDED_REPLACEMENT_TYPES = [REPLACEMENT_TYPE.BAR_CODE, REPLACEMENT_TYPE.QR_CODE, REPLACEMENT_TYPE.IMAGE, REPLACEMENT_TYPE.REPORT, REPLACEMENT_TYPE.NOTE]

const getTypeOptions = (replacements, replacementId) => {
    const replacement = replacements.find( rep => rep.getId() === replacementId)
    return replacement?.getType() === REPLACEMENT_TYPE.DATE ? FILTER_DATE_TYPE_OPTIONS : FILTER_TYPE_OPTIONS
}

/**
 * get advanced search user param key
 * @param {MESSAGE_TYPE} messageType 
 * @param {MESSAGE_LIST_TYPE} messageListType 
 * @returns {String}
 */
export const getAdvancedSearchUserParamKey = (messageType, messageListType, themeColIndex) => {
    return `${PARAM_ADVANCED_SEARCH_FILTER}-${messageType}-${messageListType}${themeColIndex >= 0 ? '-' + themeColIndex : ''}`
}

/**
 * get advanced search user values
 * @param {Object} context 
 * @param {MESSAGE_TYPE} messageType 
 * @param {MESSAGE_LIST_TYPE} messageListType 
 * @param {Number|null} themeColIndex
 * @returns {Array<Object>}
 */
export const getAdvancedSearchValues = (context, messageType, messageListType, themeColIndex) => {
    const user = context?.userService?.getActiveUser()
    const defaultValues = user?.getParam(getAdvancedSearchUserParamKey(messageType, messageListType, themeColIndex))
        if (defaultValues) {
            try {
                const _values = JSON.parse(defaultValues)
                if (_values?.length > 0) {
                    return _values
                }
            } catch (error) {
                console.log("can't parse advanced search user param, error: ", error)                
            }
        }
        return null
}

/**
 * filter messages by advanced search user values
 * @param {Object} context 
 * @param {MESSAGE_TYPE} messageType 
 * @param {MESSAGE_LIST_TYPE} messageListType 
 * @param {Number|null} themeColIndex
 * @param {Array<Object>} messages 
 * @param {Array<Object>} replacements 
 * @returns {Array<Object>}
 */
export const filterMessages = (context, messageType, messageListType, themeColIndex, messages, replacements) => {
    const user = context.userService.getActiveUser()
    const filters = user && getAdvancedSearchValues(context, messageType, messageListType, themeColIndex)

    if (messages?.length > 0 && filters?.length > 0) {
        const filteredMessages = []
        
        for (let i = 0; i < messages.length; i++) {
            let matches = false
            const item = messages[i]
            for (let ii = 0; ii < filters.length; ii++) {
                const filter = filters[ii]
                const replacement = replacements?.find( rep => rep.getId() === filter.item)
                let searchText = null
                let findText = filter.value
                let searchDate = null
                let findDate = null
                let searchMoment = null
                let findMoment = null            
                switch (filter.item) {
                    case DEFAULT_FILTER_ITEM_OPTION_NAME:
                        searchText = messageType === MESSAGE_TYPE.ARTICLE ? item.title : item.getHeader()
                        break
                    case DEFAULT_FILTER_ITEM_OPTION_ORG:
                        let orgId = null
                        switch (messageType) {
                            case MESSAGE_TYPE.ARTICLE:
                                orgId = item.definition.getOrgId()
                                break
                            case MESSAGE_TYPE.RECIPIENT:
                                orgId = item.getDefinitionOrgId()
                                break
                            case MESSAGE_TYPE.SUPPLIER:
                                orgId = item.getDeviceAssignedOrgId()
                                break
                        }
                        const displayOrg = orgId && context.fetchKnownOrg(orgId)
                        const orgNames = fetchItemDisplayOrgNames(context, displayOrg)
                        searchText = orgNames ? orgNames.join(' ') : ''
                        break
                    default:
                        if (messageType !== MESSAGE_TYPE.ARTICLE) {
                            if (replacement?.getType() === REPLACEMENT_TYPE.DATE) {
                                searchDate = getPreparedDefaultValue(replacement, item.getParam(filter.item))
                                searchMoment = searchDate ? moment(searchDate) : null
                                findDate = getPreparedDefaultValue(replacement, filter.value)
                                findMoment = findDate ? moment(findDate) : null
                            } else {
                                searchText = item.getParam(filter.item)
                            }
                        }
                        break
                }

                let match = false
                switch (filter.op) {
                    case FILTER_OP_KEYS.CONTAIN:
                        match = `${searchText||''}`.toLowerCase().indexOf(`${findText}`.toLowerCase()) !== -1
                        break
                    case FILTER_OP_KEYS.DOES_NOT_CONTAIN:
                        match = `${searchText||''}`.toLowerCase().indexOf(`${findText}`.toLowerCase()) === -1
                        break
                    case FILTER_OP_KEYS.IS:
                        if (replacement?.getType() === REPLACEMENT_TYPE.DATE) {
                            if ( searchMoment?.isValid() && findMoment?.isValid() ) {
                                match = findMoment.isSame(searchMoment)
                            }
                        } else {
                            match = `${searchText||''}`.toLowerCase() === `${findText}`.toLowerCase()
                        }
                        break
                    case FILTER_OP_KEYS.IS_NOT:
                        if (replacement?.getType() === REPLACEMENT_TYPE.DATE) {
                            if ( searchMoment?.isValid() && findMoment?.isValid() ) {
                                match = !findMoment.isSame(searchMoment)
                            }
                        } else {
                            match = `${searchText||''}`.toLowerCase() !== `${findText}`.toLowerCase()
                        }
                        break
                    case FILTER_OP_KEYS.FROM:
                    case FILTER_OP_KEYS.TO:
                        try {
                            if ( searchMoment?.isValid() && findMoment?.isValid() ) {
                                match = filter.op === FILTER_OP_KEYS.TO ? findMoment.isSameOrAfter(searchMoment) : findMoment.isSameOrBefore(searchMoment)
                            }
                        } catch (error) {
                            console.log("can't parse date string")
                            match = false
                        }
                        break
                }
                matches = filter.cond === FILTER_CONDITION_OR ? matches || match : ( ii === 0 || matches) && match
            }
            if (matches) filteredMessages.push(item)
        }
        return filteredMessages
    }
    return messages
}

const FilterRow = ({ defaultFilter, filter, filterIndex, onDeleteFilter, onChangeValue, submitted, replacements }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const replacementNames = replacements?.map(replacement => ({ label: replacement.getName(), value: replacement.getId() }))
    const itemOptions = [...DEFAULT_FILTER_ITEM_OPTIONS, ...replacementNames]
    const selectedReplacement = replacements?.find(_replacement => _replacement.getId() === filter[FILTER_KEYS.ITEM])
    const typeOptions = getTypeOptions(replacements, filter[FILTER_KEYS.ITEM])

    const renderValueField = (replacement) => {
        const value = filter[FILTER_KEYS.VALUE]
        const invalidDateValue = submitted && selectedReplacement.getType() === REPLACEMENT_TYPE.DATE && ( !value || (value?._isAMomentObject && !value.isValid()) )

        return <ReplacementField
            hideLabel
            replacement={replacement}
            value={value}
            customFieldError={invalidDateValue ? ' ' : null}
            validators={['required']}
            errorMessages={['']}
            onValueChange={(replacement, value) => {
                const values = { ...filter, [FILTER_KEYS.VALUE]: value }
                onChangeValue(filterIndex, values)
            }} />
    }

    return (
        <Box display={'flex'} alignItems={'center'} className={classes.filterWrapper}>
            <Box marginRight={1} textAlign={defaultFilter && 'center'}>
                {defaultFilter && <Typography className={classes.mediumText}>{t('when')}</Typography>}
                {!defaultFilter &&
                    <Box display={'flex'} alignItems={'center'}>
                        <Box marginRight={1}>
                            <IconButton size={'small'} onClick={() => onDeleteFilter(filterIndex)}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                        <SelectValidator
                            id={'condition-item'}
                            value={filter[FILTER_KEYS.CONDITION]}
                            className={classes.selectFieldCondition}
                            validators={['required']}
                            errorMessages={[t('this_field_is_required')]}
                            onChange={(event) => {
                                const values = { ...filter, [FILTER_KEYS.CONDITION]: event?.target?.value }
                                onChangeValue(filterIndex, values)
                            }}>
                            {FILTER_CONDITION_OPTIONS.map(option => (
                                <MenuItem value={option.value}>{t(option.label)}</MenuItem>
                            ))}
                        </SelectValidator>
                    </Box>}
            </Box>
            <Box marginRight={1}>
                <SelectValidator
                    id={'filter-item'}
                    value={filter[FILTER_KEYS.ITEM]}
                    className={classes.selectField}
                    validators={['required']}
                    errorMessages={['']}
                    onChange={(event) => {
                        const selectedItem = event?.target?.value
                        const _typeOptions = getTypeOptions(replacements, selectedItem)
                        const op = _typeOptions && !_typeOptions.find(type => type.value ===filter.op ) ? _typeOptions[0].value : filter.op
                        const values = { ...filter, [FILTER_KEYS.ITEM]: event?.target?.value, [FILTER_KEYS.OP]: op, [FILTER_KEYS.VALUE]: ''  }
                        onChangeValue(filterIndex, values)
                    }}>
                    {itemOptions.map(option => (
                        <MenuItem value={option.value}>{t(option.label)}</MenuItem>
                    ))}
                </SelectValidator>
            </Box>
            <Box marginRight={1}>
                <SelectValidator
                    id={'filter-type'}
                    value={filter[FILTER_KEYS.OP]}
                    className={classes.selectField}
                    validators={['required']}
                    errorMessages={['']}
                    onChange={(event) => {
                        const values = { ...filter, [FILTER_KEYS.OP]: event?.target?.value }
                        onChangeValue(filterIndex, values)
                    }}>
                    {typeOptions.map(option => (
                        <MenuItem value={option.value}>{t(option.label)}</MenuItem>
                    ))}
                </SelectValidator>
            </Box>
            <Box>
                {selectedReplacement && renderValueField(selectedReplacement)}
                {(filter[FILTER_KEYS.ITEM] === DEFAULT_FILTER_ITEM_OPTION_NAME || filter[FILTER_KEYS.ITEM] === DEFAULT_FILTER_ITEM_OPTION_ORG) &&
                    <ReplacementTextField
                        validators={['required']}
                        errorMessages={['']}
                        value={filter[FILTER_KEYS.VALUE]}
                        onValueChange={(_replacement, value) => {
                            const values = { ...filter, [FILTER_KEYS.VALUE]: value }
                            onChangeValue(filterIndex, values)
                        }}
                    />
                }
            </Box>
        </Box>
    )
}

const defaultItem  = {
    [FILTER_KEYS.CONDITION]: FILTER_CONDITION_OPTIONS[0].value,
    [FILTER_KEYS.ITEM]: DEFAULT_FILTER_ITEM_OPTIONS[0].value,
    [FILTER_KEYS.OP]: FILTER_TYPE_OPTIONS[0].value
}

const AdvancedSearchModal = ({ open, onClose, replacements, messageListType, messageType, themeColIndex }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const context = useContext(ApiContext)
    const user = context.userService.getActiveUser()
    const [submitted, setSubmitted] = useState(false)
    const [values, setValues] = useState([{...defaultItem}])
    const [filteredReplacements, setFilteredReplacements] = useState([])

    useEffect(() => {
        const filterReplacements = []
        for (let i = 0; i < replacements.length; i++) {
            const replacement = replacements[i]
            const replacementId = replacement.getId()
            const isIncludedReplacement = filterReplacements.find(_replacement => _replacement.getId() === replacementId) ? true : false
            if (EXCLUDED_REPLACEMENT_TYPES.indexOf(replacementId) === -1 && !isIncludedReplacement) {
                filterReplacements.push(replacement)
            }
        }
        setFilteredReplacements(filterReplacements)
        const defaultValues = getAdvancedSearchValues(context, messageType, messageListType, themeColIndex)
        if (defaultValues) {
            for (let i = 0; i < defaultValues.length; i++) {
                const defaultValue = defaultValues[i]
                const replacement = replacements.find( _replacement => _replacement.getId() === defaultValue?.item )
                if (replacement) {
                    defaultValues[i].value = getPreparedDefaultValue(replacement, defaultValues[i].value)
                }
            }
            setValues(defaultValues)
        }
    }, [])

    const formRef = useRef()

    const handleValues = (index, _values) => {
        const lastValues = [...values]
        lastValues[index] = _values
        setValues(lastValues)
        setSubmitted(false)
    }

    const addFilter = () => {
        setValues(prevState => [...prevState, {...defaultItem}])
    }

    const onDelete = (filterIndex) => {
        const filteredValues = values.filter((val, i) => i !== filterIndex)
        setValues(filteredValues)
    }

    const onSubmit = async (remove = false) => {
        const paramKey = getAdvancedSearchUserParamKey(messageType, messageListType, themeColIndex)
        if (remove === true) {
            await context.userService.deleteParam(user.getUserId(), paramKey, true)
        } else {
            setSubmitted(true)
            for (let i = 0; i < values.length; i++) {
                const value = values[i]
                const replacement = replacements.find( _replacement => _replacement.getId() === value?.item )
                if (replacement) {
                    values[i].value = await prepareReplacementValueBeforeSave(null, null, replacement, value.value)
                }
                try {
                    if (!values[i].value || values[i].value.trim() === '') {
                        console.log("invalid value exists, don't submit form. Item: ", value)
                        throw new Error("invalid value exists, don't submit form.")
                    }
                } catch (error) {
                    console.log("error: ", error)
                    return null
                }
            }
            await context.userService.putParam(user.getUserId(), paramKey, JSON.stringify(values))
        }
        onClose()
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Dialog open={open} aria-labelledby="alert" classes={{ paperWidthSm: classes.dialogRoot }}>
                <DialogTitle id="alert-title">{t('advanced_search')}</DialogTitle>
                <ValidatorForm
                    ref={formRef}
                    onSubmit={onSubmit}
                    onError={errors => console.log("AdvancedSearchModal error:", errors)}>
                    <DialogContent classes={{ root: clsx(classes.dialogContent) }}>

                        {values.map((value, index) => (
                            <FilterRow
                                defaultFilter={index === 0}
                                replacements={filteredReplacements}
                                filter={value}
                                filterIndex={index}
                                submitted={submitted}
                                onDeleteFilter={onDelete}
                                onChangeValue={handleValues} />
                        ))}

                        <Box display={'flex'} alignItems={'center'} className={classes.filterWrapper}>
                            <Button size={'small'} onClick={addFilter}>
                                <AddIcon />
                                <Typography className={classes.mediumText}>{t('add_filter')}</Typography>
                            </Button>
                        </Box>

                    </DialogContent>
                    <DialogActions className={classes.dialogActions}>
                        <Box flex={1}>
                            <Button onClick={onClose} variant={'contained'}>
                                {t('cancel')}
                            </Button>
                        </Box>
                        <Box marginRight={1}>
                            <Button
                                type="button"
                                onClick={() => onSubmit(true)}
                                color="default"
                                variant={'text'}>
                                {t('clear')}
                            </Button>
                        </Box>
                        <Button type="submit" color="primary" variant={'contained'}>
                            {t('apply')}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        </ThemeProvider>
    )
}


const useStyles = makeStyles(theme => ({
    dialogRoot: {
        minWidth: 680,
        maxWidth: '100%'
    },
    dialogContent: {
        overflowY: 'auto',
        maxHeight: '90%',
        minHeight: 300,
        paddingTop: '0px !important',
        paddingLeft: 0,
        paddingRight: 0
    },
    dialogActions: {
        margin: theme.spacing(2),
        justifyContent: 'flex-start',
        '& button:last-child': {
            marginLeft: 'auto'
        }
    },
    filterWrapper: {
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        backgroundColor: theme.palette.secondary.main,
        marginBottom: 1,
        '& > div': {
            flex: 1,
            '& > div': {
                margin: 0
            }
        }
    },
    selectField: {
        minWidth: 150
    },
    selectFieldCondition: {
        minWidth: 80
    },
    mediumText: {
        fontWeight: 500
    }
}))


export default AdvancedSearchModal
