import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { DefaultStyles } from '../../../../theme'
import { MOBILE_MENU_BREAKPOINT } from '../DrawerMenu/DrawerMenu'
import { ApiContext, findRootOrg } from '../../../../m2m-cloud-api/MessageLog/ApiContext'
import { PARAM_APP_MSG_ROTATION_MODE, PARAM_APP_MSG_ROTATION_STAY_TIME } from '../../../../m2m-cloud-api/Api/OrgService/Models/Org'
import MessageList, { MESSAGE_LIST_TYPE, MESSAGE_TYPE } from '../MessageList'
import { Tabs, Tab, TabScrollButton, ListItem, Typography, IconButton, Divider } from '@material-ui/core'
import CompletedMessages from '../CompletedMessages'
import ReactSVG from 'react-svg'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'

class SupplierTabView extends Component {

    constructor(props) {
        super(props)
        const { t } = this.props

        this.rotationModeTimer = null
        this.rotationModeStayTime = 0

        this.state = {
            tabIndex: 0,
            settingsNotificationSettings: {},
            tabInfo: null
        }
    }

    componentDidUpdate() {
        this.setRotationMode()
    }

    componentWillUnmount() {
        this.clearRotationTimer()
    }

    setRotationMode() {
        const supplierOrgs = this.context.getSupplierOrgs()
        const rootOrg = findRootOrg(this.context.allAccessibleOrgs, this.props.org)
        if (supplierOrgs && supplierOrgs.length > 1 && rootOrg) {
            const rotationMode = rootOrg.getParam(`${PARAM_APP_MSG_ROTATION_MODE}`)
            const rotationStayTime = rotationMode ? Number(rootOrg.getParam(`${PARAM_APP_MSG_ROTATION_STAY_TIME}`)) : 0
            if (rotationMode && this.context.rotationMode) {
                if (this.rotationModeStayTime !== rotationStayTime) {
                    this.rotationModeStayTime = rotationStayTime
                    this.setRotationTimer()
                }
                return
            }
        }
        this.rotationModeStayTime = 0
        this.clearRotationTimer()
    }

    setRotationTimer() {
        this.clearRotationTimer()
        if (this.rotationModeStayTime > 0) {
            this.rotationModeTimer = setInterval(() => {
                const itemIndex = this.props.rightMenuItems.length > 0 ? this.props.rightMenuItems.findIndex(item => item.selected) : -1
                if (itemIndex !== -1) {
                    const nextItemIndex = itemIndex === this.props.rightMenuItems.length - 1 ? 0 : itemIndex + 1
                    this.props.onSelectedOrgChange(this.props.rightMenuItems[nextItemIndex])
                }
            }, this.rotationModeStayTime * 1000)
        }
    }

    clearRotationTimer() {
        if (this.rotationModeTimer) {
            clearInterval(this.rotationModeTimer)
            this.rotationModeTimer = null
        }
    }

    onTabChange = (event, newValue) => {
        const { t } = this.props
        let tabInfo = null
        switch (newValue) {
            case 0:
                tabInfo = t('overview')
                break;
            case 1:
                tabInfo = t('open')
                break;
            case 2:
                tabInfo = t('processing')
                break;
            case 3:
                tabInfo = t('completed')
                break;
        }
        this.setState({ tabIndex: newValue, tabInfo })
    }

    render() {
        if (!this.props.org) return (null)
        const { classes, t, rightMenuItems, onSelectedOrgChange } = this.props
        const { tabIndex, settingsNotificationSettings, tabInfo } = this.state

        const selectedItemIndex = rightMenuItems && rightMenuItems.length > 0 ? rightMenuItems.findIndex(item => item.selected) : 0

        return (
            <div className={classes.container}>
                <div className={classes.menuContainer}>
                    <Tabs
                        className={classes.tabbar}
                        value={tabIndex}
                        textColor="primary"
                        TabIndicatorProps={{
                            style: {
                                display: "none",
                            }
                        }}
                        onChange={this.onTabChange}>
                        <Tab className={classes.tabItem} aria-label={t('all')} icon={<ReactSVG src={"/assets/light/job_overview.svg"} />} />
                        <Tab className={classes.tabItem} aria-label={t('open')} icon={<ReactSVG src={"/assets/light/icon-light-job-added.svg"} />} />
                        <Tab className={classes.tabItem} aria-label={t('processing')} icon={<ReactSVG src={"/assets/light/icon-light-job-processing.svg"} />} />
                        <Tab className={classes.tabItem} aria-label={t('completed')} icon={<ReactSVG src={"/assets/light/icon-light-job-done.svg"} />} />
                    </Tabs>
                    <Typography style={{ marginLeft: 8, marginRight: 8, fontWeight: 500 }} color={'textPrimary'} variant="body2">
                        {tabInfo || t('overview')}
                    </Typography>
                    <div className={classes.tabsWrapper}>
                        <Tabs
                            value={selectedItemIndex}
                            className={classes.rightMenu}
                            style={{ minHeight: 0, justifyContent: 'flex-end' }}
                            indicatorColor="tranparent"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto">
                            {rightMenuItems.map((item, i) => {
                                return (<Tab className={[item.selected ? classes.rightTabItemActive : classes.rightTabItem, classes.listItem].join(' ')} label={<ListItem disableRipple={true} key={item.id} button selected={item.selected}
                                    className={classes.listItem}

                                    classes={{ selected: classes.listItemActive }}
                                    onClick={() => onSelectedOrgChange(item)}>
                                    <Typography className={classes.buttonText}>{item.name}</Typography>
                                    <Typography className={item.selected ? classes.listItemLabelActive : classes.listItemLabel} variant={'body2'} color={'primary'}>{item.countProcessingMessages}/{item.countAllMessages}</Typography>
                                    <IconButton
                                        size="small"
                                        disabled={settingsNotificationSettings[item.id]}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            const _settingsNotificationSettings = settingsNotificationSettings || {}
                                            _settingsNotificationSettings[item.id] = true
                                            this.setState({ settingsNotificationSettings: _settingsNotificationSettings })
                                            this.context.setSupplierAlertEnabled(item.id, this.context.getSupplierAlertEnabled(item.id) ? false : true)
                                                .then(() => {
                                                    const _settingsNotificationSettings = settingsNotificationSettings || {}
                                                    _settingsNotificationSettings[item.id] = false
                                                    this.setState({ settingsNotificationSettings: _settingsNotificationSettings })
                                                })
                                        }}
                                        className={item.selected ? classes.notificationButtonActive : classes.notificationButton}>
                                        {this.context.getSupplierAlertEnabled(item.id) ? <NotificationsActiveIcon style={{ height: 15 }} /> : <NotificationsOffIcon style={{ height: 15 }} />}
                                    </IconButton>
                                </ListItem>} onClick={() => onSelectedOrgChange(item)} />)
                            })}
                        </Tabs>
                    </div>


                    {/* <List className={classes.rightMenu}>
                        <IconButton
                            size={'small'}
                            className={classes.iconButton}
                            onClick={() => {
                                console.log('ref')
                            }}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <div style={{ overflowX: 'scroll', display: 'flex', flexDirection: 'row', marginRight: 12, marginLeft: 12 }}>
                            {rightMenuItems.map((item, i) => {
                                return (<ListItem disableRipple={true} key={item.id} button selected={item.selected}
                                    className={classes.listItem}

                                    classes={{ selected: classes.listItemActive }}
                                    onClick={() => onSelectedOrgChange(item)}>
                                    <Typography className={classes.buttonText}>{item.name}</Typography>
                                    <Typography className={item.selected ? classes.listItemLabelActive : classes.listItemLabel} variant={'body2'} color={'primary'}>{item.countProcessingMessages}/{item.countAllMessages}</Typography>
                                    <IconButton
                                        size="small"
                                        disabled={settingsNotificationSettings[item.id]}
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            const _settingsNotificationSettings = settingsNotificationSettings || {}
                                            _settingsNotificationSettings[item.id] = true
                                            this.setState({ settingsNotificationSettings: _settingsNotificationSettings })
                                            this.context.setSupplierAlertEnabled(item.id, this.context.getSupplierAlertEnabled(item.id) ? false : true)
                                                .then(() => {
                                                    const _settingsNotificationSettings = settingsNotificationSettings || {}
                                                    _settingsNotificationSettings[item.id] = false
                                                    this.setState({ settingsNotificationSettings: _settingsNotificationSettings })
                                                })
                                        }}
                                        className={item.selected ? classes.notificationButtonActive : classes.notificationButton}>
                                        {this.context.getSupplierAlertEnabled(item.id) ? <NotificationsActiveIcon style={{ height: 15 }} /> : <NotificationsOffIcon style={{ height: 15 }} />}
                                    </IconButton>
                                </ListItem>)
                            })}
                        </div>
                        <IconButton
                            size={'small'}
                            className={classes.iconButton}
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                console.log('test')
                            }}>
                            <ChevronRightIcon />
                        </IconButton>
                    </List> */}
                </div>
                <Divider className={classes.divider} />
                {tabIndex === 0 && this.renderAllTab()}
                {tabIndex === 1 && this.renderOpenTab()}
                {tabIndex === 2 && this.renderProcessingTab()}
                {tabIndex === 3 && this.renderCompletedTab()}
            </div >
        )
    }

    renderAllTab() {
        const { classes, org, t } = this.props

        const orgId = org.getId()
        const messages = this.context.getSupplierMessages(orgId)
        const openMessages = messages && messages.filter(message => !message.isProcessing() && !message.isAcknowledged())
        const processingMessages = messages && messages.filter(message => message.isProcessing())

        const theme = this.context.getRootOrgTheme()
        let cols = theme?.cols || [
            { type: 'open', filters: [] },
            { type: 'processing', filters: [] }
        ]

        return (
            <div className={classes.tabContainer} >
                {cols.map((col, index) => (
                    <div className={classes.column}>
                        <MessageList fullWidth messageListType={col.type === 'processing' ? MESSAGE_LIST_TYPE.PROCESSING : MESSAGE_LIST_TYPE.OPEN} themeColIndex={index} filters={col.filters} messages={col.type === 'processing' ? this.context.sortMessages(processingMessages, true) : openMessages} messageType={MESSAGE_TYPE.SUPPLIER} />
                    </div>
                ))}
            </div>
        )
    }

    renderOpenTab() {
        const { classes, org, t } = this.props

        const orgId = org.getId()
        const messages = this.context.getSupplierMessages(orgId)
        const openMessages = messages && messages.filter(message => !message.isProcessing() && !message.isAcknowledged())

        return (
            <div className={classes.tabContainer} >
                <div className={classes.columnFullWidth}>
                    <MessageList messageListType={MESSAGE_LIST_TYPE.OPEN} messages={openMessages} messageType={MESSAGE_TYPE.SUPPLIER} />
                </div>
            </div>
        )
    }

    renderProcessingTab() {
        const { classes, org, t } = this.props

        const orgId = org.getId()
        const messages = this.context.getSupplierMessages(orgId)
        const processingMessages = messages && this.context.sortMessages(messages.filter(message => message.isProcessing()), true)
        return (
            <div className={classes.tabContainer} >
                <div className={classes.columnFullWidth}>
                    <MessageList messageListType={MESSAGE_LIST_TYPE.PROCESSING} messages={processingMessages} messageType={MESSAGE_TYPE.SUPPLIER} />
                </div>
            </div>
        )
    }

    renderCompletedTab() {
        const { classes, org } = this.props

        return (
            <div className={classes.tabContainer} >
                <div className={classes.columnFullWidth}>
                    <CompletedMessages messageType={MESSAGE_TYPE.SUPPLIER} org={org} />
                </div>
            </div>
        )
    }
}

SupplierTabView.propTypes = {
    org: PropTypes.any.isRequired
}

SupplierTabView.contextType = ApiContext

const styles = theme => ({
    container: {
        ...DefaultStyles.AbsoluteFill,
        flexDirection: 'column',
    },
    tabContainer: {
        position: 'relative',
        flex: 1,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row'
    },
    tabbar: {
        minHeight: 'unset',
        padding: 2,
        backgroundColor: '#f6f6f6'
    },
    tabsWrapper: {
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'flex-end'
    },
    tabItem: {
        width: 24,
        height: 24,
        minWidth: 'unset',
        minHeight: 'unset',
        marginRight: 12,
        display: 'inline-flex',
        '&:last-child': {
            marginRight: 0
        },
        '& svg': {
            display: 'flex'
        }
    },

    columnFullWidth: {
        ...DefaultStyles.AbsoluteFill
    },
    column: {
        flex: 1,
        position: 'relative'
    },
    leftColumn: {
        ...DefaultStyles.AbsoluteFill,
        left: 0,
        top: 0,
        width: '100%',
        height: '50%',
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            width: '50%',
            height: '100%'
        }
    },
    rightColumn: {
        ...DefaultStyles.AbsoluteFill,
        width: '100%',
        top: '50%',
        height: '50%',
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            top: 0,
            left: '50%',
            width: '50%',
            height: '100%',
        }
    },
    notificationButton: {
        color: theme.palette.common.black,
        padding: 0
    },
    notificationButtonActive: {
        color: theme.palette.common.white,
        padding: 0
    },
    listItem: {
        color: '#1a1a1a',
        backgroundColor: '#f6f6f6',
        padding: '6px 4px 6px 8px',
        width: 'auto',
        minHeight: 'unset',
        marginRight: 12,
        whiteSpace: 'nowrap',
        marginBottom: 4,
        paddingBottom: 2,
        '&:last-child': {
            marginRight: 0
        }
    },
    listItemActive: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main + '!important',
        width: 'auto'

    },
    listItemLabel: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: 12,
        lineHeight: 1,
    },
    listItemLabelActive: {
        backgroundColor: theme.palette.common.white,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: 12,
        lineHeight: 1,
    },
    rightMenu: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        justifyContent: 'flex-end',
        overflowX: 'auto',
        marginLeft: theme.spacing(1)
    },
    menuContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
        marginTop: 12,
        marginRight: theme.spacing(3),
        position: 'relative'
    },
    buttonText: {
        marginRight: 8,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 'normal'
    },
    divider: {
        height: 2,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.main,
    },
    iconButton: {
        backgroundColor: '#f6f6f6',
        borderRadius: 0
    },
    rightTabItem: {
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        textTransform: 'none',
        marginBottom: 4,
        paddingBottom: 0
    },
    rightTabItemActive: {
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        textTransform: 'none',
        marginBottom: 0,
        paddingBottom: 4,
        backgroundColor: theme.palette.primary.main
    }
})

export default withTranslation()(withStyles(styles)(SupplierTabView))
