import { ERROR } from './Errors';
import { ORG_TAG } from '../index';
import { ALL_GROUP_ORG_TAGS } from './OrgService/OrgService';

/**
 * is not found error
 * @param {Eroor} error
 *
 * @returns {Boolean}
 */
export function isNotFoundError(error) {
    return error && error.originError && error.originError.name === ERROR.NOT_FOUND ? true : false;
}

/**
 * is token unknown or expired
 * @param {Error} error
 *
 * @returns {Boolean}
 */
export function isTokenUnknownOrExpiredError(error) {
    return error && error.originError && error.originError.name === ERROR.TOKEN_UNKNOWN_OR_EXPIRED ? true : false;
}

/**
 * is already exists error
 * @param {Eroor} error
 *
 * @returns {Boolean}
 */
export function isAlreadyExistsError(error) {
    return error && error.originError && error.originError.name === ERROR.ALREADY_EXISTS ? true : false;
}

/**
 * is invalid code error
 * @param {Eroor} error
 *
 * @returns {Boolean}
 */
export function isInvalidCodeError(error) {
    return error && error.originError && error.originError.name === ERROR.INVALID_CODE ? true : false;
}

/**
 * is required muti-factor login
 * @param {Eroor} error
 *
 * @returns {Boolean}
 */
export function isMultiFactorRequiredError(error) {
    return error && error.originError && error.originError.name === ERROR.MULTI_FACTOR_REQUIRED ? true : false;
}

/**
 * is resource in use eror
 * @param {Eroor} error
 *
 * @returns {Boolean}
 */
export function isResourceInUseError(error) {
    return error && error.originError && error.originError.name === ERROR.RESOURCE_IN_USE ? true : false;
}

/**
 * prepare template content
 *
 * @param {MessageTemplate} template
 * @param {MessageDefinition} definition
 * @param {Device} device
 * @param {Org} deviceAssignedOrg
 *
 * @returns {Object<header,body>}
 */
export function prepareTemplateContent(template, definition, device, deviceAssignedOrg) {
    const replaceString = (text, find, replace) => {
        return text.replace(new RegExp(find, 'g'), replace || '');
    };

    const renderTemplateString = text => {
        const definitionEvents = definition.getEvents();

        text = replaceString(text, '$deviceId', device.getId());
        text = replaceString(text, '$deviceType', device.getType());
        text = replaceString(text, '$deviceSubType', device.getSubType());
        text = replaceString(text, '$devicePhysicalId', device.getPhysicalId());
        text = replaceString(text, '$deviceDriver', device.getDriver());
        text = replaceString(text, '$deviceAssignedOrgId', device.getAssignedOrg());
        text = replaceString(text, '$deviceOwnerOrgId', device.getOwnerOrg());
        text = replaceString(text, '$eventName', definitionEvents && definitionEvents.length > 0 ? definitionEvents[0] : null);
        text = replaceString(text, '$eventTimestamp', new Date().toLocaleString());
        text = replaceString(text, '$eventTargetAppEndpoint', definition.getId());

        if (deviceAssignedOrg) {
            const params = deviceAssignedOrg.getParams();
            params &&
                Object.keys(params).map(key => {
                    text = replaceString(text, '$deviceAssignedOrgParam:' + key, params[key]);
                });
        }

        if (device) {
            const settings = device.getSettings();
            settings &&
                Object.keys(settings).map(key => {
                    text = replaceString(text, '$deviceSetting:' + key, settings[key]);
                });
        }

        const definitionParams = definition.getParams();
        definitionParams &&
            Object.keys(definitionParams).map(key => {
                text = replaceString(text, '\\$def:' + key, definitionParams[key]);
            });

        return text;
    };

    return {
        header: renderTemplateString(template.header),
        body: renderTemplateString(template.body),
    };
}

/**
 * delay
 * @param {Number} timeout
 * @returns {Promise}
 */
export const delay = timeout => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, timeout);
    });
};

/**
 * build Org tree names seperated with ' / '
 * @param {Array<Org>} allOrgs
 * @param {Array<Org>} childOrg
 * @returns {String}
 */
export function buildOrgTreeNames(allOrgs, childOrg) {
    let orgs = [];
    if (childOrg) {
        let org = childOrg;
        orgs.push(org);
        for (let i = 0; i < allOrgs.length; i++) {
            org = allOrgs?.find(currentOrg => currentOrg?.getId() === org?.getParentId());
            if (org) {
                orgs = [org, ...orgs];
            }
        }
    }
    return orgs.map(org => org.getName());
}

/**
 * @deprecated please use buildParentToChildOrgTreeFromChildOrgWithTagFilter
 * @param {Array<Org>} allOrgs
 * @param {Org} childOrg
 * @returns {Array<Org>}
 */
export const buildOrgTreeFromChildOrg = (allOrgs, childOrg) => {
    return buildParentToChildOrgTreeFromChildOrgWithTagFilter(allOrgs, childOrg);
};

/**
 *
 * @param {Array<Org>} allOrgs
 * @param {Org} childOrg
 * @returns {Array<Org>}
 */
export const buildParentToChildOrgTreeFromChildOrg = (allOrgs, childOrg) => {
    let orgs = [];
    if (childOrg) {
        let org = childOrg;
        orgs.push(org);
        while (org && org.getParentId()) {
            org = allOrgs && allOrgs.find(currentOrg => currentOrg && currentOrg.getId() === org.getParentId());
            if (org) {
                orgs = [org, ...orgs]; // set parent orgs first
            }
        }
    }
    return orgs;
};

/**
 *
 * @param {Array<Org>} allOrgs
 * @param {Org} childOrg
 * @returns {Array<Org>}
 */
export const buildParentToChildOrgTreeFromChildOrgWithTagFilter = (allOrgs, childOrg) => {
    let orgs = [];
    if (childOrg) {
        let org = childOrg;
        orgs.push(org);
        while (org && org.getParentId()) {
            org = allOrgs && allOrgs.find(currentOrg => currentOrg && currentOrg.getId() === org.getParentId());
            if (org && org.getTags().find(tag => ALL_GROUP_ORG_TAGS.indexOf(tag) >= 0)) {
                orgs = [org, ...orgs]; // set parent orgs first
            }
        }
    }
    return orgs;
};

/**
 *
 * @param {Array<Org>} allOrgs
 * @param {Org} childOrg
 * @returns {undefined}
 */
export const buildOrgTreeFromParentOrg = (allOrgs, parentOrg) => {
    const addOrgs = newOrgs => {
        const _orgToAdd = newOrgs.sort((a, b) => a.getName().localeCompare(b.getName()));
        for (let i = 0; i < _orgToAdd.length; i++) {
            const o = _orgToAdd[i];
            o && orgs.push(o);
        }
    };
    let orgs = [];
    addOrgs([parentOrg]);
    const getChildItems = _org => {
        const items = allOrgs.filter(org => org.getParentId() === _org.getId()).sort((a, b) => a.getName().localeCompare(b.getName()));
        for (let i = 0; i < items.length; i++) {
            addOrgs([items[i]]);
            getChildItems(items[i]);
        }
        return items;
    };
    getChildItems(parentOrg);
    return orgs;
};
