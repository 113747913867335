import React, { useState, useContext, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Dialog, DialogContent, DialogTitle, Button, DialogActions, Grid } from '@material-ui/core'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import CloseIcon from '@material-ui/icons/Close'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useTranslation } from 'react-i18next'
import ReplacementField from './Recipient/ReplacementFields'
import { getPreparedDefaultValue, prepareReplacementValueBeforeSave } from './Recipient/ReplacementsModal'
import { REPLACEMENT_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'

const ReplacementEditDialog = ({ open, onClose, replacement, message }) => {
    const context = useContext(ApiContext)
    const { t } = useTranslation()
    const classes = useStyles()
    const [value, setValue] = useState(getPreparedDefaultValue(replacement.replacement, replacement.value))
    const [ready, setReady] = useState(false)
    const [imageData, setImageData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const _replacement = replacement?.replacement

    useEffect(() => {
        if( _replacement.getType() === REPLACEMENT_TYPE.IMAGE ) {
            if (replacement.value && replacement.value !== '') {
                loadImage()
            } else {
                setReady(true)
            }
        } else {
            setReady(true)
        }
    }, [])

    const loadImage = async () => {
        try {
            const file = await context.appMessageService.getReplacementFile(replacement.value, 400, 300)
            setImageData(file)
        } catch (error) {
            console.log('getReplacementFile - error', error)
        }
        setReady(true)
    }

    const onSubmit = async (reset = false) => {
        try {
            setLoading(true)
            const preparedValue = await prepareReplacementValueBeforeSave(context, message.getDefinitionId(), _replacement, reset === true ? '' : _replacement.getType() === REPLACEMENT_TYPE.IMAGE ? imageData : value)
            const params = { [_replacement.getId()]: preparedValue }
            const updatedMessage = await context.appMessageService.updateMessage(message.getId(), params)
            setLoading(false)
            onClose(updatedMessage)
        } catch (error) {
            console.log('Add message error... ', error)
        }
    }

    const handleDateFieldError = useCallback((replacement, error) => {
        setError(error)
    }, [replacement])

    return (
        <Dialog open={open} fullWidth maxWidth={'xs'} aria-labelledby="editable-replacement" onClose={() => void (0)}>
            <DialogTitle className={classes.dialogTitle} variant="h6" disableTypography >
                <Typography variant="h6" style={{ flexGrow: 1 }}>{replacement?.replacement?.getName()}</Typography>
                <IconButton onClick={() => onClose()} size="small">
                    <CloseIcon style={{ color: '#fff' }} />
                </IconButton>
            </DialogTitle>
            <ValidatorForm
                onSubmit={onSubmit}
                onError={errors => console.log("ReplacementEditDialog form error... ", errors)}>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid container alignItems={'flex-end'}>
                    { (_replacement.getType() !== REPLACEMENT_TYPE.IMAGE || ( ready && _replacement.getType() === REPLACEMENT_TYPE.IMAGE)) && <ReplacementField
                        replacement={replacement?.replacement}
                        loading={loading}
                        onValueChange={(replacement, value) => _replacement.getType() === REPLACEMENT_TYPE.IMAGE ? setImageData(value) : setValue(value) }
                        value={_replacement.getType() === REPLACEMENT_TYPE.IMAGE ? imageData : value}
                        onDateFieldError={handleDateFieldError}
                        //customFieldError={error}
                        onDeleteImage={(value) => setImageData(null)}/>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()} color="default">
                        {t('cancel')}
                    </Button>
                    <Button onClick={() => onSubmit(true)} color="primary">
                        {t('reset')}
                    </Button>
                    <Button color={"primary"} type={"submit"} variant='contained' disabled={error ? true : false} >
                        {t('save')}
                    </Button>
                </DialogActions>
            </ValidatorForm>

        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    field: {
        flex: 1,
        marginRight: theme.spacing(2)
    }
}))

export default ReplacementEditDialog