import moment from 'moment'
import i18n from '../../i18n'

/**
 * get date name of given date
 * @param {Date} date
 * @param {Boolean} datetimeFormat
 * @returns {String}
 */
export const getDateFormat = (date, datetimeFormat = true) => {
    let dayString = null
    const today = moment()
    const yesterday = moment().subtract(1, 'days')
    if ( moment(date).isSame( today, "day") ) dayString = ''
    if ( moment(date).isSame( yesterday, "day") ) dayString = `[${i18n.t('yesterday')}], `
    const formatDate = dayString === null ? (datetimeFormat ? 'DD.MM. HH:mm' : 'YYYY.MM.DD') : (datetimeFormat ? `${dayString}HH:mm` : `[${dayString}]` )
    return formatDate
}