import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, List, ListSubheader, ListItem, ListItemText } from '@material-ui/core'
import { ORG_TAG } from '../../../m2m-cloud-api/Api/OrgService/OrgService'
import { PARAM_CODE_NAME_SPACE } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { DRIVER_TYPE } from '../../../m2m-cloud-api/Api/DeviceService/Models/Device'
import { MESSAGE_ACTION_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/MessageAction'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import { prepareTemplateContent } from '../../../m2m-cloud-api/Api/Helper'
import { APP_MESSAGE_CREATOR, APP_MESSAGE_CREATOR_ADMIN } from '../Constants'
import Papa from 'papaparse'

import { v4 as uuidv4 } from 'uuid'


class TriggerImportDialog extends React.Component {
 
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            errorMessage: null,
            importedItems: null,
            notImportedItems: null,
        }
    }

    handleSelect({ target }) {
        const { t } = this.props

        this.setState({loading: true, errorMessage: null})

        const fileReader = new FileReader()
        fileReader.readAsText(target.files[0]);
        fileReader.onload = async (e) => {

            const importedItems = []
            const notImportedItems = []
            try {
                const data = Papa.parse(e.target.result)
                const csvData = data.data

                if (csvData.length > 0) {
                    const templateNameIndex = csvData[0].findIndex(item => item === 'Template-Name')
                    const actionNameIndex = csvData[0].findIndex(item => item === 'Action-Name')
                    const driverIndex = csvData[0].findIndex(item => item === 'Device-Driver')
                    const deviceIdIndex = csvData[0].findIndex(item => item === 'Device-PhysicalId')

                    if (csvData.length > 1) {

                        const allOrgs = await this.context.api.orgService.searchOrg({tags: [ORG_TAG.SUPPLIER_TARGET, ORG_TAG.RECIPIENT_LOCATION], searchRootOrg: [this.props.rootOrgId]})
                        const targetOrgs = allOrgs.filter( org => org.getTags().indexOf(ORG_TAG.SUPPLIER_TARGET) >= 0 )
                        const sourceOrgs = allOrgs.filter( org => org.getTags().indexOf(ORG_TAG.RECIPIENT_LOCATION) >= 0 )
                        const promisses = []
                        
                        for (let index = 0; index < targetOrgs.length; index++) {
                            const org = targetOrgs[index]
                            promisses.push( this.context.api.appMessageService.getTemplates( org.getId() ) )
                        }

                        let allTemplates = []
                        const results = await Promise.all(promisses)
                        for (let index = 0; index < results.length; index++) {
                            const templates = results[index]
                            allTemplates = [...allTemplates, ...templates]
                        }

                        const triggerEntities = await this.context.loadTriggerEntities(this.props.orgId)

                        const rootOrg = await this.context.api.orgService.read(this.props.rootOrgId)
                        const namespace = rootOrg.getParam(PARAM_CODE_NAME_SPACE)
                        if (!namespace) {
                            throw new Error("Root Org has no defined namespace.")
                        }

                        // action
                        const actions = await this.context.api.appMessageService.getActions( this.props.groupOrgId )
                        const action = actions.find( action => action.getType() === MESSAGE_ACTION_TYPE.MESSAGE && action.getData() && ( action.getData().ackable === true || action.getData().ackable === 'true') && action.getData().ackcmd === 'RESET_DEMAND' )
                        let actionId = null
                        if (!action) {
                            //console.log("action not exists for root org: ", groupOrgId)
                            actionId = uuidv4()
                            const name = "Message"
                            const data = {  ackable: true, ackcmd: 'RESET_DEMAND' } 
                            const result = await this.context.api.appMessageService.upsertAction(actionId, name, data, this.props.groupOrgId, MESSAGE_ACTION_TYPE.MESSAGE)
                            if (result) {
                                console.log("action successfully created, action id: " + actionId)
                            } else {
                                actionId = null
                                throw( new Error("can't create action") )
                            }
                        } else {
                            actionId = action.getId()
                        }


                        for (let index = 1; index < csvData.length; index++) {
                            const item = csvData[index]
                            const driver = item[driverIndex]
                            const templateName = item[templateNameIndex]
                            const deviceId = item[deviceIdIndex]
                            
                            if ( !driver || !templateName) {
                                throw new Error( t('error_invalid_csv_file') )
                            }

                            if (driver === DRIVER_TYPE.CODE) {
                                const templates = allTemplates.filter( template => template.getName() === templateName)
                                if (templates.length === 0) {
                                    notImportedItems.push( { error: t('service_not_available'), templateName, driver } )
                                } else if ( templates.length > 1 ) {
                                    notImportedItems.push( { error: t('service_multiple_available'), templateName, driver } )
                                } else {
                                    const template = templates[0]
                                    const hasDeviceId = triggerEntities.find( entity => entity.definition.templateId === template.getId() ) ? true : false
                                    if (hasDeviceId) {
                                        notImportedItems.push( { error: t('already_exists'), templateName, driver } )
                                    } else {
                                        // device
                                        const token = uuidv4().replace(new RegExp('-', 'g'), '')
                                        const name = `${new Date().getTime()}`
                                        const device = await this.context.api.deviceService.codeDriver.registerCode(namespace, name, token)
                                        await this.context.api.deviceService.updateSetting(device.getId(), APP_MESSAGE_CREATOR, APP_MESSAGE_CREATOR_ADMIN)
                                        await this.context.api.deviceService.assignDevice(device.getId(), this.props.orgId )
                                        await this.context.api.deviceService.activateDevice(device.getId())
                                        // definition
                                        const definitionId = uuidv4()
                                        await this.context.api.appMessageService.registerDefinition(definitionId, actionId, device.getId(), ['TRIGGER1'], {}, template.getOrgId(), template.getId() )
                                        const definition = await this.context.api.appMessageService.getDefinition(definitionId)

                                        const preparedTemplate = prepareTemplateContent(template, definition, device)
                                        const triggerItem = {
                                            definition: definition,
                                            device: device,
                                            id: definition.getId(),
                                            title: preparedTemplate.header
                                        }

                                        importedItems.push({template, templateName, driver, triggerItem })
                                    }
                                }

                            } else {
                                notImportedItems.push( { error: t('device_type_not_importable'), templateName, driver } )
                            }
                        }

                    }
                    
                } 

            } catch (error) {
                this.setState({loading: false, importData: null, errorMessage: mapErrorMessage(error)})
                return
            }

            this.setState({loading: false, notImportedItems, importedItems}, () => {
                this.props.onSuccess(importedItems.map( item => item.triggerItem))
            })
        }
    }

    renderResultsTable() {
        const { importedItems, notImportedItems } = this.state
        const { t, classes } = this.props

        return (
            <List className={classes.list} subheader={<li />}>
                <li key={`section-1`} className={classes.listSection}>
                    <ul className={classes.list}>
                        <ListSubheader className={classes.listHeader}>{t('imported_services')}</ListSubheader>
                        {importedItems.map((item, index) => (
                        <ListItem key={`item-1-${index}`}>
                            <ListItemText primary={item.templateName} />
                        </ListItem>
                        ))}
                        { importedItems.length === 0 && <ListItem key={`item-1-0`}>
                            <ListItemText primary={'-'} />
                        </ListItem>}
                    </ul>
                </li>
                <li key={`section-2`} className={classes.listSection}>
                    <ul className={classes.list}>
                        <ListSubheader className={classes.listHeader}>{t('not_imported_services')}</ListSubheader>
                        {notImportedItems.map((item, index) => (
                        <ListItem key={`item-2-${index}`} className={classes.listItem}>
                            <ListItemText primary={item.templateName} secondary={<div className={classes.listItemError}>{item.error}</div>}/>
                        </ListItem>
                        ))}
                        { notImportedItems.length === 0 && <ListItem key={`item-1-0`}>
                            <ListItemText primary={'-'} />
                        </ListItem>}
                    </ul>
                </li>
            </List>
        )
    }


    render() {
        const { loading, errorMessage, importedItems, notImportedItems } = this.state
        const { t, classes } = this.props

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    { loading && <LinearProgress /> }
                    <DialogTitle id="form-dialog-title">{t('import')}</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        { (!importedItems && !notImportedItems && !errorMessage) && <div className={classes.formContainer}>
                            <input
                                accept='text/csv'
                                className={classes.input}
                                id="file-select-button"
                                onChange={this.handleSelect.bind(this)}
                                type="file"
                            />
                            <label htmlFor="file-select-button">
                                <Button
                                    variant="contained"
                                    component="span"
                                    className={classes.button}
                                    size={"medium"}
                                    color="secondary"
                                >
                                    {t('select_a_csv_file')}
                                </Button>
                            </label>
                        </div> }
                        {errorMessage && <DialogContentText style={{marginTop: 20, paddingLeft: 24, paddingRight: 24}} color="error">{errorMessage}</DialogContentText>}
                        {!errorMessage && importedItems && notImportedItems && this.renderResultsTable()}
                    </DialogContent>
                    <DialogActions>
                        { (!importedItems && !notImportedItems) && <Button disabled={loading} onClick={this.props.onClose}>
                            {t('cancel')}
                        </Button>}
                        { (!loading && importedItems && notImportedItems) && <Button disabled={loading} onClick={this.props.onClose}>
                            {t('close')}
                        </Button> }
                        { errorMessage && <Button onClick={() => this.setState({ errorMessage: null})}>
                            {t('try_again')}
                        </Button> }
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

}

TriggerImportDialog.contextType = PageContext

TriggerImportDialog.propTypes = {
    open: PropTypes.bool,
    orgId: PropTypes.string.isRequired,
    groupOrgId: PropTypes.string.isRequired,
    rootOrgId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

const styles = theme => ({
    dialogContent: {
        padding: 0
    },
    formContainer: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    input: {
        display: 'none'
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
    list: {
        padding: 0
    },
    listItem: {
        paddingBottom:0,
        paddingTop:0
    },
    listHeader: {
        backgroundColor: '#262626'
    },
    listItemError: {
        color: theme.palette.error.main,
    }
})


export default withTranslation()(withStyles(styles)(TriggerImportDialog))