export default [
    {
        name: "Default",
        filePath: '/assets/PNSounds/Default.mp3'
    },
    {
        name: "Announcement",
        filePath: '/assets/PNSounds/Announcement.wav'
    },
    {
        name: "Bell",
        filePath: '/assets/PNSounds/Bell.wav'
    },
    {
        name: "Answer",
        filePath: '/assets/PNSounds/Answer.wav'
    },
    {
        name: "Confirmation",
        filePath: '/assets/PNSounds/Confirmation.wav'
    },
    {
        name: "Flut",
        filePath: '/assets/PNSounds/Flut.wav'
    },
    {
        name: "Guitar",
        filePath: '/assets/PNSounds/Guitar.wav'
    },
    {
        name: "Musical",
        filePath: '/assets/PNSounds/Musical.wav'
    },
    {
        name: "Orchestral",
        filePath: '/assets/PNSounds/Orchestral.wav'
    },
    {
        name: "Positiv",
        filePath: '/assets/PNSounds/Positiv.wav'
    }
]