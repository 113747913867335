export const REPLACEMENT_TYPE = {
  TEXT: 'freetext',
  INTEGER: 'integer',
  ENUM: 'enum',
  QR_CODE: 'qrcode',
  BAR_CODE: 'barcode',
  DATE: 'date',
  CHECKLIST: 'checklist',
  REPORT: 'report',
  IMAGE: 'image',
  NOTE: 'note',
};

export const REPLACEMENT_DATE_TYPE = {
  DATE: 'date',
  DATETIME: 'datetime',
  TIME: 'time',
};

export const REPLACEMENT_DATE_FORMAT = {
  DATE: 'L',
  DATETIME: 'L LT',
  TIME: 'LT',
};

export default class ReplacementDefinition {
  /**
   * @param {Object<Uid,Uid,String,String,Object>}
   */
  constructor({ replacementId, orgId, name, type, desc }) {
    this.id = replacementId;
    this.orgId = orgId;
    this.title = name;
    this.type = type;
    this.desc = desc;
  }

  /**
   * Get id
   * @returns {Uid}
   */
  getId() {
    return this.id;
  }

  /**
   * Get Org id
   * @returns {Uid}
   */
  getOrgId() {
    return this.orgId;
  }

  /**
   * Get template name
   * @returns {String}
   */
  getName() {
    return this.title;
  }

  /**
   * Get type
   * @returns {String}
   */
  getType() {
    return this.type;
  }

  /**
   * Get desc
   * @returns {Object}
   */
  getDesc() {
    return this.desc;
  }
}
