import Group from './Models/Group';
import Role from './Models/Role';
import Permission from './Models/Permission';
import Invitation from './Models/Invitation';
import ApiCache, { CACHE_ITEM_TYPE } from '../ApiCache';

const API_SERVICE_KEY_AUTHZ = 'core-authz';

export default class AuthzService {
  /**
   * @param {Api} api
   */
  constructor(api) {
    this.api = api;
    this.groupCache = new ApiCache(Group);
  }

  /**
   * List users from given group ip
   * @param {Uid} groupId
   *
   * @returns Promise<uuid>
   */
  listUsers(groupId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id/user/', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
    });
    return this.api.request('GET', url, null);
  }

  /**
   * list groups of cuurent user
   * @param {Uid} groupId
   *
   * @returns Promise<uuid>
   */
  listGroupsOfCurrentUser() {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/byUser', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
    });
    return this.api.request('GET', url, null);
  }
  /**
   * list groups of cuurent user
   *
   * @returns Promise<uuid>
   */
  listAccessableGroupsOfCurrentUser() {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/byUserAccessible', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
    });
    return this.api.request('GET', url, null);
  }

  /**
   * read groups of cuurent user
   * @param {Boolean} includePrivateGroup
   *
   * @returns Promise<Array<Group>>
   */
  readAccessableGroupsOfCurrentUser(includePrivateGroup = true) {
    const isPrivateGroup = group => {
      return group && group.getName().indexOf('$PRIVATEGROUP') === 0;
    };
    const sortGroups = _groups => {
      const groups = _groups.sort((a, b) => {
        var nameA = a.getName().toUpperCase();
        var nameB = b.getName().toUpperCase();

        if (isPrivateGroup(a) || nameA < nameB) {
          return -1;
        }
        if (isPrivateGroup(b) || nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return groups;
    };

    return this.listAccessableGroupsOfCurrentUser().then(groups => {
      return this.readMultipleGroups(groups).then(_groups => {
        this.groupCache.setList(_groups);
        const groups = sortGroups(this.groupCache.getCachedList());
        if (!includePrivateGroup) {
          return groups.filter(group => !isPrivateGroup(group));
        } else {
          return groups;
        }
      });
    });
  }

  /**
   * remove user from group
   * @param {Uid} groupId
   * @param {Uid} userId
   *
   * @returns Promise<Boolean>
   */
  removeUser(groupId, userId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id/user/:userId', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
      ':userId': userId,
    });
    return this.api.request('DELETE', url, null).then(result => {
      if (result.done) {
        return this.readGroup(groupId).then(group => {
          this.groupCache.set(group);
          return true;
        });
      } else {
        return result.done;
      }
    });
  }

  /**
   * read group
   * @param {Uid} groupId
   *
   * @returns Promise<Group>
   */
  readGroup(groupId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
    });
    return this.api.request('GET', url, null).then(result => new Group(result));
  }

  /**
   * update or insert group
   * @param {Uid} uid
   * @param {String} name
   *
   * @returns Promise<Boolean>
   */
  upsertGroup(uid, name) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': uid,
    });
    const body = { name: name };
    return this.api.request('PUT', url, body).then(groupObject => {
      const group = new Group(groupObject);
      this.groupCache.set(group);
      return group;
    });
  }

  /**
   * add user to the given group
   * @param {Uid} groupId
   * @param {Uid} userId
   *
   * @returns Promise<Boolean>
   */
  addUser(groupId, userId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id/user/:userId', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
      ':userId': userId,
    });
    return this.api.request('POST', url, null).then(result => {
      if (result.done) {
        return this.readGroup(groupId).then(group => {
          this.groupCache.set(group);
          return true;
        });
      } else {
        return result.done;
      }
    });
  }

  /**
   * deactivate group
   * @param {Uid} groupId
   *
   * @returns Promise<Boolean>
   */
  deactivateGroup(groupId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
    });
    return this.api.request('DELETE', url, null).then(result => {
      if (result.done) {
        this.groupCache.delete(groupId);
        return true;
      } else {
        return result.done;
      }
    });
  }

  /**
   * Lists all groups, for which the current user has the given access permission.
   * @param {Array<Uid>} groupIds
   *
   * @returns Promise<Array<Group>>
   */
  readMultipleGroups(groupIds) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/getMultiple', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
    });
    const body = groupIds;
    return this.api.request('POST', url, body).then(items => {
      items.map((group, index) => {
        if (group) {
          items[index] = new Group(group);
        }
      });
      return items;
    });
  }
  /**
   * Lists all roles
   * @param {Array<Uid>} roleIds
   *
   * @returns Promise<Array<Role>>
   */

  readMultipleRoles(roleIds) {
    const url = this.api.buildRequestURL('/:serviceKey/api/role/getMultiple', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
    });
    const body = roleIds;
    return this.api.request('POST', url, body).then(items => {
      items.map((role, index) => {
        if (role) {
          items[index] = new Role(role);
        }
      });
      return items;
    });
  }

  /**
   * list roles from given group ip
   * @param {Uid} groupId
   *
   * @returns Promise<uuid>
   */
  listRoles(groupId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id/role/', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
    });
    return this.api.request('GET', url, null);
  }

  /**
   * list accessable roles of current user
   *
   * @returns Promise<uuid>
   */
  listAccessableRolesOfCurrentUser() {
    const url = this.api.buildRequestURL('/:serviceKey/api/role/byUserAccessible', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
    });
    return this.api.request('GET', url, null);
  }

  /**
   * read accessable roles of current user
   * @param {Boolean} includePrivateRole
   * @returns Promise<Array<Role>>
   */
  readAccessableRolesOfCurrentUser(includePrivateRole = true) {
    const isPrivateRole = role => {
      return role && role.getName().indexOf('$PRIVATEROLE') === 0;
    };
    return this.listAccessableRolesOfCurrentUser().then(roles => {
      return this.readMultipleRoles(roles).then(_roles => {
        const roles = _roles.sort((a, b) => {
          if (a && b) {
            var nameA = a.getName().toUpperCase();
            var nameB = b.getName().toUpperCase();

            if (isPrivateRole(a) || nameA < nameB) {
              return -1;
            }
            if (isPrivateRole(b) || nameA > nameB) {
              return 1;
            }
          }
          return 0;
        });

        if (!includePrivateRole) {
          return roles.filter(role => !isPrivateRole(role));
        } else {
          return roles;
        }
      });
    });
  }

  /**
   * read role
   * @param {Uid} roleId
   *
   * @returns Promise<Role>
   */
  readRole(roleId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/role/:id', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': roleId,
    });
    return this.api.request('GET', url, null).then(result => new Role(result));
  }

  /**
   * update or insert role
   * @param {Uid} uid
   * @param {String} name
   *
   * @returns Promise<Boolean>
   */
  upsertRole(uid, name) {
    const url = this.api.buildRequestURL('/:serviceKey/api/role/:id', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': uid,
    });
    const body = { name: name };
    return this.api.request('PUT', url, body).then(result => new Role(result));
  }

  /**
   * add role to the given group
   * @param {Uid} groupId
   * @param {Uid} roleId
   *
   * @returns Promise<Boolean>
   */
  addRole(groupId, roleId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id/role/:roleId', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
      ':roleId': roleId,
    });
    return this.api.request('POST', url, null).then(result => new Group(result));
  }

  /**
   * deactivate role
   * @param {Uid} roleId
   *
   * @returns Promise<Boolean>
   */
  deactivateRole(roleId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/role/:id', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': roleId,
    });
    return this.api.request('DELETE', url, null).then(result => result.done);
  }

  /**
   * remove role
   * @param {Uid} groupId
   * @param {Uid} roleId
   *
   * @returns Promise<Boolean>
   */
  removeRole(groupId, roleId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id/role/:roleId', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
      ':roleId': roleId,
    });
    return this.api.request('DELETE', url, null).then(result => result.done);
  }

  /**
   * list permissions
   * @param {Uid} roleId
   *
   * @returns Promise<Permission>
   */
  listPermissions(roleId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/role/:id/permission/', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': roleId,
    });
    return this.api.request('GET', url, null).then(result => {
      result.items.map((permission, index) => {
        result.items[index] = new Permission(permission);
      });
      return result;
    });
  }

  /**
   * add permission to the given role
   * @param {Uid} roleId
   * @param {String} entityGroup
   * @param {String} action
   * @param {String} resource
   *
   * @returns Promise<Boolean>
   */
  addPerm(roleId, entityGroup, action, resource) {
    const url = this.api.buildRequestURL('/:serviceKey/api/role/:id/permission/:entityId/:action/:resource', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': roleId,
      ':entityId': entityGroup,
      ':action': action,
      ':resource': resource,
    });
    return this.api.request('POST', url, null).then(result => result.done);
  }

  /**
   * check permission
   * @param {Uid} userId
   * @param {String} entityGroup
   * @param {String} action
   * @param {String} resource
   *
   * @returns NotUsed ?
   */
  checkPerm(userId, entityGroup, action, resource) {
    const url = this.api.buildRequestURL('/:serviceKey/api/perm/check/:userId/:entityGroup/:action/:resource', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':userId': userId,
      ':entityGroup': entityGroup,
      ':action': action,
      ':resource': resource,
    });
    return this.api.request('GET', url, null, null, true, 'text').then(() => true);
  }

  /**
   * remove permission
   * @param {Uid} roleId
   * @param {String} entityGroup
   * @param {String} action
   * @param {String} resource
   *
   * @returns Promise<Boolean>
   */
  removePerm(roleId, entityGroup, action, resource) {
    const url = this.api.buildRequestURL('/:serviceKey/api/role/:id/permission/:entityId/:action/:resource', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': roleId,
      ':entityId': entityGroup,
      ':action': action,
      ':resource': resource,
    });
    return this.api.request('DELETE', url, null).then(result => result.done);
  }
  /**
   * List user permissions
   * @param {Uid} userId
   * @param {String} entityType
   *
   * @returns Promise<Permission>
   */
  listUserPermissionsByEntityId(userId, entityType) {
    const url = this.api.buildRequestURL('/:serviceKey/api/perm/byUserAndEntity/:userId/:entityType', {
      serviceKey: API_SERVICE_KEY_AUTHZ,
      ':userId': userId,
      ':entityType': entityType,
    });
    return this.api.request('GET', url, null).then(result => new Permissions(result));
  }
  /**
   * Invite user
   * @param {Uid} groupId
   * @param {Uid} userId
   *
   * @returns Promise<Boolean>
   */
  inviteUser(groupId, userId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id/invite/:userId', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
      ':userId': userId,
    });
    return this.api.request('POST', url, null).then(result => result.done);
  }

  /**
   * list offered invitations
   *
   * @returns Promise<Invitation>
   */
  listOfferedInvitations() {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/offeredInvitations', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
    });
    return this.api.request('GET', url, null).then(result => {
      result.map((invitation, index) => {
        result[index] = new Invitation(invitation);
      });
      return result;
    });
  }

  /**
   * list pending invitations
   *
   * @returns Promise<Invitation>
   */
  listPendingInvitations() {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/pendingInvitations', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
    });
    return this.api.request('GET', url, null).then(result => {
      result.map((invitation, index) => {
        result[index] = new Invitation(invitation);
      });
      return result;
    });
  }

  /**
   * accept invitations
   * @param {Uid} groupId
   *
   * @returns Promise<Boolean>
   */
  acceptInvitation(groupId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:groupId/acceptInvitation', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':groupId': groupId,
    });
    return this.api.request('POST', url, null).then(result => result.done);
  }

  /**
   * revoke invitations
   * @param {Uid} groupId
   *
   * @returns Promise<Boolean>
   */
  revokeInvitation(groupId, userId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/group/:id/invite/:userId', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':id': groupId,
      ':userId': userId,
    });
    return this.api.request('DELETE', url, null).then(result => result.done);
  }

  /**
   * list manageable users from given user
   * @param {Uid} userId
   * @returns Promise<Array<Uid>>
   */
  listManageableUsers(userId) {
    const url = this.api.buildRequestURL('/:serviceKey/api/listManageableUsers/:userId', {
      ':serviceKey': API_SERVICE_KEY_AUTHZ,
      ':userId': userId,
    });
    return this.api.request('GET', url, null);
  }

  /**
   * get manageable users from given user
   * @param {Uid} userId
   * @returns Promise<Array<Uid>>
   */
  /*getManageableUsers(userId) {
        return this.listGroupsWithManageUsersPermByUser( userId )
        .then(groups => {
            console.log('getManageableUsers - groups', groups)
            const roleIds = []
            groups && groups.length > 0 && groups.map( group => {
                group.roles && group.roles.map( roleId => {
                    if (roleIds.indexOf(roleId) === -1) roleIds.push(roleId)
                })
            })
            if (roleIds.length > 0) {
                console.log('getManageableUsers - readMultipleRoles', roleIds)
                return this.readMultipleRoles( roleIds )
                .then( roles => {
                    console.log('getManageableUsers - roles', roles)
                    const manageableGroupIds = []
                    if (roles && roles.length > 0) {
                        roles.map(role => {
                            const permissions = role && role.getPermissions()
                            if ( permissions && permissions.length > 0 ) {
                                permissions.map(permission => {
                                    const resource = permission.getResource()
                                    if (manageableGroupIds.indexOf(resource) === -1) manageableGroupIds.push(resource)
                                })
                            }
                        })
                    }

                    if (manageableGroupIds.length > 0) {
                        const distinctUserIds = []
                        console.log('getManageableUsers - manageableGroupIds', manageableGroupIds)
                        return this.readMultipleGroups( manageableGroupIds )
                        .then( manageableGroups => {
                            console.log('getManageableUsers - manageableGroups', manageableGroups)
                            if ( manageableGroups && manageableGroups.length ) {
                                for (let i = 0; i < manageableGroups.length; i++) {
                                    const mangeableGroup = manageableGroups[i]
                                    console.log('getManageableUsers - mangeableGroup', mangeableGroup, mangeableGroup.getUsers())
                                    mangeableGroup.getUsers().map( userId => {
                                        if (distinctUserIds.indexOf(userId) === -1) distinctUserIds.push(userId)
                                    })
                                }
                            }
                            return distinctUserIds
                        })
                    } else {
                       return []
                    }
                })
            } else {
                return []
            }
        })
    }*/

  /**
   * query groups which control the given user
   * @param {Uid} userId
   * @returns Promise<Array<Uid>>
   */
  /*listGroupsWithManageUsersPermByUser(userId) {
        const url = this.api.buildRequestURL('/:serviceKey/api/group/withManageUsersPerm/byUser/:userId', {
            ':serviceKey': API_SERVICE_KEY_AUTHZ,
            ':userId': userId
        })
        return this.api.request('GET', url, null).then(result => {
            if (result && result.length > 0) {
                return this.readMultipleGroups(result)
            }
            return []
        })
    }*/
}
