import UpdateMeta from '../../Models/UpdateMeta';

export const DEVICE_TYPE = { VIRTUAL: 'virtual', CLAIM_DEVICE: 'claim_device', TIMER: 'timer' };
export const STA2_TYPE = { PUSH_LOG: 'pushLOG2', WEIGHT_LOG: 'weightLOG2', TURN_LOG: 'turnLOG2' };
export const DRIVER_TYPE = { STA2: 'sta2', CODE: 'code', TIMER: 'timer', KK: 'kk', ESPI: 'espi', SOLUM: 'solum', HG1: 'hg1', THINGSPARK: 'thingspark' };
export const SETTING_KEY_CRON = 'drv.timer.cron';

export default class Device {
  /**
   * @param {Object<Uid,String,String,Uid,Uid,Object,Object>}
   */
  constructor({ assignedOrg, claimKey, driver, id, ownerOrg, physicalId, settings, subType, type, activated }) {
    this.assignedOrg = assignedOrg;
    this.claimKey = claimKey;
    this.driver = driver;
    this.id = id;
    this.ownerOrg = ownerOrg;
    this.physicalId = physicalId;
    this.settings = settings;
    this.subType = subType;
    this.type = type;
    this.activated = activated;
  }

  /**
   * Get id
   * @returns {Uid}
   */
  getId() {
    return this.id;
  }

  /**
   * Is avtivated
   * @returns {Boolean}
   */
  isActivated() {
    return this.activated === true;
  }

  /**
   * Get assigned Org
   * @returns {Uid}
   */
  getAssignedOrg() {
    return this.assignedOrg;
  }

  /**
   * Get owner org
   * @returns {Uid}
   */
  getOwnerOrg() {
    return this.ownerOrg;
  }

  /**
   * Get claim key
   * @returns {String}
   */
  getClaimKey() {
    return this.claimKey;
  }

  /**
   * Get driver
   * @returns {String}
   */
  getDriver() {
    return this.driver;
  }

  /**
   * Get physical id
   * @returns {String}
   */
  getPhysicalId() {
    return this.physicalId;
  }

  /**
   * Get settings
   * @returns {Object}
   */
  getSettings() {
    return this.settings;
  }

  /**
   * Get setting
   * @param {String} name
   * @returns {String}
   */
  getSetting(name) {
    return this.settings && this.settings[name];
  }

  /**
   * Set setting
   * @param {String} name
   * @param {String} value
   * @returns {Self}
   */
  setSetting(name, value) {
    if (!this.settings) this.settings = {};
    this.settings[name] = value;
    return this;
  }

  /**
   * Get type
   * @returns {String}
   */
  getType() {
    return this.type;
  }

  /**
   * Get sub type
   * @returns {String}
   */
  getSubType() {
    return this.subType;
  }
}
