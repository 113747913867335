import React, { PureComponent } from 'react'
import { Grid, Button, IconButton, useMediaQuery, Typography } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import ReactSVG from 'react-svg'
import MenuIcon from '@material-ui/icons/Menu'

// export const IconButton = ({ Icon, onPress }) => {
// 	return (
// 		<Button onClick={() => onPress()}>
// 			{Icon && Icon}
// 		</Button>
// 	)
// }
const packageJSON = require('../../../../package.json')
export const SelectButton = ({ title, onPress, style }) => {
    const classes = useStyles();
    return (
        <Button className={classes.selectButton} style={style} size='small' onClick={() => onPress()}>
            <div className={classes.selectButtonText}>{title}</div>
            <ReactSVG className={classes.selectButtonIcon} src="../assets/light/arrow-expand.svg" />
        </Button>
    )
}

export const IconWithTextButton = ({ Icon, title, onPress }) => {
    const classes = useStyles();
    return (
        <Button className={classes.iconWithTextButton} onClick={() => onPress()}>
            <span className={classes.iconWithTextButtonIcon}>{Icon && Icon}</span>
            {/* {title && <div className={classes.iconWithTextButtonText}>{title}</div>} */}
        </Button>
    )
}

class HeaderBar extends PureComponent {
    render() {
        const { classes, t, leftContent, centerContent, rightContent } = this.props

        return (
            <Grid container className={classes.container} >
                <Grid item xs={12} className={classes.contentContainer}>
                    {leftContent && <div className={classes.leftContent} >
                        {leftContent}
                    </div>}

                    <Grid className={classes.logoContainer}>
                        <ReactSVG className={classes.logo} src="../assets/logo.svg" />
                        <img src="../assets/message_log_logo.png" alt="message log logo" className={classes.messagelogLogo} />
                        <Typography variant={'caption'} color={'textSecondary'} className={classes.versionText}>{packageJSON.version}</Typography>
                    </Grid>

                    {centerContent && <div className={classes.centerContent} >
                        {centerContent}
                    </div>}
                    {rightContent && <div className={classes.rightContent}>
                        {rightContent}
                    </div>}
                </Grid>
            </Grid>
        )
    }
}

HeaderBar.propTypes = {
    LeftContent: PropTypes.any,
    rightContent: PropTypes.any
}

const useStyles = makeStyles(theme => ({
    iconWithTextButton: props => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        textTransform: 'none',
        justifyContent: 'center',
        minWidth: 'unset',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        marginLeft: 12,
        '& svg': {
            width: 20,
            height: 20,
            display: 'flex'
        }
    }),
    iconWithTextButtonIcon: props => ({
        // marginTop: 
    }),
    iconWithTextButtonText: props => ({
        color: theme.palette.common.white,
        marginLeft: theme.spacing(1)
    }),
    selectButtonText: props => ({
        color: theme.palette.common.white,
        paddingLeft: 4
    }),
    selectButton: props => ({
        display: 'flex',
        backgroundColor: 'rgba(255,255,255,0.15)',
        textTransform: 'none',
        height: 28,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        '&:active': {
            backgroundColor: 'rgba(255,255,255,0.2)'
        },
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.2)'
        }
    }),
    selectButtonIcon: props => ({
        '& svg': {
            display: 'flex'
        }
    }),
}));


const styles = theme => ({
    container: {
        backgroundColor: theme.palette.common.black,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: theme.spacing(6)
    },
    contentContainer: {
        display: 'flex',
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            height: 40,
            width: 58
        },
        '& > div': {
            overflow: 'hidden',
            height: 40,
        },
        marginLeft: 8,
    },
    leftContent: {
        display: 'flex',
        color: '#fff'
    },
    centerContent: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        color: '#fff'
    },
    rightContent: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: '#fff'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginRight: theme.spacing(4)
    },
    messagelogLogo: {
        height: 30,
        marginLeft: 2
    },
    versionText: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing(1),
        fontSize: 9,
        fontWeight: 700,
        letterSpacing: '-0.01em',
        color: theme.palette.common.white
    }
})

export default withTranslation()(withStyles(styles)(HeaderBar))

