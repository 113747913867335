import React from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Select, MenuItem, LinearProgress} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import PropTypes from 'prop-types'

import { PARAM_FORM_USER_FIELDS, PARAM_FORM_TECHNICAL_USER_FIELDS, PARAM_FORM_NICKNAME_FIELD, PARAM_FORM_LANGUAGE_FIELD, PARAM_LANGUAGE } from '../../../m2m-cloud-api/Api/UserService/Models/User'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'

class EditUserDialog extends React.Component {
    
    constructor(props) {
        super(props)

        this.state = {
            user: null,
            params: {},
            userType: 0,
            loading: true,
            errorMessage: null
        }
    }

    async componentDidMount() {
        const { api, userId } = this.props
        const user = userId ? await api.userService.loadUser(userId) : api.userService.getActiveUser()
        this.setState({user}, () => {
            this.setState({
                params: this.getDefaultValues(),
                userType: this.isAdminUser() ? 1 : 0,
                loading: false
            })
        })
    }

    isAdminUser() {
        const { userId } = this.props
        return this.props.adminsGroup && this.props.adminsGroup.getUsers() && this.props.adminsGroup.getUsers().indexOf(userId) >= 0
    }

    isDefaultUser() {
        const { userId } = this.props
        return this.props.usersGroup && this.props.usersGroup.getUsers() && this.props.usersGroup.getUsers().indexOf(userId) >= 0
    }

    getFormFields() {
        const { t } = this.props
        const { user } = this.state

        let fields = []
        if ( user.isTechnicalUser() ) {
            fields = [PARAM_FORM_NICKNAME_FIELD, ...PARAM_FORM_TECHNICAL_USER_FIELDS, PARAM_FORM_LANGUAGE_FIELD]
        } else {
            fields = [PARAM_FORM_NICKNAME_FIELD, ...PARAM_FORM_USER_FIELDS, PARAM_FORM_LANGUAGE_FIELD]
        }
        return fields
    }


    getDefaultValues() {
        const { user } = this.state
        let params = {}
        this.getFormFields().map( field => {
            params[field.id] = user.getParam(field.id)
        })
        return params
    }

    buildApiUpdateRequests() {
        const { userId } = this.props
        const { user, params, userType } = this.state
        const promisses = []

        if ( userType === 0 && this.isAdminUser() ) {
            promisses.push( this.props.api.authzService.addUser(this.props.usersGroup.getId(), userId) )
            if ( this.isAdminUser() ) {
                promisses.push( this.props.api.authzService.removeUser(this.props.adminsGroup.getId(), userId) )
            }
        }
        if ( userType === 1 && this.isDefaultUser() ) {
            promisses.push( this.props.api.authzService.addUser(this.props.adminsGroup.getId(), userId) )
            if ( this.isDefaultUser() ) {
                promisses.push( this.props.api.authzService.removeUser(this.props.usersGroup.getId(), userId) )
            }
        }

        this.getFormFields().map( field => {
            const fieldValue =  params[field.id]
            if (!fieldValue || fieldValue.trim() === "") {
                promisses.push( this.props.api.userService.deleteParam(user.getUserId(), field.id ) )
            } else {
                promisses.push( this.props.api.userService.putParam(user.getUserId(), field.id, params[field.id] ) )
            }
        })
        return promisses
    }

    handleSubmit() {
        const {t} = this.props

        this.setState({loading: true, errorMessage: null})

        return Promise.all( this.buildApiUpdateRequests() )
        .then(async results => {
            const lang = this.state.params[PARAM_LANGUAGE]
            if (lang && lang.trim() !== '') {
                i18n.changeLanguage(lang)
            }
            this.props.onSuccess()
        })
        .catch( error => {
            this.setState({
                errorMessage: mapErrorMessage(error)
            })
        })
        //.finally(() => this.setState({loading: false}))
    }

    render() {
        const { params, loading, errorMessage, user, userType} = this.state
        const { t, classes } = this.props

        if (!user) return (null)

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    { loading && <LinearProgress className={classes.progress} /> }
                    <DialogTitle id="form-dialog-title">{t('edit_profile')}</DialogTitle>
                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit.bind(this)}
                        onError={errors => console.log("form error:", errors)}>
                        <DialogContent>
                            <TextValidator
                                select
                                value={userType}
                                disabled={loading}
                                onChange={(event) => {
                                    this.setState({ userType: event.target.value })
                                }}
                                margin="dense"
                                label={t('user_type')}
                                fullWidth>
                                    <MenuItem key={0} value={0}>
                                        {t('default_user')}
                                    </MenuItem>
                                    <MenuItem key={1} value={1}>
                                        {t('admin')}
                                    </MenuItem>
                            </TextValidator>
                            {this.getFormFields().map( field => {
                                const value = params[field.id]
                                if (field.type === 'select') {
                                    return <FormControl key={field.id} className={classes.formControl} fullWidth>
                                        <InputLabel htmlFor="user-lang">{t(field.title)}</InputLabel>
                                        <Select
                                            value={value}
                                            disabled={loading}
                                            className={classes.field}
                                            onChange={(event) => {
                                                let _params = params
                                                _params[field.id] = event.target.value
                                                this.setState({ params: _params, errorMessage: null })
                                            }}
                                            inputProps={{
                                                name: field.id,
                                                id: 'user-lang',
                                            }}>
                                            {field.options.map(option => (
                                                <MenuItem key={option} value={option}>{t(option)}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                } else {
                                    return <TextValidator
                                        className={classes.field}
                                        key={field.id}
                                        disabled={loading}
                                        value={value}
                                        onChange={(event) => {
                                            let _params = params
                                            _params[field.id] = event.target.value
                                            this.setState({params: _params, errorMessage: null})
                                        }}
                                        margin="dense"
                                        id={field.id}
                                        multiline={field.type === 'Text' ? true : false}
                                        label={ t(field.title) }
                                        validators={field.validators}
                                        errorMessages={field.errorMessages.map(message => t(message))}
                                        fullWidth
                                    />
                                }
                            })}
                            { errorMessage && <DialogContentText className={classes.errorText}>
                                { errorMessage }
                            </DialogContentText> }
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={loading} onClick={this.props.onCancel}>
                                {t('cancel')}
                            </Button>
                            <Button disabled={loading} type="submit" color="primary">
                                {t('save')}
                            </Button>
                        </DialogActions>
                    </ValidatorForm>
                </Dialog>
            </div>
        )
    }
}


EditUserDialog.propTypes = {
    api: PropTypes.any.isRequired,
    userId: PropTypes.string,
    usersGroup: PropTypes.object.isRequired,
    adminsGroup: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}


const styles = theme => ({
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20
    },
    field: {
        marginTop: 10,
        marginBottom: 10
    }
})


export default withTranslation()(withStyles(styles)(EditUserDialog))