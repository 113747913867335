import React, { Fragment, useState, useContext, useEffect, useCallback } from 'react'
import { PageContext } from '../Context/PageProvider'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListSubheader, ListItemSecondaryAction, IconButton, ListItemText, Divider, Menu, MenuItem, ListItemIcon, Typography, RootRef } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { ORG_TAG } from '../../../m2m-cloud-api'
import { sortItems } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { PARAM_APP_MSG_VISIBILITY_MODE, PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS, PARAM_APP_MSG_ITEM_SORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { USER_PARAM_KEY_RECIPIENT, USER_PARAM_KEY_SUPPLIER } from '../../../m2m-cloud-api/MessageLog/Contants'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import ReactSVG from 'react-svg'
import UserAccessManagementDialog from './UserAccessManagementDialog'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import UsageReportExportDialog from './UsageReportExportDialog'
import TriggerGroupDialog from './TriggerGroupDialog'
import ActionGroupDialog from './ActionGroupDialog'
import GroupViewListHeader from './GroupViewListHeader'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreIcon from '@material-ui/icons/MoreVert'
import PeopleIcon from '@material-ui/icons/GroupAdd'
import DownloadIcon from '@material-ui/icons/GetApp'

const mapicon = (entity) => {
    const visibilityMode = entity.getParam(PARAM_APP_MSG_VISIBILITY_MODE)
    switch (visibilityMode) {
        case PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.CONFIG:
        case PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.GROUP:
            return "/assets/visibility_group.svg"
        default:
            return "/assets/visibility_privat.svg"
    }
}

const getListItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        background: "rgba(255,255,255,.1)"
    })
})

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

export const SPECIAL_ITEM_ID = {
    REPLACEMENTS: 'replacements'
}

const ListItemMenu = ({ menuAnchorEl, onClose, onEditClick, onDeleteClick, onUserManagementClick, onUsageReportsExportClick }) => {
    const context = useContext(PageContext)
    const { t } = useTranslation()

    return (
        <Menu
            id={`menu-group-list}`}
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={onClose}>
            <MenuItem
                onClick={onEditClick}>
                <ListItemIcon style={{ minWidth: 35 }}>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit"> {t('edit')}</Typography>
            </MenuItem>
            <MenuItem
                onClick={onDeleteClick}>
                <ListItemIcon style={{ minWidth: 35 }}>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit"> {t('delete')}</Typography>
            </MenuItem>
            <MenuItem
                onClick={onUserManagementClick}>
                <ListItemIcon style={{ minWidth: 35 }}>
                    <PeopleIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit"> {t('access_management')}</Typography>
            </MenuItem>
            <MenuItem
                onClick={onUsageReportsExportClick}>
                <ListItemIcon style={{ minWidth: 35 }}>
                    <DownloadIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit"> {t('export_usage_reports')}</Typography>
            </MenuItem>
        </Menu>
    )
}

const GroupViewList = ({ groupOrgId, activeItemId, onChangeActiveItemId, customSortEnabled }) => {
    const context = useContext(PageContext)
    const classes = useStyles()
    const { t } = useTranslation()

    const [lastGroupOrgId, setLastGroupOrgId] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [customSortEnabledRecipients, setCustomSortEnabledRecipients] = useState(false)
    const [customSortEnabledSuppliers, setCustomSortEnabledSuppliers] = useState(false)
    const [recipientOrgs, setRecipientOrgs] = useState(null)
    const [supplierOrgs, setSupplierOrgs] = useState(null)
    const [menuAnchorEl, setMenuAnchorEl] = useState(null)
    const [activeEntity, setActiveEntity] = useState(null)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showUsageReportsExportDialog, setShowUsageReportsExportDialog] = useState(false)
    const [showAccessManagementDialog, setShowAccessManagementDialog] = useState(false)


    useEffect(() => {
        const groupOrgIdChanged = lastGroupOrgId !== groupOrgId
        const recipientOrgs = context.childrenOrgs.filter(org => org.getParentId() === groupOrgId && org.hasTags([ORG_TAG.RECIPIENT_LOCATION]))
        const supplierOrgs = context.childrenOrgs.filter(org => org.getParentId() === groupOrgId && org.hasTags([ORG_TAG.SUPPLIER_TARGET]))
        
        const groupOrg = context.childrenOrgs.find(org => org.getId() === groupOrgId)
        const recipientOrgIds = groupOrg && groupOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}-${USER_PARAM_KEY_RECIPIENT}`)
        const supplierOrgIds = groupOrg && groupOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}-${USER_PARAM_KEY_SUPPLIER}`)

        setRecipientOrgs(sortItems(customSortEnabledRecipients && recipientOrgIds, recipientOrgs))
        setSupplierOrgs(sortItems(customSortEnabledSuppliers && supplierOrgIds, supplierOrgs))
        setLastGroupOrgId(groupOrgId)
        if (groupOrgId) {
            activateFirstItem([...supplierOrgs, ...recipientOrgs], groupOrgIdChanged)
        }
    }, [groupOrgId, context.childrenOrgs, customSortEnabledRecipients, customSortEnabledSuppliers])

    useEffect(async() => {
        const groupOrg = context.childrenOrgs.find(org => org.getId() === groupOrgId)
        if (groupOrg) {
            const recipientOrgIds = groupOrg && groupOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}-${USER_PARAM_KEY_RECIPIENT}`)
            const supplierOrgIds = groupOrg && groupOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}-${USER_PARAM_KEY_SUPPLIER}`)
            const hasCustomSortRecipients = !(recipientOrgIds === null || recipientOrgIds === undefined)
            const hasCustomSortSuppliers = !(supplierOrgIds === null || supplierOrgIds === undefined)
            if ( hasCustomSortRecipients && !customSortEnabledRecipients ) {
                setSorting(ORG_TAG.RECIPIENT_LOCATION, null)
            } else if ( !hasCustomSortRecipients && customSortEnabledRecipients ) {
                setSorting(ORG_TAG.RECIPIENT_LOCATION, [])
            }
            if ( hasCustomSortSuppliers && !customSortEnabledSuppliers ) {
                setSorting(ORG_TAG.SUPPLIER_TARGET, null)
            } else if ( !hasCustomSortSuppliers && customSortEnabledSuppliers ) {
                setSorting(ORG_TAG.SUPPLIER_TARGET, [])
            }
        }
    }, [customSortEnabledRecipients, customSortEnabledSuppliers])

    const setSorting = async (orgType, orgIds =null) => {
        let promises = []
        const paramsToUpdate = {}
        const key = `${PARAM_APP_MSG_ITEM_SORT}-${orgType === ORG_TAG.RECIPIENT_LOCATION ? USER_PARAM_KEY_RECIPIENT : USER_PARAM_KEY_SUPPLIER}`
        if (orgIds === null) {
            promises.push(context.api.orgService.deleteParam(groupOrgId, key))
            paramsToUpdate[key] = null
        }
        if (orgIds) {
            promises.push(context.api.orgService.putParam(groupOrgId, key, orgIds.join(',')))
            paramsToUpdate[key] = orgIds.join(',')
        }
        if (promises.length > 0) {
            await context.updateAndCacheTopOrg(groupOrgId, promises)
            await context.updateCachedOrgParams(groupOrgId, paramsToUpdate)
        }
    }

    const activateFirstItem = (orgs, groupOrgIdChanged) => {
        if (!activeItemId || groupOrgIdChanged || (activeItemId !== SPECIAL_ITEM_ID.REPLACEMENTS && !orgs.find(org => org.getId() === activeItemId))) {
            if (orgs.length > 0) {
                onChangeActiveItemId(orgs[0].getId())
            } else {
                onChangeActiveItemId(SPECIAL_ITEM_ID.REPLACEMENTS)
            }
        }
    }

    const updateOrgParams = useCallback(async (params) => {
        setLoading(true)
        try {
            const promises = []
            Object.keys(params).map(name => {
                if (params[name] === null) {
                    promises.push(context.api.orgService.deleteParam(activeEntity.getId(), name))
                } else {
                    promises.push(context.api.orgService.putParam(activeEntity.getId(), name, params[name]))
                }
            })
            const org = await context.updateAndCacheTopOrg(activeEntity.getId(), promises)
            await context.loadRootOrgCildrens()
            resetDialogs()
        } catch (error) {
            setErrorMessage(t(mapErrorMessage(error)))
            setLoading(false)
        }
    }, [activeEntity && activeEntity.getId()])

    const deleteOrg = useCallback(async () => {
        setLoading(true)
        try {
            await context.updateAndCacheTopOrg(activeEntity.getId(), [context.api.orgService.deactivate(activeEntity.getId())])
            await context.loadRootOrgCildrens()
            resetDialogs()
        } catch (error) {
            setErrorMessage(t(mapErrorMessage(error)))
            setLoading(false)
        }
    }, [activeEntity && activeEntity.getId()])

    const resetDialogs = useCallback(() => {
        setLoading(false)
        setErrorMessage(null)
        setShowEditDialog(false)
        setShowDeleteDialog(false)
        setShowUsageReportsExportDialog(false)
        setShowAccessManagementDialog(false)
    }, [])

    const onSelectMenu = (entity, menuAnchorEl) => {
        setActiveEntity(entity)
        setMenuAnchorEl(menuAnchorEl)
    }
    const onEditClick = () => {
        setMenuAnchorEl(null)
        setShowEditDialog(true)
    }
    const onDeleteClick = () => {
        setMenuAnchorEl(null)
        setShowDeleteDialog(true)
    }
    const onUserManagementClick = () => {
        setMenuAnchorEl(null)
        setShowAccessManagementDialog(true)
    }
    const onUsageReportsExportClick = () => {
        setMenuAnchorEl(null)
        setShowUsageReportsExportDialog(true)
    }


    const onDragEnd = async (result, orgType = ORG_TAG.RECIPIENT_LOCATION) => {
        if (!result.destination) {
            return
        }
        const orgItems = orgType === ORG_TAG.RECIPIENT_LOCATION ? recipientOrgs : supplierOrgs
        const items = reorder(
            orgItems,
            result.source.index,
            result.destination.index
        )
        orgType === ORG_TAG.RECIPIENT_LOCATION ? setRecipientOrgs(items) : setSupplierOrgs(items)
        const itemIds = items.map(item => item.id)
        setSorting(orgType, itemIds)
    }

    if (!recipientOrgs || !supplierOrgs) return null

    return (
        <Fragment>
            <GroupViewListHeader listViewType={ORG_TAG.SUPPLIER_TARGET} groupOrgId={groupOrgId} title={`${t('i_am_a_supplier')} (${supplierOrgs?.length})`} customSortEnabled={customSortEnabledSuppliers} onCustomSortChange={(enabled) => setCustomSortEnabledSuppliers(enabled)}/>
            <List className={classes.container} subheader={<li />}>
                { supplierOrgs?.length > 0 && <li key={1}>
                    <DragDropContext onDragEnd={(result) => onDragEnd(result, ORG_TAG.SUPPLIER_TARGET)}>
                        <Droppable droppableId="droppable-supplier-list">
                            {(provided, snapshot) => (
                                <RootRef rootRef={provided.innerRef}>
                                    <div>
                                    {supplierOrgs?.map((entity, index) => (
                                        <Draggable key={entity.getId()} draggableId={entity.getId()} index={index} isDragDisabled={!customSortEnabledSuppliers}>
                                            {(provided, snapshot) => (
                                                <div key={entity.getId()} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getListItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                    <ListItem className={classes.listItem} selected={activeItemId === entity.getId()} onClick={() => onChangeActiveItemId(entity.getId())}>
                                                        {customSortEnabledSuppliers && <ListItemIcon className={classes.listItemIcon}>
                                                            <ReactSVG className={activeItemId === entity.getId() ? classes.selectedDragIcon : classes.dragIcon} src={"/assets/light/drag.svg"} />
                                                        </ListItemIcon>}
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <ReactSVG className={activeItemId === entity.getId() ? classes.selectedIcon : classes.icon} src={mapicon(entity)} />
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.listItemText} primary={entity.getName()} />
                                                        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                                                            <IconButton
                                                                className={classes.moreIconButton}
                                                                size={'small'}
                                                                onClick={(event) => onSelectMenu(entity, event.currentTarget)}
                                                                edge="end">
                                                                <MoreIcon className={activeItemId === entity.getId() ? classes.selectedIcon : classes.icon} />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider className={classes.divider} />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    </div>
                                    {provided.placeholder}
                                </RootRef>
                            )}
                        </Droppable>
                    </DragDropContext>
                </li>}
                <GroupViewListHeader className={classes.groupViewListHeader} listViewType={ORG_TAG.RECIPIENT_LOCATION} groupOrgId={groupOrgId} title={`${t('sender')} (${recipientOrgs?.length})`} customSortEnabled={customSortEnabledRecipients} onCustomSortChange={(enabled) => setCustomSortEnabledRecipients(enabled)}/>
                { recipientOrgs?.length > 0 && <li key={0}>
                    <DragDropContext onDragEnd={(result) => onDragEnd(result, ORG_TAG.RECIPIENT_LOCATION)}>
                        <Droppable droppableId="droppable-recipient-list">
                            {(provided, snapshot) => (
                                <RootRef rootRef={provided.innerRef}>
                                    <div>
                                        {recipientOrgs?.map((entity, index) => (
                                            <Draggable key={entity.getId()} draggableId={entity.getId()} index={index} isDragDisabled={!customSortEnabledRecipients}>
                                                {(provided, snapshot) => (
                                                    <div key={entity.getId()} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getListItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                        <ListItem className={classes.listItem} selected={activeItemId === entity.getId()} onClick={() => onChangeActiveItemId(entity.getId())}>
                                                            {customSortEnabledRecipients && <ListItemIcon className={classes.listItemIcon}>
                                                                <ReactSVG className={activeItemId === entity.getId() ? classes.selectedDragIcon : classes.dragIcon} src={"/assets/light/drag.svg"} />
                                                            </ListItemIcon>}
                                                            <ListItemText className={classes.listItemText} primary={entity.getName()} />
                                                            <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                                                                <IconButton
                                                                    className={classes.moreIconButton}
                                                                    size={'small'}
                                                                    onClick={(event) => onSelectMenu(entity, event.currentTarget)}
                                                                    edge="end">
                                                                    <MoreIcon className={activeItemId === entity.getId() ? classes.selectedIcon : classes.icon} />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider className={classes.divider} />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                    {provided.placeholder}
                                </RootRef>
                            )}
                        </Droppable>
                    </DragDropContext>
                </li>}
                <Typography className={classes.listItemTitle}>{t('settings')}</Typography>
                <ListItem className={classes.listItem} selected={activeItemId === SPECIAL_ITEM_ID.REPLACEMENTS} onClick={() => onChangeActiveItemId(SPECIAL_ITEM_ID.REPLACEMENTS)}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ReactSVG className={activeItemId === SPECIAL_ITEM_ID.REPLACEMENTS ? classes.selectedIcon : classes.icon} src="/assets/parameters.svg" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={t('selectable_features')} />
                </ListItem>
            </List>
            {showEditDialog && activeEntity && activeEntity.hasTags([ORG_TAG.RECIPIENT_LOCATION]) && <TriggerGroupDialog
                loading={loading}
                groupEntity={activeEntity}
                errorMessage={errorMessage}
                onSubmit={updateOrgParams}
                onCancel={resetDialogs} />}
            {showEditDialog && activeEntity && activeEntity.hasTags([ORG_TAG.SUPPLIER_TARGET]) && <ActionGroupDialog
                loading={loading}
                groupEntity={activeEntity}
                errorMessage={errorMessage}
                onSubmit={errorMessage ? null : updateOrgParams}
                onCancel={resetDialogs} />}
            {showAccessManagementDialog && activeEntity && <UserAccessManagementDialog
                open={true}
                orgId={activeEntity.getId()}
                onCancel={resetDialogs}
                onSuccess={resetDialogs} />}
            {showUsageReportsExportDialog && activeEntity && <UsageReportExportDialog
                open={true}
                org={activeEntity}
                rootOrg={context.getSelectedRootOrg()}
                onClose={resetDialogs} />}
            {showDeleteDialog && activeEntity && <AlertDialog
                open={true}
                loading={loading}
                errorMessage={errorMessage}
                title={activeEntity.hasTags([ORG_TAG.SUPPLIER_TARGET]) ? t('delete_supplier_group_org_title') : t('delete_recipient_group_org_title')}
                message={errorMessage ? null : activeEntity.hasTags([ORG_TAG.SUPPLIER_TARGET]) ? t('delete_supplier_group_org_description') : t('delete_recipient_group_org_description')}
                submitButtonTitle={errorMessage ? t('delete') : null}
                cancelButtonTitle={errorMessage ? t('close') : t('cancel')}
                buttonVariant={'text'}
                submitButtonColor={'primary'}
                onSubmit={errorMessage ? null : deleteOrg}
                onCancel={resetDialogs} />}
            <ListItemMenu menuAnchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(null)} onEditClick={onEditClick} onDeleteClick={onDeleteClick} onUserManagementClick={onUserManagementClick} onUsageReportsExportClick={onUsageReportsExportClick} />
        </Fragment>

    )
}

const useStyles = makeStyles(theme => ({
    container: {
        overflowX: 'hidden'
    },
    groupViewListHeader: {
        marginTop: theme.spacing(6)
    },
    listItemTitle: {
        fontSize: theme.font.extraSmall,
        lineHeight: '16px',
        letterSpacing: .4,
        fontWeight: 'normal',
        color: 'rgba(255, 255, 255, 0.5)',
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: theme.margin.large,
        backgroundColor: theme.palette.common.darkBackground,
        paddingTop: theme.padding.small,
        paddingBottom: theme.padding.small,
        marginTop: theme.spacing(6)
    },
    fistListItemTitle: {
        marginTop: 0
    },
    listItem: {
        paddingLeft: 10,
        paddingRight: 10,
        cursor: 'pointer',
        '&.Mui-selected, &:hover.Mui-selected': {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.common.white
        }
    },
    listItemIcon: {
        minWidth: 'auto',
        marginRight: theme.margin.medium
    },
    divider: {
        backgroundColor: theme.palette.common.black,
        opacity: 0.3,
    },
    dragIcon: {
        color: '#fff',
        opacity: 0.35
    },
    selectedDragIcon: {
        color: '#000',
        opacity: 0.35
    },
    icon: {
        fill: theme.palette.common.white,
        color: theme.palette.common.white,
        opacity: 0.8
    },
    selectedIcon: {
        color: theme.palette.common.black,
        fill: theme.palette.common.black,
        opacity: 0.8
    }
}))

export default GroupViewList