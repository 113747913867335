import Org from "./Models/Org";
import ApiCache from "../ApiCache";
import { API_SERVICE_KEY_FILE_SERVICE } from "../AppMesageService/AppMessageService";
import { getInvalidParameterError } from "../Errors";

const API_SERVICE_KEY_ORG = "core-org";
const API_SERVICE_KEY_ORG_SEARCH = "core-org-search";

export const ORG_TAG = {
    ROOT: "app-msg.root",
    GROUP: "app-msg.group",
    GROUP_SUPPLIER: "app-msg.group-supplier",
    GROUP_RECIPIENT: "app-msg.group-recipient",
    /**
     * @deprecated
     * should be handled like groups
     */
    CONFIG: "app-msg.config",
    RECIPIENT_LOCATION: "app-msg.source",
    SUPPLIER_TARGET: "app-msg.target",
};

export const ALL_GROUP_ORG_TAGS = [ORG_TAG.GROUP, ORG_TAG.GROUP_RECIPIENT, ORG_TAG.GROUP_SUPPLIER, ORG_TAG.CONFIG]
export const ALL_GROUP_WITHOUT_CONFIG_ORG_TAGS = ALL_GROUP_ORG_TAGS.filter(tag => tag !== ORG_TAG.CONFIG)

export default class OrgService {
    /**
     * @param {Api} api
     */
    constructor(api) {
        this.api = api;
        this.apiCache = new ApiCache(Org);
    }

    /**
     * Creates an organizational entity
     * @param {Uid} parent (optional)
     *
     * @returns Promise<Org>
     */
    create(parent = null, params = null, tags = null) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/", {
            ":serviceKey": API_SERVICE_KEY_ORG,
        });
        const body = {
            parent: parent,
            tags: !tags || tags.length === 0 ? null : tags,
            params: !params || params.length === 0 ? null : params,
        };
        return this.api.request("POST", url, body).then((result) => {
            const createdOrg = new Org(result);
            this.apiCache.add(createdOrg);
            return createdOrg;
        });
    }

    /**
     * Creates an organizational entity
     * @param {Uid} orgId
     * @param {Uid} parent (optional)
     *
     * @returns Promise<uuid>
     */
    /*createIdempotent(orgId, parent = null) {
          const url = this.api.buildRequestURL('/:serviceKey/api/org/:orgId', { 
              ':serviceKey': API_SERVICE_KEY_ORG,
              ':orgId': orgId
          })
          const body = { parent }
          return this.api.request('POST', url, body)
      }*/

    /**
     * update org
     * @param {Uid} orgId
     * @param {Array<Promise>} promises
     * @returns Promise<Org>
     */
    updateOrg(orgId, promises) {
        const me = this;
        return Promise.all(promises).then((results) => {
            return me.read(orgId).then((org) => {
                me.apiCache.update(org);
                return org;
            });
        });
    }

    /**
     * set tags
     * @param {Uid} orgId
     * @param {Array<String> | null} tags
     * @returns {Boolean}
     */
    setTags(orgId, tags) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId/tags", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
        });
        return this.api.request("POST", url, tags).then((result) => {
            return result.done;
        });
    }

    /**
     * set vis
     * @param {Uid} orgId
     * @param {String} vis
     * @returns {Boolean}
     */
    putVis(orgId, vis) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId/vis/:vis", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
            ":vis": vis,
        });
        return this.api.request("PUT", url, null).then((result) => result.done);
    }

    /**
     * delete vis
     * @param {Uid} orgId
     * @param {String} vis
     */
    deleteVis(orgId, vis) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId/vis/:vis", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
            ":vis": vis,
        });
        return this.api.request("DELETE", url, null).then((result) => result.done);
    }

    /**
     * get param
     * @param {Uid} orgId
     * @param {String} key
     * @returns {String}
     */
    getParam(orgId, key) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId/params/:key", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
            ":key": key,
        });
        return this.api.request("GET", url, null);
    }

    /**
     * set param
     * @param {Uid} orgId
     * @param {String} key
     * @param {String} value
     */
    putParam(orgId, key, value) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId/params/:key", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
            ":key": key,
        });
        const body = value;
        return this.api.request("PUT", url, body, { "Content-Type": "text/plain" });
    }

    /**
     * delete param
     * @param {Uid} orgId
     * @param {String} key
     */
    deleteParam(orgId, key) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId/params/:key", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
            ":key": key,
        });
        return this.api.request("DELETE", url, null).then((result) => result.done);
    }

    /**
     * Can read organizational entity
     * @param {Uid} orgId
     *
     * @returns Promise<Boolean>
     */
    canReadOrg(orgId) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:id/canReadOrg", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":id": orgId,
        });
        return this.api.request("GET", url, null).then((result) => result.done);
    }

    /**
     * Can write organizational entity
     * @param {Uid} orgId
     *
     * @returns Promise<Boolean>
     */
    canWriteOrg(orgId) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:id/canWriteOrg", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":id": orgId,
        });
        return this.api.request("GET", url, null).then((result) => result.done);
    }

    /**
     * Check access multiple organizational entities
     * @param {Uid} access
     *
     * @returns Promise<Array<Uid>>
     */
    checkAccessMultiple(access, orgIds) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/checkAccess/:access", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":access": access,
        });
        return this.api.request("POST", url, orgIds);
    }

    /**
     * Read
     * @param {Uid} orgId
     * @returns Promise<Org>
     */
    read(orgId) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
        });
        return this.api.request("GET", url, null).then((result) => new Org(result));
    }

    /**
     * Read multiple orgs
     * @param {Array<Uid>} orgIds
     * @returns Promise<Org>
     */
    readMultiple(orgIds) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/readMultiple", {
            ":serviceKey": API_SERVICE_KEY_ORG,
        });
        return this.api.request("POST", url, orgIds).then((items) => {
            items.map((org, index) => {
                if (org) {
                    items[index] = new Org(org);
                }
            });
            return items;
        });
    }

    /**
     * Deactivate org
     * @param {Uid} orgId
     * @returns {Boolean}
     */
    deactivate(orgId) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
        });
        return this.api.request("DELETE", url, null).then((result) => result.done);
    }

    /**
     * Reactivate org
     * @param {Uid} orgId
     * @returns {Boolean}
     */
    reactivate(orgId) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId/reactivate ", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
        });
        return this.api.request("GET", url, null).then((result) => result.done);
    }

    /**
     * move org
     * @param {Uid} orgId
     * @param {Uid} newParentId
     * @returns {Boolean}
     */
    move(orgId, newParent) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org/:orgId/move", {
            ":serviceKey": API_SERVICE_KEY_ORG,
            ":orgId": orgId,
        });
        const body = { newParent };
        return this.api.request("POST", url, body).then((result) => result.done);
    }

    /**
     * search org
     * @param {Object<from, size, keywords, terms, searchRootOrg, tags, params>} params
     * @returns {Array<Org>}
     */
    searchOrg({ from = 0, size = 10000, keywords = "*", terms = null, searchRootOrg = null, tags = null, params = null, visibility = null, modeVisibility = null }) {
        const url = this.api.buildRequestURL("/:serviceKey/api/org-search/?from=:from&size=:size", {
            ":serviceKey": API_SERVICE_KEY_ORG_SEARCH,
            ":from": from,
            ":size": size,
        });
        const body = {
            keywords: keywords ? keywords : "*",
        };
        if (terms) {
            body.terms = terms;
        }
        if (searchRootOrg) {
            body.searchRootOrg = searchRootOrg;
        }
        if (tags) {
            body.tags = tags;
        }
        if (params) {
            body.params = params;
        }
        if (visibility) {
            body.visibility = visibility;
        }
        if (modeVisibility) {
            body.modeVisibility = modeVisibility;
        }

        return this.api.request("POST", url, body).then((result) => {
            result.items.map((org, index) => {
                result.items[index] = new Org(org);
            });
            if (terms === null && searchRootOrg === null && tags === null && params === null) {
                this.apiCache.setList(result.items);
                return this.getOrgListFromCache();
            } else {
                return result.items;
            }
        });
    }

    /**
     * get org list from cache
     * @param {Boolean} filterDeactivated
     * @returns {Array<Org>}
     */
    getOrgListFromCache(filterDeactivated = true) {
        return this.apiCache.getCachedList().filter((org) => !filterDeactivated || !org.isDeactivated());
    }

    /**
     * get org from cache
     * @returns {Org}
     */
    getOrgFromCache(orgId) {
        return this.getOrgListFromCache().find((org) => org.getId() === orgId);
    }

    /**
     * get files of given org
     * @param {Uid} orgId
     * @returns {Boolean}
     */
    getFiles(orgId) {
        const url = this.api.buildRequestURL("/:serviceKey/api/files/byOrg?org=:orgId", {
            ":serviceKey": API_SERVICE_KEY_FILE_SERVICE,
            ":orgId": orgId,
        });
        return this.api.request("GET", url, null).then((result) => result);
    }

    /**
     * download file
     * @param {Uid} fileId
     *
     * @returns Promise<String>
     */
    getFile(fileId, width = null, height = null) {
        if (!fileId) {
            return Promise.reject(getInvalidParameterError("fileId"));
        }
        const url = this.api.buildRequestURL("/:serviceKey/api/files/:fileId/data:query", {
            ":serviceKey": API_SERVICE_KEY_FILE_SERVICE,
            ":fileId": fileId,
            ":query": width && height ? `?convertor=thumbnail&convertorParams=${width}x${height}` : "",
        });
        return this.api.request("GET", url, null, null, null, "blob").then((result) => {
            return result;
        });
    }

    /**
     * upload file to given org
     * @param {Uid} orgId
     * @param {String} type
     * @param {String} typeInfo
     * @param {Object} fileObject
     *
     * @returns Promise<Object>
     */
    uploadFile(orgId, type, typeInfo, fileObject) {
        if (!orgId) {
            return Promise.reject(getInvalidParameterError("orgId"));
        }
        if (!type) {
            return Promise.reject(getInvalidParameterError("type"));
        }
        if (!typeInfo) {
            return Promise.reject(getInvalidParameterError("typeInfo"));
        }
        if (!fileObject) {
            return Promise.reject(getInvalidParameterError("fileObject"));
        }
        const url = this.api.buildRequestURL("/:serviceKey/api/files/?type=:type&typeInfo=:typeInfo&extension=:extension&org=:org", {
            ":serviceKey": API_SERVICE_KEY_FILE_SERVICE,
            ":type": type,
            ":typeInfo": typeInfo,
            ":extension": fileObject.name.split(".").pop(),
            ":org": orgId,
        });

        const data = fileObject;
        return this.api.request("POST", url, data, null, true, "json", fileObject.type);
    }

    /**
     * delete file
     * @param {Uid} fileId
     *
     * @returns Promise<String>
     */
    deleteFile(fileId) {
        if (!fileId) {
            return Promise.reject(getInvalidParameterError("fileId"));
        }
        const url = this.api.buildRequestURL("/:serviceKey/api/files/:fileId", {
            ":serviceKey": API_SERVICE_KEY_FILE_SERVICE,
            ":fileId": fileId,
        });
        return this.api.request("DELETE", url, null, null, true, "text");
    }
}
