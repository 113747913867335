import React, { useContext } from 'react'
import { withRouter } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { PARAM_APP_MSG_ROTATION_MODE, PARAM_APP_MSG_ROTATION_STAY_TIME } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import ReactSVG from 'react-svg'


const RotationModeButton = ({ t }) => {
    const context = useContext(ApiContext)
    const classes = useStyles()
    
    const supplierOrgs = context.getSupplierOrgs()
    if (supplierOrgs && supplierOrgs.length > 1) {
        const supplierParentOrgIds = context.getSupplierParentOrgIds()
        if ( supplierParentOrgIds && supplierParentOrgIds.length > 0 ) {
            const rootOrg = context.fetchKnownOrg(supplierParentOrgIds[0])
            const rotationMode = rootOrg && rootOrg.getParam(`${PARAM_APP_MSG_ROTATION_MODE}`)
            const rotationModeEnabled = rotationMode && rotationMode === 'true'
    
            const isActive = context.rotationMode
    
            if ( rotationModeEnabled ) {
                return (
                    <IconButton size="small" className={isActive ? [classes.buttonActive, classes.button].join(' ') : classes.button} onClick={() => context.toggleRotationMode() }>
                        <div className={isActive ? [classes.buttonIconContainerActive, classes.buttonIconContainer].join(' ') : classes.buttonIconContainer}>
                            <ReactSVG src={isActive ? "/assets/presentation_stop.svg" : "/assets/presentation_start.svg"} />
                        </div>
                    </IconButton>
                )
            }
        }
    }

    return (null)
}

const useStyles = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        '& svg': {
            padding: 0
        }
    },
    buttonActive: {
        color: theme.palette.common.black,
    },
    buttonIconContainer: {
        marginTop: -4,
        width: 32,
        height: 28,
        paddingTop: 2
    },
    buttonIconContainerActive: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
    }
}))

export default withRouter(withTranslation()(RotationModeButton))