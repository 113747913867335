import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { DefaultStyles } from '../../../../theme'
import { MOBILE_MENU_BREAKPOINT } from '../DrawerMenu/DrawerMenu'
import { ApiContext } from '../../../../m2m-cloud-api/MessageLog/ApiContext'
import { PARAM_APP_MSG_RECIPIENT_SIMPLY_MODE } from '../../../../m2m-cloud-api/Api/OrgService/Models/Org'
import MessageList, { MESSAGE_LIST_TYPE, MESSAGE_TYPE } from '../MessageList'
import { Tabs, Tab, Typography, Divider } from '@material-ui/core'
import CompletedMessages from '../CompletedMessages'
import ReactSVG from 'react-svg'
import clsx from 'clsx'

class RecipientTabView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tabIndex: 0,
            tabInfo: null,
            isSimpleRecipientMode: false
        }
    }

    componentDidUpdate() {
        const parentOrg = this.props.org && (this.context.allAccessibleOrgs || []).find( o => o.getId() === this.props.org.getParentId() )
        const isSimpleRecipientMode = parentOrg && Boolean(parentOrg.getParam(PARAM_APP_MSG_RECIPIENT_SIMPLY_MODE)) || false
        if (this.state.isSimpleRecipientMode !== isSimpleRecipientMode) {
            this.setState({isSimpleRecipientMode})
        }
    }

    onTabChange(event, newValue) {
        const { t } = this.props
        let tabInfo = null
        switch (newValue) {
            case 0:
                tabInfo = t('overview')
                break;
            case 1:
                tabInfo = t('open')
                break;
            case 2:
                tabInfo = t('processing')
                break;
            case 3:
                tabInfo = t('completed')
                break;
        }
        this.setState({ tabIndex: newValue, tabInfo })
    }

    render() {
        const { org, classes, t } = this.props
        const { tabIndex, tabInfo, isSimpleRecipientMode } = this.state
        if (!org) return (null)

        return (
            <div className={classes.container}>
                {!isSimpleRecipientMode &&<div className={classes.menuContainer}>
                    <Tabs
                        className={classes.tabbar}
                        value={tabIndex}
                        textColor="primary"
                        TabIndicatorProps={{
                            style: {
                                display: "none",
                            }
                        }}
                        onChange={this.onTabChange.bind(this)}>
                            
                        <Tab className={classes.tabItem} aria-label={t('all')} icon={<ReactSVG src={"/assets/light/job_overview.svg"} style={{ height: 24, display: 'inline-flex' }} />} />
                        <Tab className={classes.tabItem} aria-label={t('open')} icon={<ReactSVG src={"/assets/light/icon-light-job-added.svg"} />} style={{ height: 24, display: 'inline-flex' }} />
                        <Tab className={classes.tabItem} aria-label={t('processing')} icon={<ReactSVG src={"/assets/light/icon-light-job-processing.svg"} />} style={{ height: 24, display: 'inline-flex' }} />
                        <Tab className={classes.tabItem} aria-label={t('completed')} icon={<ReactSVG src={"/assets/light/icon-light-job-done.svg"} />} style={{ height: 24, display: 'inline-flex' }} />
                    </Tabs>
                    <Typography style={{ marginLeft: 8, marginRight: 8, fontWeight: 500 }} color={'textPrimary'} variant="body2">
                        {tabInfo || t('overview')}
                    </Typography>
                </div>}
                {tabIndex === 0 && this.renderAllTab()}
                {tabIndex === 1 && this.renderAvailableTab()}
                {tabIndex === 2 && this.renderOrdersTab()}
                {tabIndex === 3 && this.renderCompletedTab()}
            </div>
        )
    }

    renderAllTab() {
        const { classes, triggerArticleEvent, t } = this.props
        const { isSimpleRecipientMode } = this.state

        const recipientArticles = this.context.getTriggerableRecipientArticles()
        return (
            <div className={clsx(classes.tabContainer, isSimpleRecipientMode && classes.tabContainerSimpleMode)} >
                <div className={isSimpleRecipientMode ? classes.centerColumn : classes.leftColumn}>
                    <MessageList fullWidth messageListType={MESSAGE_LIST_TYPE.AVAILABLE} triggerArticleEvent={triggerArticleEvent} messages={recipientArticles} messageType={MESSAGE_TYPE.ARTICLE} />
                </div>
                {!isSimpleRecipientMode && <div className={classes.rightColumn}>
                    <MessageList fullWidth messageListType={MESSAGE_LIST_TYPE.ORDERS} messages={this.context.recipientMessages} messageType={MESSAGE_TYPE.RECIPIENT} />
                </div>}
            </div>
        )
    }

    renderAvailableTab() {
        const { classes, triggerArticleEvent, t } = this.props
        const { isSimpleRecipientMode } = this.state

        const messages = this.context.recipientMessages
        const openMessages = messages && messages.filter(message => !message.isProcessing() && !message.isAcknowledged())


        return (
            <div className={clsx(classes.tabContainer, isSimpleRecipientMode && classes.tabContainerSimpleMode)} >
                <div className={classes.columnFullWidth}>
                    <MessageList messageListType={MESSAGE_LIST_TYPE.AVAILABLE} messages={openMessages} messageType={MESSAGE_TYPE.RECIPIENT} />
                </div>
            </div>
        )
    }

    renderOrdersTab() {
        const { classes, t } = this.props
        const { isSimpleRecipientMode } = this.state

        const messages = this.context.recipientMessages
        const processingMessages = messages && messages.filter(message => message.isProcessing())

        return (
            <div className={clsx(classes.tabContainer, isSimpleRecipientMode && classes.tabContainerSimpleMode)} >
                <div className={classes.columnFullWidth}>
                    <MessageList messageListType={MESSAGE_LIST_TYPE.ORDERS} messages={processingMessages} messageType={MESSAGE_TYPE.RECIPIENT} />
                </div>
            </div>
        )
    }

    renderCompletedTab() {
        const { classes, org, t } = this.props
        const { isSimpleRecipientMode } = this.state

        return (
            <div className={clsx(classes.tabContainer, isSimpleRecipientMode && classes.tabContainerSimpleMode)} >
                <div className={classes.columnFullWidth}>
                    <CompletedMessages messageType={MESSAGE_TYPE.RECIPIENT} org={org} />
                </div>
            </div>
        )
    }
}

RecipientTabView.propTypes = {
    org: PropTypes.any.isRequired,
    triggerArticleEvent: PropTypes.func
}

RecipientTabView.contextType = ApiContext

const styles = theme => ({
    container: {
        ...DefaultStyles.AbsoluteFill,
        flexDirection: 'column',
    },
    tabContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 56,
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row'
    },
    tabContainerSimpleMode: {
        top: 16,
    },
    tabbar: {
        minHeight: 'unset',
        padding: 2,
        backgroundColor: '#f6f6f6'
    },
    tabItem: {
        width: 24,
        height: 24,
        minWidth: 'unset',
        minHeight: 'unset',
        marginRight: 12,
        '&:last-child': {
            marginRight: 0
        },
        '& svg': {
            display: 'flex'
        }
    },

    columnFullWidth: {
        ...DefaultStyles.AbsoluteFill
    },
    leftColumn: {
        ...DefaultStyles.AbsoluteFill,
        left: 0,
        top: 0,
        width: '100%',
        height: '50%',
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            width: '50%',
            height: '100%'
        }
    },
    rightColumn: {
        ...DefaultStyles.AbsoluteFill,
        width: '100%',
        top: '50%',
        height: '50%',
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            top: 0,
            left: '50%',
            width: '50%',
            height: '100%',
        }
    },
    centerColumn: {
        ...DefaultStyles.AbsoluteFill,
        left: 'auto',
        right: 'auto',
        top: 0,
        width: '100%',
        height: '50%',
        maxWidth: 1024,
        [theme.breakpoints.up(MOBILE_MENU_BREAKPOINT)]: {
            width: '100%',
            height: '100%'
        }
    },
    menuContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
        marginTop: 12,
        marginRight: theme.spacing(3),
    },
    buttonText: {
        marginRight: 8,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 'normal'
    },
    divider: {
        height: 2,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: 4,
        backgroundColor: theme.palette.primary.main
    }
})

export default withTranslation()(withStyles(styles)(RecipientTabView))
