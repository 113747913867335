import i18n from 'i18next'

/**
 * map error mesage
 * @param {Error} error 
 * 
 * @returns {String} - translated error text
 */
export function mapErrorMessage(error) {
    if (error.originError && error.originError.name) {
        const key = 'error_' + error.originError.name
        const message = i18n.t(key)
        if (key === message) {
            if (error.originError.detail && error.originError.detail.trim() !== "") {
                return error.originError.detail
            }
            return error.originError.name
        }
        return message
    }
    return error.message
}