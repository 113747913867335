import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core'

import { v4 as uuidv4 } from 'uuid'


class ActionImportDialog extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            importActions: null,
            importReplacements: null,
            errorMessage: null,
            apiErrors: []
        }
    }

    handleSubmit() {
        const { importActions, importReplacements } = this.state
        const { orgId, groupOrgId } = this.props

        this.setState({ loading: true}, async () => {
            const apiErrors = []
            const addedTemplates = []

            try {
               const replacementEntities = await this.context.loadReplacementEntities(groupOrgId)
                for (let index = 0; index < importActions.length; index++) {
                    const item = importActions[index]
                    try {
                        const replacementIds = []
                        if (item.replacementIds && item.replacementIds.length > 0) {
                            for (let index = 0; index < item.replacementIds.length; index++) {
                                const replacementId = item.replacementIds[index]
                                const importReplacement = importReplacements.find( importReplacement => importReplacement.id === replacementId)
                                if (importReplacement) {
                                    const existingReplacement = replacementEntities.find( replacement => importReplacement && replacement.type === importReplacement.type && replacement.title.toLowerCase() === importReplacement.title.toLowerCase())
                                    if (existingReplacement) {
                                        replacementIds.push( existingReplacement.id)
                                    } else {
                                        const replacementId = uuidv4()
                                        const replacement = await this.context.api.appMessageService.upsertTextReplacement(replacementId, groupOrgId, importReplacement.title, importReplacement.type, importReplacement.desc)
                                        replacementEntities.push(replacement)
                                        replacementIds.push( replacementId )
                                    }
                                } else {
                                    console.warn('ignore replacement: ' + replacementId, ', replacement item not found inside import file. (removed?)')
                                }
                            }
                        }
                        const template = await this.context.api.appMessageService.upsertTemplate(uuidv4(), item.name, item.header, item.body, orgId, replacementIds )
                        addedTemplates.push(template)
                    } catch (error) {
                        apiErrors.push(error)
                    }
                }
            } catch (error) {
                apiErrors.push(error)
            }
            
            this.setState({loading: false, apiErrors: apiErrors})
            this.props.onSuccess(addedTemplates)

            console.warn('apiErrors', apiErrors)
        })
    }

    handleSelect({ target }) {
        const { t } = this.props

        const fileReader = new FileReader()
        fileReader.readAsText(target.files[0]);
        fileReader.onload = (e) => {

            let importData = null
            try {
                importData = JSON.parse(e.target.result)
            } catch (error) {
                this.setState({importActionsData: null, importReplacements: null, errorMessage: t("json_parse_error")})
                return
            }

            const isValidItem = (item) => {
                return item && item.templateId && item.orgId && item.name && item.header ? true : false
            }
            

            const validActionsToImport = importData && importData.actions && importData.actions.length > 0 ? importData.actions.filter( item => isValidItem(item) ) : []
            if ( validActionsToImport.length > 0) {
                this.setState({importActions: validActionsToImport, importReplacements: importData.replacements})
            } else {
                this.setState({importActions: null, importReplacements: null, errorMessage: t("invalid_import_file")})
            }

        }
    }

    render() {
        const { importActions, loading, errorMessage } = this.state
        const { t, classes } = this.props

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('import')}</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        { (!errorMessage && importActions !== null) && <Fragment>
                            <Typography variant="subtitle1" color={'inherit'} className={classes.toolbarTitle}>
                                {t('actions_to_import', { count: importActions.length })}
                            </Typography>
                        </Fragment> }
                        { (!errorMessage && importActions === null) && <Fragment>
                            <input
                                accept='application/json'
                                className={classes.input}
                                id="file-select-button"
                                onChange={this.handleSelect.bind(this)}
                                type="file"
                            />
                            <label htmlFor="file-select-button">
                                <Button
                                    variant="contained"
                                    component="span"
                                    className={classes.button}
                                    size={"medium"}
                                    color="secondary"
                                >
                                    {t('select_a_json_file')}
                                </Button>
                            </label>
                        </Fragment> }
                        {errorMessage && <DialogContentText style={{marginTop: 20}} color="error">{errorMessage}</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} onClick={this.props.onCancel}>
                            {t('cancel')}
                        </Button>
                        { !errorMessage && <Button disabled={importActions === null || loading} onClick={this.handleSubmit.bind(this)} color="primary">
                            {t('import')}
                        </Button> }
                        { errorMessage && <Button onClick={() => this.setState({importActions: null, importReplacements: null, errorMessage: null})}>
                            {t('try_again')}
                        </Button> }
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

}

ActionImportDialog.contextType = PageContext

ActionImportDialog.propTypes = {
    open: PropTypes.bool,
    orgId: PropTypes.string.isRequired,
    groupOrgId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

const styles = theme => ({
    dialogContent: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
        textAlign: 'center'
    },
    input: {
        display: 'none'
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
    parametersLabel: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    chip: {
        marginRight: theme.spacing(1),
        borderRadius: 2
    }
})


export default withTranslation()(withStyles(styles)(ActionImportDialog))