import React, { PureComponent, Fragment } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Typography, Button, List, ListItem, ListItemText, ListSubheader, ListItemSecondaryAction, Divider, IconButton, Tooltip, Drawer, Box } from '@material-ui/core'
import { ApiContext, APP_MODE } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import { PARAM_APP_MSG_SEND_ORG_NAME, PARAM_APP_MSG_COLOR } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { REPLACEMENT_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import { isNotFoundError } from '../../../m2m-cloud-api/Api/Helper'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import ImageModal from './ImageModal'
import ReplacementImage from './ReplacementImage'
import ReportDialog from './Supplier/ReportDialog'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'
import CloseIcon from '@material-ui/icons/Close'
import { getDateFormat } from '../../../Libs/Utilities/DateHelper'
import { PRIMARY_COLOR, theme } from '../../../theme'
import { isDarkColor } from '../../../Helper'
import EditIcon from '@material-ui/icons/Edit'

import Loading from '../../../Libs/Pages/Loading'
import Searchbox from '../../../Libs/Components/Searchbox'
import ReactSVG from 'react-svg'
import Moment from 'react-moment'
import clsx from 'clsx'
import EditableReplacementDialog from './ReplacementEditDialog'
import ReplacementEditDialog from './ReplacementEditDialog'
import MessageNoteModal from './MessageNoteModal'
import { PARAM_ON_HOLD } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Message'
import MessageListItem from './MessageListItem'
import AdvancedSearchModal, { filterMessages, getAdvancedSearchValues } from './AdvancedSearchModal'

export const MESSAGE_TYPE = {
    ARTICLE: 'article',
    RECIPIENT: 'recipient',
    SUPPLIER: 'supplier'
}

export const MESSAGE_LIST_TYPE = {
    OPEN: 'open',
    PROCESSING: 'processing',
    AVAILABLE: 'available',
    COMPLETED: 'completed',
    ORDERS: 'orders'
}

const INVISIBLE_REPLACEMENTS = [REPLACEMENT_TYPE.REPORT, REPLACEMENT_TYPE.NOTE, REPLACEMENT_TYPE.CHECKLIST]

export const mapReplacements = (context, _message) => {
    const [message] = context.sortMessageReplacements([_message])
    const params = message.getParams() || {}
    const paramIds = Object.keys(params)
    const replacements = context.replacements
    let replacementValues = []

    for (let i = 0; i < paramIds.length; i++) {
        const paramId = paramIds[i]
        const replacement = replacements?.find(replacement => replacement.getId() === paramId)
        if (replacement) {
            replacementValues.push({
                replacement,
                value: params[replacement.getId()]
            })
        }
    }
    return replacementValues
}

const mapAllReplacements = (context, messages) => {
    const filteredReplacements = []
    messages?.map(message => {
        const replacements = mapReplacements(context, message)
        for (let i = 0; i < replacements.length; i++) {
            const replacement = replacements[i]
            const replacementId = replacement.replacement.getId()
            const isIncludedReplacement = filteredReplacements.find(_replacement => _replacement.getId() === replacementId) ? true : false
            if (!isIncludedReplacement) {
                filteredReplacements.push(replacement.replacement)
            }

        }
    })
    return filteredReplacements
}

export const fetchItemDisplayOrgNames = (context, displayOrg) => {
    const orgNames = []
    const parentGroupOrgs = context.fetchKnownParentGroupOrgs(displayOrg)
    parentGroupOrgs.forEach(groupOrg => {
        if (groupOrg && groupOrg.getName() && Boolean(groupOrg.getParams()[PARAM_APP_MSG_SEND_ORG_NAME]) === true) orgNames.push(groupOrg.getName())
    })
    if (displayOrg && displayOrg.getName()) {
        orgNames.push(displayOrg.getName())
    }
    return orgNames
}

const FiltersTitle = ({ filters }) => {
    if (filters?.length > 0) {
        return <span style={{ marginLeft: 4 }}>
            ( {filters.map((filter, index) => <Fragment>{(index > 0 ? ', ' : '')}<span style={{ textDecoration: filter.type === 'not_contains' ? 'line-through' : 'none' }}>{filter.value.trim()}</span></Fragment>)} )
        </span>
    }
    return <Fragment />
}

class MessageList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            messages: null,
            processingMessage: null,
            reportMessage: null,
            triggeringEvent: false,
            errorMessage: null,
            searchBarVisible: false,
            searchTerm: '',
            imageFile: null,
            editableReplacement: null,
            supplierEditReplacement: null,
            drawerMenuopen: false,
            activeMessage: null,
            messageNoteValues: null,
            advancedSearchModalVisible: false
        }
    }

    async deleteMessage(message) {
        const { messageType, t } = this.props

        try {
            let result = null
            if (messageType === MESSAGE_TYPE.RECIPIENT) {
                result = await this.context.deleteMessage(message.getId())
            } else if (messageType === MESSAGE_TYPE.SUPPLIER) {
                result = await this.context.unsetMessageProcessing(message.getId())
            }
            this.onCloseDrawerMenu()
        } catch (error) {
            console.log('deleteMessage - error', error)
            let message = mapErrorMessage(error)
            if (isNotFoundError(error)) {
                message = t('error_message_not_found')
            }
            this.setState({ errorMessage: message })
        }
    }

    render() {
        const { classes, messages, messageType, actionButtons, t, themeColIndex, filters, messageListType } = this.props
        const { searchBarVisible, searchTerm, imageFile, editableReplacement, supplierEditReplacement, activeMessage, messageNoteValues, advancedSearchModalVisible } = this.state
        const user = this.context.userService.getActiveUser()

        const advancedSearchFilters = getAdvancedSearchValues(this.context, messageType, messageListType, this.props.themeColIndex)
        let filteredMessages = !advancedSearchFilters ? messages : filterMessages(this.context, messageType, messageListType, this.props.themeColIndex, messages, messageType !== MESSAGE_TYPE.ARTICLE ? mapAllReplacements(this.context, messages) : null)

        if (searchTerm?.trim() !== "" || filters?.length > 0) {
            filteredMessages = messages && messages.filter(message => {
                if (searchTerm?.trim() !== "" && messageType === MESSAGE_TYPE.ARTICLE) {
                    const displayOrg = this.context.fetchKnownOrg(message.definition.getOrgId())
                    const orgNames = fetchItemDisplayOrgNames(this.context, displayOrg)
                    const searchString = `${message.title || ''} ${orgNames ? orgNames.join(' ') : ''}`
                    return searchString.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
                } else {
                    const displayOrg = this.context.fetchKnownOrg(messageType === MESSAGE_TYPE.RECIPIENT ? message.getDefinitionOrgId() : message.getDeviceAssignedOrgId())
                    const orgNames = fetchItemDisplayOrgNames(this.context, displayOrg)
                    const ordererUser = message.getTriggeringUserId() && this.props.publicUsers && this.props.publicUsers.find(publicUser => publicUser.getId() === message.getTriggeringUserId())
                    const processingUser = message.getProcessingUserId() && this.props.publicUsers && this.props.publicUsers.find(publicUser => publicUser.getId() === message.getProcessingUserId())
                    const acknowledgedUser = message.getAcknowledgedUserId() && this.props.publicUsers && this.props.publicUsers.find(publicUser => publicUser.getId() === message.getAcknowledgedUserId())
                    const replacements = mapReplacements(this.context, message)
                    const replacementStrings = replacements ? replacements.map(replacement => {
                        if (!replacement || !replacement.replacement) return ''
                        return `${replacement.replacement.getName()} ${replacement.replacement.getType() !== REPLACEMENT_TYPE.IMAGE ? replacement.value : ''}`
                    }) : []
                    const searchString = `${message.title || ''} ${message.header || ''} ${orgNames ? orgNames.join(' ') : ''} ${ordererUser && ordererUser.getNickname() || ''} ${processingUser && processingUser.getNickname() || ''} ${acknowledgedUser && acknowledgedUser.getNickname() || ''} ${replacementStrings.join(' ')}`.toLowerCase()
                    let matches = true
                    filters?.map(filter => {
                        if (matches) {
                            if (filter.type === 'contains' && filter.value?.trim() !== "") {
                                matches = searchString.indexOf(filter.value?.trim().toLowerCase()) >= 0
                            } else if (filter.type === 'not_contains') {
                                matches = searchString.indexOf(filter.value?.trim().toLowerCase()) === -1
                            }
                        }
                    })
                    return matches && (searchTerm?.trim() === "" || searchString.indexOf(searchTerm.toLowerCase()) >= 0)
                }
            })
        }

        return (
            <div className={classes.container}>
                <div className={classes.listHeader}>
                    <Box display={'flex'} position={'absolute'}>
                        <div className={classes.listHeaderTitle}>{t(messageListType)}<FiltersTitle filters={filters} /></div>
                        <div className={classes.listHeaderCount}>
                            <div className={classes.listHeaderBadge}>
                                {filteredMessages ? filteredMessages.length : 0}
                            </div>
                        </div>
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'} flex={1}>
                        {actionButtons && actionButtons.map(action => (<Tooltip title={action.toolTip}>
                            <IconButton size="small" className={classes.actionButtons} onClick={action.onClick}>
                                {action.icon}
                            </IconButton>
                        </Tooltip>))}
                        <ListSubheader className={classes.listSearchContainer} style={{ width: searchBarVisible ? '100%' : 0 }}>
                            <Searchbox value={searchTerm} onChange={(value) => this.setState({ searchTerm: value })} className={classes.searchBar} />
                        </ListSubheader>
                        <IconButton
                            className={!searchBarVisible ? classes.listHeaderIconButton : [classes.listHeaderIconButton, classes.listHeaderIconButtonActive].join(' ')}
                            size={'small'}
                            disabled={!messages || messages.length === 0}
                            onClick={() => {
                                const newState = { searchBarVisible: !searchBarVisible }
                                if (searchBarVisible) {
                                    newState.searchTerm = ''
                                }
                                this.setState(newState)
                            }}>
                            <SearchIcon />
                        </IconButton>
                        <IconButton
                            className={clsx(classes.listHeaderIconButton, advancedSearchModalVisible && classes.listHeaderIconButtonActive)}
                            size={'small'}
                            onClick={() => {
                                this.setState({ advancedSearchModalVisible: !advancedSearchModalVisible })
                            }}>
                            <FilterListIcon />
                            {advancedSearchFilters && <span className={classes.listHeaderIconButtonBadge}></span>}
                        </IconButton>
                    </Box>
                </div>

                {(filteredMessages && filteredMessages.length > 0) && <div className={classes.listBody} >
                    <List className={classes.list}>
                        {filteredMessages.map((item, i) => messageType === MESSAGE_TYPE.ARTICLE ? this.renderArticleItem(item) : this.renderMessageItem(item))}
                    </List>
                </div>}
                {!messages &&
                    <div className={classes.loading}>
                        <Loading />
                    </div>
                }
                {messages && messages.length > 0 && filteredMessages.length === 0 &&
                    <div className={classes.listBody}>
                        <Typography className={classes.noMessageText}>{t('no_match_found')}</Typography>
                    </div>
                }
                {(messages && messages.length === 0 && messageType === MESSAGE_TYPE.SUPPLIER) &&
                    <div className={classes.listBody}>
                        <Typography className={classes.noMessageText}>{t('no_orders_available')}</Typography>
                    </div>
                }
                {this.renderErrorMessage()}
                {this.renderMessageReportDialog()}
                {imageFile && <ImageModal open={true} onClose={() => this.setState({ imageFile: null })} replacement={imageFile} />}
                {editableReplacement && <EditableReplacementDialog
                    open={true}
                    onClose={() => this.setState({ editableReplacement: null })}
                    replacement={editableReplacement.replacement}
                    message={editableReplacement.message} />
                }
                {supplierEditReplacement && <ReplacementEditDialog
                    open={true}
                    onClose={(updatedMessage) => this.setState({ supplierEditReplacement: null, activeMessage: updatedMessage || activeMessage }, () => updatedMessage && this.context.restartSync())}
                    replacement={supplierEditReplacement.replacement}
                    message={supplierEditReplacement.message} />
                }
                {messageNoteValues &&
                    <MessageNoteModal
                        open={true}
                        replacementId={messageNoteValues.replacementId}
                        messageNote={messageNoteValues.note}
                        message={activeMessage}
                        onClose={(updatedNote) => {
                            if (messageNoteValues && updatedNote) {
                                const activeMessage = this.state.activeMessage
                                activeMessage.setParam(messageNoteValues.replacementId, updatedNote === true ? '' : updatedNote) // deleted message is true
                                this.setState({ activeMessage })
                            }
                            this.setState({ messageNoteValues: null })
                            this.context.restartSync()
                        }}
                    />}
                {this.renderDrawerMenu()}
                {advancedSearchModalVisible &&
                    <AdvancedSearchModal
                        open={true}
                        onClose={() => this.setState({ advancedSearchModalVisible: false })}
                        replacements={messageType !== MESSAGE_TYPE.ARTICLE ? mapAllReplacements(this.context, messages) : []}
                        messageListType={messageListType}
                        messageType={messageType}
                        themeColIndex={this.props.themeColIndex}
                    />}
            </div>
        )
    }

    renderArticleItem(article) {
        const { classes, triggerArticleEvent, t } = this.props
        const { triggeringEvent } = this.state
        const context = this.context

        let actionColor = PRIMARY_COLOR
        let actionTextColor = '#ffffff'
        const displayOrg = this.context.fetchKnownOrg(article.definition.getOrgId())
        const orgNames = fetchItemDisplayOrgNames(this.context, displayOrg)
        if (displayOrg && displayOrg.getParam(PARAM_APP_MSG_COLOR)) {
            actionColor = displayOrg.getParam(PARAM_APP_MSG_COLOR)
            actionTextColor = isDarkColor(actionColor) ? '#ffffff' : '#000000'
        }
        const visibleReplacementIds = article.replacementIds && article.replacementIds.filter(replacement => INVISIBLE_REPLACEMENTS.indexOf(replacement.getType()) === -1)

        const onlyQrCode = visibleReplacementIds && visibleReplacementIds.length === 1 && visibleReplacementIds[0].getType() === REPLACEMENT_TYPE.QR_CODE
        const onlyBarcode = visibleReplacementIds && visibleReplacementIds.length === 1 && visibleReplacementIds[0].getType() === REPLACEMENT_TYPE.BAR_CODE
        const hasParameters = !onlyQrCode && !onlyBarcode && visibleReplacementIds && visibleReplacementIds.length > 0

        let startIcon = null
        if (onlyQrCode || onlyBarcode || hasParameters) {
            startIcon = <ReactSVG className={classes.sendButtonIcon} src="/assets/parameters.svg" currentcolor={actionTextColor} />
        }

        const userFontSize = context.userFontSize
        const userFontSizeSmall = userFontSize ? (theme.font.small + userFontSize) : theme.font.small
        const userFontSizeLarge = userFontSize ? (theme.font.large + userFontSize) : theme.font.large

        return (
            <div key={article.definition.getId()}>
                <ListItem className={[classes.listItem, classes.articleListItem].join(' ')} style={{ paddingTop: 6, paddingBottom: 6 }}>
                    <ListItemText
                        className={classes.listItemText}
                        primary={
                            <Fragment>
                                {orgNames.length && <Typography
                                    component="div"
                                    variant="body2"
                                    className={classes.listBoxItemRecipient}
                                    style={{ fontSize: userFontSizeSmall }}
                                    color="textPrimary">
                                    {orgNames.join(' | ')}
                                </Typography>}
                                <Typography
                                    component="div"
                                    variant="body2"
                                    style={{ fontSize: userFontSizeLarge }}
                                    className={classes.listBoxItemTitle}>
                                    {article.title}
                                </Typography>
                            </Fragment>
                        }
                        secondary={null} />
                    <ListItemSecondaryAction>
                        <Button
                            className={classes.sendButton}
                            startIcon={startIcon}
                            disabled={triggeringEvent}
                            variant='contained'
                            color="primary"
                            style={{ backgroundColor: actionColor, color: actionTextColor }}
                            size='small'
                            onClick={() => {
                                this.setState({
                                    triggeringEvent: true
                                }, () => {
                                    triggerArticleEvent(article, () => {
                                        this.setState({
                                            triggeringEvent: false
                                        })
                                    })
                                })
                            }}>
                            {t('send')}
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
                {/* <Divider className={classes.listItemDivider} /> */}
            </div>
        )
    }

    renderMessageItem(message) {
        const { activeMessage } = this.state
        const { publicUsers, messageType } = this.props
        return (
            <MessageListItem
                message={message}
                messageType={messageType}
                publicUsers={publicUsers}
                isSelected={activeMessage && activeMessage.getId() === message.getId()}
                onSelectMessage={() => this.setState({ drawerMenuopen: true, activeMessage: message })}
                processingMessage={this.state.processingMessage}
                onSetProcessingMessage={this.onSetProcessingMessage.bind(this)} />
        )
    }

    hasMessageReport(message) {
        const replacements = mapReplacements(this.context, message)
        return replacements && replacements.length > 0 && replacements.find(replacement => replacement.replacement && replacement.replacement.getType() === REPLACEMENT_TYPE.REPORT) ? true : false
    }

    renderErrorMessage() {
        const { errorMessage } = this.state
        const { t } = this.props
        if (errorMessage) {
            return (
                <AlertDialog
                    open={errorMessage ? true : false}
                    title={t('error')}
                    message={errorMessage}
                    onSubmit={() => this.setState({ errorMessage: null })} />
            )
        } else {
            return (null)
        }
    }

    renderMessageReportDialog() {
        const { processingMessage } = this.state
        const { t } = this.props

        if (!processingMessage || !processingMessage.isProcessing() || !this.hasMessageReport(processingMessage)) return null

        return (
            <ReportDialog
                message={processingMessage}
                onSuccess={() => {
                    this.setState({ processingMessage: null })
                }}
                onCancel={() => {
                    this.setState({ processingMessage: null })
                }} />
        )

    }

    onSetProcessingMessage(message) {
        const { t } = this.props

        const handleError = (error) => {
            let message = mapErrorMessage(error)
            if (isNotFoundError(error)) {
                message = t('error_message_not_found')
            }
            this.setState({ errorMessage: message })
        }

        this.setState({ processingMessage: message })
        setTimeout(() => {
            if (!message.isProcessing() && !message.isAcknowledged()) {
                this.context.setMessageProcessing(message.getId())
                    .then(result => {
                        this.setState({ activeMessage: result })
                    })
                    .catch(error => {
                        console.warn('setMessageProcessing - error', error)
                        handleError(error)
                    })
                    .finally(() => {
                        this.setState({ processingMessage: null })
                        this.onCloseDrawerMenu()
                    })
            } else if (message.isProcessing()) {
                const isReportRequired = this.hasMessageReport(message)
                if (!isReportRequired) {
                    this.context.setMessageAcknowledged(message.getId())
                        .then(result => {
                            this.setState({ activeMessage: result })
                        })
                        .catch(error => {
                            console.warn('setMessageAcknowledged - error', error)
                            handleError(error)
                        })
                        .finally(() => {
                            this.setState({ processingMessage: null, editableReplacement: null })
                            this.onCloseDrawerMenu()
                        })
                }

            }
        }, 200)

    }

    onNextMessage(messages, message) {
        const messageIndex = messages.findIndex(item => item.getId() === message.getId()) + 1
        this.setState({ activeMessage: messages[messageIndex] })
    }

    onBackMessage(messages, message) {
        const messageIndex = messages.findIndex(item => item.getId() === message.getId()) - 1
        this.setState({ activeMessage: messages[messageIndex] })
    }

    onCloseDrawerMenu() {
        this.setState({ drawerMenuopen: false, activeMessage: null })
    }

    renderNoteField(replacementId) {
        const { classes, messageType } = this.props
        const { activeMessage } = this.state
        const note = activeMessage.getParam(replacementId)
        const isComplatedMessage = activeMessage.isAcknowledged()
        return (
            <Box display={'flex'} alignItems={'center'} className={classes.noteTextWrapper}>
                <Typography className={classes.noteText}>{note}</Typography>
                {!isComplatedMessage && messageType !== MESSAGE_TYPE.RECIPIENT && <IconButton aria-label="edit-message-note"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    className={classes.menuIconButton}
                    onClick={() => this.setState({ messageNoteValues: { replacementId, note } })}>
                    <EditIcon className={classes.editMessageNoteIcon} />
                </IconButton>}
            </Box>
        )
    }

    prepareReportText(value) {
        const valueObj = JSON.parse(value)
        if (valueObj.text) return valueObj.text
        return ''
    }

    renderDrawerMenu() {
        const { classes, t, messages, messageType } = this.props
        const { activeMessage, drawerMenuopen } = this.state

        const appMode = this.context.appMode

        if (!activeMessage) {
            return null
        }

        const deleteOrderSupported = (messageType === MESSAGE_TYPE.SUPPLIER && activeMessage.isProcessing() && !activeMessage.isAcknowledged())
        const device = this.context.fetchKnownDevice(activeMessage.getTriggeringDeviceId())
        const isPhysicalDevice = device && device.getDriver() === 'sta2'
        const deleteOrderDisabled = deleteOrderSupported && messageType === MESSAGE_TYPE.RECIPIENT && isPhysicalDevice

        const replacements = mapReplacements(this.context, activeMessage)
        const changedParamValues = this.context.getChangedParamValues(activeMessage.getId())
        const startDate = activeMessage.getCreatedDate()
        const processingDate = activeMessage.getProcessingDate()
        const endDate = activeMessage.getAcknowledgedDate() ? activeMessage.getAcknowledgedDate() : null
        const ordererUserId = activeMessage.getTriggeringUserId()
        const processingUserId = activeMessage.isProcessing() ? activeMessage.getProcessingUserId() : activeMessage.getAcknowledgedUserId()
        const acknowledgedUserId = activeMessage.isAcknowledged() ? activeMessage.getAcknowledgedUserId() : activeMessage.getAcknowledgedUserId()
        let ordererUser = ordererUserId && this.props.publicUsers && this.props.publicUsers.find(publicUser => publicUser.getId() === ordererUserId)
        let processingUser = processingUserId && this.props.publicUsers && this.props.publicUsers.find(publicUser => publicUser.getId() === processingUserId)
        let acknowledgedUser = acknowledgedUserId && this.props.publicUsers && this.props.publicUsers.find(publicUser => publicUser.getId() === acknowledgedUserId)
        if (!ordererUser && ordererUserId) {
            ordererUser = this.context.getPublicUser(ordererUserId)
        }
        if (!processingUser && processingUserId) {
            processingUser = this.context.getPublicUser(processingUserId)
        }
        if (!acknowledgedUser && acknowledgedUserId) {
            acknowledgedUser = this.context.getPublicUser(acknowledgedUserId)
        }

        const isFirst = messages.findIndex(message => message.getId() === activeMessage.getId()) === 0
        const isLast = messages.findIndex(message => message.getId() === activeMessage.getId()) === (messages.length - 1)

        const displayOrg = this.context.fetchKnownOrg(messageType === MESSAGE_TYPE.RECIPIENT ? activeMessage.getDefinitionOrgId() : activeMessage.getDeviceAssignedOrgId())
        const orgNames = fetchItemDisplayOrgNames(this.context, displayOrg)

        const isHold = activeMessage.getParam(PARAM_ON_HOLD) === 'true' ? true : false

        return (
            <Drawer anchor={'right'}
                open={drawerMenuopen}
                style={{ width: 263 }}
                ModalProps={{
                    onBackdropClick: () => this.onCloseDrawerMenu(),
                    onEscapeKeyDown: () => this.onCloseDrawerMenu()
                }}>
                <div style={{ width: 263, display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', padding: '8px 12px 0 12px' }}>
                        <div style={{ flex: 1, display: 'flex' }}>
                            <IconButton aria-label="back"
                                disabled={isFirst} disableRipple disableFocusRipple disableTouchRipple
                                className={classes.menuIconButton}
                                onClick={() => this.onBackMessage(messages, activeMessage)}>
                                <ReactSVG src="/assets/sidebar_arrow_up.svg" style={{ color: isFirst ? 'rgba(117,117,117,0.35)' : '#000' }} />
                            </IconButton>
                            <IconButton aria-label="next"
                                disabled={isLast} disableRipple disableFocusRipple disableTouchRipple
                                style={{ marginLeft: 12 }}
                                className={classes.menuIconButton}
                                onClick={() => this.onNextMessage(messages, activeMessage)}>
                                <ReactSVG src="/assets/sidebar_arrow_down.svg" style={{ color: isLast ? 'rgba(117,117,117,0.35)' : '#000' }} />
                            </IconButton>
                        </div>
                        <IconButton aria-label="delete"
                            className={classes.menuIconButton}
                            onClick={() => this.onCloseDrawerMenu()}>
                            <CloseIcon style={{ color: '#000' }} />
                        </IconButton>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 32, padding: '0px 12px' }}>
                        <Typography variant="h2" color={'inherit'} className={classes.menuTitle}>
                            {activeMessage.getHeader()}
                        </Typography>

                        {orgNames.length > 0 && <Typography
                            component="div"
                            variant="body2"
                            className={classes.menuSubtitle}
                            color="inherit">
                            {orgNames.join(' | ')}
                        </Typography>}
                    </div>
                    <Divider className={classes.drawerDivider} />
                    <div style={{ display: 'flex', flex: 1, overflowY: 'scroll', padding: '0px 12px 12px 12px' }}>
                        <Typography component="div" variant="body2" className={classes.listBoxItemLine}>
                            {replacements && replacements.map((replacement, index) => {
                                const isSupplierReplacement = replacement.replacement.desc?.serviceProvider ? true : false
                                const renderReplacementEdit = isSupplierReplacement &&  messageType === MESSAGE_TYPE.SUPPLIER && !activeMessage.isAcknowledged() ? true : false
                                const renderSupplierReplacement = replacement.replacement.desc?.serviceProvider && messageType === MESSAGE_TYPE.RECIPIENT
                                const isEditable = messageType === MESSAGE_TYPE.SUPPLIER && activeMessage.isProcessing() && replacement.replacement.getType() === REPLACEMENT_TYPE.INTEGER && replacement.replacement.desc?.editable
                                const changedParamValue = changedParamValues && changedParamValues[replacement.replacement.getId()]
                                const isNoteType = replacement.replacement.getType() === REPLACEMENT_TYPE.NOTE
                                const replacementValue = replacement.value?.trim() === '' ? null : replacement.value
                                const noteValue = isNoteType && replacement.value
                                const isReport = replacement.replacement.getType() === REPLACEMENT_TYPE.REPORT


                                if ( (replacement.replacement.desc?.serviceProvider && messageType === MESSAGE_TYPE.RECIPIENT) || (isNoteType && !noteValue && messageType === MESSAGE_TYPE.RECIPIENT) || (isNoteType && !noteValue && activeMessage.isAcknowledged()) || (isReport && !activeMessage.isAcknowledged())) return null
                                return (
                                    <Fragment key={replacement.replacement.getId()}>
                                       <Box display={'flex'} alignContent={'center'}>
                                            <Typography
                                                component="span"
                                                variant="body1"
                                                onClick={() => isEditable && this.setState({ editableReplacement: { replacement, message: activeMessage } })}
                                                className={classes.menuSubtitle}>
                                                {replacement.replacement.getName()}
                                            </Typography>
                                            {(replacementValue && renderReplacementEdit) && <IconButton
                                                    className={clsx(classes.editIcon, classes.sidebarEditIcon)}
                                                    size="small"
                                                    onClick={async () => this.setState({ supplierEditReplacement: { replacement, message: activeMessage } })}>
                                                    <ReactSVG src="/assets/light/icon-light-edit.svg" style={{ color: '#1a1a1a' }} currentcolor={'#000'} />
                                                </IconButton>}
                                        </Box>

                                        {!replacementValue && renderReplacementEdit && // supplier replacements
                                            <Button
                                                variant={'contained'}
                                                color={'secondary'}
                                                onClick={() => {
                                                    this.setState({ supplierEditReplacement: { replacement, message: activeMessage } })
                                                }}
                                                className={classes.addNoteButton}>{t('add')}
                                            </Button>
                                        }
                                        {!noteValue && isNoteType && messageType === MESSAGE_TYPE.SUPPLIER && // supplier note replacements (deprecated)
                                            <Button
                                                variant={'contained'}
                                                color={'secondary'}
                                                onClick={() => {
                                                    this.setState({
                                                        messageNoteValues: {
                                                            replacementId: replacement.replacement.getId(),
                                                            note: ''
                                                        }
                                                    })
                                                }}
                                                className={classes.addNoteButton}>{t('add_note')}
                                            </Button>
                                        }
                                        {noteValue && isNoteType && this.renderNoteField(replacement.replacement.getId())}

                                        <div style={{ flexDirection: 'row', marginTop: 4 }}>
                                            {replacement.replacement.getType() === REPLACEMENT_TYPE.IMAGE && replacement.value && replacement.value !== '' ?
                                                <ReplacementImage onClick={async () => this.setState({ imageFile: replacement })} className={classes.sidebarImage} replacement={replacement} size={{ width: 400, height: 300 }} />
                                                : !noteValue && <Typography
                                                    component="span"
                                                    variant="body1"
                                                    onClick={() => isEditable && this.setState({ editableReplacement: { replacement, message: activeMessage } })}
                                                    className={classes.badge}
                                                    style={{
                                                        textDecoration: changedParamValue && 'line-through'
                                                    }}>
                                                    {isReport ? this.prepareReportText(replacementValue) : replacementValue}
                                                </Typography>
                                            }
                                            {changedParamValue && <Typography
                                                component="span"
                                                variant="body1"
                                                className={classes.listBoxItemLineValue}>
                                                {` → ${changedParamValue}`}
                                            </Typography>}
                                            {isEditable && <IconButton
                                                className={classes.editIcon}
                                                size="small"
                                                onClick={async () => this.setState({ editableReplacement: { replacement, message: activeMessage } })}
                                            >
                                                <ReactSVG src="/assets/light/icon-light-edit.svg" style={{ color: '#1a1a1a' }} currentcolor={'#000'} />
                                            </IconButton>}
                                        </div>
                                    </Fragment>)
                            })}
                        </Typography>
                    </div>
                    <div className={clsx(classes.menuFooter, activeMessage.isAcknowledged() && 'completed')}>
                        {activeMessage.isAcknowledged() && <div className={classes.menuFooterList}>
                            <div className={classes.menuListIcon}>
                                <ReactSVG src={"/assets/light/icon-light-job-done-filled.svg"} style={{ height: 24, display: 'inline-flex' }} />
                            </div>
                            <div className={classes.menuFooterListDesc}>
                                <div className={classes.menuTitle}>{t('completed')}</div>
                                <div className={classes.menuFooterSubtitle}>
                                    <div style={{ flex: 1 }}>{acknowledgedUser?.getNickname()}</div>
                                    <div>{endDate && <Moment element={Typography} className={classes.listBoxItemDate} style={{ marginRight: 4 }} date={endDate} format={getDateFormat(endDate)} />}</div>
                                </div>
                            </div>
                        </div>}
                        {(activeMessage.isProcessing() || activeMessage.isAcknowledged()) && <div className={classes.menuFooterList}>
                            <div className={clsx(classes.menuListIcon, 'processing')}>
                                <ReactSVG src={"/assets/light/icon-light-job-processing-filled.svg"} style={{ height: 24, display: 'inline-flex' }} />
                            </div>
                            <div className={clsx(classes.menuFooterListDesc, 'processing')}>
                                <div className={classes.menuTitle}> {t('processing')} </div>
                                <div className={clsx(classes.menuFooterSubtitle, 'processing')}>
                                    <div style={{ flex: 1 }}>{processingUser?.getNickname()}</div>
                                    <div>{processingDate && <Moment element={Typography} className={classes.listBoxItemDate} style={{ marginRight: 4 }} date={processingDate} format={getDateFormat(processingDate)} />}</div>
                                </div>
                            </div>
                        </div>}
                        <div className={classes.menuFooterList}>
                            <div className={classes.menuListIcon}>
                                <ReactSVG src={"/assets/light/icon-light-job-added-filled.svg"} style={{ height: 24, display: 'inline-flex' }} />
                            </div>
                            <div className={classes.menuFooterListDesc} >
                                <div className={classes.menuTitle}>{t('created')}</div>
                                <div className={classes.menuFooterSubtitle}>
                                    <div style={{ flex: 1 }}>{ordererUser?.getNickname()}</div>
                                    <div>{startDate && <Moment element={Typography} className={classes.listBoxItemDate} style={{ marginRight: 4 }} date={startDate} format={getDateFormat(startDate)} />}</div>
                                </div>
                            </div>
                        </div>

                        {appMode === APP_MODE.SUPPLIER && (activeMessage.isProcessing() || !activeMessage.isAcknowledged()) && (
                            <Button color={'secondary'} variant={'contained'} className={classes.drawerButton} onClick={this.onSetMessageHold.bind(this)}>{t(isHold ? 'resume' : 'hold')}</Button>
                        )}
                        {deleteOrderSupported && !isHold &&
                            <Button color={'secondary'} variant={'contained'} disabled={deleteOrderDisabled} onClick={() => this.deleteMessage(activeMessage)} className={classes.drawerButton}>{t('revoke')}</Button>}
                        {appMode === APP_MODE.SUPPLIER && !activeMessage.isProcessing() && !activeMessage.isAcknowledged() && !isHold && (
                            <Button color={'primary'} variant={'contained'} onClick={() => this.onSetProcessingMessage(activeMessage)}>{t('start_process')}</Button>
                        )}
                        {appMode === APP_MODE.SUPPLIER && activeMessage.isProcessing() && !activeMessage.isAcknowledged() && !isHold && (
                            <Button color={'primary'} variant={'contained'} onClick={() => this.onSetProcessingMessage(activeMessage)}>{t('complete')}</Button>
                        )}
                        {appMode === APP_MODE.RECIPIENT && !activeMessage.isProcessing() && !activeMessage.isAcknowledged() && (
                            <Button color={'primary'} variant={'contained'} onClick={() => this.deleteMessage(activeMessage)}>{t('delete')}</Button>
                        )}
                    </div>
                </div>
            </Drawer>
        )
    }

    async onSetMessageHold() {
        try {
            const { activeMessage } = this.state
            const currentStatus = activeMessage.getParam(PARAM_ON_HOLD)
            const status = currentStatus === "true" ? false : currentStatus === "false" ? true : "true"
            const params = { [PARAM_ON_HOLD]: status }
            await this.context.appMessageService.updateMessage(activeMessage.getId(), params)
            this.onCloseDrawerMenu()
            this.context.restartSync()
        } catch (error) {
            console.log('onSetMessageHold error... ', error)
        }
    }

}

MessageList.propTypes = {
    messageListType: PropTypes.string.isRequired,
    messages: PropTypes.array,
    publicUsers: PropTypes.array,
    messageType: PropTypes.string,
    triggerArticleEvent: PropTypes.func,
    fullWidth: PropTypes.bool,
    actionButtons: PropTypes.array,
    filters: PropTypes.array,
    themeColIndex: PropTypes.number
}


MessageList.contextType = ApiContext


const styles = theme => ({
    container: {
        position: 'absolute',
        left: theme.spacing(3),
        top: 0,
        right: theme.spacing(3),
        bottom: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: 1,
        userSelect: 'none'
    },
    listSearchContainer: {
        maxWidth: 260,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // width: '100%',
        overflow: 'hidden',
        transition: 'width 0.2s linear',
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: theme.spacing(2),
    },
    searchBar: {
        width: '100%',
        minWidth: 'auto',
        maxWidth: '100%',
    },
    listHeaderIconButton: {
        width: 32,
        height: 32,
        color: theme.palette.common.primary
    },
    listHeaderIconButtonActive: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    listHeaderIconButtonBadge: {
        right: theme.spacing(0.5),
        top: theme.spacing(0.75),
        width: 10,
        height: 10,
        borderRadius: 5,
        position: 'absolute',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid #fff',
    },
    drawerDivider: {
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),

    },
    listItem: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        backgroundColor: '#f6f6f6',
        transition: 'box-shadow .2s'
    },
    articleListItem: {
        cursor: 'auto',
    },
    listBoxItemTitle: {
        fontSize: theme.font.large,
        fontWeight: '500',
        lineHeight: 1.2,
        marginBottom: 4
    },
    listBoxItemLine: {
        lineHeight: 1.2,
        flex: 1
    },
    listBoxItemLineValue: {
        fontSize: theme.font.medium,
        fontWeight: '500',
        lineHeight: 1.2
    },
    listBoxItemRecipient: {
        fontSize: theme.font.extraSmall,
        lineHeight: 1.33,
        flex: 1
    },
    listBoxItemDate: {
        fontSize: theme.font.extraSmall,
        lineHeight: 1.33,
        fontWeight: 500
    },
    listHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        left: 0,
        right: 0,
        borderBottom: '2px solid rgba(153, 153, 153, 0.1)',
        paddingBottom: theme.spacing(0.5),
        alignItems: 'center'
    },
    listHeaderTitle: {
        fontWeight: 500,
        fontSize: theme.font.small,
        marginRight: theme.spacing(1)
    },
    listHeaderCount: {
        flex: 1,
        fontSize: theme.font.small,
    },
    listHeaderBadge: {
        fontSize: theme.font.small,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        width: 14,
        height: 16,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    listBody: {
        transition: 'top 0.2s linear',
        position: 'absolute',
        top: 48,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'auto',
        width: props => props.fullWidth ? '100%' : '50%',
        margin: 'auto'
    },
    list: {
        padding: 0
    },
    noMessageText: {
        color: theme.palette.secondary.dark,
        fontSize: theme.font.medium
    },
    loading: {
        marginTop: 50
    },
    sendButton: {
        boxShadow: 'none',
        borderRadius: 0
    },
    sendButtonIcon: {
        width: 16,
        height: 16,
        marginTop: -8,
        '& svg': {
            width: 16,
            height: 16,
        }
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0
    },
    menuFooter: {
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
        backgroundColor: 'rgba(153, 153, 153, 0.1)',
        color: '#1a1a1a',
        '&.completed': {
            color: '#5f6368 !important',
            '& .processing': {
                color: '#5f6368'
            },
            '& svg': {
                color: '#5f6368 !important'
            }
        }
    },
    menuFooterList: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 12,


    },
    menuFooterListDesc: {
        flexDirection: 'column',
        flex: 1,
        '&.processing': {
            color: theme.palette.primary.main
        }
    },
    menuTitle: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 1.22
    },
    menuListIcon: {
        marginRight: theme.spacing(1),
        '&.processing': {
            '& svg': {
                color: theme.palette.primary.main
            }
        }
    },
    menuSubtitle: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 'normal',
        flexDirection: 'row',
        display: 'flex',
        marginTop: 12,
        '&.processing': {
            color: theme.palette.primary.main
        }
    },
    menuFooterSubtitle: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 'normal',
        flexDirection: 'row',
        display: 'flex',
        marginTop: 4,
        '&.processing': {
            color: theme.palette.primary.main
        }
    },
    badge: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 1.33,
        color: theme.palette.common.white,
        flexDirection: 'row',
        display: 'inline-flex',
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: '#1a1a1a !important'
    },
    menuIconButton: {
        padding: 0,
        display: 'flex',
        marginRight: 12,
        '&:last-child': {
            marginRight: 0
        }
    },
    actionButtons: {
        marginTop: -theme.spacing(0.5),
        marginRight: theme.spacing(1.5),
        color: theme.palette.common.black
    },
    editIcon: {
        fontSize: '1rem',
        padding: 0,
        marginLeft: theme.spacing(1.5)
    },
    sidebarEditIcon: {
        marginTop: 4,
        marginBottom: -6
    },
    sidebarImage: {
        width: '100%',
        height: 200
    },
    addNoteButton: {
        width: '100%',
        marginTop: theme.spacing(.5)
    },
    noteTextWrapper: {
        backgroundColor: '#FDFF97',
        padding: theme.spacing(.5)
    },
    noteText: {
        fontWeight: 500,
        flex: 1,
        wordBreak: 'break-word'
    },
    editMessageNoteIcon: {
        color: theme.palette.common.black,
    },
    drawerButton: {
        marginBottom: theme.spacing(1.5)
    },
    holdMessageItem: {
        opacity: .5
    }
})

export default withTranslation()(withStyles(styles)(MessageList))