import React, { useState, useEffect, useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Box, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel, Tabs, Tab, IconButton } from '@material-ui/core'
import { PageContext } from '../../Context/PageProvider'
import clsx from 'clsx'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import ReactSVG from 'react-svg'
import DeleteIcon from '@material-ui/icons/Delete'
import { ROWS_FIELD_OPTIONS, ROWS_BOLD_OPTIONS, COLUMN_FILTER_OPTIONS, COLUMN_TYPE_OPTIONS } from './OptionsHelper'
import { v4 as uuidv4 } from 'uuid'

export const getDefaultTheme = () => {
    const object = {
        id: uuidv4(),
        title: '',
        colCount: 2,
        default: false,
        rows: [{
            field: ROWS_FIELD_OPTIONS[0].value,
            size: 1,
            bold: false
        },
        {
            field: ROWS_FIELD_OPTIONS[1].value,
            size: 3,
            bold: false
        },
        {
            field: ROWS_FIELD_OPTIONS[2].value,
            size: 1,
            bold: false
        }],
        cols: [{
            type: COLUMN_TYPE_OPTIONS[0].value,
            filters: []
        },
        {
            type: COLUMN_TYPE_OPTIONS[1].value,
            filters: []
        }]
    }
    return object
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const ThemeOptionsTab = ({ values, onChangeValue, colCount = 2 }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const [columns, setColumns] = useState([])
    const rowsCount = Array(3).fill(null).map((_, i) => i)

    useEffect(() => {
        const cols = Array(colCount).fill(null).map((_, i) => i)
        setColumns(cols)
        let newCols = [...values.cols].filter( (col, index) => index < cols.length)
        for (let i = newCols.length; i < cols.length; i++) {
            newCols.push(
                {
                    type: COLUMN_TYPE_OPTIONS[0].value,
                    filters: []
                }
            )
        }
        values.cols = newCols
        onChangeValue(values)
    }, [colCount])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const onAddFilter = (index) => {
        values.cols[index].filters.push({ type: COLUMN_FILTER_OPTIONS[0].value, value: '' })
        onChangeValue(values)
    }

    const onDeleteFilter = (colIndex, filterIndex) => {
        delete values.cols[colIndex].filters[filterIndex]
        values.cols[colIndex].filters = values.cols[colIndex].filters.filter(filter => filter)
        onChangeValue(values)
    }

    return (
        <div className={classes.tabRoot}>
            <Tabs textColor={'primary'} indicatorColor={'primary'} value={value} onChange={handleChange} aria-label="theme tabs">
                <Tab label={t('row').toUpperCase()} {...a11yProps(0)} />
                {columns.map((col, i) =>
                    <Tab label={`${t('column').toUpperCase()} ${i + 1}`} {...a11yProps(i + 1)} />
                )}
            </Tabs>
            <TabPanel value={value} index={0}>
                <Grid className={classes.rowPreviewWrapper}>
                    <ReactSVG src="/assets/row_preview.svg" />
                </Grid>

                {rowsCount.map((row, i) => (
                    <Box display={'flex'} className={clsx(classes.contentHoriztontalPadding, classes.rowOptionsWrapper)}>
                        <FormControl className={classes.rowsDataWrapper}>
                            <InputLabel id={`row-${i}-data-label`}>{t('row')} {i + 1}</InputLabel>
                            <Select
                                labelId={`row-${i}-data-label`}
                                id={`row-${i}-data`}
                                value={values.rows[i].field}
                                className={classes.selectField}
                                onChange={(event) => {
                                    values.rows[i].field = event?.target?.value
                                    onChangeValue(values)
                                }}
                            >
                                {ROWS_FIELD_OPTIONS.map(option => (
                                    <MenuItem value={option.value}>{t(option.label)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.rowsSizeWrapper}>
                            <InputLabel id={`row-${i}-size-label`}>{t('size')}</InputLabel>
                            <Select
                                labelId={`row-${i}-size-label`}
                                id={`row-${i}-size`}
                                value={values.rows[i].size}
                                className={classes.selectField}
                                onChange={(event) => {
                                    values.rows[i].size = event?.target?.value
                                    onChangeValue(values)
                                }}>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.rowsBoldWrapper}>
                            <InputLabel id={`row-${i}-bold-label`}>{t('font_weight')} </InputLabel>
                            <Select
                                labelId={`row-${i}-bold-label`}
                                id={`row-${i}-bold`}
                                value={values.rows[i].bold}
                                className={classes.selectField}
                                onChange={(event) => {
                                    values.rows[i].bold = event?.target?.value
                                    onChangeValue(values)
                                }}>
                                {ROWS_BOLD_OPTIONS.map(option => (
                                    <MenuItem value={option.value}>{t(option.label)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                ))}

            </TabPanel>
            {columns.map((col, index) =>
                <TabPanel value={value} index={index + 1}>
                    <Box className={clsx(classes.contentHoriztontalPadding, classes.columnOptionsWrapper)}>
                        <FormControl>
                            <InputLabel id={`select-column-type-label`}>{t('actions_type')} </InputLabel>
                            <Select
                                labelId={`select-column-type-label`}
                                id={`select-column-type`}
                                value={values.cols[index]?.type}
                                className={classes.selectField}
                                onChange={(event) => {
                                    values.cols[index].type = event?.target?.value || ''
                                    onChangeValue(values)
                                }}>
                                {COLUMN_TYPE_OPTIONS.map(option => (
                                    <MenuItem value={option.value}>{t(option.label)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <Grid className={clsx(classes.columnFilterContainer, classes.contentHoriztontalPadding)}>
                            <Typography>{t('filter')}</Typography>
                            <Button onClick={() => onAddFilter(col)}>{t('add_filter')}</Button>
                        </Grid>
                        <Grid className={classes.contentHoriztontalPadding}>
                            {values.cols[index]?.filters?.length <= 0 && <Typography className={classes.noFilterText}>{t('no_filter_set')}</Typography>}
                            {values.cols[index]?.filters?.map((filter, filterIndex) => (
                                <Box className={classes.filterWrapper}>
                                    <Select
                                        id={`select-column-type`}
                                        value={filter.type}
                                        className={classes.selectField}
                                        onChange={(event) => {
                                            values.cols[index].filters[filterIndex].type = event?.target?.value
                                            onChangeValue(values)
                                        }}>
                                        {COLUMN_FILTER_OPTIONS.map(option => (
                                            <MenuItem value={option.value}>{t(option.label)}</MenuItem>
                                        ))}
                                    </Select>
                                    <TextValidator
                                        value={values.cols[index].filters[filterIndex].value}
                                        onChange={(event) => {
                                            values.cols[index].filters[filterIndex].value = event?.target?.value
                                            onChangeValue(values)
                                        }}
                                        margin={'none'}
                                        validators={['required']}
                                        errorMessages={[t('this_field_is_required')]}/>
                                    <IconButton size={'small'} onClick={() => onDeleteFilter(col, filterIndex)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            ))}
                        </Grid>
                    </Box>
                </TabPanel>
            )}
        </div>
    );
}
const ThemeModal = ({ open, onClose, title, theme, onApplyTheme }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const context = useContext(PageContext)
    const [values, setValues] = useState(theme)
    const formRef = useRef()

    const handleValues = (_values) => {
        setValues(prevState => ({ ...prevState, ..._values }))
    }

    const onSubmit = () => {
        onApplyTheme(values)
    }

    return (
        <Dialog open={open} aria-labelledby="alert" classes={{ paperWidthSm: classes.dialogRoot }}>
            <DialogTitle id="alert-title">{title}</DialogTitle>
            <ValidatorForm
                ref={formRef}
                onSubmit={onSubmit}
                onError={errors => console.log("MessageNoteModal error:", errors)}>
                <DialogContent classes={{ root: clsx(classes.dialogContent) }}>
                    <Box display={'flex'} className={classes.contentHoriztontalPadding}>
                        <TextValidator
                            value={values.title}
                            onChange={(event) => setValues(prevState => ({ ...prevState, title: event?.target?.value }))}
                            margin={'none'}
                            label={t('title')}
                            validators={['required']}
                            errorMessages={[t('this_field_is_required')]}
                            className={classes.titleField}/>
                        <FormControl className={classes.columnsCountWrapper}>
                            <InputLabel id="select-column-count-label">{t('columns')}</InputLabel>
                            <Select
                                labelId="select-column-count-label"
                                id="select-column-count"
                                value={values.colCount}
                                className={classes.selectField}
                                onChange={(event) => setValues(prevState => ({ ...prevState, colCount: Number(event?.target?.value) }))}>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            labelPlacement={'top'}
                            className={classes.switchWrapper}
                            control={<Switch
                                checked={values.default}
                                onChange={(event) => setValues(prevState => ({ ...prevState, default: event?.target?.checked }))}
                                name={'default-theme'}
                                color={'primary'}
                            />}
                            label={t('is_default_theme')}/>
                    </Box>
                    <ThemeOptionsTab
                        values={JSON.parse(JSON.stringify(values))}
                        onChangeValue={handleValues}
                        colCount={values.colCount}/>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={onClose}>
                        {t('cancel')}
                    </Button>
                    <Button type="submit" color="primary" variant={'contained'}>
                        {t('apply')}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    )
}


const useStyles = makeStyles(theme => ({
    dialogRoot: {
        minWidth: 600,
        maxWidth: '100%'
    },
    dialogContent: {
        overflowY: 'auto',
        maxHeight: '90%',
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: 420,
        paddingTop: '0px !important'
    },
    dialogActions: {
        margin: theme.spacing(2),
        justifyContent: 'flex-start',
        '& button:last-child': {
            marginLeft: 'auto'
        }
    },
    contentHoriztontalPadding: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    columnsCountWrapper: {
        margin: `0px ${theme.spacing(4)}px`
    },
    switchWrapper: {
        alignItems: 'flex-start',
        margin: 0
    },
    titleField: {
        flex: 1
    },
    selectField: {
        height: 32,
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 40
        }
    },
    tabRoot: {
        marginTop: theme.spacing(2),
        flexGrow: 1,
        '& .MuiTab-root': {
            flex: 1,
            maxWidth: '100%',
            backgroundColor: '#222'
        },
    },
    rowPreviewWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(2),
        '& > div > div': {
            height: 40
        }
    },
    rowOptionsWrapper: {
        display: 'flex',
        marginTop: theme.spacing(1.5)
    },
    rowsDataWrapper: {
        flex: 1
    },
    rowsSizeWrapper: {
        margin: `0px ${theme.spacing(2)}px`
    },
    rowsBoldWrapper: {
        minWidth: 100
    },
    columnOptionsWrapper: {
        marginTop: theme.spacing(1.5),
        '& .MuiFormControl-root': {
            minWidth: 250
        }
    },
    columnFilterContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        '& p': {
            flex: 1
        }
    },
    noFilterText: {
        color: theme.palette.secondary.main,
        marginTop: theme.spacing(2)
    },
    filterWrapper: {
        display: 'flex',
        marginTop: theme.spacing(2),
        '& .MuiSelect-root': {
            minWidth: 150
        },
        '& .MuiTextField-root': {
            flex: 1,
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1.5),
        },
        '& .MuiIconButton-root': {
            height: 32
        }
    }
}))


export default ThemeModal
