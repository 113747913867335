import React, { useState } from 'react'
import moment from "moment"
import MomentUtils from "@date-io/moment"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers'

export const DatePickerKeyboard = ({label, value, onChange, ...args}) => {
    return <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
            margin="normal"
            label={label}
            format="DD.MM.YYYY"
            {...args}
            value={value}
            onChange={onChange}
        />
    </MuiPickersUtilsProvider>
}

export const DatePicker = ({label, value, onChange, onError, error, ...args}) => {
    return <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
            margin="normal"
            label={label}
            clearable={true}
            format="L"
            mask={ ampmFormat() ? "__/__/____" : "__.__.____" }
            {...args}
            helperText={error?.trim()}
            error={Boolean(error)}
            onError={onError}
            value={value}
            onChange={onChange}
        />
    </MuiPickersUtilsProvider>
}

export const TimePicker = ({label, value, onChange, onError, error, ...args}) => {
    return <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardTimePicker
            margin="normal"
            ampm={ampmFormat()}
            mask={ ampmFormat() ? "__:__ _M" : "__:__" }
            clearable={true}
            label={label}
            {...args}
            helperText={error?.trim()}
            error={Boolean(error)}
            onError={onError}
            value={value}
            onChange={onChange}
        />
    </MuiPickersUtilsProvider>
}

export const DateTimePicker = ({label, value, onChange, onError, error, ...args}) => {
    return <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDateTimePicker
            margin="normal"
            ampm={ampmFormat()}
            clearable={true}
            mask={ ampmFormat() ? "__/__/____ __:__ _M" : "__.__.____ __:__" }
            label={label}
            {...args}
            helperText={error?.trim()}
            error={Boolean(error)}
            onError={onError}
            value={value}
            onChange={onChange}
        />
    </MuiPickersUtilsProvider>
}

export const ampmFormat = () => {
    const formattedValue = moment().format('LT').toLocaleUpperCase()
    return formattedValue.indexOf('AM') >= 0 || formattedValue.indexOf('PM') >= 0 
}