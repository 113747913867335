import Device from '../DeviceService/Models/Device';
const { getInvalidParameterError, ApiError } = require('../Errors');

const API_SERVICE_KEY_TIMER_DRIVER = 'drv-timer';

export default class TimerDriver {
  constructor(api, apiCache) {
    this.api = api;
    this.apiCache = apiCache;
  }

  /**
   * Register timer with Org namespace and cronString
   * @param {Uid} orgId
   * @param {String} cronString
   * @returns {undefined}
   */
  registerTimer(orgId, cronString) {
    if (!orgId) {
      return Promise.reject(getInvalidParameterError('orgId'));
    }
    if (!cronString) {
      return Promise.reject(getInvalidParameterError('cronString'));
    }
    const url = this.api.buildRequestURL('/:serviceKey/api/register', {
      ':serviceKey': API_SERVICE_KEY_TIMER_DRIVER,
    });
    const body = { orgId, definition: cronString };
    return this.api.request('POST', url, body).then(result => {
      // TODO: returns ?
      const createdDevice = new Device(result);
      this.apiCache.add(createdDevice);
      return createdDevice;
    });
  }
}
