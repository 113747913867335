//import i18n from 'i18next'

const ERROR = {
  UNKNOWN: 'Unknown',
  SERVER_ERROR: 'ServerError',
  INTERNAL_SERVER_ERROR: 'InternalServerError',
  FORBIDDEN: 'Forbidden',
  UNAUTHORIZED: 'Unauthorized',
  USER_NOT_FOUND: 'UserNotFound',
  NOT_FOUND: 'NotFound',
  INVALID_CODE: 'InvalidCode',
  ALREADY_EXISTS: 'AlreadyExisting',
  INVALID_PARAMETER: 'InvalidParameter',
  RESOURCE_IN_USE: 'RESOURCE_IN_USE',
  MULTI_FACTOR_REQUIRED: 'MultiFactorRequired',
  WEBSOCKET_ERROR: 'WebsocketError',
  TOKEN_UNKNOWN_OR_EXPIRED: 'TokenUnknownOrExpired',
};

class ApiError extends Error {
  constructor(message) {
    super(message.name || ERROR.UNKNOWN);
    this.name = 'ApiError';
    this.originError = message || { detail: ERROR.UNKNOWN, name: ERROR.UNKNOWN };
  }

  /**
   * get error type
   * @returns {ERROR}
   */
  getErrorType() {
    return this.originError.name;
  }
}

function getForbiddenError() {
  return new ApiError({ detail: 'forbidden', name: ERROR.FORBIDDEN });
}

function getUnauthorizedError() {
  return new ApiError({ detail: 'unauthorized', name: ERROR.UNAUTHORIZED });
}

function getInvalidParameterError(param) {
  return new ApiError({ detail: 'invalid parameter given', name: ERROR.INVALID_PARAMETER, param: param });
}

function getServerError() {
  return new ApiError({ detail: 'server error', name: ERROR.SERVER_ERROR });
}

function getInternalServerError() {
  return new ApiError({ detail: 'internal server error', name: ERROR.INTERNAL_SERVER_ERROR });
}

function getWebSocketError() {
  return new ApiError({ detail: 'web socket error', name: ERROR.WEBSOCKET_ERROR });
}

function getUknownOrExpiredError() {
  return new ApiError({ detail: 'token unknown or expired', name: ERROR.TOKEN_UNKNOWN_OR_EXPIRED });
}

export { ERROR, ApiError, getForbiddenError, getUnauthorizedError, getServerError, getInternalServerError, getInvalidParameterError, getWebSocketError, getUknownOrExpiredError };
