import { Route, Redirect } from 'react-router-dom'
import React, { useContext, Fragment } from 'react'
import { ApiContext } from '../../m2m-cloud-api/MessageLog/ApiContext'
import { getBasePath } from './App'
import WhatsNewModal from './Components/WhatsNewModal'

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const context = useContext(ApiContext)
    if (Component) {
        Component.context = ApiContext
    }
    return (
        <Route {...rest} render={(props) => (context.userService.getActiveUser() ? <Fragment><Component {...rest} {...props} context={context}/><WhatsNewModal/></Fragment> : <Redirect to={`/auth?redirect=${getBasePath()}`} />)} />
    )
}

export default ProtectedRoute