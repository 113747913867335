import React, { useState, useEffect } from 'react'
import { makeStyles, } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, LinearProgress, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core'
import { PARAM_NAME, PARAM_APP_MSG_SEND_ORG_NAME, PARAM_APP_MSG_RECIPIENT_SIMPLY_MODE } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'


const useStyles = makeStyles(theme => ({
}))

function GroupDialog ({groupEntity, onSubmit, onCancel, loading, errorMessage}) {
    const { t } = useTranslation()
    const classes = useStyles()
    const [name, setName] = useState(groupEntity && groupEntity.getName() || '')
    const [sendOrgName, setSendOrgName] = useState((groupEntity && groupEntity.getParam(PARAM_APP_MSG_SEND_ORG_NAME) === 'true') || false)
    const [simplyRecipientMode, setSimplyRecipientMode] = useState((groupEntity && groupEntity.getParam(PARAM_APP_MSG_RECIPIENT_SIMPLY_MODE) === 'true') || false)
    const title = groupEntity ? t('edit_group_org_title'): t('add_new_group_org_title')
    
    return (
        <Dialog open={true} fullWidth maxWidth={'xs'} aria-labelledby="alert">
            { loading && <LinearProgress /> }
            <DialogTitle id="alert-title">{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={name}
                    disabled={loading}
                    onChange={ (event) => setName(event.target.value)}
                    label={t('name')}
                    fullWidth
                />
                <FormControl className={classes.formControl} fullWidth margin="dense">
                    <FormControlLabel 
                        control={<Checkbox color='primary' checked={sendOrgName} onChange={(event) => setSendOrgName(event.target.checked)}/>}
                        label={t('send_group_name')} />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                    <FormControlLabel 
                        control={<Checkbox color='primary' checked={simplyRecipientMode} onChange={(event) => setSimplyRecipientMode(event.target.checked)}/>}
                        label={t('simply_recipient_mode')} />
                </FormControl>
                {errorMessage && <DialogContentText style={{marginTop: 20, whiteSpace: 'pre-line'}} color="error">{errorMessage}</DialogContentText>}
            </DialogContent>  
            <DialogActions>
                {<Button disabled={loading} onClick={onCancel} color="default">
                    {t('cancel')}
                </Button> }
                <Button disabled={!name || name.trim() === "" || loading} onClick={() => onSubmit({ [PARAM_NAME]: name, [PARAM_APP_MSG_SEND_ORG_NAME]: sendOrgName ? true : null, [PARAM_APP_MSG_RECIPIENT_SIMPLY_MODE]: simplyRecipientMode ? true : null })} color={"primary"}>
                    {groupEntity ? t('ok') : t('create')}
                </Button>
            </DialogActions>  
        </Dialog>
    )
}

export default GroupDialog