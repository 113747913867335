import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Box, Typography, IconButton, ClickAwayListener, Tooltip } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import ReactSVG from 'react-svg'

const RecoveryCodesList = ({ recoveryCodes = [] }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const downloadCodes = useCallback(() => {
        const element = document.createElement("a")
        const file = new Blob([recoveryCodes.join('\n')], { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = "recovery-codes.txt"
        document.body.appendChild(element)
        element.click()
    }, [recoveryCodes])

    const copyToClipboard = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(recoveryCodes.join('\n'))
            setTooltipOpen(true)
        } catch (error) {
            console.log('copy error... ', error)
        }
    }, [recoveryCodes])

    const handleTooltipClose = () => setTooltipOpen(false)

    return (
        <Box>
            <Box marginTop={3} display={'flex'}>
                <Box className={classes.recoveryCodesWrapper}>
                    {recoveryCodes?.map((code, index) => index < recoveryCodes.length / 2 && <Typography variant={'caption'} color={'textSecondary'}>{code}</Typography>)}
                </Box>
                <Box className={classes.recoveryCodesWrapper}>
                    {recoveryCodes?.map((code, index) => index >= recoveryCodes.length / 2 && <Typography variant={'caption'} color={'textSecondary'}>{code}</Typography>)}
                </Box>
            </Box>
            <Box display={'inline-flex'} alignItems={'center'} className={classes.downloadRecoveryWrapper} onClick={() => console.log('download recovery codes...')}>
                <Box class={classes.buttonWrapper}>
                    <IconButton aria-label="download" size={'small'} onClick={downloadCodes}>
                        <GetAppIcon />
                    </IconButton>
                    <Typography onClick={downloadCodes}>{t('download')}</Typography>
                </Box>
                <Box class={classes.buttonWrapper}>
                    <Box>
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                                onClose={handleTooltipClose}
                                open={tooltipOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={t('copied')}>
                                <IconButton aria-label="copy_to_clipboard" size={'small'} onClick={copyToClipboard}>
                                    <ReactSVG src="/assets/light/copy_to_clipboard.svg" />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                    </Box>
                    <Typography onClick={copyToClipboard}>{t('copy_to_clipboard')}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    recoveryCodesWrapper: {
        backgroundColor: theme.palette.common.white,
        minHeight: 120,
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 12,
        paddingBottom: 12,
    },
    downloadRecoveryWrapper: {
        marginTop: 12,
    },
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 12,
        cursor: 'pointer',
        '& span > div > div': {
            width: 24,
            height: 24
        },
    }
}))

export default RecoveryCodesList