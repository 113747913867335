import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import {Dialog, MenuItem, DialogActions, DialogContent, DialogTitle, Button, } from '@material-ui/core'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'

const styles = theme => ({
	errorText: {
		color: theme.palette.error.main,
		marginTop: 20
	}
})

export class InviteUserDialog extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			errorMessage: null,
            mail: null,
            userType: 0
		}
    }
    
	render() {
		const { loading, errorMessage, userType } = this.state
		const { t } = this.props
		return (
			<div>
				<Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">{t('invite_user')}</DialogTitle>
					<DialogContent>
						<ValidatorForm
							ref="form"
							onSubmit={this.handleSubmit.bind(this)}
							onError={errors => console.log("form error:", errors)}>
                                <TextValidator
                                    select
                                    value={userType}
                                    onChange={(event) => {
                                        this.setState({ userType: event.target.value })
                                    }}
                                    margin="dense"
                                    label={t('user_type')}
                                    fullWidth>
                                        <MenuItem key={0} value={0}>
                                            {t('default_user')}
                                        </MenuItem>
                                        <MenuItem key={1} value={1}>
                                            {t('admin')}
                                        </MenuItem>
                                </TextValidator>

							<TextValidator
								label={t('email')}
								value={this.state.mail}
								onChange={(event) => {
									this.setState({ mail: event.target.value })
								}}
								margin="dense"
								validators={['required', 'isEmail']}
								errorMessages={[t('this_field_is_required'), t('email_is_not_valid')]}
								fullWidth
							/>
						</ValidatorForm>
					</DialogContent>
					<DialogActions>
						<Button disabled={loading} onClick={this.props.onCancel} color="primary">
							{t('cancel')}
						</Button>
						<Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
							{t('invite_user')}
						</Button>
					</DialogActions>
				</Dialog>
				{errorMessage && (
					<AlertDialog
						open={errorMessage ? true : false}
						title={t('error')}
						message={errorMessage}
						submitButtonTitle={t('ok')}
						onSubmit={() => this.setState({ errorMessage: null })}
					/>
				)}
			</div>
		)
	}

	async handleSubmit() {
        const { usersGroup, adminsGroup, api } = this.props
        const { mail, userType } = this.state

        /*const groupId = userType === 1 ? adminsGroup.getId() : usersGroup.getId()
		api.userService.inviteToGroup(groupId, mail)
			.then(success => {
				this.setState({ mail: null, loading: false, })
				this.props.onSuccess()
			})
			.catch(error => {
				this.setState({ items: null, loading: false, errorMessage: mapErrorMessage(error) })
            })*/
        const promisses = [api.userService.inviteToGroup( usersGroup.getId() , mail)]
        if (userType === 1) {
            promisses.push(  api.userService.inviteToGroup( adminsGroup.getId() , mail) )
        }

        Promise.all(promisses)
            .then(success => {
                this.setState({ mail: null, loading: false, })
                this.props.onSuccess()
            })
            .catch(error => {
                this.setState({ items: null, loading: false, errorMessage: mapErrorMessage(error) })
            })
	}

}

InviteUserDialog.contextType = PageContext

InviteUserDialog.propTypes = {
    api: PropTypes.object.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
    usersGroup: PropTypes.object.isRequired,
    adminsGroup: PropTypes.object.isRequired,
}

export default withTranslation()(withStyles(styles)(InviteUserDialog))
