/**
 * message log app-mode
 */
export const APP_MODE = {
  SELECT_SUPPLIER_OR_RECIPIENT: 'none',
  SUPPLIER: 's',
  RECIPIENT: 'r',
};

export const ROOT_PARAM_KEY = 'app-msg'; //'app-msg'
export const ROOT_PARAM_OLD_FALLBACK_KEY = 'msg-app';

/**
 * user param keys
 */
export const USER_PARAM_KEY_CONFIG = 'config';
export const USER_PARAM_KEY_LOCATION = 'location'; // DEPRECATED
export const USER_PARAM_KEY_MODE = 'mode';
export const USER_PARAM_KEY_RECIPIENT = 'recipient';
export const USER_PARAM_KEY_SUPPLIER = 'supplier';
export const USER_PARAM_KEY_ALERT = 'alert';
export const USER_PARAM_KEY_NOTIFICATION = 'pns';
export const USER_PARAM_KEY_SELECTED_SUPPLIER = 'selected-supplier';
export const USER_PARAM_KEY_SELECTED_RECIPIENT = 'selected-recipient';
export const USER_PARAM_KEY_SELECTED_THEME = 'selected-theme';

/**
 * org param keys
 */
export const ORG_PARAM_KEY_ACCESS = `${ROOT_PARAM_KEY}:access`; /* DEPRECATED */
export const ORG_PARAM_KEY_ACCESSIBILITY = `${ROOT_PARAM_KEY}:accessibility`;
export const ORG_PARAM_KEY_ACCESSIBILITY_ADMINS_EXCLUDED = `${ROOT_PARAM_KEY}:accessibility-exclude`;
export const ORG_PARAM_KEY_ROOT_ORG_SUPPLIER_NAME = `${ROOT_PARAM_KEY}:root-supplier`;
export const ORG_PARAM_KEY_ROOT_ORG_RECIPIENT_NAME = `${ROOT_PARAM_KEY}:root-recipient`;
export const ORG_PARAM_KEY_REPLACEMENT = 'replacement';

/**
 * local setting keys
 */

export const SETTING_SELECTED_SUPPLIER_ORG_ID = `apiContext-setting-selected-supplier`;
export const SETTING_USER_FONT_SIZE = `user-font-size`;

/**
 * visibility keys
 */
export const VISIBILITY_MESSAGE_LOG_TEMPLATE = 'messagelog-demo';

/**
 * device reports
 */
export const CORE_DEVICE_REPORT_SUMMARY = `core-device-reportsummary`;
export const CORE_DEVICE_REPORT_DEFINATION = `core-device-reportdefinition`;
export const CORE_DEVICE_REPORT_CATEGORY = `core-device-reportcategory`;
export const CORE_DEVICE_REPORT_SUMMARY_EVENTS = `${CORE_DEVICE_REPORT_SUMMARY}:events`;
export const CORE_DEVICE_REPORT_SUMMARY_DEVICE_SEARCH_FILTER = `${CORE_DEVICE_REPORT_SUMMARY}:devicesearch`;
export const CORE_DEVICE_REPORT_PARAM_FILTER = `${CORE_DEVICE_REPORT_SUMMARY}:paramfilter`;
export const CORE_DEVICE_REPORT_LINK_DETAILED = `${CORE_DEVICE_REPORT_SUMMARY}:link.detailed`;
