import React, { useContext, useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { withLightTheme } from '../../../theme'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { APP_MESSAGE_CREATOR, APP_MESSAGE_CREATOR_ADMIN, APP_MESSAGE_STA2_LOGMODE, APP_MESSAGE_STA2_WEIGHT_LOG_ORDER_POINT } from '../Constants'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Paper, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Typography, Toolbar, Checkbox, LinearProgress, Select, Box, MenuItem} from '@material-ui/core'
import { MESSAGE_ACTION_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/MessageAction'
import { PARAM_CODE_NAME_SPACE, PARAM_APP_MSG_VISIBILITY_MODE, PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { ORG_TAG } from '../../../m2m-cloud-api'
import { prepareTemplateContent } from '../../../m2m-cloud-api/Api/Helper'
import AlertDialog from '../../../Libs/Components/AlertDialog'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import {Virtuoso} from 'react-virtuoso'
import { ListContainer, ItemContainerNoLeftPadding} from '../../../Libs/Components/ListContainer'
import Searchbox from '../../../Libs/Components/Searchbox'
import DeviceSelectionDialog from './DeviceSelectionDialog'
import ScheduleSelectionDialog from '../../../Libs/Components/ScheduleSelectionDialog'
import ErrorIcon from '@material-ui/icons/Error'
import ls from "local-storage"
import ReactSVG from 'react-svg'

import { v4 as uuidv4 } from 'uuid'

const DEVICE_TYPES = {
    VIRTUAL: 'virtual',
    PHYSICAL: 'physical',
    TIMER: 'timer'
}

const contentHeight = 400
const toolbarHeight = 40

const EMPTY_SELECT_VALUE = ''

const ActionParamLabel = ({ item, groupOrgId, classes }) => {
    const context = useContext(PageContext)
    const [replacementsTitle, setReplacementsTitle] = useState(null)

    const replacememtsString = item?.template?.replacementIds?.join(',')
    useEffect(async () => {
        const titles = await context.fetchReplacementTitles(groupOrgId, item?.template?.getReplacementIds())
        console.log('titles', "fasfdsfsafas", titles)
        setReplacementsTitle(titles)
    }, [replacememtsString])

    if(!replacementsTitle) return null
    return(
        <Typography className={classes.actionParamLabel} variant="subtitle2">{replacementsTitle}</Typography>
    )
}

class TriggerDialog extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            ready: false,
            selectedProviderId: null,
            selectedActionId: null,
            selectedActions: [],
            providerEntities:  null,
            loading: false,
            searchTerm: '',
            errorMessage: null
        }

        this.setAsyncState = async (state) => {
            return new Promise((resolve) => {
                this.setState(state, resolve)
            })
        }

        this.handleResponseError = (error) => {
            console.log('handleResponseError', error)
            const { t } = this.props
            this.setState({
                errorMessage: t(mapErrorMessage(error)),
                loading: false
            })
            console.error(t(mapErrorMessage(error)))
        }

        this.getSettingsKey = () => {
            const user = this.context.api.userService.getActiveUser()
            const selectedRootOrg = this.context.getSelectedRootOrg()
            if (user && selectedRootOrg) {
                return `${user.getUserId()}-${selectedRootOrg.getId()}-add-trigger`
            }
            return null
        }

        this.getSettings = () => {
            const key = this.getSettingsKey()
            if (key) {
                const settings = ls.get(key)
                return settings
            }
            return null
        }

        this.setSettings = ({selectedProviderId}) => {
            const key = this.getSettingsKey()
            if (key) {
                const newSettings = {
                    selectedProviderId
                }
                const settings = this.getSettings()
                if (JSON.stringify(settings) !== JSON.stringify(newSettings)) {
                    ls.set(key, newSettings)
                    return true
                }
            }
            return false
        }
    }

    async componentDidMount() {
        const { groupOrgId, rootOrgId } = this.props
        const settings = this.getSettings()

        const actionGroupsFromCurrentMessagingGroup = await this.context.api.orgService.searchOrg({tags: [ORG_TAG.SUPPLIER_TARGET], params: {[PARAM_APP_MSG_VISIBILITY_MODE]: PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.GROUP}, searchRootOrg: [rootOrgId]})
        const actionGroupsFromCurrentMessagingConfig = await this.context.api.orgService.searchOrg({tags: [ORG_TAG.SUPPLIER_TARGET], params: {[PARAM_APP_MSG_VISIBILITY_MODE]: PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.CONFIG}, searchRootOrg: [rootOrgId]})
        const actionGroupsFromCurrentMessagingLocal = await this.context.api.orgService.searchOrg({tags: [ORG_TAG.SUPPLIER_TARGET], searchRootOrg: [groupOrgId]})

        const entities = [] 
        const allEntities = [...actionGroupsFromCurrentMessagingGroup, ...actionGroupsFromCurrentMessagingConfig, ...actionGroupsFromCurrentMessagingLocal]
        allEntities.map( entity => {
            if ( entities.findIndex( e => e.getId() === entity.getId() ) === -1) {
                entities.push(entity)
            }
        })
        entities.sort((a, b) => {
            return a.getName().localeCompare(b.getName())
        })

        if (settings && settings.selectedProviderId && entities && entities.find( org => org.getId() === settings.selectedProviderId) ) {
            await this.setAsyncState({selectedProviderId: settings.selectedProviderId, entities, ready: true})
            await this.loadProviderActions()
        } else {
            await this.setAsyncState({entities, ready: true})
        }
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedProviderId !== this.state.selectedProviderId) {
            this.setSettings({selectedProviderId: this.state.selectedProviderId})
            this.loadProviderActions()
        }
    }

    async loadProviderActions() {
        const { selectedProviderId } = this.state

        await this.setAsyncState({providerActions: null, loading: true}) 
        if (selectedProviderId) {
            try {
                let providerActions = await this.context.loadActionEntities(selectedProviderId)
                const hasProviderActions = providerActions && providerActions.length > 0 ? true : false
                const triggerEntities = await this.context.loadTriggerEntities(this.props.orgId)
                const usedTriggerEntityIds = triggerEntities.map( triggerEntity => triggerEntity.definition && triggerEntity.definition.getTemplateId() || null )
                //providerActions = providerActions.filter( providerAction => usedTriggerEntityIds.indexOf(providerAction.id) === -1 )
                await this.setAsyncState({providerActions, usedTriggerEntities: triggerEntities, hasProviderActions, loading: false})
            } catch (error) {
                this.handleResponseError(error)
            }
        }
    }

    /**
     * load tigger item entities from api
     */
    loadTriggerGroupItems() {
        this.setState({triggerGroupItemEntities: null}, () => {
            const { selectedTriggerGroupId } = this.state
            if (selectedTriggerGroupId) {
                this.context.loadTriggerEntities(selectedTriggerGroupId)
                .then( triggerGroupItemEntities => {
                    this.setState({triggerGroupItemEntities})
                })
                .catch( error => this.handleResponseError(error))
            } else {
                this.setState({triggerGroupItemEntities: null})
            }
        })
    }


    async handleSubmit() {
        const { providerActions, selectedActions } = this.state
        const { groupOrgId } = this.props
        const selectedRootOrg = this.context.getSelectedRootOrg()

        try {

            const namespace = selectedRootOrg.getParam(PARAM_CODE_NAME_SPACE)
            if (!namespace) {
                throw new Error("Root Org has no defined namespace.")
            }

            await this.setAsyncState({loading: true})
            let items = []
            for (let index = 0; index < selectedActions.length; index++) {
                const selectedAction = selectedActions[index]
                const orgId = selectedAction.template.getOrgId()
                const selectedActionId = selectedAction.id

                // action
                const actions = await this.context.api.appMessageService.getActions( groupOrgId )
                const action = actions.find( action => action.getType() === MESSAGE_ACTION_TYPE.MESSAGE && action.getData() && ( action.getData().ackable === true || action.getData().ackable === 'true') && action.getData().ackcmd === 'RESET_DEMAND' )
                let actionId = null
                if (!action) {
                    //console.log("action not exists for root org: ", groupOrgId)
                    actionId = uuidv4()
                    const name = "Message"
                    const data = {  ackable: true, ackcmd: 'RESET_DEMAND' } 
                    const result = await this.context.api.appMessageService.upsertAction(actionId, name, data, groupOrgId, MESSAGE_ACTION_TYPE.MESSAGE)
                    if (result) {
                        console.log("action successfully created, action id: " + actionId)
                    } else {
                        actionId = null
                        throw( new Error("can't create action") )
                    }
                } else {
                    actionId = action.getId()
                }

                // device
                let device = null
                if (selectedAction.deviceType === DEVICE_TYPES.PHYSICAL) {
                    device = selectedAction.driverItem.device
                    if ( selectedAction.driverItem.logType !== null ) {
                        await this.context.api.deviceService.updateSettings(device.getId(), { [APP_MESSAGE_STA2_LOGMODE]: selectedAction.driverItem.logType, [APP_MESSAGE_STA2_WEIGHT_LOG_ORDER_POINT]: selectedAction.driverItem.orderPoint })
                        const resetCommandResult = await this.context.api.deviceService.sendSta2DeviceCommand(device.getId(), 'RESET')
                    }
                } else if (selectedAction.deviceType === DEVICE_TYPES.VIRTUAL) {
                    const token = uuidv4().replace(new RegExp('-', 'g'), '')
                    const name = `${new Date().getTime()}`
                    device = await this.context.api.deviceService.codeDriver.registerCode(namespace, name, token)
                    //console.log('deviceRegisterResult, deviceId: ', device.getId())
                    const updateSettingResult = await this.context.api.deviceService.updateSetting(device.getId(), APP_MESSAGE_CREATOR, APP_MESSAGE_CREATOR_ADMIN)
                    //console.log('updateSettingResult: ', updateSettingResult) 
                } else if (selectedAction.deviceType === DEVICE_TYPES.TIMER) {
                    const { cronString } = selectedAction.driverItem
                    device = await this.context.api.deviceService.timerDriver.registerTimer(selectedRootOrg.getId(), cronString)
                    const updateSettingResult = await this.context.api.deviceService.updateSetting(device.getId(), APP_MESSAGE_CREATOR, APP_MESSAGE_CREATOR_ADMIN)
                }
                console.log('device', device)
                if ( device.getAssignedOrg() !== this.props.orgId ) {
                    const assignDeviceResult = await this.context.api.deviceService.assignDevice(device.getId(), this.props.orgId)
                    //console.log('assignDeviceResult: ', assignDeviceResult)
                }
                if ( !device.isActivated() ) {
                    const activateDeviceResult = await this.context.api.deviceService.activateDevice(device.getId())
                    //console.log('activateDeviceResult: ', activateDeviceResult)
                }
                // definition
                const definitionId = uuidv4()
                const definitionRegisterResponse = await this.context.api.appMessageService.registerDefinition(definitionId, actionId, device.getId(), ['TRIGGER1'], {}, orgId, selectedActionId)
                //console.log('definitionRegisterResponse: ', definitionRegisterResponse)

                const definition = await this.context.api.appMessageService.getDefinition(definitionId)

                const providerAction = providerActions.find( providerAction => providerAction.id ===  selectedActionId)
                let template = null
                if (providerAction) {
                    template = providerAction.template
                } else {
                    template = action = await this.context.api.appMessageService.getTemplate(selectedActionId)
                }
                const preparedTemplate = prepareTemplateContent(template, definition, device)
                
                items.push({
                    definition: definition,
                    device: device,
                    id: definition.getId(),
                    title: preparedTemplate.header
                })
            }
            await this.setAsyncState({loading: false})
            this.props.onSuccess(items)
        } catch (error) {
            this.handleResponseError(error)
        }

    }

    render() {
        const { loading, errorMessage, selectedProviderId, selectedActions, providerActions, usedTriggerEntities, hasProviderActions, searchTerm, entities } = this.state
        const { t, classes } = this.props
        const usedProviderVirtualActions = providerActions && providerActions.filter( providerAction => usedTriggerEntities.find( e => e.definition && e.definition.getTemplateId() === providerAction.id && e.device.getDriver() === DEVICE_TYPES.VIRTUAL)  )
        const usedProviderPhysicalActions = providerActions && providerActions.filter( providerAction => usedTriggerEntities.find( e => e.definition && e.definition.getTemplateId() === providerAction.id && e.device.getDriver() === DEVICE_TYPES.PHYSICAL) )
        const usedProviderTimerActions = providerActions && providerActions.filter( providerAction => usedTriggerEntities.find( e => e.definition && e.definition.getTemplateId() === providerAction.id && e.device.getDriver() === DEVICE_TYPES.TIMER) )
        const availableProviderActions = providerActions && providerActions.filter( a => !(usedProviderVirtualActions.find( action => action.id === a.id) && usedProviderPhysicalActions.find( action => action.id === a.id) && usedProviderTimerActions.find( action => action.id === a.id) ) )

        const filteredItems = availableProviderActions && availableProviderActions.filter( entity => entity.title.toLowerCase().indexOf( searchTerm.toLowerCase() ) >= 0 )

        const toggleSelection = async (action) => {
            let newSelectedActions= [...this.state.selectedActions]
            if ( selectedActions.findIndex(a => a.id === action.id) >= 0 ) {
                delete action.deviceType
                delete action.driverItem
                newSelectedActions = newSelectedActions.filter( a => a.id !== action.id )
            } else {
                const isVirtualItemUsed = usedProviderVirtualActions.find( e => e.id === action.id) ? true : false
                if (!isVirtualItemUsed) {
                    action.deviceType = DEVICE_TYPES.VIRTUAL
                } else {
                    action.deviceType = EMPTY_SELECT_VALUE
            }
                newSelectedActions.push(action)
            }
            await this.setAsyncState({selectedActions: newSelectedActions})
        }

        const setDeviceType = async (action, deviceType) => {
            let newSelectedActions = [...this.state.selectedActions]
            for (let index = 0; index < newSelectedActions.length; index++) {
                if ( newSelectedActions[index].id ===  action.id) {
                    delete newSelectedActions[index].driverItem
                    newSelectedActions[index].deviceType = deviceType
                }
            }
            await this.setAsyncState({selectedActions: newSelectedActions})
            console.log('setDeviceType, selectedActions', this.state.selectedActions)
        }

        const setDriverItem = async (action, driverItem) => {
            let newSelectedActions = [...this.state.selectedActions]
            for (let index = 0; index < newSelectedActions.length; index++) {
                if ( newSelectedActions[index].id ===  action.id) {
                    newSelectedActions[index].driverItem = driverItem
                }
            }
            await this.setAsyncState({selectedActions: newSelectedActions})
            console.log('setDriverItem, selectedActions', this.state.selectedActions)
        }

        const mapVisibilityIcon = (entity) => {
            const visibilityMode = entity.getParam(PARAM_APP_MSG_VISIBILITY_MODE)
            switch (visibilityMode) {
                case PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.CONFIG:
                case PARAM_APP_MSG_VISIBILITY_MODE_OPTIONS.GROUP:
                    return "/assets/visibility_group.svg"
                default:
                    return "/assets/visibility_privat.svg"
            }
        } 

        const getActionWithoutSetup = (deviceType) => {
            return selectedActions && selectedActions.find( action => [deviceType].indexOf(action.deviceType) >= 0 && !action.driverItem)
        }

        const mapVisibilityIconColor = (entity) => {
            return selectedProviderId === entity.getId() ? '#fff' : '#706F6F'
        }

        const shouldDisplayErrorIcon = (providerEntity) => {
            return selectedActions.find( selectedAction => selectedAction.deviceType === EMPTY_SELECT_VALUE && providerEntity.getId() === selectedAction.template.orgId ) ? true : false
        }

        const canSubmit = () => {
            return selectedActions.length === 0 || selectedActions.find( selectedAction => selectedAction.deviceType === EMPTY_SELECT_VALUE ) ? false : true
        }

        return (
            <div>
                <Dialog style={{pointerEvents: providerActions && loading ?'none' : null}} open={this.props.open} fullWidth maxWidth={'md'} aria-labelledby="form-dialog-title">
                    { providerActions && loading && <LinearProgress className={classes.progress} /> }
                    <div className={classes.dialogTitleContainer}>
                        <DialogTitle id="form-dialog-title">
                            <div>{t('add_sender')}</div>
                        </DialogTitle>
                        <div className={classes.searchContainer}>
                            <Searchbox
                                value={this.state.searchTerm}
                                display={loading}
                                onChange={(value) => this.setState({searchTerm: value})}/>
                        </div>
                    </div>
                    <Divider/>
                    <DialogContent className={classes.dialogContent}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="stretch">
                            <Grid item xs={4} className={classes.gridCol}>
                                <Toolbar className={classes.toolbar}>
                                    <Typography color={'textSecondary'} variant={'body2'}>{t('service_provider_group')}</Typography>
                                </Toolbar>
                                <Paper className={classes.listPaper}>
                                    <List className={classes.providerList}>
                                        { entities && entities.map( (entity) => (
                                            <ListItem key={entity.getId()} button={true} selected={selectedProviderId === entity.getId()} className={classes.providerListItem} onClick={() => this.setState({selectedProviderId: entity.getId()})}>
                                                <ListItemIcon style={{minWidth: 'auto', marginRight: 12, marginTop: 4}}>
                                                    <ReactSVG beforeInjection={(svg) => {svg.setAttribute('fill', mapVisibilityIconColor(entity))}} src={mapVisibilityIcon(entity)} />
                                                </ListItemIcon>
                                                <ListItemText className={classes.listItemText} primary={entity.getName()} />
                                                { shouldDisplayErrorIcon(entity) && <ListItemSecondaryAction>
                                                    <ErrorIcon className={selectedProviderId === entity.getId() ? classes.invertedErrorIcon : classes.errorIcon} /> 
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                        )) }
                                        { !entities || entities.length ===0 && (
                                            <Typography style={{marginLeft: 24, marginTop: 16}} color={'textSecondary'} variant="body2">{t('no_entries_available')}</Typography>
                                        )}
                                    </List>
                                </Paper>
                            </Grid>
                            <Grid item xs={8} className={classes.gridCol}>
                                <Toolbar className={classes.toolbar}>
                                    { !providerActions && loading && <LinearProgress className={classes.providerActionProgress} /> }
                                    <Typography className={classes.title} color={'textSecondary'} variant={'body2'}>{t('offered_services')}</Typography>
                                </Toolbar>
                                    <Paper className={classes.listPaper}>
                                    <div style={{flex:1}}>
                                        { providerActions && providerActions.length === 0 && <Typography style={{marginLeft: 24, marginTop: 16}} color={'textSecondary'} variant="body2">{t('no_entries_available')}</Typography>}
                                        { providerActions && providerActions.length > 0 && filteredItems.length === 0 && <Typography style={{marginLeft: 24, marginTop: 16}} color={'textSecondary'} variant="body2">{t('no_match_found')}</Typography>}
                                        { filteredItems && filteredItems.length > 0 && <Virtuoso
                                            components={{
                                                Item: ItemContainerNoLeftPadding
                                            }}
                                            style={{width: '100%', listStyle: 'none', flex: 1, height: '100%', paddingTop: 0, paddingBottom: 0}}
                                            totalCount={filteredItems && filteredItems.length || 0}
                                            itemContent={index => {
                                                const item = filteredItems[index]
                                                const isVirtualItemUsed = usedProviderVirtualActions.find( e => e.id === item.id) ? true : false
                                                const isPhysicalItemUsed = usedProviderPhysicalActions.find( e => e.id === item.id) ? true : false
                                                const isTimerItemUsed = usedProviderTimerActions.find( e => e.id === item.id) ? true : false
                                                const selectedAction = selectedActions.find( a => a.id === item.id)
                                                const selectedActionDeviceType = selectedAction && selectedAction.deviceType ? selectedAction.deviceType : EMPTY_SELECT_VALUE

                                                return <ListItem className={classes.actionListItem} onClick={() => toggleSelection(item)}>
                                                    <ListItemIcon style={{minWidth: 44}}>
                                                        <Checkbox
                                                            checked={selectedActions.findIndex( a => a.id === item.id) >= 0}
                                                            color="primary"
                                                            onChange={() => toggleSelection(item)}/>
                                                    </ListItemIcon>
                                                    <ListItemText 
                                                        className={classes.listItemText} 
                                                        primary={
                                                            <Box display={'flex'} alignItems={'center'}>
                                                                <Typography variant="subtitle1">{item.title}</Typography>
                                                                <ActionParamLabel item={item} groupOrgId={this.props.groupOrgId} classes={classes} />
                                                            </Box>
                                                        } 
                                                        secondary={(item.driverItem && item.driverItem.device)
                                                            ? 
                                                            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <span className={classes.deviceBadge}>{item.driverItem.device.getPhysicalId()}</span>
                                                            </span> 
                                                            : null} />
                                                    {selectedActions.findIndex( a => a.id === item.id) >= 0 && 
                                                    <Select
                                                        classes={{root: classes.select}}
                                                        variant="outlined"
                                                        displayEmpty
                                                        value={selectedActionDeviceType}
                                                        onClick={(event) => { 
                                                            event.preventDefault()
                                                            event.stopPropagation()
                                                        }}
                                                        onChange={(event) => { 
                                                            event.preventDefault()
                                                            event.stopPropagation()
                                                            setDeviceType(item, event.target.value)
                                                        }}>
                                                        <MenuItem value={EMPTY_SELECT_VALUE}><em>{t('please_select')}</em></MenuItem>
                                                        <MenuItem disabled={isVirtualItemUsed} value={DEVICE_TYPES.VIRTUAL}>{t('virtual_device')}</MenuItem>
                                                        <MenuItem disabled={isPhysicalItemUsed} value={DEVICE_TYPES.PHYSICAL}>{t('physical_device')}</MenuItem>
                                                        <MenuItem disabled={isTimerItemUsed} value={DEVICE_TYPES.TIMER}>{t('schedule')}</MenuItem>
                                                    </Select>}
                                                </ListItem>
                                            }}>
                                        </Virtuoso>}
                                    </div>
                                    </Paper>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        { selectedActions.length > 0 && <Typography className={classes.selectedCountText} color={'textSecondary'} variant={'body2'}>{t('selected_count', { count: selectedActions.length })}</Typography> }
                        <Button disabled={loading} onClick={this.props.onCancel}>
                            {t('cancel')}
                        </Button>
                        <Button disabled={loading || !canSubmit()} onClick={() => this.handleSubmit()} color="primary">
                            {t('save')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <DeviceSelectionDialog 
                    open={ getActionWithoutSetup(DEVICE_TYPES.PHYSICAL) ? true : false} 
                    org={this.props.rootOrgId}
                    onSuccess={async (device, logType, orderPoint) => {
                        console.log('device, logType, orderPoint', device, logType, orderPoint)
                        await setDriverItem( getActionWithoutSetup(DEVICE_TYPES.PHYSICAL) , {device, logType, orderPoint})
                    }}
                    onCancel={async () => {
                        await toggleSelection( getActionWithoutSetup(DEVICE_TYPES.PHYSICAL) )
                    }}/>
                <ScheduleSelectionDialog 
                    open={ getActionWithoutSetup(DEVICE_TYPES.TIMER) ? true : false} 
                    onSuccess={async (cronString) => {
                        await setDriverItem( getActionWithoutSetup(DEVICE_TYPES.TIMER) , {cronString})
                    }}
                    onCancel={async () => {
                        await toggleSelection( getActionWithoutSetup(DEVICE_TYPES.TIMER) )
                    }}/>
                { errorMessage && <AlertDialog 
                    open={true} 
                    title={t('error')}
                    message={errorMessage}
                    buttonVariant={'text'}
                    onSubmit={ () => this.setState({errorMessage: null}) } 
                /> }
            </div>
        )
    }
}

TriggerDialog.contextType = PageContext

TriggerDialog.propTypes = {
    open: PropTypes.bool,
    rootOrgId: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    groupOrgId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

const styles = theme => ({
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3)
    },
    dialogContent: {
        padding: 0
    },
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    },
    select: {
        padding: '5px 10px'
    },
    gridCol: {
        height: contentHeight,
        position: 'relative',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.palette.divider + ' !important'
    },
    listItemText: {
        marginRight: theme.spacing(1),
        '& span': {
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis'
        }
    },
    listPaper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: toolbarHeight,
        bottom: 0,
        left:0,
        right: 0,
        boxShadow: 'none',
        overflow: 'auto',
        overflowY: 'auto',
        overflowX: 'hidden',
        color: 'inherit',
        backgroundColor: 'transparent',
    },
    toolbar: {
        minHeight: toolbarHeight,
        height: toolbarHeight,
        paddingLeft: theme.spacing(3)
    },
    title: {
        flex:1
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column',
        maringRight: theme.spacing(1)
    },
    providerActionProgress: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0
    },
    providerList: {
        paddingTop:0,
        paddingBottom:0
    },
    providerListItem: {
        paddingLeft: theme.spacing(3),
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            }
        }
    },
    emptyListLabel: {
        padding: theme.spacing(3)
    },
    actionListItem: {
        paddingLeft: theme.spacing(1),
        paddingTop: 0,
        paddingBottom: 0,
        cursor: 'pointer'
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
    errorIcon: {
        color: theme.palette.error.main,
    },
    invertedErrorIcon: {
        color: theme.palette.common.white,
    },
    selectedCountText: {
        position: 'absolute',
        left: theme.spacing(3)
    },
    deviceType: {
    },
    deviceBadge: {
        backgroundColor: theme.palette.secondary.main,
        padding: '2px 4px',
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        fontSize: theme.font.small,
        marginRight: theme.spacing(0.5)
    },
    actionParamLabel:{
        backgroundColor: theme.palette.grey['100'],
        textAlign: 'right',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        fontStyle: 'italic',
        marginLeft: theme.spacing(1),
        color: theme.palette.secondary.main
    }
})


export default withTranslation()(withLightTheme(withStyles(styles)(TriggerDialog)))