import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { Popper, Button, ButtonGroup, ClickAwayListener, Grow, Paper, MenuItem, MenuList, Badge, Typography } from '@material-ui/core';
import ReactSVG from 'react-svg'
import { get, set } from "local-storage"
import { withDarkTheme } from '../../theme'
import ChangePasswordDialog from './ChangePasswordDialog'
import EditProfile from './EditProfile'
import InvitationsDialog from './InvitationsDialog'
import AlertDialog from './AlertDialog'
import EnableTwoFactorAuthDialog from './TwoFactorAuth/EnableTwoFactorAuthDialog'
import InstallTwoFactorAuthDialog from './TwoFactorAuth/InstallTwoFactorAuthDialog'

const HANDLED_INVITATIONS_KEY = 'invitations'

class UserButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popperVisible: false,
            changePasswordModalVisible: false,
            editProfileModalVisible: false,
            invitationsModalVisible: false,
            newInvititionsAlertVisible: false,
            enableTwoFactorAuthDialogVisible: false,
            installTwoFactorAuthDialogVisible: false
        }
        this.anchorRef = React.createRef();

    }

    componentDidUpdate() {
        const { newInvititionsAlertVisible } = this.state
        const { pendingInvitations, userService } = this.props
        const user = userService && userService.getActiveUser()
        if (!newInvititionsAlertVisible && pendingInvitations && pendingInvitations.length > 0 && user) {
            let changed = false
            const key = `${HANDLED_INVITATIONS_KEY}-${user.getUserId()}`
            const pendingInvitationData = pendingInvitations.map(invitation => ({ groupId: invitation.groupId, inviter: invitation.inviter }))
            const invitations = get(key)
            if (!invitations) {
                changed = true
            } else {
                const notHandledInvitation = pendingInvitationData.find(pendingInvitation => !invitations.find(invitation => pendingInvitation.groupId === invitation.groupId && pendingInvitation.inviter === invitation.inviter))
                if (notHandledInvitation) {
                    changed = true
                }
            }
            if (changed) {
                set(key, pendingInvitationData)
                this.setState({ newInvititionsAlertVisible: true })
            }
        }

    }

    render() {
        const { classes, t, userService, pendingInvitations } = this.props
        const { popperVisible, changePasswordModalVisible, editProfileModalVisible, invitationsModalVisible, newInvititionsAlertVisible, enableTwoFactorAuthDialogVisible, installTwoFactorAuthDialogVisible } = this.state
        const user = userService && userService.getActiveUser()

        return (
            <div className={classes.userButton}>
                <ButtonGroup variant="text" color="primary" ref={this.anchorRef} aria-label="split button">
                    <Button
                        className={classes.buttonGroup}
                        color="secondary"
                        size="small"
                        aria-controls={popperVisible ? 'split-button-menu' : undefined}
                        aria-expanded={popperVisible ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={(e) => this.handleToggle()}
                    >
                        <Badge badgeContent={pendingInvitations && pendingInvitations.length || 0} color="error" variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
                            <ReactSVG src="/assets/light/icon-light-account.svg" />
                        </Badge>
                        {user && user.getNickName()}
                    </Button>
                </ButtonGroup>
                <Popper open={popperVisible} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal placement={'bottom-end'}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={(event) => this.handleClose(event)}>
                                    <MenuList id="button-menu">
                                        <MenuItem onClick={() => this.setState({ editProfileModalVisible: true, popperVisible: false })}>
                                            <Typography>{t('edit_profile')}</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => this.setState({ changePasswordModalVisible: true, popperVisible: false })}>
                                            <Typography>{t('change_password')}</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => this.setState({ invitationsModalVisible: true, popperVisible: false })}>
                                            <Typography>{t('invitations')}</Typography>{pendingInvitations && pendingInvitations.length > 0 && <Typography className={classes.badge}>{pendingInvitations.length}</Typography>}
                                        </MenuItem>
                                        <MenuItem onClick={() => this.setState({ enableTwoFactorAuthDialogVisible: true, popperVisible: false })}>
                                            <Typography>{t('multi_factor_authentication')}</Typography>
                                        </MenuItem>
                                        <MenuItem onClick={() => this.onLogout()}>
                                            <Typography>{t('logout')}</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                {changePasswordModalVisible && <ChangePasswordDialog
                    open={true}
                    userService={this.props.userService}
                    onCancel={() => this.setState({ changePasswordModalVisible: false })}
                    onSuccess={() => this.setState({ changePasswordModalVisible: false })}
                />}
                {editProfileModalVisible && <EditProfile
                    open={true}
                    userService={this.props.userService}
                    onCancel={() => this.setState({ editProfileModalVisible: false })}
                    onSuccess={() => this.setState({ editProfileModalVisible: false })}
                />}
                {invitationsModalVisible && <InvitationsDialog
                    open={true}
                    userService={this.props.userService}
                    authzService={this.props.authzService}
                    onSuccess={() => { if (this.props.onInvitationAccepted) this.props.onInvitationAccepted() }}
                    onClose={() => this.setState({ invitationsModalVisible: false })}
                />}
                <AlertDialog
                    open={newInvititionsAlertVisible}
                    title={t('invitation')}
                    message={pendingInvitations && pendingInvitations.length === 1 ? t('you_have_new_invitation') : t('you_have_new_invitations')}
                    submitButtonTitle={t('show')}
                    cancelButtonTitle={t('close')}
                    onSubmit={() => {
                        this.setState({ invitationsModalVisible: true, newInvititionsAlertVisible: false })
                    }}
                    onCancel={() => {
                        this.setState({ newInvititionsAlertVisible: false })
                    }} />

                {enableTwoFactorAuthDialogVisible && <EnableTwoFactorAuthDialog
                    open={true}
                    userService={this.props.userService}
                    onClose={(status) => {
                        const twoFactorStatus = typeof status === 'boolean' && status
                        this.setState({
                            enableTwoFactorAuthDialogVisible: false,
                            installTwoFactorAuthDialogVisible: twoFactorStatus
                        })
                    }}
                />}
                {installTwoFactorAuthDialogVisible && <InstallTwoFactorAuthDialog
                    open={true}
                    userService={this.props.userService}
                    onCancel={() => {
                        this.setState({ installTwoFactorAuthDialogVisible: false })
                    }}
                />}
            </div>
        )
    }

    onLogout() {
        this.props.userService.logoutUser()
    }

    handleToggle() {
        this.setState({
            popperVisible: true
        })
    }
    handleClose(event) {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({
            popperVisible: false
        })
    }

    getGroups(invitations) {
        let groupIds = []
        invitations && invitations.map(invitation => {
            if (groupIds.indexOf(invitation.getGroupId()) === -1) {
                groupIds.push(invitation.getGroupId())
            }
        })
        return groupIds
    }


}

UserButton.propTypes = {
    userService: PropTypes.any.isRequired,
    authzService: PropTypes.any.isRequired,
    onInvitationAccepted: PropTypes.func
}
const styles = theme => ({
    userButton: {
        zIndex: 2,
        marginTop: 4,
        marginBottom: 4,
        '& svg': {
            marginRight: theme.spacing(1),
            marginTop: 4,
            width: 24,
            height: 24
        }
    },
    buttonGroup: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: theme.palette.common.white,
        fontSize: 14
    },
    badge: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderRadius: theme.shape.borderRadius,
        fontSize: theme.font.small
    }
})
export default withTranslation()(withStyles(styles)(withDarkTheme(UserButton)))


