export const PARAM_ON_HOLD = 'onhold';
const STATIC_PARAM_KEYS = [PARAM_ON_HOLD];

export default class Message {
  /**
   * @param {Object<Uid,String,String,Uid,Uid,Uid,Boolean,String,Integer,Uid,Integer,Uid,Integer,Object,Object>}
   */
  constructor({
    messageId,
    header,
    body,
    definitionId,
    definitionOrgId,
    deviceAssignedOrgId,
    acked,
    ackCmd,
    ackTs,
    ackUserId,
    processingTs,
    processingUserId,
    ts,
    params,
    configOrg,
    groupOrg,
    rootOrg,
    triggeringDeviceId,
    triggeringUserId,
    ackRep,
    paramsOrigin,
  }) {
    this.messageId = messageId;
    this.header = header;
    this.body = body;
    this.definitionId = definitionId;
    this.definitionOrgId = definitionOrgId;
    this.deviceAssignedOrgId = deviceAssignedOrgId;
    this.acked = acked;
    this.ackCmd = ackCmd;
    this.ackTs = ackTs;
    this.ackUserId = ackUserId;
    this.processingTs = processingTs;
    this.processingUserId = processingUserId;
    this.ts = ts;
    this.params = params;
    this.configOrg = configOrg;
    this.groupOrg = groupOrg;
    this.rootOrg = rootOrg;
    this.triggeringDeviceId = triggeringDeviceId;
    this.triggeringUserId = triggeringUserId;
    this.priority = 1;
    this.ackReport = ackRep;
    this.paramsOrigin = paramsOrigin;
  }

  /**
   * Get message id
   * @returns {Uid}
   */
  getId() {
    return this.messageId;
  }

  /**
   * Get header
   * @returns {String}
   */
  getHeader() {
    return this.header;
  }

  /**
   * Get body
   * @returns {String}
   */
  getBody() {
    return this.body;
  }

  /**
   * Get definition id
   * @returns {Uid}
   */
  getDefinitionId() {
    return this.definitionId;
  }

  /**
   * Get definition org id
   * @returns {Uid}
   */
  getDefinitionOrgId() {
    return this.definitionOrgId;
  }

  /**
   * Get device assigned org id
   * @returns {Uid}
   */
  getDeviceAssignedOrgId() {
    return this.deviceAssignedOrgId;
  }

  /**
   * is message acknowledged
   * @returns {Boolean}
   */
  isAcknowledged() {
    return this.acked;
  }

  /**
   * get acknowledge command
   * @returns {String}
   */
  getAcknowledgeCommand() {
    return this.ackCmd;
  }

  /**
   * get acknowledged date
   * @returns {Date | null}
   */
  getAcknowledgedDate() {
    if (this.ackTs) {
      return new Date(this.ackTs);
    }
    return null;
  }

  /**
   * get acknowledged user id
   * @returns {Uid}
   */
  getAcknowledgedUserId() {
    return this.ackUserId;
  }

  /**
   * is message processing
   * @returns {Boolean}
   */
  isProcessing() {
    return !this.isAcknowledged() && this.getProcessingDate() > 0;
  }

  /**
   * get processing date
   * @returns {Date | null}
   */
  getProcessingDate() {
    if (this.processingTs) {
      return new Date(this.processingTs);
    }
    return null;
  }

  /**
   * get processing user id
   * @returns {Uid}
   */
  getProcessingUserId() {
    return this.processingUserId;
  }

  /**
   * get created date
   * @returns {Date}
   */
  getCreatedDate() {
    return new Date(this.ts);
  }

  /**
   * get params
   * @param {Boolean} includeStaticKeys
   * @returns {Array<Object<key,value>>}
   */
  getParams(includeStaticKeys = false) {
    if (includeStaticKeys) {
      return this.params;
    }
    if (this.params) {
      const filteredParams = {};
      const keys = Object.keys(this.params);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (STATIC_PARAM_KEYS.indexOf(key) === -1) {
          filteredParams[key] = this.params[key];
        }
      }
      return filteredParams;
    }
    return null;
  }

  /**
   * get param given key
   * @param {Uid} key
   * @returns {Object}
   */
  getParam(key) {
    return this.params[key];
  }

  /**
   * Set param
   * @param {String} name
   * @returns {String}
   */
  setParam(name, value) {
    if (!this.params) this.params = [];
    this.params[name] = value;
  }

  /**
   * get config org id
   * @returns {Uid}
   */
  getConfigOrgId() {
    return this.configOrg;
  }

  /**
   * get group org id
   * @returns {Uid}
   */
  getGroupOrgId() {
    return this.groupOrg;
  }

  /**
   * get root org id
   * @returns {Uid}
   */
  getRootOrgId() {
    return this.rootOrg;
  }

  /**
   * get triggering device id
   * @returns {Uid}
   */
  getTriggeringDeviceId() {
    return this.triggeringDeviceId;
  }

  /**
   * get triggering user id
   * @returns {Uid}
   */
  getTriggeringUserId() {
    return this.triggeringUserId;
  }

  /**
   * get message priority
   */
  getPriority() {
    return this.priority;
  }

  /**
   * set message priority
   * @param {Number} priority
   */
  setPriority(priority) {
    this.priority = priority;
  }

  /**
   * get acknowledged report
   */
  getAcknowledgedReport() {
    return this.ackReport;
  }

  /**
   * get params origin priority
   */
  getParamsOrigin() {
    return this.paramsOrigin;
  }
}
