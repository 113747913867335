export default class Invitation {
  /**
   * @param {Object<Uid,Uid,Uid,Number>}
   */
  constructor({ inviter, invitee, groupId, creationTS }) {
    this.inviter = inviter;
    this.invitee = invitee;
    this.groupId = groupId;
    this.creationTS = creationTS;
  }

  /**
   * Get inviter
   * @returns {Uid}
   */
  getInviter() {
    return this.inviter;
  }

  /**
   * Get invitee
   * @returns {Uid}
   */
  getInvitee() {
    return this.invitee;
  }

  /**
   * Get groupId
   * @returns {Uid}
   */
  getGroupId() {
    return this.groupId;
  }

  /**
   * Get creationTS
   * @returns {Number}
   */
  getCreationTS() {
    return this.creationTS;
  }
}
