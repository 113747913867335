import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Paper, InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

function Searchbox({ value, onChange, className, ...otherProps }) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Paper className={className ? [classes.searchPaper, className].join(' ') : classes.searchPaper} {...otherProps}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
                className={classes.searchTextField}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                placeholder={t('search')}/>
            { value !== "" && <CloseIcon className={classes.closeIcon} onClick={() => onChange('')} /> }
        </Paper>
    )
}


const useStyles = makeStyles(theme => ({
    searchPaper: {
        boxShadow: 'none',
        display: 'flex',
        backgroundColor: theme.palette.grey["200"],
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        maxWidth: 250,
        minWidth: 120
    },
    searchIcon: {
        margin: theme.spacing(0.5),
        color: theme.palette.secondary.main
    },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: theme.spacing(0.5),
        color: theme.palette.secondary.main,
        width: 20,
        cursor: 'pointer'
    },
    searchTextField: {
        width: '100%',
        paddingRight: 30,
        color: theme.palette.secondary.main
    },
}))

export default Searchbox