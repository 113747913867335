import React, { Fragment, forwardRef } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import { DatePicker } from '../../../Libs/Components/DatePicker'
import MaterialTable from 'material-table'
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import NextPage from '@material-ui/icons/NavigateNext';
import PreviousPage from '@material-ui/icons/NavigateBefore'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tabs, Tab, Table, TableBody, TableCell, TableHead, TableRow, Grid, IconButton, LinearProgress, MenuItem, Select, InputLabel, FormControl, TablePagination, Typography } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { mapErrorMessage } from '../../Utilities/ApiHelper'
import {
    VictoryChart,
    VictoryLine,
    VictoryAxis,
    VictoryScatter,
    createContainer,
    VictoryTheme
} from 'victory'
import moment from 'moment'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../theme'

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi")
const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <PreviousPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <NextPage {...props} ref={ref} />),
}

const TAB_TYPE = {
    CURRENT: 0,
    HISTORICAL: 1,
}

const HISTORICAL_EVENT_TYPE_ALL = 'all'
const CHART_HEIGHT = 480
const HEADER_AND_FOOTER_SIZE = 550

class DeviceEventDataDialog extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            errorMessage: null,
            items: null,
            tabIndex: TAB_TYPE.CURRENT,
            physicalGatewayIds: null,
            historicalStartDate: new Date(),
            historicalEndDate: new Date(),
            historicalEventType: null,
            eventTypes: null,
            historicalTabIndex: 0,
            chartHeight: CHART_HEIGHT
        }
        this.victoryContainerRef = null
    }

    chechWindowResize = () => {
        const newChartHeight = Math.min(window.innerHeight - HEADER_AND_FOOTER_SIZE, 550)
        if (this.state.chartHeight !== newChartHeight) {
            this.setState({chartHeight: newChartHeight})
        }
    }


    componentDidMount() {
        window.addEventListener("resize", this.chechWindowResize)
        this.chechWindowResize()
        this.loadData()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.chechWindowResize)
    }

    loadData() {
        switch (this.state.tabIndex) {
            case TAB_TYPE.CURRENT:
                this.queryCurrentDeviceData()
                break
            case TAB_TYPE.HISTORICAL:
                this.queryHistoricalDeviceData()
                break
        }
    }

    queryCurrentDeviceData() {
        this.setState({ loading: true })

        this.props.api.deviceService.readEventDescs(this.props.device.getId())
            .then(events => {
                const eventTypes = events.map(event => event.getName())
                this.props.api.deviceService.getDeviceData([this.props.device.getId()], eventTypes)
                    .then(eventValues => {
                        let historicalEventType = this.state.historicalEventType
                        if (historicalEventType === null && eventTypes.length > 0) {
                            const preperedDefaultEventName = 'TRIGGER1'
                            const searchIndex = eventTypes.indexOf(preperedDefaultEventName)
                            historicalEventType = searchIndex >= 0 ? eventTypes[searchIndex] : eventTypes[0]
                        }
                        this.setState({ eventTypes: eventTypes.sort(), historicalEventType, eventItemValues: this.prepareEventValues(eventValues.entries, true), loading: false })
                    })
                    .catch(error => {
                        this.setState({ items: null, loading: false, errorMessage: mapErrorMessage(error) })
                    })
            })
            .catch(error => {
                console.log('eventTypes error: ', error)
                this.setState({ eventTypes: null, loading: false, errorMessage: mapErrorMessage(error) })
            })

    }

    queryHistoricalDeviceData() {
        this.setState({ loading: true })

        const from = new Date(this.state.historicalStartDate.setHours(0, 0, 0, 0)).getTime()
        const to = new Date(this.state.historicalEndDate.setHours(23, 59, 59, 999)).getTime()

        const filterEventTypes = this.state.historicalEventType === HISTORICAL_EVENT_TYPE_ALL ? this.state.eventTypes || [] : [this.state.historicalEventType]

        this.props.api.deviceService.getHistoricalDeviceData([this.props.device.getId()], filterEventTypes, from, to)
            .then(result => {
                const { items, physicalGatewayIds } = this.prepareEventValues(result.entries)
                this.setState({ items: null }, () => {
                    this.setState({ items, physicalGatewayIds, loading: false })
                })
            })
            .catch(error => {
                console.warn('getHistoricalDeviceData, error', error)
                this.setState({ items: null, physicalGatewayIds: null, loading: false, errorMessage: mapErrorMessage(error) })
            })
    }

    prepareEventValues(entries, sortByEventName = false) {
        let items = []
        let physicalGatewayIds = []
        entries.map(item => {
            const data = item.data
            const timeStamps = Object.keys(data)
            timeStamps.map(timestamp => {
                const event = data[timestamp]
                const eventNames = Object.keys(event)
                if (eventNames && eventNames.length > 0) {
                    eventNames.map(eventName => {
                        const eventValue = event[eventName]
                        items.push({
                            name: eventName,
                            value: eventValue,
                            date: new Date(Number(timestamp))
                        })
                    })
                }
            })
            if (item.physicalGatewayIds && item.physicalGatewayIds.length > 0) {
                item.physicalGatewayIds.map(physicalGatewayId => {
                    physicalGatewayIds.push(physicalGatewayId)
                })
            }
        })
        if (sortByEventName) {
            items = items.sort((a, b) => a.name.localeCompare(b.name))
        } else {
            items = items.sort((a, b) => b.date.getTime() - a.date.getTime())
        }
        return { items, physicalGatewayIds }
    }

    renderTable() {
        const { t, classes } = this.props
        const items = this.state.tabIndex === TAB_TYPE.CURRENT ? this.state.eventItemValues && this.state.eventItemValues.items : this.state.items
        if (!items) return (null)

        return (
            <Fragment>
                <MaterialTable
                    title={null}
                    style={{
                        boxShadow: 'none',
                    }}
                    localization={{
                        body: { emptyDataSourceMessage: t('no_data_available') }
                    }}

                    icons={tableIcons}
                    options={{
                        toolbar: false,
                        search: false,
                        sorting: false,
                        selection: false,
                        paging: this.state.tabIndex === TAB_TYPE.CURRENT ? false : true,
                        pageSize: this.state.tabIndex === TAB_TYPE.CURRENT ? 1000 : 20,
                        filtering: false,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        minBodyHeight: this.state.chartHeight,
                        maxBodyHeight: this.state.chartHeight
                    }}
                    columns={[
                        {
                            title: t('event'),
                            field: 'name'
                        },
                        {
                            title: t('date'),
                            field: 'date',
                            render: rowData => <Moment className={classes.eventDate} format="YYYY-MM-DD HH:mm:ss.SSS">{rowData.date}</Moment>
                        },
                        {
                            title: t('value'),
                            field: 'value'
                        }

                    ]}
                    data={items}
                />
            </Fragment>
        )
    }

    renderHistoricalChart() {
        const { t, classes } = this.props
        const { eventTypes, eventItemValues } = this.state
        let items = this.state.tabIndex === TAB_TYPE.CURRENT ? this.state.eventItemValues && this.state.eventItemValues.items : this.state.items

        const hasYNoNumberValues = this.state.tabIndex === TAB_TYPE.HISTORICAL && items?.find( item => item.value && (item.value.trim() === '' || isNaN(item.value) )) ? true : false
        items = items?.map(item => {
            return { x: item.date, y: hasYNoNumberValues ? item.value : Number(item.value) }
        })

        if (!items || items.length <= 0) return (
            <Grid container className={classes.chartNoDataRoot}>
                <Typography variant={'body2'} color={'textSecondary'}>{t('no_data_available')}</Typography>
            </Grid>
        )
        return (
            <Grid container className={classes.chartRoot}>
                <VictoryChart
                    width={1000}
                    height={this.state.chartHeight}
                    theme={VictoryTheme.material}
                    scale={{ x: "time" }}
                    domainPadding={20}
                    containerComponent={
                        <VictoryZoomVoronoiContainer
                            zoomDimension={"x"}
                            labels={({ datum }) => {
                                if (datum.childName === "chart-line-0") {
                                    return `${moment(datum.x).format("YYYY-MM-DD HH:mm:ss.SSS")}`
                                } else if (datum.childName === "chart-scatter-1") {
                                    return `${t('value')}: ${datum.y}`
                                }
                            }}
                        />
                    }
                >
                    <VictoryLine
                        style={{ data: { stroke: SECONDARY_COLOR } }}
                        data={items}
                        interpolation="stepBefore"
                        sortOrder={'descending'}
                    />
                    <VictoryScatter data={items} style={{ data: { fill: PRIMARY_COLOR } }} />
                    <VictoryAxis
                        dependentAxis
                        tickFormat={(t) => t}
                        style={{
                            axis: {
                                stroke: "#fff"
                            },
                            grid: {
                                stroke: "transparent"
                            },
                            tickLabels: {
                                fontSize: 11,
                                fill: '#fff'
                            }
                        }}
                    />
                    <VictoryAxis
                        fixLabelOverlap
                        tickFormat={(t) => {
                            return [
                                moment(t).format("YYYY-MM-DD"),
                                moment(t).format("HH:mm")
                            ];
                        }}
                        style={{
                            axis: {
                                stroke: "#fff"
                            },
                            grid: {
                                stroke: "transparent"
                            },
                            tickLabels: {
                                fontSize: 10,
                                fill: '#fff'
                            }
                        }}
                    />
                </VictoryChart>
            </Grid>
        )
    }

    renderCurrrentTable() {
        const { eventTypes, eventItemValues } = this.state
        const { t, classes } = this.props

        if (!eventTypes || !eventItemValues) return (null)

        return (
            <Fragment>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('event')}</TableCell>
                            <TableCell align="right">{t('value')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {eventTypes.map(eventItem => {
                            const event = eventItemValues.items.find(eventValue => eventValue.name === eventItem)
                            const value = event ? event.value : '-'
                            const date = event ? event.date : null
                            return (<TableRow >
                                <TableCell component="th" scope="row">
                                    {eventItem}
                                </TableCell>
                                <TableCell align="right" title={value} className={classes.eventValueCell}>{value}<br />{date ? <Moment className={classes.eventDate} format="YYYY-MM-DD HH:mm:ss.SSS">{date}</Moment> : <div>&nbsp;</div>}</TableCell>
                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </Fragment>
        )
    }

    renderHistoricaltab() {
        const { eventTypes, loading, errorMessage, historicalTabIndex } = this.state
        const { t, classes } = this.props

        return (
            <Fragment>
                <Tabs
                    className={classes.tabs}
                    orientation="horizontal"
                    indicatorColor="primary"
                    textColor="primary"
                    value={historicalTabIndex}
                    onChange={(event, newValue) => this.setState({ historicalTabIndex: newValue })}>
                    <Tab label={t('list')} />
                    <Tab label={t('chart')} />
                </Tabs>
                {historicalTabIndex === 0 && this.renderTable()}
                {historicalTabIndex === 1 && this.renderHistoricalChart()}
            </Fragment>
        )
    }

    render() {
        const { eventTypes, loading, errorMessage } = this.state
        const { t, classes } = this.props

        return (
            <div>
                <Dialog classes={{ paper: classes.dialogPaper }} open={this.props.open} onClose={this.props.onClose} fullWidth  maxWidth={'lg'} aria-labelledby="form-dialog-title">
                    {loading && <LinearProgress className={classes.progress} />}
                    <DialogTitle id="form-dialog-title">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ flex: 1, paddingTop: 8 }}>{t('device_event_data')}</div>
                            <IconButton onClick={() => this.loadData()} edge="end">
                                <RefreshIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <Tabs
                        className={classes.tabs}
                        orientation="horizontal"
                        indicatorColor="primary"
                        textColor="primary"
                        value={this.state.tabIndex}
                        onChange={(event, newValue) => this.setState({ tabIndex: newValue }, () => this.loadData())}>
                        <Tab label={t('current')} />
                        <Tab label={t('historical')} />
                    </Tabs>
                    {this.state.tabIndex === TAB_TYPE.CURRENT &&
                        <Fragment>
                            <DialogContent>
                                {this.renderTable()}
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={loading} onClick={this.props.onClose} type="submit">
                                    {t('close')}
                                </Button>
                            </DialogActions>
                        </Fragment>
                    }
                    {this.state.tabIndex === TAB_TYPE.HISTORICAL &&
                        <Fragment>
                            <DialogActions className={classes.header}>
                                <DialogContent className={classes.historicalFilterRoot}>
                                    <Grid container spacing={3}>
                                        <Grid item md={4}>
                                            <DatePicker
                                                label={t('date_from')}
                                                value={this.state.historicalStartDate}
                                                onChange={(date) => {
                                                    this.setState({ historicalStartDate: date ? date.toDate() : null }, () => {
                                                        if (date && date.isValid()) {
                                                            this.queryHistoricalDeviceData()
                                                        }
                                                    })
                                                }} />
                                        </Grid>
                                        <Grid item md={4}>
                                            <DatePicker
                                                label={t('date_to')}
                                                value={this.state.historicalEndDate}
                                                onChange={(date) => {
                                                    this.setState({ historicalEndDate: date ? date.toDate() : null }, () => {
                                                        if (date && date.isValid()) {
                                                            this.queryHistoricalDeviceData()
                                                        }
                                                    })
                                                }} />
                                        </Grid>
                                        <Grid item md={4}>
                                            <FormControl className={classes.selectFormControl} fullWidth>
                                                <InputLabel htmlFor="user-type">{t('event')}</InputLabel>
                                                <Select
                                                    select
                                                    value={this.state.historicalEventType}
                                                    onChange={(event) => {
                                                        this.setState({ historicalEventType: event.target.value }, () => {
                                                            this.queryHistoricalDeviceData()
                                                        })
                                                    }}
                                                    margin="dense"
                                                >
                                                    <MenuItem key={HISTORICAL_EVENT_TYPE_ALL} value={HISTORICAL_EVENT_TYPE_ALL}>
                                                        {t('all_events')}
                                                    </MenuItem>
                                                    {eventTypes.map(eventItem => (
                                                        <MenuItem key={eventItem} value={eventItem}>
                                                            {eventItem}
                                                        </MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </DialogActions>
                            <DialogContent className={classes.dialogContent}>
                                {this.renderHistoricaltab()}
                            </DialogContent>
                            {errorMessage && <DialogContentText className={classes.errorText}>
                                {errorMessage}
                            </DialogContentText>}
                            <DialogActions>
                                {(this.state.physicalGatewayIds && this.state.physicalGatewayIds.length > 0) && <div className={classes.physicalGatewayIdsContainer}>{t('physical_gateway_ids', { 'ids': this.state.physicalGatewayIds.join(', ') })}</div>}
                                <Button disabled={loading} onClick={this.props.onClose} type="submit" color="primary">
                                    {t('close')}
                                </Button>
                            </DialogActions>
                        </Fragment>}
                </Dialog>
            </div>
        )
    }
}

DeviceEventDataDialog.propTypes = {
    api: PropTypes.any.isRequired,
    open: PropTypes.bool,
    device: PropTypes.any,
    onClose: PropTypes.func.isRequired
}


const styles = theme => ({
    dialogPaper: {
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: 20
    },
    header: {
        backgroundColor: theme.palette.secondary.main,
    },
    physicalGatewayIdsContainer: {
        flex: 1,
        marginLeft: 20,
        fontSize: theme.font.small
    },
    tabs: {
        paddingBottom: 10
    },
    eventValueCell: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 200,
        fontSize: theme.font.large
    },
    eventDate: {
        fontSize: theme.font.small
    },
    selectFormControl: {
        marginTop: 19
    },
    progress: {
        position: 'absolute',
        width: '100%'
    },
    dialogContent: {
        padding: 4,
        paddingTop: 8,
    },
    chartRoot: {
        // width: '50%',
        margin: '0 auto'
    },
    chartNoDataRoot: {
        padding: 20,
        minHeight: CHART_HEIGHT + 53
    },
    historicalFilterRoot: {
        overflow: 'hidden'
    }
})


export default withTranslation()(withStyles(styles)(DeviceEventDataDialog))