import React, { useState, useContext } from 'react'
import { ApiContext } from '../../../../m2m-cloud-api/MessageLog/ApiContext'
import { mapErrorMessage } from '../../../../Libs/Utilities/ApiHelper'
import { isNotFoundError } from '../../../../m2m-cloud-api/Api/Helper'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, LinearProgress } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
}))

function ReportDialog ({message, onSuccess, onCancel}) {
    const { t } = useTranslation()
    const context = useContext(ApiContext)
    const classes = useStyles()
    const [val, setVal] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const submit = () => {
        setLoading(true)
        context.setMessageAcknowledgedReport(message.getId(), val)
            .then(result => {
                setErrorMessage(null)
                setLoading(false)
                onSuccess()
            })
            .catch(error => {
                console.warn('setMessageAcknowledged - error', error)
                let message = mapErrorMessage(error)
                if (isNotFoundError(error)) {
                    message = t('error_message_not_found')
                }
                setErrorMessage(message)
                setLoading(false)
            })
    }

    return (
        <Dialog open={true} fullWidth maxWidth={'xs'} aria-labelledby="alert">
            { loading && <LinearProgress /> }
            <DialogTitle className={classes.dialogTitle} id="alert-title">{t('report')}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    value={val}
                    disabled={loading}
                    onChange={ (event) => setVal(event.target.value)}
                    label={t('report')}
                    fullWidth
                />
                {errorMessage && <DialogContentText style={{marginTop: 20, whiteSpace: 'pre-line'}} color="error">{errorMessage}</DialogContentText>}
            </DialogContent>  
            <DialogActions>
                { onCancel && <Button disabled={loading} onClick={onCancel} color="default">
                    {t('cancel')}
                </Button> }
                <Button disabled={!val || val.trim() === "" || loading} onClick={() => submit()} color={"primary"}>
                    {t('submit')}
                </Button>
            </DialogActions>  
        </Dialog>
    )
}

export default ReportDialog