import React, { Suspense } from "react"
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom"
import i18n from '../../i18n'
import ApiProvider from '../../m2m-cloud-api/MessageLog/ApiContext'
import WebStorage from '../../m2m-cloud-api/Storage/WebStorage'
import DefaultRoute from './DefaultRoute'
import ProtectedRoute from './ProtectedRoute'

import Loading from '../../Libs/Pages/Loading'

import Auth from '../../Libs/Pages/User/Auth'
import PasswordReset from '../../Libs/Pages/User/PasswordReset'
import Activate from '../../Libs/Pages/User/Activate'
import Signup from '../../Libs/Pages/User/Signup'
import { service } from '../../index'
import SelectScreen from './Pages/SelectScreen'
import SupplierScreen from './Pages/SupplierScreen'
import RecipientScreen from './Pages/RecipientScreen'


export const getBasePath = () => {
    return '/'
}

export const getPath = (path) => {
    const rootPath = getBasePath()
    if (!path || path === '') return rootPath
    return rootPath === '/' ? rootPath + path : rootPath + '/' + path
}

export const MAX_COMNTENT_WIDTH = 1600

let apiContext = null

function AppRouter() {

    return (
        <Suspense fallback={<Loading />}>
            <Router>
                <ApiProvider ref={(ref) => apiContext = ref}
                    domain={service.api.domain}
                    storage={new WebStorage()}
                    debugEnabled={false}
                    onLogout={() => {
                        //window.location.href = `/auth?redirect=${APP_ENTRY_POINT_URL}`
                    }}
                    onReady={(user) => {
                        const userLanguage = user && user.getUserLanguage()
                        if (userLanguage) {
                            i18n.changeLanguage(userLanguage)
                        }
                    }}>
                    <Switch>
                        <DefaultRoute path="/auth" exact component={Auth} />
                        <DefaultRoute path="/password-reset/:email?/:code?" exact component={PasswordReset} />
                        <DefaultRoute path="/signup" exact component={Signup} />
                        <DefaultRoute path="/activate/:id/:code?" exact component={Activate} />
                        <ProtectedRoute path={getPath('supplier')} exact component={SupplierScreen} />
                        <ProtectedRoute path={getPath('recipient')} exact component={RecipientScreen} />
                        <ProtectedRoute path={getPath()} exact component={SelectScreen} />
                        <ProtectedRoute path="/">
                            <Redirect to={getPath()} />
                        </ProtectedRoute>
                    </Switch>
                </ApiProvider>
            </Router>
        </Suspense>
    )
}

export default AppRouter