import React, { useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { lightTheme } from '../../../theme'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import packageJson from '../../../../package.json'
import ls from "local-storage"

const APP_VERSION_STORAGE_KEY = 'app-version'

const shouldWhatsNewModalVisible = () => {
    const storageVersion = ls.get(APP_VERSION_STORAGE_KEY)
    const currentVersion = packageJson.version
    if (!storageVersion || storageVersion !== currentVersion) {
        return true
    }
    return false
}

const setWhatsNewModalShown = () => {
    const currentVersion = packageJson.version
    ls.set(APP_VERSION_STORAGE_KEY, currentVersion)
}


const WhatsNewModal = ({ t }) => {
    const classes = useStyles()
    const iframeRef = useRef()
    const [open, setOpen] = useState(shouldWhatsNewModalVisible())

    if (!open) return (null)

    const onLoadIframe = () => {
        if (iframeRef.current?.contentWindow) {
            const lang = i18n.language
            iframeRef.current.contentWindow.handleDocument(lang)
        }
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <Dialog open={open} PaperProps={{ className: classes.dialogContainer }}>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <iframe onLoad={onLoadIframe} ref={iframeRef} src={'/whats-new/Web.bundle/'} frameBorder={0} className={classes.iframe} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpen(false); setWhatsNewModalShown() }}>
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        width: '100%',
        maxWidth: 480
    },
    iframe: {
        width: '100%',
        minHeight: 500
    }
}))

export default withTranslation()(WhatsNewModal)