import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemSecondaryAction, IconButton, ListItemText, Drawer, Divider, Menu, MenuItem, ListItemIcon, Typography, Checkbox, Box } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreIcon from '@material-ui/icons/MoreVert'
import ResetIcon from '@material-ui/icons/SettingsBackupRestore'
import LinkIcon from '@material-ui/icons/Link'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactSVG from 'react-svg'

function TriggerListItem({ entity, triggerUrl, checked, onClick, onScheduleEditClick, onResetClick, onDeleteClick, onChangeOrderpointClick, badgeLabel, titleBadgeLabel, customSortEnabled, paramsLabel, ...otherProps }) {
    const classes = useStyles()
    const [menuAnchorEl, setMenuAnchorEl] = useState(null)
    const { t } = useTranslation()

    const callOnDeleteClick = (event) => {
        setMenuAnchorEl(null)
        onDeleteClick(entity)
    }
    const callOnResetClick = (event) => {
        setMenuAnchorEl(null)
        onResetClick(entity)
    }
    const callOnChangeOrderpointClick = (event) => {
        setMenuAnchorEl(null)
        onChangeOrderpointClick(entity)
    }

    const callOnScheduleEditClick = (event) => {
        setMenuAnchorEl(null)
        onScheduleEditClick(entity)
    }

    return (
        <Fragment>
            <ListItem {...otherProps} className={classes.listItem}>
                {customSortEnabled && <ListItemIcon className={classes.dragListItemIcon}>
                    <ReactSVG src={"/assets/light/drag.svg"} className={classes.dragIcon} />
                </ListItemIcon>}
                <ListItemIcon className={classes.listCheckboxItemIcon}>
                    <Checkbox
                        edge="start"
                        color="primary"
                        checked={checked}
                        onClick={() => onClick(entity)}
                        tabIndex={-1}
                        inputProps={{ 'aria-labelledby': entity.id }}
                    />
                </ListItemIcon>
                <ListItemText
                    className={classes.listItemText}
                    primary={
                        <Typography variant="subtitle2">
                            <Box display={'flex'} alignItems={'center'}>
                                <div>{entity.title}</div>
                                {paramsLabel && <Typography className={`${classes.listItemTextSecondary} paramsLabel`} variant="subtitle2">{paramsLabel}</Typography>}
                            </Box>
                            {titleBadgeLabel && <div className={classes.titleBadge}>{titleBadgeLabel}</div>}
                        </Typography>
                    }
                    secondary={badgeLabel && <Typography className={classes.listItemTextSecondary} variant="subtitle2">{badgeLabel}</Typography>}
                />
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                    <IconButton
                        className={classes.moreIconButton}
                        size={'small'}
                        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
                        edge="end">
                        <MoreIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            { menuAnchorEl && <Menu
                id="menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}>
                {onScheduleEditClick && <MenuItem
                    onClick={callOnScheduleEditClick}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('edit_schedule')}</Typography>
                </MenuItem>}
                {onChangeOrderpointClick && <MenuItem
                    onClick={callOnChangeOrderpointClick}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ReactSVG src="/assets/weight_limit.svg" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('change_order_weight')}</Typography>
                </MenuItem>}
                {onResetClick && <MenuItem
                    onClick={callOnResetClick}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <ResetIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('reset')}</Typography>
                </MenuItem>}
                {triggerUrl &&
                    <CopyToClipboard text={triggerUrl}>
                        <MenuItem
                            onClick={(event) => setMenuAnchorEl(null)}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <LinkIcon fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit"> {t('copy_event_trigger_link')}</Typography>
                        </MenuItem>
                    </CopyToClipboard>}
                <MenuItem
                    onClick={callOnDeleteClick}>
                    <ListItemIcon className={classes.listItemIcon}>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"> {t('delete')}</Typography>
                </MenuItem>
            </Menu>}
        </Fragment>
    )
}


const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25)
    },
    listItemText: {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 40,
        alignItems: 'center'
    },
    listItemTextSecondary: {
        backgroundColor: theme.palette.grey['100'],
        textAlign: 'right',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        '&.paramsLabel': {
            fontStyle: 'italic',
            marginLeft: theme.spacing(1)
        }
    },
    listItemSecondaryAction: {
        right: theme.spacing(1)
    },
    moreIconButton: {
        color: 'inherit',
    },
    titleBadge: {
        display: 'inline-block',
        backgroundColor: theme.palette.secondary.main,
        padding: '0px 4px',
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        fontSize: theme.font.small,
        marginRight: theme.spacing(0.5)
    },
    listItemIcon: {
        minWidth: 35
    },
    listCheckboxItemIcon: {
        minWidth: 'auto'
    },
    dragListItemIcon: {
        minWidth: 'auto', 
        marginRight: 8
    },
    dragIcon: {
        width: 24, 
        height: 24
    }

}))

export default TriggerListItem