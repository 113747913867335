import React, { Component, Fragment, useState, useEffect, useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import ReactSVG from 'react-svg'
import { Typography, LinearProgress, IconButton, Grid } from '@material-ui/core'
import DefaultListItem from './DefaultListItem'
import ReplacementsListItem from './ReplacementsListItem'
import { ListContainer, ItemContainer } from '../../../Libs/Components/ListContainer'
import { Virtuoso } from 'react-virtuoso'
import { REPLACEMENT_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import { PARAM_APP_MSG_ITEM_SORT } from '../../../m2m-cloud-api/Api/OrgService/Models/Org'
import { SECONDARY_COLOR } from '../../../theme'
import { ORG_PARAM_KEY_REPLACEMENT } from '../../../m2m-cloud-api/MessageLog/Contants'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { sortItems } from '../../../m2m-cloud-api/MessageLog/ApiContext'

const getListItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        background: "rgba(255,255,255,.5)"
    }),
})
const DraggableItem = withTranslation()(({ t, provided, index, snapshot, item, groupOrgId, checked, onClick, onEditClick, onDeleteClick, onCopyUidClick, customSortEnabled }) => {
    const context = useContext(PageContext)

    const fetchReplacementTypeName = (replacement) => {
        switch (replacement.getType()) {
            case REPLACEMENT_TYPE.TEXT:
                return 'text_value'
            case REPLACEMENT_TYPE.INTEGER:
                return 'integer_value'
            case REPLACEMENT_TYPE.ENUM:
                return 'list_of_values'
            case REPLACEMENT_TYPE.DATE:
                return 'date'
            case REPLACEMENT_TYPE.QR_CODE:
                return 'qrcode_value'
            case REPLACEMENT_TYPE.BAR_CODE:
                return 'barcode_value'
            case REPLACEMENT_TYPE.IMAGE:
                return 'image'
            case REPLACEMENT_TYPE.REPORT:
                return 'report'
            case REPLACEMENT_TYPE.NOTE:
                return 'note'
            default:
                return null
        }
    }



    return (
        <div {...provided.draggableProps} {...provided.dragHandleProps} style={getListItemStyle(snapshot.isDragging, provided.draggableProps.style)} ref={provided.innerRef}>

            <ReplacementsListItem
                index={index}
                entityType={t(fetchReplacementTypeName(item))}
                entity={item}
                checked={checked}
                onClick={onClick}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                onCopyUidClick={onCopyUidClick}
                customSortEnabled={customSortEnabled} />
        </div>
    )
})

class ReplacementsList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedIds: [],
            customSortEnabled: null
        }
    }

    componentDidMount() {
        this.checkAndSetCustomSortEnabled()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.orgId !== this.props.orgId) {
            this.checkAndSetCustomSortEnabled()
            this.setState({ selectedIds: [] })
        }
    }

    checkAndSetCustomSortEnabled() {
        const org = this.context.childrenOrgs.find(org => this.props.orgId === org.getId())
        const replacementSortParam = org?.getParam(this.prepareSortParam())
        this.setState({ customSortEnabled: replacementSortParam !== null && replacementSortParam !== undefined ? true : false })
    }

    onClickItem = (entity) => {
        const { selectedIds } = this.state

        if (selectedIds.find(id => id === entity.id)) {
            this.setState({ selectedIds: selectedIds.filter(id => id !== entity.id) }, () => this.props.onSelectionChange(selectedIds))
        } else {
            this.setState({ selectedIds: [...selectedIds, entity.id] }, () => this.props.onSelectionChange(selectedIds))
        }
    }

    prepareSortParam() {
        return `${PARAM_APP_MSG_ITEM_SORT}-${ORG_PARAM_KEY_REPLACEMENT}`
    }


    setOrgItemSortType(customSortEnabled) {
        const { orgId } = this.props

        this.setState({ customSortEnabled }, async () => {
            try {
                const paramsToUpdate = {}
                if (customSortEnabled) {
                    const promises = [this.context.api.orgService.putParam(orgId, this.prepareSortParam(), '')]
                    paramsToUpdate[this.prepareSortParam()] = ''
                    await this.context.updateAndCacheTopOrg(orgId, promises)
                } else {
                    const promises = [this.context.api.orgService.deleteParam(orgId, this.prepareSortParam())]
                    paramsToUpdate[this.prepareSortParam()] = undefined
                    await this.context.updateAndCacheTopOrg(orgId, promises)
                }
                await this.context.updateCachedOrgParams(orgId, paramsToUpdate)
            } catch (error) {
                this.handleResponseError(error)
            }
        })
    }

    async setOrgItemSorting(orgId, itemIds) {
        try {
            const promises = [this.context.api.orgService.putParam(orgId, this.prepareSortParam(), itemIds.join(','))]
            await this.context.updateCachedOrgParams(orgId, { [this.prepareSortParam()]: itemIds.join(',') })
        } catch (error) {
            this.handleResponseError(error)
        }
    }

    render() {
        const { t, classes, items, searchTerm, orgId } = this.props
        const { selectedIds, customSortEnabled } = this.state

        if (!items) return (<LinearProgress />)

        let filteredItems = items.filter(entity => entity.getName().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0)

        const org = orgId && this.context.childrenOrgs.find(org => org.getId() === orgId)
        const deviceSortParam = org && org.getParam(this.prepareSortParam())
        filteredItems = sortItems(deviceSortParam, filteredItems)

        const onDragEnd = (result) => {
            //send to params
            if (!result.destination) {
                return
            }
            const items = reorder(
                filteredItems,
                result.source.index,
                result.destination.index
            );
            const itemIds = items.map(item => item.getId())
            this.setOrgItemSorting(orgId, itemIds)
            filteredItems = items
        }

        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list)
            const [removed] = result.splice(startIndex, 1)
            result.splice(endIndex, 0, removed)
            return result
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 'inherit' }}>
                <Grid container className={classes.listTitleContainer}>
                    <Typography variant="h5" className={classes.listTitle}>{t('replacements')}</Typography>
                    <Grid container alignItems="center" className={classes.sortTypeContainer}>
                        <IconButton
                            edge="end"
                            size="small"
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            style={{ marginRight: 4 }}
                            className={!customSortEnabled && classes.activeSortType}
                            onClick={() => this.setOrgItemSortType(false)}>
                            <ReactSVG
                                style={{ width: 24, height: 24 }}
                                src={"/assets/light/sorting_ascending.svg"}
                            />
                        </IconButton>
                        <IconButton
                            edge="end"
                            size="small"
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            className={customSortEnabled && classes.activeSortType}
                            onClick={() => this.setOrgItemSortType(true)}>
                            <ReactSVG
                                style={{ width: 24, height: 24 }}
                                src={"/assets/light/sorting_manual.svg"}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
                <div style={{ flex: 1 }}>
                    {items.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body">{t('no_entries_available')}</Typography>}
                    {items.length > 0 && filteredItems.length === 0 && <Typography style={{ marginLeft: 24, marginTop: 16 }} color={'textSecondary'} variant="body2">{t('no_match_found')}</Typography>}


                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="droppable-replacements-list"
                            mode="virtual"
                            renderClone={(provided, snapshot, rubric) => (
                                <DraggableItem
                                    provided={provided}
                                    snapshot={snapshot}
                                    item={filteredItems[rubric.source.index]}
                                    index={rubric.source.index}
                                    checked={selectedIds.find(id => id === filteredItems[rubric.source.index].id) ? true : false}
                                    onClick={this.onClickItem}
                                    onEditClick={(entity) => this.props.onEdit(entity)}
                                    onDeleteClick={(entity) => this.props.onDelete(entity)}
                                    onCopyUidClick={(entity) => console.log('onCopyUidClick')}
                                    customSortEnabled={customSortEnabled} />

                            )}
                        >


                            {(provided, snapshot) => (
                                <Fragment>
                                    <Virtuoso
                                        components={{
                                            Item: ItemContainer,
                                        }}
                                        style={{ listStyleType: 'none' }}
                                        scrollerRef={provided.innerRef}
                                        totalCount={filteredItems && filteredItems.length || 0}
                                        data={filteredItems}
                                        itemContent={(index, item) =>
                                            <Draggable key={filteredItems[index].id} draggableId={filteredItems[index].id} index={index} isDragDisabled={!customSortEnabled}>
                                                {(provided, snapshot) => (
                                                    <DraggableItem
                                                        provided={provided}
                                                        snapshot={snapshot}
                                                        item={item}
                                                        index={index}
                                                        checked={selectedIds.find(id => id === filteredItems[index].id) ? true : false}
                                                        onClick={this.onClickItem}
                                                        onEditClick={(entity) => this.props.onEdit(entity)}
                                                        onDeleteClick={(entity) => this.props.onDelete(entity)}
                                                        onCopyUidClick={(entity) => console.log('onCopyUidClick')}
                                                        customSortEnabled={customSortEnabled} />
                                                )}
                                            </Draggable>
                                        }
                                        Footer={() => (
                                            <div style={{ height: 80, width: '100%' }}>
                                            </div>
                                        )}>
                                    </Virtuoso>
                                    {provided.placeholder}
                                </Fragment>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        )
    }
}

ReplacementsList.contextType = PageContext

ReplacementsList.propTypes = {
    items: PropTypes.array,
    searchTerm: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    orgId: PropTypes.string.isRequired
}

const styles = theme => ({
    listTitleContainer: {
        alignItems: 'center',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        marginBottom: theme.spacing(1)
    },
    listTitle: {
        flex: 1,
        color: theme.palette.grey['900'],
        fontWeight: theme.typography.fontWeightMedium
    },
    sortTypeContainer: {
        width: 'auto'
    },
    activeSortType: {
        backgroundColor: SECONDARY_COLOR,
        color: theme.palette.common.white,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: SECONDARY_COLOR,
        }
    },
})


export default withTranslation()(withStyles(styles)(ReplacementsList))