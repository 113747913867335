import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { ApiContext } from '../../../m2m-cloud-api/MessageLog/ApiContext'
import CloseIcon from '@material-ui/icons/Close'
import Loading from '../../../Libs/Pages/Loading'
import ReplacementImage from './ReplacementImage'

const ImageModal = ({ open, onClose, replacement }) => {
    const classes = useStyles()
    const [loading, setLoading] = useState(true)

    return (
        <Dialog fullWidth open={open} PaperProps={{ style: { maxWidth: '100%', height: '100%' } }} onClose={() => void (0)}>
            <DialogTitle className={classes.dialogTitle} variant="h6" disableTypography >
                <Typography variant="h6" style={{ flexGrow: 1 }}>{replacement && replacement.replacement.getName()}</Typography>
                <IconButton onClick={() => onClose()}>
                    <CloseIcon style={{ color: '#fff' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                { loading && <Loading /> }
                <ReplacementImage onLoad={() => setLoading(false) } className={classes.imageFile} replacement={replacement} size={{width: 1024, height: 1024 }} /> 
            </DialogContent>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    },
    dialogContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageFile: {
        maxWidth: 1024,
        maxHeight: 1024,
        width: '100%',
        height: '100%'
    }
}))

export default ImageModal